import { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { triggerBase64Download } from 'common-base64-downloader-react'

import { covertFileIntoBase64 } from '../../../helper'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const PreviewFile = ({ file, onDownLoadFile,onRemoveFile }) => {
  const fileType = file.slice(file?.indexOf('/') + 1, file?.indexOf(';'))

  if (fileType === 'pdf') {
    return <div>Pdf</div>
  } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
    return (
      <>
        <div>
          <img src={file} height={100} style={{maxWidth:'100%',overflow:'hidden'}}></img>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            onClick={() => {
              onDownLoadFile()
            }}
            style={{ cursor: 'pointer', color: 'blue' }}>
            Click here to download File
          </span>
          <span
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              onRemoveFile()
            }}>
            Remove File
          </span>
        </div>
      </>
    )
  }
}

const fileTypes = ['PDF', 'EPS', 'JPG', 'JPEG', 'TIFF', 'GIF', 'PNG', 'ZIP']

const FileUpload = ({ item, onFileChange = () => {}, answers, setAnswers, isCommonField = false, requiredError, isReadOnly }) => {
  const [file, setFile] = useState(null)
  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  const handleChange = (file) => {
    covertFileIntoBase64(file).then((data) => {
      onFileChange(data)
      if (!isCommonField) {
        const old_answers = [...answers]
        const updated_answers = old_answers?.map((question) => {
          if (question?.item_question_id === item?.id) {
            return {
              ...question,
              question_type: 'upload',
              upload_base64: data,
            }
          } else {
            return question
          }
        })

        setAnswers(updated_answers)
      }
    })
  }

  const onDownLoadFile = () => {
    triggerBase64Download(file, (Math.random() + 1).toString(36).substring(7))
  }

  const onRemoveFile = () => {
    onFileChange(undefined)
    if (!isCommonField) {
      const old_answers = [...answers]
      const updated_answers = old_answers?.map((question) => {
        if (question?.item_question_id === item?.id) {
          return {
            ...question,
            question_type: 'upload',
            upload_base64: null,
          }
        } else {
          return question
        }
      })
      setAnswers(updated_answers)
    }
  }

  useEffect(() => {
    // console.log("current object:::::::",current_answer_object)
    setFile(current_answer_object?.upload_base64)
  }, [JSON.stringify(current_answer_object)])

  const fileType = file?.slice(file?.indexOf('/') + 1, file?.indexOf(';'))
  let fileIsImage
  if (file && fileType && (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png')) {
    fileIsImage = true
  } else {
    fileIsImage = false
  }

  return (
    <div>
      {item?.question && <div style={{ marginBottom: '1rem' }}>{item?.question}</div>}
      <FileUploader handleChange={handleChange} name='file' types={fileTypes} multiple={false} fileOrFiles={file} />
      {file && !fileIsImage && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            onClick={() => {
              if (fileIsImage) {
                setPreviewModalOpen(!previewModalOpen)
              } else {
                onDownLoadFile()
              }
            }}
            style={{ cursor: 'pointer' }}>
            {fileIsImage ? 'Preview' : 'Click here to download'}
          </span>
          <span
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              onRemoveFile()
            }}>
            Remove File
          </span>
        </div>
      )}
      {file && fileIsImage && <PreviewFile file={file} onDownLoadFile={onDownLoadFile} onRemoveFile={onRemoveFile} />}
      {item?.validations?.includes('required') &&
        requiredError &&
        (!current_answer_object?.upload_base64 || current_answer_object?.upload_base64?.length === 0) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
      <Modal isOpen={previewModalOpen} toggle={() => setPreviewModalOpen(!previewModalOpen)} style={{ width: 500 }}>
        <ModalHeader toggle={() => setPreviewModalOpen(!previewModalOpen)}>Preview</ModalHeader>
        <ModalBody></ModalBody>
      </Modal>
    </div>
  )
}

export default FileUpload
