import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from 'react-redux'
import { submitRequest } from '../../Redux/Reducers/CMS'

import { authLogout } from '../../Redux/Reducers/user'

const select = (state) => ({
  isAuth: state.User.isAuth,
})

const HandleLogout = ({ isAuth }) => {
  const dispatch = useDispatch()
  const Queue = useSelector((state) => state.Queue)

  useEffect(() => {
    if (isAuth) {
      const queues = Queue.queues
      if (queues?.length > 0) {
        queues?.map((queue, index) => {
          setTimeout(() => {
            dispatch(
              submitRequest({
                url: queue?.url,
                data: queue?.payload?.data,
                queue: true,
              })
            )
          }, 800 * index)
        })
        setTimeout(() => {
          dispatch(authLogout())
        }, 800 * queues.length + 1)
      } else {
        dispatch(authLogout())
      }
    }
  }, [])
  return <div className='text-center mt-5'>Logging out...</div>
}

export default connect(select)(HandleLogout)
