import { request } from './api'

export function requestThemeOptions() {
  return request('theme', 'get')
}

export function updateThemeOption(option) {
  return request('edit-theme', 'post', option)
}

export function addCustomColor(option) {
  return request('save-custom-color', 'post', option)
}

export function delCustomColor(option) {
  return request('delete-custom-color', 'post', option)
}
