import React, { Fragment, Component, useState } from 'react'

import { Col, Row, Button, Card, Nav, NavLink, NavItem, Dropdown } from 'reactstrap'

import { cms, renderBlockColorClass, renderBlockColorStyle, renderBlockStyle, renderTextColorClass } from '../../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { clearCurrentRowAllData, clearCurrentRowData, clearParentTableData, setParentTableData } from '../../../../Redux/Reducers/CMS'

const MailSidebar = ({
  page,
  setAddDataDetail,
  hidden_tab,
  show_tabs_on_load,
  setSelectedGridData,
  selectedGridData,
  setAddDataDetailToEdit,
  setIsChildTabSelected,
  isChildTabSelected,
  addDataDetail,
  addDataDetailToEdit,
}) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const primaryColor = useSelector((theme) => theme.ThemeOptions.primaryColor)
  const parentTableData = useSelector((state) => state.CMS.parentTableData)
  const linkClassName = renderTextColorClass(primaryColor)?.split(' ')[0] + '-text'

  const dispatch = useDispatch()

  console.log('add data details::::::::::', addDataDetail)

  return (
    <Card className='app-inner-layout__sidebar' style={{ position: 'relative' }}>
      <Nav vertical>
        <NavItem className='pt-4 pl-3 pr-3 pb-3'>
          <Button
            block
            color='primary'
            className={`btn-pill btn-shadow ${renderBlockColorClass(primaryColor)}`}
            onClick={() => {
              const addDataDetail = {
                open: true,
                mainGrid: hidden_tab?.component_call_form?.[0]?.main_grid,
                tableGrid: hidden_tab?.component_call_form?.[0],
                pageName: hidden_tab?.name,
                parentPageName: hidden_tab?.name,
                isTab: false,
                isAddNew: true,
              }
              setAddDataDetail(addDataDetail)
              if(currentRow?.length === 2){
                dispatch(clearCurrentRowData())
              }
              if (parentTableData?.length > 1) {
                dispatch(clearParentTableData())
              }
              setAddDataDetailToEdit()
            }}
            style={renderBlockStyle(primaryColor)}>
            Write New Email
          </Button>
        </NavItem>
        <NavItem className='nav-item-header'>My Account</NavItem>

        <Dropdown toggle={() => {}} isOpen={true} className='email-sidebar'>
          {hidden_tab && (
            <div
              className={`p-2 pl-3 cursor-pointer ${
                selectedGridData?.currentTabData?.name === hidden_tab?.name &&
                selectedGridData?.selectedGrid !== 'restore_grid' &&
                !addDataDetailToEdit?.open &&
                linkClassName
              }`}
              // active={selectedGridData?.currentTabData?.name === hidden_tab?.name && selectedGridData?.selectedGrid !== 'restore_grid' ? true : false}
              onClick={() => {
                if (currentRow?.length > 1) {
                  dispatch(clearCurrentRowData())
                }
                if (parentTableData?.length > 1) {
                  dispatch(clearParentTableData())
                }
                setAddDataDetailToEdit()
                setIsChildTabSelected(false)
                setSelectedGridData({
                  tableGrid: hidden_tab?.component_call_form?.[0],
                  selectedGrid: 'main_grid',
                  page_data: page,
                  currentTabData: hidden_tab,
                  parentPageName: hidden_tab?.name,
                  pageName: hidden_tab?.name,
                  content: hidden_tab?.component_call_form?.[0],
                })
              }}
              style={
                selectedGridData?.currentTabData?.name === hidden_tab?.name && selectedGridData?.selectedGrid !== 'restore_grid'
                  ? renderBlockColorStyle(primaryColor)
                  : {}
              }>
              <div className='d-flex align-items-center'>
                <div>
                  <i className={`nav-link-icon ${hidden_tab?.icon}`}> </i>
                  <span className='ml-2'>{cms(hidden_tab?.cms_tab_key)}</span>
                </div>

                {/* <div className='ml-auto badge badge-pill badge-info'>8</div> */}
              </div>
            </div>
          )}
          {currentRow?.length > 1 && (
            <div
              className={`mb-1 p-2 pl-3 cursor-pointer ${!isChildTabSelected && linkClassName}`}
              // active={selectedGridData?.tableGrid?.name === tab?.name ? true : false}
              onClick={() => {
                setIsChildTabSelected(false)
              }}>
              <div className='d-flex align-items-center'>
                <div>
                  <i className={`nav-link-icon fa-solid fa-message`}> </i>
                  <span className='ml-2'>Message</span>
                </div>
                {/* <div className='ml-auto badge badge-pill badge-info'>8</div> */}
              </div>
            </div>
          )}

          {show_tabs_on_load && 
            currentRow?.length > 1 &&
            show_tabs_on_load
              ?.filter((tab) => tab.component_call_form?.length > 0)
              ?.map((tab) => {
                return (
                  <div
                    className={`mb-1 p-2 pl-3 cursor-pointer ${
                      selectedGridData?.tableGrid?.name === tab?.name && isChildTabSelected && linkClassName
                    }`}
                    // active={selectedGridData?.tableGrid?.name === tab?.name ? true : false}
                    onClick={() => {
                      setIsChildTabSelected(true)
                      setSelectedGridData({
                        tableGrid: tab?.component_call_form?.[0],
                        selectedGrid: 'main_grid',
                        page_data: page,
                        currentTabData: tab,
                        parentPageName: hidden_tab?.name,
                        pageName: tab?.name,
                        content: tab?.component_call_form?.[0],
                      })
                    }}>
                    <div className='d-flex align-items-center'>
                      <div>
                        <i className={`nav-link-icon ${tab?.icon}`}> </i>
                        <span className='ml-2'>{cms(tab?.cms_tab_key)}</span>
                      </div>

                      {/* <div className='ml-auto badge badge-pill badge-info'>8</div> */}
                    </div>
                  </div>
                )
              })}
          <NavItem className='nav-item-divider' />

          <div
            className={`p-2 pl-3 cursor-pointer ${
              selectedGridData?.currentTabData?.name === hidden_tab?.name && selectedGridData?.selectedGrid === 'restore_grid' && linkClassName
            }`}
            // active={selectedGridData?.currentTabData?.name === hidden_tab?.name && selectedGridData?.selectedGrid === 'restore_grid' ? true : false}
            onClick={() => {
              if (currentRow?.length > 1) {
                dispatch(clearCurrentRowData())
              }
              if (parentTableData?.length > 1) {
                dispatch(clearParentTableData())
              }
              setAddDataDetailToEdit()
              setIsChildTabSelected(false)
              setSelectedGridData({
                tableGrid: hidden_tab?.component_call_form?.[0],
                selectedGrid: 'restore_grid',
                page_data: page,
                currentTabData: hidden_tab,
                parentPageName: hidden_tab?.name,
                pageName: hidden_tab?.name,
                content: hidden_tab?.component_call_form?.[0],
              })
            }}
            style={
              selectedGridData?.currentTabData?.name === hidden_tab?.name && selectedGridData?.selectedGrid === 'restore_grid'
                ? renderBlockColorStyle(primaryColor)
                : {}
            }>
            <div className='d-flex align-items-center'>
              <div>
                <i className='nav-link-icon pe-7s-box2'> </i>
                <span className='ml-2'>Trash</span>
              </div>
            </div>
          </div>
          {/* <DropdownItem
            active={selectedGridData?.currentTabData?.name === hidden_tab?.name ? true : false}
            // onClick={() => {
            //   setSelectedGridData({
            //     tableGrid: hidden_tab?.component_call_form?.[0],
            //     selectedGrid: 'main_grid',
            //     page_data: page,
            //     currentTabData: hidden_tab,
            //     parentPageName: hidden_tab?.name,
            //     pageName: hidden_tab?.name,
            //     content: hidden_tab?.component_call_form?.[0],
            //   })
            // }}
          >
            <div className='d-flex align-items-center'>
              <div>
                <i className='nav-link-icon pe-7s-chat'> </i>
                <span className='ml-2'>Others</span>
              </div>
            </div>
          </DropdownItem> */}
        </Dropdown>

        <NavItem className='nav-item-divider' />
        {/* 
        <NavItem className='nav-item-header'>
          Tags
          <small className='ml-1'>Important</small>
        </NavItem>
        <NavItem>
          <DropdownItem className='d-flex align-items-center'>
            <div className='badge ml-0 mr-3 badge-dot badge-dot-xl badge-success'>Dark</div>
            Important
          </DropdownItem>
          <DropdownItem className='d-flex align-items-center'>
            <div className='badge ml-0 mr-3 badge-dot badge-dot-xl badge-danger'>Dark</div>
            Favourites
          </DropdownItem>
          <DropdownItem className='d-flex align-items-center'>
            <div className='badge ml-0 mr-3 badge-dot badge-dot-xl badge-warning'>Dark</div>
            Actions
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className='d-flex align-items-center'>
            <div className='badge ml-0 mr-3 badge-dot badge-dot-xl badge-primary'>Dark</div>
            Other Tags
          </DropdownItem>
        </NavItem> */}
      </Nav>
      {/* <Modal isOpen={addDataDetail?.open} toggle={() => setAddDataDetail()} style={{paddingBottom:'10rem'}}>
        <ModalHeader toggle={() => setAddDataDetail()}>Sent Mail</ModalHeader>
        <ModalBody>
          <AddGridData addDataDetail={addDataDetail} setAddGridDataDetail={setAddDataDetail} hideTitle={true} clearCurrentRowOnBack={false} />
        </ModalBody>
      </Modal> */}
    </Card>
  )
}

export default MailSidebar
