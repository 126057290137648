import { AvField } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { cms, tidyName } from '../../../../helper'
import IconSet from '../../../icon'

const RenderValueField = ({ field, value, onChangeForm, form }) => {

  switch (field?.component_name) {
    case 'toggle_switch':
      return (
        <AvField type='select' label='value' name='value' value={form?.value} onChange={(e) => onChangeForm(e.target.value, 'value')}>
          <option>Select Value</option>
          <option value={1}>True</option>
          <option value={0}>False</option>
        </AvField>
      )
    default:
      return <AvField type='text' name='value' label='value'></AvField>
  }
}

const DependentForm = ({ allFields, key, form, dependentForm, setDependentForm, index }) => {
  const removeForm = () => {
    const old_form = [...dependentForm]
    old_form?.splice(index, 1)
    setDependentForm(old_form)
  }

  const onChangeForm = (value, field) => {
    const old_form = [...dependentForm]
    switch (field) {
      case 'dependent_field':
        old_form?.splice(index, 1, {
          ...form,
          dependent_field: value,
        })
        break
      case 'condition':
        old_form?.splice(index, 1, {
          ...form,
          condition: value,
        })
        break
      case 'value':
        old_form?.splice(index, 1, {
          ...form,
          value: value,
        })
        break
    }

    setDependentForm(old_form)
  }

  return (
    <>
      <Col md={12}>
        {index + 1}.{' '}
        <span
          style={{ color: 'red' }}
          onClick={() => {
            removeForm()
          }}>
          Remove Form
        </span>
      </Col>
      <Col md={4}>
        <AvField
          type='select'
          name='dependent_field'
          label='Select Field'
          value={form?.dependent_field}
          onChange={(e) => onChangeForm(e.target.value, 'dependent_field')}>
          <option value=''>Select Field</option>
          {allFields?.map((field,i) => {
            return <option key={i} value={field?.field_id}>{cms(field?.cms_key) || tidyName(field?.name)}</option>
          })}
        </AvField>
      </Col>
      <Col md={4}>
        <AvField type='select' name='condition' label='Condition' value={form?.condition} onChange={(e) => onChangeForm(e.target.value, 'condition')}>
          <option value=''>Select Condition</option>
          <option value='equals'>Equals</option>
          <option value='less_than'>Less Than</option>
          <option value='less_than_and_equal'>Less Than And Equal</option>
          <option value='greater_than'>Greater Than</option>
          <option value='greater_than_and_equal'>Greater Than And Equal</option>
        </AvField>
      </Col>
      <Col md={4}>
        <RenderValueField
          field={allFields?.filter((field) => field?.field_id === form?.dependent_field)[0]}
          value={form?.value}
          onChangeForm={onChangeForm}
          form={form}></RenderValueField>
      </Col>
    </>
  )
}

const AddDependentField = ({ allFields, updateDependentFormData = () => {}, dependentFormData,field }) => {
  const [dependentForm, setDependentForm] = useState([])
  const onAddField = () => {
    setDependentForm([
      ...dependentForm,
      {
        dependent_field: '',
        condition: '',
        value: '',
      },
    ])
  }

  useEffect(()=>{
    if(field?.field_setting?.depend_fields){
      setDependentForm(field?.field_setting?.depend_fields)
    }
  },[])


  useEffect(() => {
    updateDependentFormData(dependentForm)
  }, [dependentForm])

  
  return (
    <Row>
      <Col md={12} onClick={() => onAddField()}>
        <hr></hr>
        <span>Add Dependent Field</span>{' '}
        <span className='float-right' style={{ marginTop: '-0.5rem' }}>
          {' '}
          <Button color='success' onClick={() => onAddField()} className='btn m-1 float-right'>
            <IconSet icon={'fa-solid fa-plus'} color='light' />
          </Button>
        </span>{' '}
        <hr></hr>
      </Col>
      {dependentForm &&
        dependentForm?.length > 0 &&
        dependentForm?.map((form, index) => {
          return (
            <DependentForm
              allFields={allFields}
              form={form}
              dependentForm={dependentForm}
              setDependentForm={setDependentForm}
              key={index}
              index={index}
            />
          )
        })}
    </Row>
  )
}

export default AddDependentField
