import React, { useEffect, useState } from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Loader from 'react-loaders'
import { submitRequest } from '../../../Redux/Reducers/CMS'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import { ListGroupItem, UncontrolledCollapse } from 'reactstrap'
import cx from 'classnames'

const EditCompanyLogo = (props) => {
  const [load, updateLoad] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [reRender, setReRender] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [form, updateForm] = useState([])
  const dispatch = useDispatch()
  const domain = useSelector((state) => state.CMS.coreData.domains)
  useEffect(() => {
    // setCompanyName(domain.name);
    const domainData = props.domainLogo
    const data = []
    domainData?.forEach((item) => {
      const itemData = {
        domain_id: item.domain_id,
        logo: item.logo === null ? '' : item.logo,
        logo_default: item.logo_default,
        domain_name: item.domain_name,
      }
      data.push(itemData)
    })
    updateForm(data)
  }, [])

  const onDomainNameChange = (value, id) => {
    const data = form?.filter((item) => item.domain_id === id)[0]
    data.domain_name = value
  }

  const onLogoDefaultChange = (id) => {
    const temp = []
    const selectedData = form?.filter((item) => item.domain_id === id)[0]
    const remainingData = form?.filter((item) => item.domain_id !== id)
    if (!selectedData?.logo_default) {
      let i
      for (i of remainingData) {
        if (i.logo_default) {
          i.logo_default = !i.logo_default
        }
      }
      selectedData.logo_default = !selectedData?.logo_default
      setReRender(!reRender)
    } else {
      selectedData.logo_default = !selectedData?.logo_default
      setReRender(!reRender)
    }
  }

  const onFileChange = (e, id) => {
    const data = form?.filter((item) => item.domain_id === id)[0]
    const formData = new FormData()
    // formData.append("domain_name",data.domain_name)
    formData.append('id', data.domain_id)
    // formData.append("logo_default",data.logo_default)
    formData.append('file', e.target.files[0])
    request('file-upload/domain', 'POST', formData).then((res) => {
      data.logo = res.data.path
      setReRender(!reRender)
    })

    // let index = form?.indexOf(data)
    // form.splice(index,1,formData)

    // data.logo=e.target.files[0];
    // data=formData
    setSelectedFile(e.target.files[0])
  }

  const onRemoveLogo = (id) => {
    const data = form?.filter((item) => item.domain_id === id)
    data[0].logo = ''
    setReRender(!reRender)
  }

  const handleSubmit = () => {
    // const formData = new FormData();
    // if (selectedFile) {
    //   formData.append("logo", selectedFile, selectedFile?.name);
    // }
    // formData.append("name", companyName);
    // formData.append("noReload", false);
    dispatch(
      submitRequest({
        url: 'set-domains',
        data: { domains: form },
      })
    )
    props.onToggle()
  }
  const domainData = props.domainLogo

  return (
    <>
      <Modal isOpen={props.isOpen} toggle={() => props.onToggle()} style={{ width: 900 }}>
        <ModalHeader toggle={() => props.onToggle()}>Edit Company logo</ModalHeader>
        <ModalBody>
          <Container>
            <AvForm onSubmit={handleSubmit}>
              {domainData &&
                domainData?.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <ListGroupItem id={item.domain_name.replace(/ /g, '_')}>{item.domain_name}</ListGroupItem>
                      {item.domain_name && (
                        <UncontrolledCollapse toggler={'#' + item.domain_name.replace(/ /g, '_')}>
                          <div style={{ margin: '1rem' }}>
                            <Row>
                              <Col md={4}>
                                <AvField
                                  name='field_id'
                                  label='Domain name'
                                  type='text'
                                  value={form?.filter((data) => data.domain_id === item.domain_id)[0]?.domain_name}
                                  onChange={(e) => onDomainNameChange(e.target.value, item.domain_id)}
                                  // required
                                  // validate={{
                                  //   pattern: {
                                  //     value: "^[A-Za-z0-9_]+$",
                                  //     errorMessage:
                                  //       "Your name must be composed only with letter and numbers",
                                  //   },
                                  // }}
                                />
                              </Col>
                              <Col md={4}>
                                <AvField
                                  name='file_upload'
                                  label='Upload logo'
                                  type='file'
                                  onChange={(e) => onFileChange(e, item.domain_id)}
                                  multiple={false}
                                />
                              </Col>
                              <Col md={3}>
                                <div className='logo-container-edit-form'>
                                  <div>Logo</div>
                                  <div>
                                    {(form?.filter((data) => data.domain_id === item.domain_id)[0]?.logo === null ||
                                      form?.filter((data) => data.domain_id === item.domain_id)[0]?.logo === '') && (
                                      <em style={{ marginTop: '1rem' }}>Logo in not uploaded</em>
                                    )}
                                    {!(
                                      form?.filter((data) => data.domain_id === item.domain_id)[0]?.logo === null ||
                                      form?.filter((data) => data.domain_id === item.domain_id)[0]?.logo === ''
                                    ) &&
                                      form
                                        ?.filter((data) => data.idomain_idd === item.domain_id)
                                        ?.map((item, key) => (
                                          <img key={key} src={`${process.env.REACT_APP_STORAGE}${item?.logo}`} width='100px' height='40px' />
                                        ))}
                                  </div>
                                </div>
                              </Col>
                              <Col md={4}>
                                <span onClick={(e) => alert(item.domain_id)}>Logo default</span>
                                <div
                                  className='switch has-switch ml-2'
                                  style={{ marginBottom: '-5px' }}
                                  data-on-label='ON'
                                  data-off-label='OFF'
                                  onClick={(e) => onLogoDefaultChange(item.domain_id)}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': form?.filter((data) => data.domain_id === item.domain_id)[0]?.logo_default,
                                      'switch-off': !form?.filter((data) => data.domain_id === item.domain_id)[0]?.logo_default,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-info'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-info'>OFF</span>
                                  </div>
                                </div>
                              </Col>
                              {form
                                ?.filter((data) => data.domain_id === item.domain_id)
                                ?.map((item, key) => {
                                  if (item.logo === null || item.logo === '') {
                                    return
                                  } else {
                                    return (
                                      <Col md={4} key={key}>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            color: '#db5d3d',
                                          }}
                                          onClick={(e) => {
                                            onRemoveLogo(item.domain_id)
                                          }}>
                                          Remove logo
                                        </div>
                                      </Col>
                                    )
                                  }
                                })}
                            </Row>
                          </div>
                        </UncontrolledCollapse>
                      )}
                    </React.Fragment>
                  )
                })}
              {/* <AvGroup>
                <Label for='example'>Company Name</Label>
                <AvInput name='rank' id='example' value={companyName} onChange={onCompanyNameChange} />
                <AvFeedback>This is an error!</AvFeedback>
              </AvGroup>
              <AvField name='name' label='Upload logo' type='file' onChange={onFileChange} /> */}
              <Button
                color='primary'
                style={{
                  width: 90,
                  height: 38,
                  fontSize: '15px',
                  marginTop: '1rem',
                }}>
                {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save '}
              </Button>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditCompanyLogo
