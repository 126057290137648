import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import Icon from '../../icon'
import { cms } from '../../../helper'
import { setOpenModal } from '../../../Redux/Reducers/CMS'
import { useDispatch } from 'react-redux'

const IconButton = ({ item, toggleActions, gridData, clearSelectedValue=()=>{} }) => {
  const dispatch = useDispatch()

  const triggerAction = (item) => {
    item.component_call_actions.map((action, key) => {
      if (action.component_name === 'open_modal') {
        dispatch(setOpenModal({ active: true, action: { ...action, gridData } }))
      }
      if (action.component_name === 'enable_or_disable') {
        toggleActions(action)
        clearSelectedValue()
      }
    })
  }

  //<i class="fa-duotone fa-list"></i>
  return (
    <React.Fragment>
      <a id='toolTip' color='link' className='form-header-icon' onClick={() => triggerAction(item)}>
        <Icon type={item.icon_type} icon={item.icon || 'fa-duotone fa-list'} color='info' />
      </a>
      {item.cms_tooltip !== null && (
        <UncontrolledTooltip placement='right' target='toolTip'>
          {cms(item.cms_key_tooltip)}
        </UncontrolledTooltip>
      )}
    </React.Fragment>
  )
}

export default IconButton
