import React, { Fragment } from 'react'

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

const NoDomain = () => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component='div'
        transitionName='TabsAnimation'
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md='12'>
            <Card className='main-card mb-3'>
              <CardBody>
                <CardTitle>Domain not found!</CardTitle>
                <div className='text-center'>
                  <div className='font-10rem'>
                    <div className='mx-auto mb-0 font-size-lg text-capitalize font-weight-normal card-title'>Please select any domain</div>
                    <i className='fa-solid fa-face-frown-open icon-gradient bg-mean-fruit' />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}




export default NoDomain