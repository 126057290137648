import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { can } from '../helper'
import ErrorPage from '../Pages/ErrorPage'
import Tab from './tab'
import CreatePageGrid from './EditCMS/ContentForms/createGrid'

const select = (state) => ({
  // editMode: state.CMS.editMode,
  coreData: state.CMS.coreData,
})

const CMSPageContent = ({
  pageContent,
  tabContent = [],
  coreData,
  parentPageName,
  addDataDetail,
  setAddDataDetail,
  setQuestionnaireAction,
  questionnaireAction,
  pageData,
}) => {
  const [page, updatePage] = useState([])

  useEffect(() => {
    updatePage(pageContent)
  }, [pageContent, coreData])

  const renderContent = (pages) => {
    if (page.length === 0) {
      return <CreatePageGrid pageName={parentPageName} />
    }

    return pages.map((item, key) => {
      const { allowed, permission, component_name, component_call_form } = item
      if (!allowed || !can(permission)) return null
      return item && component_name === 'hidden_tab' ? (
        <Tab
          sourceTable={item.source_table}
          key={key}
          content={component_call_form}
          noTitle
          tabContent={tabContent}
          tabName={item.name}
          pageName={item.name}
          parentPageName={parentPageName}
          currentTabData={item}
          addDataDetail={addDataDetail}
          setAddDataDetail={setAddDataDetail}
          setQuestionnaireAction={setQuestionnaireAction}
          questionnaireAction={questionnaireAction}
          pageData={pageData}
        />
      ) : (
        <ErrorPage hideButton key={key} />
      )
    })
  }

  return renderContent(page)
}

export default connect(select)(withRouter(CMSPageContent))
