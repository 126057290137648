import React, { useEffect, useState } from 'react'

import { Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Input } from 'reactstrap'

import { AvField } from 'availity-reactstrap-validation'
import { connect, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import AvCheckbox from 'availity-reactstrap-validation/lib/AvCheckbox'
import AvCheckboxGroup from 'availity-reactstrap-validation/lib/AvCheckboxGroup'

import { cms, editCMS, getFromRedux, tidyName, validateForAv } from '../../../helper'
import ToggleField from './toggleField'
import { request } from '../../../Redux/Sagas/requests/api'
import { setLoader } from '../../../Redux/Reducers/loader'
import { setTableDropDown } from '../../../Redux/Reducers/CMS'

const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const AddOption = ({ addDropDownOpen }) => {
  return (
    <Dropdown isOpen={addDropDownOpen}>
      {/* <DropdownToggle caret><i class="fa-solid fa-plus-large"></i></DropdownToggle> */}
      <DropdownMenu center>
        <DropdownItem text>
          Some Action <input></input>
        </DropdownItem>
        <DropdownItem text>Dropdown Item Text</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

const select = (state) => ({
  coreData: state.CMS.coreData,
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
})

const CMSMultiCheckbox = ({
  data,
  onChangeAction,
  updateKeysForReduxData,
  fieldKey,
  toggleActions,
  cmsEditMode,
  id,
  value,
  isTable,
  index,
  inlineEditActive = true,
  currentRow,
  fieldActions,
  ...props
}) => {
  const [reduxData, updateReduxData] = useState(undefined)
  const [selectOptions, updateOptions] = useState([])
  const [selectedOption, updateSelectedOption] = useState()
  const tableOptions = useSelector((state) => state.CMS.tableDropDown)
  const dispatch = useDispatch()
  const [addDropDownOpen, setAddDropDownOpen] = useState(false)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    fieldActions(data, data?.component_call_actions, data?.field_id, value || [])
  }, [])

  const renderOption = async (data_conection, data) => {
    if (data_conection) {
      if (data_conection.length > 0) {
        data_conection?.map((connection) => {
          if (connection.component_name === 'redux_data') {
            const getReduxData = getFromRedux(connection.redux_data)
            updateReduxData(getReduxData)
            let options = []
            if (getReduxData && typeof getReduxData.length === 'number') {
              getReduxData.map((item) => {
                options.push({
                  value: item[connection.value],
                  label: item[connection.data],
                })
              })
            }
            updateOptions(options)

            const default_value = options?.filter((item) => item.value === value || item.label === value)[0]

            updateSelectedOption(default_value)
            updateKeysForReduxData({ value: connection.value, data: connection.data }, fieldKey)
          }
        })
      } else if (data_conection.component_name === 'template_data') {
        if (!isTable || (isTable && index === 0 && !tableOptions[data?.field_id])) {
          dispatch(setLoader(true))
          const response = await request('connect-template-data', 'post', data_conection)
          dispatch(setLoader(false))
          if (response?.data?.data) {
            let options = []
            const is_signle_field = data_conection.fields?.split(',')?.length === 1 ? true : false
            response?.data?.data.map((item) => {
              if (is_signle_field) {
                options.push({
                  value: item?.id,
                  label: item[data_conection.fields],
                })
              } else {
                options.push({
                  value: item[data_conection.fields?.split(',')[0]],
                  label: item[data_conection.fields?.split(',')[1]],
                })
              }
            })
            updateOptions(options)
            let payload = {}
            payload[data?.field_id] = options
            dispatch(setTableDropDown(payload))
            let default_value
            if (isTable) {
              default_value = options?.filter((item) => item.value === value || item.label === value)[0]
            } else {
              default_value = options?.filter((item) => item.value === value || item.label === value)[0]
              updateSelectedOption(default_value)
            }
            updateSelectedOption(default_value)
          }
        } else {
          updateOptions(tableOptions[data?.field_id])
        }
      } else if (data_conection.component_name === 'manual') {
        if (data_conection?.default_options) {
          let options = []
          if (Array.isArray(data_conection?.default_options)) {
            data_conection?.default_options?.map((item) => {
              options?.push({
                label: tidyName(item),
                value: item,
              })
            })
          } else {
            Object.keys(data_conection?.default_options)?.map((key) => {
              options?.push({
                value: key,
                label: data_conection?.default_options[key],
              })
            })
          }

          fieldActions(data, data?.component_call_actions, data?.field_id, value || [])

          updateSelectedOption(value)
          updateOptions(options)
        }
      }
    } else if (data.values) {
      let options = []
      let hasKey = true
      for (const property in data.values) {
        if (property == 0) {
          hasKey = false
        }
        options.push({
          value: hasKey ? property : data.values[property],
          label: tidyName(data.values[property]),
        })
      }
      updateOptions(options)
    }
  }

  useEffect(() => {
    renderOption(data.component_call_data_connection, data)
    if (data.selectData && data.dataKeys) {
      let options = []
      data.selectData.map((item) => {
        options.push({
          id: item[data.dataKeys.value],
          name: item[data.dataKeys.data],
        })
      })
      updateOptions(options)
    }
    if (isTable) {
      const default_value = selectOptions?.filter((item) => item.value === value || item.label === value)[0]

      updateSelectedOption(default_value)
    }
  }, [value])

  useEffect(() => {
    if (isTable && tableOptions[data?.field_id]) {
      updateOptions(tableOptions[data?.field_id])

      const default_value = selectOptions?.filter((item) => item.value === value || item.label === value)[0]
      updateSelectedOption(default_value)
    }
  }, [tableOptions[data?.field_id]])

  if (!inlineEditActive) {
    if (data.field_setting?.type === 'enum') {
      let haveKey = true
      const selectedValue = data.field_setting?.values?.filter((item, key) => {
        if (key == 0) {
          haveKey = false
        }
        if (haveKey) {
          return key === value
        } else {
          return item === value
        }
      })
      return selectedValue.length > 0 ? tidyName(selectedValue[0]) : 'no'
    } else if (selectOptions?.length > 0 && value) {
      const selectedValue = selectOptions.filter((item) => item.value === value)
      return selectedValue.length > 0 ? selectedValue[0].label : 'no'
    } else {
      return value
    }
  }

  let isFieldVisible = true

  if (data?.field_setting?.depend_fields && currentRow) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      return checkSingleCondition(dependForm?.condition, currentRow[dependForm?.dependent_field], parseInt(dependForm?.value))
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (!isFieldVisible) return

  const onChangeValue = (value, checked) => {
    const selected_data = selectedOption ? [...selectedOption] : []
    if (checked) {
      selected_data?.push(value)
    } else {
      const index = selected_data?.indexOf(value)
      selected_data?.splice(index, 1)
    }
    updateSelectedOption(selected_data)
    fieldActions(data, data?.component_call_actions, data?.field_id, selected_data)
  }

  return (
    <>
      <div>
        <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>
          {cms(data.cms_key)}{' '}
          {data.component_call_fields && (
            <>
              {''}
              <ToggleField data={data} toggleActions={toggleActions} />
            </>
          )}
        </label>
        <Row form>
          <Col md={12} style={{ maxHeight: '5rem', overflow: 'auto' }}>
            <Form>
              {/* <FormGroup check> */}
              <AvCheckboxGroup name={data?.field_id} value={selectedOption}>
                {selectOptions?.map((item, key) => (
                  <>
                    <AvCheckbox
                      key={item?.value}
                      label={item?.label}
                      value={item?.value}
                      onChange={(e) => onChangeValue(e.target.value, e.target.checked)}
                      checked={selectedOption?.includes(item?.value) ? true : false}
                    />
                  </>
                ))}
              </AvCheckboxGroup>
              {/* </FormGroup> */}
            </Form>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default connect(select)(CMSMultiCheckbox)
