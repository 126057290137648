import { persistStore, persistCombineReducers } from 'redux-persist'
import localforage from 'localforage'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../Reducers'
import rootSaga from '../Sagas/rootSaga'

const persistConfig = {
  key: 'root',
  storage: localforage,
}

const persistedReducer = persistCombineReducers(persistConfig, { ...rootReducer })
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))

const configureStore = () => {
  const store = createStore(persistedReducer, enhancer)
  const persistor = persistStore(store)
  sagaMiddleware.run(rootSaga)
  return { persistor, store }
}
const { persistor, store } = configureStore()
export { persistor, store }
