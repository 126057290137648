import { request } from './api'

export function requestCMS(cache = true) {
  return request('cms?cache=' + cache, 'get')
}

export function requestCMSUpdateValue(data) {
  return request('cms-update', 'post', data)
}

export function requestCoreAPI(cache) {
  return request('core-api?cache=' + cache, 'get')
}

export function requestUpdateAPI(cache) {
  return request('update-api?cache=' + cache, 'get')
}

export function requestScheduleAPI() {
  return request('schedule-api', 'get')
}

export function requestCMSSubmitApi(data) {
  return request(data.url, 'post', { id: data.payload[1] })
}

export function requestConnectApi(data) {
  let method = 'post'
  if (data.method) {
    method = data.method
  }
  return request(data.url, method, data.data)
}

export function requestConnectTemplateApi(data) {
  let method = 'post'
  if (data.method) {
    method = data.method
  }
  return request('connect-template', method, data.data)
}

export function requestConnectTemplateRestoreApi(data) {
  let method = 'post'
  if (data.method) {
    method = data.method
  }
  return request('connect_template_restore', method, data.data)
}

export function requestCommonApi({ url, data = {} }) {
  return request(url, 'post', data)
}

export function requestFreeApi({ url, data = {} }) {
  return request(url, 'post', data)
}

export function submitTopNavButtonApi(data) {
  return request('submit-top-nav-button', 'post', data)
}

export function removeItemByName(data) {
  return request('remove/' + data.type, 'post',data)
}
export function removeTabByName(data) {
  return request('remove/page-button', 'post',{name:data?.name,page_name:data?.page_name})
}

export function submitPage(data) {
  return request('submit-page', 'post', data)
}

export function submitPageContent(data) {
  return request('submit-page-content', 'post', data)
}
