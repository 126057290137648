import { AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import RenderFields from '../../../../../Forms/renderFields'
import IconSet from '../../../../../icon'
import '../../addForm.scss'

const NotesAccordian = ({ item, index, content, onValueChange, submittedFormData, allForm, onSubmitForm, onDelete }) => {
  const [isAccordianOpen, setIsAccordianOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const onDeleteNotes = (e) => {
    e.stopPropagation()
    onDelete(index, item?.id)
  }
  return (
    <Card>
      <CardHeader id='headingOne'>
        <Button
          block
          color='link'
          className='text-left m-0 p-0 notes_header'
          onClick={() => setIsAccordianOpen(!isAccordianOpen)}
          aria-expanded={isAccordianOpen}
          aria-controls='collapseOne'>
          <h6 className='m-0 p-0' style={{ color: 'rgb(0,0,0,0.7)' }}>
            <IconSet icon={isAccordianOpen ? 'far fa-arrow-down' : 'far fa-arrow-right'} color='primary' className='expand_notes_icon' />
            {item?.name}
          </h6>
          <Button className='btn-danger float-right' style={{ marginTop: '7px' }} onClick={(e) => onDeleteNotes(e)}>
            <IconSet icon='pe-7s-trash' color='light' />
          </Button>
        </Button>
      </CardHeader>
      <Collapse isOpen={isAccordianOpen} data-parent='#accordion' id='collapseOne' aria-labelledby='headingOne'>
        {isEdit ? (
          <AvForm
            onValidSubmit={(e, values) => {
              onSubmitForm(values, item?.id, index)
              setIsEdit(false)
            }}
            style={{ marginBottom: '1rem', padding: '1rem' }}>
            <RenderFields
              allForm={allForm}
              content={content}
              twoCol={true}
              onValueChange={onValueChange}
              defaultFormValue={item}
              submittedFormData={submittedFormData}
            />
            <Button color='primary'>Save</Button>
          </AvForm>
        ) : (
          <>
            {' '}
            <CardBody>{item?.note?.replace(/<[^>]+>/g, '')}</CardBody>
            <div>
              <span className='float-right' style={{ marginRight: '1rem', fontStyle: 'italic', marginTop: '1.4rem', fontSize: '0.8rem' }}>
                {item?.created_at && !item?.updated_at && <div>Created at {moment(item?.created_at).format('D MMM YYYY h:mm a')}</div>}
                {item?.updated_at && <div>Updated at {moment(item?.updated_at).format('D MMM YYYY h:mm a')}</div>}
              </span>
            </div>
            <div>
              <Button color='primary' style={{ margin: '1rem' }} onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            </div>
          </>
        )}
      </Collapse>
    </Card>
  )
}

const ShowNotes = ({ addedNotes, onSubmitForm, content, onValueChange, submittedFormData, allForm, onDeleteNotes }) => {
  return (
    <div style={{ marginTop: '1rem' }}>
      {addedNotes?.map((item, index) => {
        return (
          <NotesAccordian
            key={index}
            item={item}
            index={index}
            onSubmitForm={onSubmitForm}
            allForm={allForm}
            content={content}
            onValueChange={onValueChange}
            submittedFormData={submittedFormData}
            onDelete={onDeleteNotes}
          />
        )
      })}
    </div>
  )
}

export default ShowNotes
