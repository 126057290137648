import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CardTitle } from 'reactstrap'
import { getExpandableAnswer } from '../../../CMSComponent/Grid/Component/ExpandableRow/expandableQuestion'
import { checkIsDate } from '../../../helper'
import './item-reports-preview.scss'

const getExpandableAnswerComment = (question) => {
  switch (question.type) {
    case 'radio':
    case 'select':
    case 'text':
      return question?.response?.[0]?.comment
    case 'date':
      return question?.response?.[0]?.comment
    case 'time':
      return question?.response?.[0]?.comment
    case 'date-time':
      return question?.response?.[0]?.comment
    case 'rich-text':
      return question?.response?.[0]?.comment
    case 'check':
    case 'multy-select':
      return question?.response?.comment
    case 'signiture_select':
      return question?.response[0]?.comment
    case 'signiture_name':
      return question?.response[0]?.comment
    case 'signiture_basic':
      return question?.response[0]?.comment
    case 'upload':
      return question?.response[0]?.comment
  }
}

const getLocationAddressString = (location) => {
  let address = []
  const details = ['client_address_name', 'client_street', 'town', 'client_zip', 'client_state', 'client_country']

  details?.forEach((item, index) => {
    if (location[item]) {
      address?.push('<p>' + location[item] + '</p>')
    }
  })

  return address?.join('')
}

const getReportsPreviewData = (group) => {
  const location = group?.location
  const date = checkIsDate(group?.completed_end_date_time) && new Date(group?.completed_end_date_time)
  const arrival_date = checkIsDate(group?.completed_start_date_time) && new Date(group?.completed_start_date_time)
  const departure_date = checkIsDate(group?.completed_end_date_time) && new Date(group?.completed_end_date_time)
  const address = getLocationAddressString(location)

  return [
    {
      title: 'Date:',
      value: date ? moment(date).format('Do MMM YY') : '',
    },
    {
      title: 'Customer Name:',
      value: group?.location?.name,
    },
    {
      title: 'Address:',
      value: address,
      isHTML: true,
    },
    {
      title: 'Reason:',
      value: group?.location?.termination_reason,
      isHTML: true,
    },
    {
      title: 'Emergency Services Called:',
      value: '',
    },
    {
      title: 'Monitoring Station:',
      value: '',
    },
    {
      title: 'Time Call Received:',
      value: '',
    },
    {
      title: 'Arrival:',
      value: arrival_date ? moment(arrival_date).format('Do MMM YY') : '',
    },
    {
      title: 'Departure:',
      value: departure_date ? moment(departure_date).format('Do MMM YY') : '',
    },
    {
      title: 'Time On Site:',
      value: '',
    },
  ]
}
const ItemsReportsPreview = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [data, setData] = useState([])

  const group_data = currentRow?.[currentRow?.length - 1]

  useEffect(() => {
    const newData = getReportsPreviewData(group_data)
    setData(newData)
  }, [])
  if (!group_data) return
  return (
    <div className='item-reports-preview'>
      <CardTitle style={{ fontSize: '1.3rem !important' }}>Email Preview</CardTitle>
      <div className='general-details-container'>
        <div className='general-details-title'>GENERAL DETAILS</div>

        {data?.map((item, index) => {
          return (
            <div className='section-container' key={index}>
              <div className='left-section'>{item?.title}</div>
              {item?.isHTML ? (
                <div className='right-section' dangerouslySetInnerHTML={{ __html: item?.value || '' }} style={{ flex: 1 }}></div>
              ) : (
                <div className='right-section'>{item?.value}</div>
              )}
            </div>
          )
        })}
      </div>
      <div>
        <div className='general-details-title'>REPORT</div>
        <div className='report-container'>
          <div className='item-group-title'>{group_data?.group_name}</div>
          {group_data?.items?.map((item, index) => {
            if (!item?.item_questions || item?.item_questions?.length === 0) return
            return (
              <div key={index} className='item-container'>
                <div className='item-title'>{item?.item_name}</div>
                {item?.item_questions?.map((question, index) => {
                  return (
                    <div key={index} className='question-container'>
                      <div className='index'>{index + 1}</div>
                      <div className='question'>{question?.question}</div>
                      <div className='answer'>{getExpandableAnswer(question)}</div>
                      <div className='comment'>
                        {getExpandableAnswerComment(question) && (
                          <div dangerouslySetInnerHTML={{ __html: getExpandableAnswerComment(question) }} style={{ flex: 1 }}></div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ItemsReportsPreview
