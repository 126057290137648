import React, { Component } from "react";
import ErrorPage from ".";
import { setPageCrashedStatus } from "../../helper";
import PageCrash from "./pageCrash";

export class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo:null
  };
  componentDidCatch(error, errorInfo) {
    this.setState({ error,errorInfo });
  }
  render() {
    if (this.state.error ) {
      setPageCrashedStatus(true)
      return (
        <PageCrash error={this.state.error} info={this.state.errorInfo}/>
      );
    } else {
      setPageCrashedStatus(false)
        // alert('child')
      //when there's not an error, render children untouched
      return this.props.children
    }
  }
}
