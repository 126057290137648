import cx from 'classnames'
import TextTimeFilter from './textTimeFilter'

const AggregateFilter = ({ value, updateValue, triggerSearch,column,handleSubmit }) => {

  switch (column?.field_id) {
    case 'hr_under_percent':
    case 'hr_more_percent':
    case '0_20_min':
    case '21_40_min':
    case '41_60_min':
    case '60_+_min':
    case 'count':
    case 'completed_min':
      return (
        <div className='form-group'>
          <form onSubmit={handleSubmit}>
            <input
              name='search'
              className='form-control '
              value={value || ''}
              onChange={(e) => updateValue(parseInt(e.target.value))}
              placeholder={`search..`}
            />
          </form>
        </div>
      )
    case 'hr_under_bool':
    case 'hr_more_bool':
      return (
        <div
          className='switch has-switch '
          style={{ marginBottom: '-5px' }}
          data-on-label='ON'
          data-off-label='OFF'
          onClick={(e) => {
            updateValue(!value)
            triggerSearch(!value)
          }}>
          <div
            className={cx('switch-animate', {
              'switch-on': value,
              'switch-off': !value,
            })}>
            <input type='checkbox' />
            <span className='switch-left bg-info'>ON</span>
            <label>&nbsp;</label>
            <span className='switch-right bg-info'>OFF</span>
          </div>
        </div>
      )

    case 'text_time':
      return <TextTimeFilter value={value} updateValue={updateValue} triggerSearch={triggerSearch} column={column} handleSubmit={handleSubmit} />
    case 'month':
      return <TextTimeFilter value={value} updateValue={updateValue} triggerSearch={triggerSearch} column={column} handleSubmit={handleSubmit} isMonthFilter={true}/>
  }
  return
}


export default AggregateFilter;