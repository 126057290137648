import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import AddCustomColor from './add'
import List from './list'
import { useSelector } from 'react-redux'
// import { v4 as uuidv4 } from 'uuid'
import Icon from '../../icon'
// import Button from '../../forms/fields/button'

const CustomColor = ({ activeColor, setColor }) => {
  const [showAddCard, setShowAddCard] = useState(false)
  const [accordion, setAccordion] = useState([false])
  const { themeColors } = useSelector((state) => state.CMS)

  const toggleAccordion = (tab) => {
    // debugger
    const updateAccordion = accordion.map((x, index) => (tab === index ? !x : false))
    setAccordion(updateAccordion)
  }

  const toggleShowAddCard = () => setShowAddCard(!showAddCard)

  return (
    <div id='accordion' className='accordion-wrapper border-0'>
      <Card>
        <CardHeader id='headingOne' className='p-1 border-0'>
          <Button
            // block
            color='link'
            className='text-left m-0 p-0'
            onClick={() => toggleAccordion(0)}
            aria-expanded={accordion[0]}
            aria-controls='collapseOne'>
            <Icon icon={'fas fa-pen'} bgcolor='green' color='info' navheadericon='true' />
            {/* <h5 className='m-0 p-0' style={{ fontWeight: 300, fontSize: '0.968rem' }}></h5> */}
          </Button>
        </CardHeader>
        <Collapse isOpen={accordion[0]} data-parent='#accordion' id='collapseOne' aria-labelledby='headingOne'>
          <CardBody>
            {showAddCard ? (
              <AddCustomColor toggleShowCard={toggleShowAddCard} />
            ) : (
              <>
                <Button color='link' onClick={() => toggleShowAddCard()}>
                  Add
                </Button>
                <List customColors={themeColors} />
              </>
            )}
          </CardBody>
        </Collapse>
      </Card>
    </div>
  )
}

export default CustomColor
