import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import cx from 'classnames'

// Layout
import AppHeader from '../Layout/AppHeader'
import AppSidebar from '../Layout/AppSidebar'
import AppFooter from '../Layout/AppFooter'
import ThemeOptions from '../Layout/ThemeOptions'

import { userTimeout } from '../Redux/Reducers/user'
import { can, checkThemePermission, renderBlockColorClass, renderBlockStyle } from '../helper'
import CMSModal from '../CMSComponent/CMSModal'
import OpenModal from '../CMSComponent/Forms/Fields/openModal'
import { closeOpenModal } from '../Redux/Reducers/CMS'

const select = (state) => ({
  user: state.User.user,
  themeOptions: state.ThemeOptions,
  editModal: state.CMS.editModal,
  modelDala: state.CMS.modelDala,
})

const Wrapper = ({ user, children, themeOptions, newNav }) => {
  const dispatch = useDispatch()

  window.onunload = () => {
    dispatch(userTimeout())
  }

  useEffect(() => {
    if (themeOptions.updatedAt === null) {
      // dispatch(reduxLoad())
    }
  }, [user])

  return (
    <Fragment>
      {(checkThemePermission('edit_theme') || checkThemePermission('Edit_theme')) && <ThemeOptions />}
      <AppHeader canEditCms={can('edit_theme')} newNav={newNav} />
      <div className='app-main'>
        <AppSidebar />
        <div className='app-main__outer'>
          <div className={cx('app-main__inner', renderBlockColorClass(themeOptions.bodyColor))} style={renderBlockStyle(themeOptions.bodyColor)}>
            {children}
          </div>
          <AppFooter />
        </div>
      </div>
      <CMSModal />
      <OpenModal />
    </Fragment>
  )
}

export default connect(select)(Wrapper)
