export const ChartOptions = {
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
    },
    title: {
      align: 'start',

      padding: {
        top: 10,
        bottom: 30,
      },
      font: {
        size: 15,
      },
    },

    // tooltip: {
    //   yAlign: 'bottom',
    //   borderColor: [
    //     'rgb(255, 99, 132)',
    //     'rgb(255, 159, 64)',
    //     'rgb(255, 205, 86)',
    //     'rgb(75, 192, 192)',
    //     'rgb(54, 162, 235)',
    //     'rgb(153, 102, 255)',
    //     'rgb(201, 203, 207)',
    //   ],
    //   borderRadius: '1rem',
    //   borderWidth: 1,
    //   backgroundColor: [
    //     'rgba(255, 99, 132, 0.2)',
    //     'rgba(255, 159, 64, 0.2)',
    //     'rgba(255, 205, 86, 0.2)',
    //     'rgba(75, 192, 192, 0.2)',
    //     'rgba(54, 162, 235, 0.2)',
    //     'rgba(153, 102, 255, 0.2)',
    //     'rgba(201, 203, 207, 0.2)',
    //   ],
    //   displayColors: false,
    //   // callbacks: {
    //   //   title: function (context) {
    //   //     return 'Enrollments'
    //   //   },
    //   // },
    //   titleAlign: 'center',
    //   bodyAlign: 'center',
    // },
  },
  scales: {
    //   y: {
    //     ticks: {
    //       // color: `${theme.palette.text_primary}`,
    //       fontWeight: 100,
    //     },
    //     grid: {
    //       // color: `${theme.palette.text_primary}12`,
    //     },
    //   },
    x: {
      ticks: {
        fontWeight: 100,
      },
      grid: {
        display: false,
      },
    },
  },
}

export const ChartDatasetOptions = {
  borderColor: [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
  ],
  borderRadius: '1rem',
  borderWidth: 1,
  backgroundColor: [
    'rgba(255, 99, 132, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 205, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(201, 203, 207, 0.2)',
  ],
}