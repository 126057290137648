import React, { useEffect, useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { connect, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Button } from 'reactstrap'

import { cms, editCMS, getFromRedux, renderBlockColorClass, renderBlockStyle, tidyName, validateForAv } from '../../../helper'
import ToggleField from './toggleField'
import { request } from '../../../Redux/Sagas/requests/api'
import { setLoader } from '../../../Redux/Reducers/loader'
import { clearAllFieldInForm, setDropdownOptionsByPage, setTableDropDown, setTableDropDownFullData, submitRequest } from '../../../Redux/Reducers/CMS'
import IconSet from '../../icon'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { AsyncPaginate } from 'react-select-async-paginate'

import { getDropdownOptionsToUpdateInReduxAfterClearCurrentDropdown, getDropdownOptionsToUpdateInRedux } from '../../../Helper/selectHelper'

const RenderThirdDependTable = ({ dependTableRow, tableSecondColumnOptions, thirdTableValue, setThirdTableValue, title, setDependTableRow }) => {
  const onChangeValue = (id, value_id, value_name) => {
    const new_value_array = [...thirdTableValue]
    const changed_object = new_value_array?.filter((item) => item?.id === id)[0]
    changed_object['resource'] = {
      id: value_id,
      name: value_name,
    }
    setThirdTableValue(new_value_array)
  }

  const onDelete = (id) => {
    const new_value_array = [...thirdTableValue]
    const changed_object = new_value_array?.filter((item) => item?.id === id)[0]
    const index = new_value_array?.indexOf(changed_object)
    new_value_array?.splice(index, 1)

    setThirdTableValue(new_value_array)

    const new_row_array = [...dependTableRow]
    const row_to_remove = new_row_array?.filter((item) => item?.id === id)[0]
    const row_index = new_row_array?.indexOf(row_to_remove)
    new_row_array?.splice(row_index, 1)

    setDependTableRow(new_row_array)
  }

  return (
    <table style={{ borderCollapse: 'collapse', width: '100%' }} className='table'>
      <thead>
        <tr>
          <th></th>
          <th>{cms(title)}</th>
          <th>Select Resources</th>
        </tr>
      </thead>
      <tbody>
        {dependTableRow?.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                {' '}
                <span className='btn-danger' style={{ cursor: 'hover', padding: '6px 9px', borderRadius: '5px' }} onClick={() => onDelete(item?.id)}>
                  <IconSet icon='pe-7s-trash' color='light' />
                </span>
              </td>
              <td>{cms(item?.name)}</td>
              <td>
                <Select
                  options={tableSecondColumnOptions}
                  id={'second-column'}
                  onChange={(e) => onChangeValue(item?.id, e.value, e.label)}
                  // value={selectedSecondOption}
                  styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
                  // isDisabled={!inlineEditActive}
                  // placeholder=''
                  // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
                  // isSearchable={ inlineEditActive }
                  // menuIsOpen={inlineEditActive}
                  // {...props}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const AddOption = ({ addDropDownOpen }) => {
  return (
    <Dropdown isOpen={addDropDownOpen}>
      {/* <DropdownToggle caret><i class="fa-solid fa-plus-large"></i></DropdownToggle> */}
      <DropdownMenu center>
        <DropdownItem text>
          Some Action <input></input>
        </DropdownItem>
        <DropdownItem text>Dropdown Item Text</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

const AdvancedMultiSelect = (state) => ({
  coreData: state.CMS.coreData,
  cmsEditMode: state.CMS.editMode,
  currentFormData: state.CMS.coreData.currentFormData,
  currentRow: state.CMS.currentRow,
})

const CMSSelect = ({
  data,
  onChangeAction,
  updateKeysForReduxData,
  fieldKey,
  toggleActions,
  cmsEditMode,
  id,
  value,
  isTable,
  index,
  inlineEditActive = true,
  currentFormData,
  coreData,
  repeatingFormItem,
  currentRow,
  isRepeatingForm = false,
  ...props
}) => {
  const [reduxData, updateReduxData] = useState(undefined)
  const [selectOptions, updateOptions] = useState([])
  const [selectedOption, updateSelectedOption] = useState()
  const tableOptions = useSelector((state) => state.CMS.tableDropDown)
  const tableOptionsFullData = useSelector((state) => state.CMS.tableDropDownFullData)
  const dropdownOptionsByPage = useSelector((state) => state.CMS.dropdownOptionsByPage)
  const primaryColor = useSelector((theme) => theme.ThemeOptions.primaryColor)
  const dispatch = useDispatch()
  const [cachedReduxDataUsed, setCachedReduxDataUsed] = useState(false)
  const [addDropDownOpen, setAddDropDownOpen] = useState(false)
  const [dataRequested, setDataRequested] = useState(false)

  const [responseData, setResponseData] = useState()

  const [finalFieldValue, setFinalFieldValue] = useState({})

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const obj = {}
    obj[data?.field_id] = ''
    obj[data?.field_setting?.second_dropdown?.depend_field_id] = ''
    obj[data?.field_setting?.table_field?.depend_table_field_id] = ''
    setFinalFieldValue({
      ...obj,
    })
  }, [])

  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    hasMore: true,
  })

  const loadOptions = async (search, loadedOption) => {
    let options = []
    const data_connection = data?.component_call_data_connection

    if (data_connection) {
      if (data_connection.component_name === 'template_data') {
        let payload = { ...data_connection, page: paginationData?.pageNo }
        const clauseData = data_connection?.clause_data
        const new_clause_data = []
        const a = data?.field_id

        clauseData?.forEach((data) => {
          if (data?.ref_for_form_field && repeatingFormItem) {
            const field = data?.value?.split('.')
            let new_clause
            if (field?.length === 0) {
              new_clause = {
                ...data,
                value: repeatingFormItem[field[0]],
              }
            } else {
              new_clause = {
                ...data,
                value: repeatingFormItem[field[0]][field[1]],
              }
            }
            new_clause_data?.push(new_clause)
          } else {
            new_clause_data?.push(data)
          }
        })

        payload = { ...payload, clause_data: new_clause_data }

        let with_payload = []
        if (data?.field_setting?.second_dropdown && data?.field_setting?.second_dropdown?.depend_field) {
          with_payload.push(data?.field_setting?.second_dropdown?.depend_field)
        }
        if (data?.field_setting?.table_field && data?.field_setting?.table_field?.depend_table_field) {
          with_payload.push(data?.field_setting?.table_field?.depend_table_field)
        }

        payload = { ...payload, clause_data: new_clause_data }
        if (with_payload?.length > 0) {
          payload = { ...payload, with: with_payload?.join(',') }
        }
        // const with_data = checkDependDataExist(data?.field_id, allFieldsInForm)
        // if (with_data) {
        //   payload = { ...payload, with: with_data }
        // }
        if (search && search?.length > 0) {
          payload = {
            ...payload,
            searchKey: search,
          }
          const optionsObjectToStoreInRedux = getDropdownOptionsToUpdateInReduxAfterClearCurrentDropdown(
            dropdownOptionsByPage,
            'alarms',
            data?.field_id,
            data_connection?.source_table
          )
          dispatch(setDropdownOptionsByPage(optionsObjectToStoreInRedux))
        }
        if (dropdownOptionsByPage?.['alarms']?.[data?.field_id]?.[data_connection?.source_table] && !search && !cachedReduxDataUsed) {
          setPaginationData(dropdownOptionsByPage['alarms'][data?.field_id][data_connection?.source_table]?.paginationData)
          setCachedReduxDataUsed(true)

          return {
            options: dropdownOptionsByPage['alarms'][data?.field_id][data_connection?.source_table]?.options,
            hasMore: dropdownOptionsByPage['alarms'][data?.field_id][data_connection?.source_table]?.hasMore,
          }
        }
        if (dropdownOptionsByPage?.['alarms']?.[data?.field_id]?.[data_connection?.source_table]) {
          if (payload?.page < dropdownOptionsByPage?.['alarms']?.[data?.field_id]?.[data_connection?.source_table]?.paginationData?.pageNo) {
            setPaginationData(dropdownOptionsByPage['alarms'][data?.field_id][data_connection?.source_table]?.paginationData)
            payload = {
              ...payload,
              page: dropdownOptionsByPage?.['alarms']?.[data?.field_id]?.[data_connection?.source_table]?.paginationData?.pageNo,
            }
          }
        }

        const response = await request('connect-template-data', 'post', payload)

        if (response?.data?.data?.data && response?.data?.data?.data?.length !== 0) {
          const is_signle_field = data_connection.fields?.split(',')?.length === 1 ? true : false
          response?.data?.data?.data.map((item) => {
            if (is_signle_field) {
              options.push({
                value: item?.id,
                label: item[data_connection.fields],
              })
            } else {
              options.push({
                value: item[data_connection.fields?.split(',')[0]],
                label: item[data_connection.fields?.split(',')[1]],
              })
            }
            if (loadedOption && loadedOption?.length === 0) {
              updateOptions(response.data.data.data)
            } else {
              updateOptions([...selectOptions, ...response.data.data.data])
            }
          })
          setPaginationData({
            pageNo: response?.data?.data?.current_page + 1,
            hasMore: response?.data?.data?.next_page_url ? true : false,
          })
          let filteredOptions = []
          if (!search) {
            filteredOptions = options
            const optionsObjectToStoreInRedux = getDropdownOptionsToUpdateInRedux(
              dropdownOptionsByPage,
              'alarms',
              data?.field_id,
              data_connection?.source_table,
              options,
              {
                pageNo: response?.data?.data?.current_page + 1,
                hasMore: response?.data?.data?.next_page_url ? true : false,
              },
              response?.data?.data?.next_page_url ? true : false,
              response?.data?.data?.data
            )

            dispatch(setDropdownOptionsByPage(optionsObjectToStoreInRedux))
          } else {
            const searchLower = search.toLowerCase()

            filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower))
          }
          return {
            options: filteredOptions,
            hasMore: response?.data?.data?.next_page_url ? true : false,
          }
        } else if (response?.data?.data?.data?.length === 0) {
          setPaginationData({
            pageNo: paginationData?.pageNo + 1,
            hasMore: false,
          })
          return {
            options: options,
            hasMore: false,
          }
        }
      } else {
        return {
          options: [],
          hasMore: false,
        }
      }
    }
    return {
      options: [],
      hasMore: false,
    }
  }

  useEffect(() => {
    if (!selectOptions?.includes(selectedOption)) {
      // updateSelectedOption({})
    }
  }, [JSON.stringify(selectOptions), JSON.stringify(selectedOption)])

  const [firstFieldObject, setFirstFieldObject] = useState()
  const [dependTableRow, setDependTableRow] = useState()
  const [thirdTableValue, setThirdTableValue] = useState()

  useEffect(() => {
    let options = selectOptions
    if (dropdownOptionsByPage['alarms']?.[data?.field_id]?.[data?.component_call_data_connection?.source_table]?.options_data) {
      options = dropdownOptionsByPage['alarms']?.[data?.field_id]?.[data?.component_call_data_connection?.source_table]?.options_data
    }
    const selectedFirstFieldObject = options?.filter((data) => data?.id === selectedOption?.value)
    if (selectedFirstFieldObject?.length > 0) {
      setFirstFieldObject(selectedFirstFieldObject[0])
      if (data?.field_setting?.table_field?.depend_table_field) {
        const new_row = selectedFirstFieldObject[0][data?.field_setting?.table_field?.depend_table_field]?.map((item) => {
          return {
            id: item?.id,
            name: item?.name || item?.item_name,
          }
        })
        const value = selectedFirstFieldObject[0][data?.field_setting?.table_field?.depend_table_field]?.map((item) => {
          return {
            id: item?.id,
            name: item?.item_name || item?.name,
            resource: {},
          }
        })
        setThirdTableValue(value)
        setDependTableRow(new_row)
      }
    }
  }, [JSON.stringify(selectedOption)])

  const [secondFieldOptions, setSecondFieldOptions] = useState([])
  const [selectedSecondOption, setSelectedSecondOption] = useState()
  const [selectedSecondObject, setSelectedSecondObject] = useState()

  useEffect(() => {
    const second_field_setting = data?.field_setting?.second_dropdown

    const second_field_depend_field =
      data?.field_setting?.second_dropdown?.depend_field && data?.field_setting?.second_dropdown?.depend_field?.split('.')
    const options =
      firstFieldObject &&
      firstFieldObject[second_field_depend_field[0]]?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        }
      })
    setSecondFieldOptions(options)
  }, [JSON.stringify(firstFieldObject)])
  useEffect(() => {
    const second_field_depend_field =
      data?.field_setting?.second_dropdown?.depend_field && data?.field_setting?.second_dropdown?.depend_field?.split('.')
    if (second_field_depend_field?.length > 0 && firstFieldObject) {
      const new_selected_second_object = firstFieldObject[second_field_depend_field[0]]?.filter((item) => item?.id === selectedSecondOption?.value)
      if (new_selected_second_object && new_selected_second_object?.length > 0) {
        setSelectedSecondObject(new_selected_second_object[0])
      }
    }
  }, [JSON.stringify(selectedSecondOption)])

  const [tableSecondColumnOptions, setTableSecondColumnOptions] = useState()

  useEffect(() => {
    const second_field_depend_field =
      data?.field_setting?.second_dropdown?.depend_field && data?.field_setting?.second_dropdown?.depend_field?.split('.')

    if (second_field_depend_field && second_field_depend_field?.length > 1 && selectedSecondObject) {
      const options = selectedSecondObject[second_field_depend_field[1]]?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        }
      })

      setTableSecondColumnOptions(options)
    }
  }, [JSON.stringify(selectedSecondObject)])

  useEffect(() => {
    const new_value = {
      ...finalFieldValue,
    }
    new_value[data?.field_id] = selectedOption?.value
    setFinalFieldValue(new_value)
  }, [selectedOption?.value])

  useEffect(() => {
    const new_value = {
      ...finalFieldValue,
    }
    new_value[data?.field_setting?.second_dropdown?.depend_field_id] = selectedSecondOption?.value
    setFinalFieldValue(new_value)
  }, [selectedSecondOption?.value])

  useEffect(() => {
    const new_value = {
      ...finalFieldValue,
    }
    new_value[data?.field_setting?.table_field?.depend_table_field_id] = thirdTableValue
    setFinalFieldValue(new_value)
  }, [JSON.stringify(thirdTableValue)])

  const changeOption = (value, actions, field) => {
    if (value) {
      updateSelectedOption(value)
      setSelectedSecondOption()
      setSelectedSecondObject()
    }
  }

  const onSubmit = () => {
    // dispatch(submitRequest({ url: 'raise-job', data: { ...finalFieldValue } }))
    request('raise-job', 'POST', finalFieldValue).then((res) => {
      updateSelectedOption(null)
      setSelectedSecondOption()
      setSelectedSecondObject()
      setFirstFieldObject()
      setThirdTableValue()
      setDependTableRow()
      dispatch(setNotification({ type: 'success', message: 'Form submitted successfully!' }))
      dispatch(clearAllFieldInForm(true))
      setTimeout(() => {
        dispatch(clearAllFieldInForm(false))
      }, 5000)
      // data.logo = res.data.path
      // this.setState({ logo: res.data.path })
      // this.props.fetchUser()
    })
  }

  if (!inlineEditActive) {
    if (data.field_setting?.type === 'enum') {
      let haveKey = true
      const selectedValue = data.field_setting?.values?.filter((item, key) => {
        if (key == 0) {
          haveKey = false
        }
        if (haveKey) {
          return key === value
        } else {
          return item === value
        }
      })
      return selectedValue.length > 0 ? tidyName(selectedValue[0]) : 'no'
    } else if (selectOptions?.length > 0 && value) {
      const selectedValue = selectOptions.filter((item) => item.value === value)
      return selectedValue.length > 0 ? selectedValue[0].label : 'no'
    } else {
      return value
    }
  }

  let isFieldVisible = true

  if (data?.field_setting?.depend_fields && data?.field_setting?.depend_fields?.length > 0 && currentRow && currentRow[currentRow?.length - 1]) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      return checkSingleCondition(dependForm?.condition, currentRow[currentRow?.length - 1][dependForm?.dependent_field], parseInt(dependForm?.value))
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (!isFieldVisible) return

  return (
    <Row>
      <Col md={6} className='mb-4'>
        {(!isTable || (isTable && isRepeatingForm)) && (
          <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>
            {cms(data.cms_key)} {!data?.cms_key && tidyName(data?.name)}{' '}
            {(data.component_call_fields || data?.field_setting?.component_call_fields) && <ToggleField data={data} toggleActions={toggleActions} />}
          </label>
        )}
        {/* <span>
      <i className="fa-solid fa-plus-large" onClick={()=>setAddDropDownOpen(!addDropDownOpen)}></i> 
      <AddOption addDropDownOpen={addDropDownOpen}/>
      </span> */}
        <AsyncPaginate
          options={selectOptions}
          id={id}
          onChange={(e) => changeOption(e, data.component_call_actions, data.field_id)}
          value={selectedOption}
          styles={{ menu: (base) => ({ ...base, zIndex: 15000 }) }}
          {...props}
          loadOptions={loadOptions}
        />

        {/* <Select
          isLoading={loading}
          // menuIsOpen={true}
          // isDisabled={!inlineEditActive}
          // placeholder=''
          // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
          // isSearchable={ inlineEditActive }
          // menuIsOpen={inlineEditActive}
        /> */}
      </Col>

      {data?.field_setting?.second_dropdown && (
        <Col md={6} className='mb-4'>
          {' '}
          <label>{cms(data?.field_setting?.second_dropdown?.cms_key_depend_field_title)}</label>
          <Select
            options={secondFieldOptions}
            id={id}
            onChange={(e) => setSelectedSecondOption(e)}
            value={selectedSecondOption ? selectedSecondOption : null}
            styles={{ menu: (base) => ({ ...base, zIndex: 15000 }) }}
            isLoading={loading}
            // isDisabled={!inlineEditActive}
            // placeholder=''
            // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
            // isSearchable={ inlineEditActive }
            // menuIsOpen={inlineEditActive}
            {...props}
          />
        </Col>
      )}
      {firstFieldObject && (
        <Col md={12} style={{ marginTop: '1rem', maxHeight: '16rem', overflow: 'scroll' }}>
          <RenderThirdDependTable
            dependTableRow={dependTableRow}
            tableSecondColumnOptions={tableSecondColumnOptions}
            thirdTableValue={thirdTableValue}
            setThirdTableValue={setThirdTableValue}
            title={data?.field_setting?.table_field?.cms_key_depend_table_field_title}
            setDependTableRow={setDependTableRow}
          />
        </Col>
      )}
      <AvField type='hidden' name={data?.field_id} value={selectedOption?.value}></AvField>
      <AvField name={data?.field_setting?.second_dropdown?.depend_field_id} type='hidden' value={selectedSecondOption?.value}></AvField>
      <AvField
        name={data?.field_setting?.table_field?.depend_table_field_id}
        type='hidden'
        value={finalFieldValue?.[data?.field_setting?.table_field?.depend_table_field_id]}></AvField>
    </Row>
  )
}

export default connect(AdvancedMultiSelect)(CMSSelect)
