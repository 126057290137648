export const FETCH_USER = 'FETCH_USER'
export const SET_USER = 'SET_USER'
export const SET_CREDENTIALS = 'SET_CREDENTIALS'
export const CLEAR_CREDENTIALS = 'CLEAR_CREDENTIALS'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_KEEP_ME_LOGGEDIN = 'SET_KEEP_ME_LOGGEDIN'
export const SET_AUTH = 'SET_AUTH'
export const USER_TIMEOUT = 'USER_TIMEOUT'
export const LOGIN_AT = 'LOGIN_AT'
export const RESET_USER = 'RESET_USER'
export const USER_REGISTER = 'USER_REGISTER'
export const SET_REGISTER = 'SET_REGISTER'
export const CLEAR_REGISTER = 'CLEAR_REGISTER'
export const POST_FORGOT_PASSWORD = 'POST_FORGOT_PASSWORD'
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD'
export const CLEAR_FORGOT_PASSWORD = 'CLEAR_FORGOT_PASSWORD'
export const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD'
export const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD'
export const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD'
export const SET_DEFAULT_URL = 'SET_DEFAULT_URL'
export const SET_USER_PROFILE_PIC = 'SET_USER_PROFILE_PIC'

const initialSate = {
  user: undefined,
  isAuth: false,
  keepme: false,
  loginAt: null,
  token: undefined,
  defaultUrl: '',
  credentials: undefined,
  register: undefined,
  forgotPassword: undefined,
  resetPassword: undefined,
}

export const setCredentials = (credentials) => ({
  type: SET_CREDENTIALS,
  credentials,
})

export const setDefaultUrl = (defaultUrl) => ({
  type: SET_DEFAULT_URL,
  defaultUrl,
})

export const clearCredentials = () => ({
  type: CLEAR_CREDENTIALS,
})

export const setKeepLoggedIn = () => ({
  type: SET_KEEP_ME_LOGGEDIN,
})

export const logTime = (loginAt) => ({
  type: LOGIN_AT,
  loginAt,
})

export const authLogin = () => ({
  type: AUTH_LOGIN,
})

export const setToken = (token) => ({
  type: SET_TOKEN,
  token,
})

export const fetchUser = (data) => ({
  type: FETCH_USER,
  data,
})

export const setUser = (user) => ({
  type: SET_USER,
  user,
})

export const setIsAuth = (isAuth) => ({
  type: SET_AUTH,
  isAuth,
})

export const userTimeout = () => ({
  type: USER_TIMEOUT,
})

export const authLogout = () => ({
  type: AUTH_LOGOUT,
})

export const setRegister = (register) => ({
  type: SET_REGISTER,
  register,
})

export const userRegister = () => ({
  type: USER_REGISTER,
})

export const clearRegister = () => ({
  type: CLEAR_REGISTER,
})

export const setForgotPassword = (forgotPassword) => ({
  type: SET_FORGOT_PASSWORD,
  forgotPassword,
})

export const postForgotPassword = () => ({
  type: POST_FORGOT_PASSWORD,
})

export const clearForgotPassword = () => ({
  type: CLEAR_FORGOT_PASSWORD,
})

export const setResetPassword = (resetPassword) => ({
  type: SET_RESET_PASSWORD,
  resetPassword,
})

export const postResetPassword = () => ({
  type: POST_RESET_PASSWORD,
})

export const clearResetPassword = () => ({
  type: CLEAR_RESET_PASSWORD,
})

export const resetUser = () => ({
  type: RESET_USER,
})

export const setUserProfilePic = (url) => ({
  type: SET_USER_PROFILE_PIC,
  url,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case SET_USER:
      const { user } = action
      return { ...state, user }
    case SET_AUTH:
      const { isAuth } = action
      return { ...state, isAuth }
    case SET_CREDENTIALS:
      const { credentials } = action
      return { ...state, credentials }
    case SET_KEEP_ME_LOGGEDIN:
      return { ...state, keepme: true }
    case CLEAR_CREDENTIALS:
      return { ...state, credentials: undefined }
    case SET_TOKEN:
      const { token } = action
      return { ...state, token }
    case SET_DEFAULT_URL:
      const { defaultUrl } = action
      return { ...state, defaultUrl }
    case LOGIN_AT:
      const { loginAt } = action
      return { ...state, loginAt }
    case SET_REGISTER:
      const { register } = action
      return { ...state, register }
    case CLEAR_REGISTER:
      return { ...state, register: undefined }
    case SET_FORGOT_PASSWORD:
      const { forgotPassword } = action
      return { ...state, forgotPassword }
    case CLEAR_FORGOT_PASSWORD:
      return { ...state, forgotPassword: undefined }
    case SET_RESET_PASSWORD:
      const { resetPassword } = action
      return { ...state, resetPassword }
    case CLEAR_RESET_PASSWORD:
      return { ...state, resetPassword: undefined }
    case RESET_USER:
      return initialSate
    case SET_USER_PROFILE_PIC:
      return { ...state, user: { ...state.user, photo: action.url } }
    default:
  }
  return state
}
