import { useState } from 'react'
import { Button } from 'reactstrap'
import IconSet from '../../icon'
import AddEditSlide from './addEditSlide'
import SlideList from './slideList'

const SlideMain = () => {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false)
  const [editItem, setEditItem] = useState({
    data: null,
    open: false,
  })
  return (
    <div>
      <div className='d-flex mb-2 justify-content-end'>
        <Button
          color={isAddFormOpen ? 'danger' : 'success'}
          onClick={() => {
            setIsAddFormOpen(!isAddFormOpen)
            setEditItem({
              data: null,
              open: false,
            })
          }}
          className='btn m-1 float-right'>
          <IconSet icon={isAddFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
        </Button>
      </div>
      {isAddFormOpen ? (
        <AddEditSlide setIsAddFormOpen={setIsAddFormOpen} />
      ) : (
        <SlideList editItem={editItem} setEditItem={setEditItem} setIsAddFormOpen={setIsAddFormOpen} />
      )}
    </div>
  )
}

export default SlideMain
