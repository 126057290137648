import { useState } from "react"
import { Alert } from "reactstrap"
import IconSet from "../../../icon"

const GridNote = ({gridNote}) =>{
  const [isExpanded, setIsExpanded] = useState(false)

  //<i class="fa-sharp fa-solid fa-chevron-down"></i>
  if (gridNote === '<div></div>') return
    return (
      <Alert color='info' style={{ maxHeight: isExpanded ? 'fit-content' : '3rem' }} className='grid_note_alert'>
        <div dangerouslySetInnerHTML={{ __html: gridNote }} style={{ flex: 1 }}></div>
        <div style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
          {!isExpanded ? (
            <IconSet icon='fa-sharp fa-solid fa-chevron-down' color='info' style={{ fontSize: '1.5rem' }} />
          ) : (
            <IconSet icon='fa-sharp fa-solid fa-chevron-up' color='info' style={{ fontSize: '1.5rem' }} />
          )}
        </div>
      </Alert>
    )
}

export default GridNote