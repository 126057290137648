import { useSelector } from 'react-redux'
import BarChart from '../../../../../CMSComponent/Common/Chart/barChart'
import { ChartDatasetOptions, ChartOptions } from '../../../../../CMSComponent/Common/Chart/chartHelper'
import { cms, editCMS } from '../../../../../helper'

const CompletedByTaskType = ({ dashboardData }) => {
  const dashboard_cms_key = useSelector((state) => state.CMS?.dashboardCMSKey?.completed_by_task_types?.cms_key_heading)
  const editMode = useSelector((state) => state.CMS?.editMode)
  const completedByTaskTypeData = dashboardData?.completed_by_task_types
  let label = completedByTaskTypeData?.map((item) => item?.type_name)
  let value = completedByTaskTypeData?.map((item, index) => parseInt(item?.time))
  // let value = [4,40]

  const options = {
    ...ChartOptions,
    plugins: {
      ...ChartOptions?.plugins,
    },

    scales: {
      ...ChartOptions.scales,
      y: {
        ...ChartOptions.scales.y,
        title: {
          display: true,
          text: 'MINUTES',
          color: 'black',
        },
      },
      x: {
        ...ChartOptions.scales.x,
        title: {
          display: true,
          text: 'TASK TYPES',
          color: 'black',
        },
      },
    },
  }

  const data = {
    labels: label,
    datasets: [
      {
        ...ChartDatasetOptions,
        label: 'Completed By Task Type',

        data: value,
        maxBarThickness: 40,
      },
    ],
  }

  return (
    <div style={{ padding: '1rem', height: '100%' }}>
      <div className='dashboard-chart-title' style={{ cursor: editMode ? 'pointer' : '' }} onClick={() => editCMS(dashboard_cms_key)}>
        {cms(dashboard_cms_key)}
      </div>
      <BarChart options={options} data={data} />
    </div>
  )
}

export default CompletedByTaskType
