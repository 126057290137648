import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { getColor } from '../../helper'
import IconSet from '../icon'

export const PROFILE_MODAL_DEFAULT_DATA = {
  name: 'profile_form',
  component_type: 'buttons',
  component_name: 'nav_buttons',
  permission: 'view_profiles',
  allowed: 1,
  icon_type: 'font_awesome',
  icon: 'fas fa-user',
  component_call_data_connection: {
    commponent_type: 'connect_redux',
    action: 'connect_redux',
    name: 'profile',
  },
  color: 'dark',
  component_call_actions: [
    {
      commponent_type: 'actions',
      component_name: 'open_modal',
      page_name: 'profiles',
      post_data: null,
    },
  ],
  break_left: true,
  text_right_header: 'data.first_name',
  text_right_sub_header: 'data.surname',
}

export const DefaultProfileDropdown = ({ user, nav }) => {
  const history = useHistory()

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {' '}
          {nav?.name === 'profile_form' && user?.photo && user?.photo?.length > 0 ? (
            <img
              src={process.env.REACT_APP_URL + user.photo}
              style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}></img>
          ) : (
            <IconSet
              data-name='name2'
              type={nav.icon_type}
              icon={nav.icon}
              style={{ color: getColor(nav.color) }}
              color={nav.color}
              navheadericon='true'
            />
          )}
        </div>
        <Button color='dark' style={{ borderRadius: '5px' }} onClick={()=>history?.push('/logout')}>
          Logout
        </Button>
      </div>
      <AvForm style={{marginTop:'2rem'}}>
        <AvField type='text' value={user?.full_name} disabled name='name' label='Name' />
        <AvField type='text' value={user?.email} disabled name='email' label='Email' />
        <AvField type='text' value={user?.phone_number} disabled name='phone' label='Phone Number' />
      </AvForm>
    </div>
  )
}
