import { AvField } from 'availity-reactstrap-validation'
import { Multiselect } from 'react-widgets'
  const actionTypes = ['ADD', 'EDIT', 'DELETE', 'RESTORE', 'FILTER', 'SEARCH', 'EXPORT', 'Preferences', 'Mail']

const ActionTypeField = ({ onChange = () => {},value }) => {
  return (
    <>
      <label>Action Types</label>
      <Multiselect data={actionTypes} onChange={(value) => onChange(value)} textField='action_types' className='mb-4' />
      <AvField name='action_types_copy' value={value} type='hidden' required />
    </>
  )
}

export default ActionTypeField
