import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'
import { Button, Col, Container, Row } from 'reactstrap'
import Loader from 'react-loaders'

import bg4 from '../../../assets/utils/images/dropdown-header/abstract4.jpg'
import { getColor } from '../../../helper'
import { submitTopNavButton } from '../../../Redux/Reducers/CMS'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
})

const AddTopNavButton = ({ editPageMode, close }) => {
  const dispatch = useDispatch()
  const [load, updateLoad] = useState(false)
  const [form, updateForm] = useState({
    page_name: '',
    break_left: '',
    icon: '',
    icon_color: 'primary',
    text_right_header: '',
    text_right_sub_header: '',
  })

  const onChange = (e) => {
    updateForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    dispatch(submitTopNavButton(values))
    close()
  }

  useEffect(() => {}, [])

  return (
    <>
      <div className='dropdown-menu-header'>
        <div className='dropdown-menu-header-inner bg-plum-plate'>
          <div
            className='menu-header-image'
            style={{
              backgroundImage: 'url(' + bg4 + ')',
            }}
          />
          <div className='menu-header-content text-white'>
            <h5 className='menu-header-title'>Add Top Nav button</h5>
            <h6 className='menu-header-subtitle'>Please enter some detais about the button.</h6>
          </div>
        </div>
      </div>
      <div className='dropdown-form'>
        <Container>
          <AvForm onValidSubmit={handleValidSubmit}>
            <Row>
              <Col md={4}>
                <AvField
                  name='page_name'
                  label='Button Name'
                  type='text'
                  required
                  validate={{ pattern: { value: '^[A-Za-z0-9_]+$', errorMessage: 'Your name must be composed only with letter and numbers' } }}
                />
              </Col>
              <Col md={4}>
                <AvField name='icon' label='Icon' type='text' />
              </Col>
              <Col md={4}>
                <AvField name='text_right_header' label='Text Right Header' type='text' />
              </Col>
              <Col md={4}>
                <AvField name='text_right_sub_header' label='Text Right Sub Header' type='text' />
              </Col>
              <Col md={4}>
                <label>
                  Icon Color{' '}
                  <div
                    title='#f44336'
                    className='select-color'
                    style={{
                      boxShadow: getColor(form.icon_color) + ' 0px 0px 0px 14px inset',
                    }}></div>
                </label>
                <AvField type='select' name='icon_color' onChange={(e) => onChange(e)}>
                  <option value={'primary'}>primary</option>
                  <option value={'secondary'}>secondary</option>
                  <option value={'accent'}>accent</option>
                  <option value={'dark'}>dark</option>
                  <option value={'danger'}>danger</option>
                  <option value={'info'}>info</option>
                  <option value={'success'}>success</option>
                  <option value={'warning'}>warning</option>
                  <option value={'light'}>light</option>
                </AvField>
              </Col>
              <Col md={4}>
                <AvCheckboxGroup name='break' label='Break'>
                  <AvCheckbox label='Separator at left side' value='break_left' name='break' />
                </AvCheckboxGroup>
              </Col>
              <Col md={12}>
                <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                  {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Submit'}
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </>
  )
}

export default connect(select)(AddTopNavButton)
