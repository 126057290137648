import { AvField } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { cms, editCMS } from '../../../helper'

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
]

const SelectMultipleTemplates = ({ data, cmsEditMode, submittedFormData, value }) => {
  const [options, setOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState()

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])

  console.log('afdvalue:::::::::::', value,selectedOptions)

  useEffect(() => {
    const option = submittedFormData?.templates?.map((template) => {
      return {
        value: template?.id || template?.temporary_id,
        label: template?.item_name || template?.id || template?.temporary_id,
      }
    })
    setOptions(option)
  }, [submittedFormData?.templates])
  
  return (
    <div className='mb-3'>
      {' '}
      <label onClick={() => (cmsEditMode && data?.cms_key ? editCMS(data.cms_key) : null)}>{cms(data?.cms_key)}</label>
      <Select
        options={options}
        isMulti={true}
        value={selectedOptions}
        onChange={(v) => setSelectedOptions(v)}
        classNamePrefix='select'
        styles={{
          menu: (base) => ({ ...base, zIndex: 15000 }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            // borderColor: isFieldInSync ? '#30B1ff' : '#ced4da',
          }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: 'hsl(0, 0%, 70%)',
            }
          },
        }}
        isClearable
      />
      <AvField type='hidden' name={data?.field_id || data?.name} value={selectedOptions}></AvField>
    </div>
  )
}

export default SelectMultipleTemplates
