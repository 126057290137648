import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Form } from 'reactstrap'
import Rodal from 'rodal'

import { cms, tidyName } from '../helper'
import { closeModal, submitCmsModal, setModalValue, setCMS, updateCMSKey } from '../Redux/Reducers/CMS'
import { setQueue } from '../Redux/Reducers/queue'

const select = (state) => ({
  editModal: state.CMS.editModal,
  cmsModalValue: state.CMS.cmsModalValue,
  cmsData: state.CMS.cmsData,
  cmsEditDefaultValue: state.CMS.cmsEditDefaultValue,
})

const CMSModal = ({ editModal, cmsModalValue, cmsData, cmsEditDefaultValue }) => {
  const dispatch = useDispatch()
  const [inputValue, updateInputValue] = useState('')
  const [defaultValue, setDefaultValue] = useState('')

  const handleCmsValue = (value) => {
    updateInputValue(value)
    let new_data = {
      ...cmsData,
    }
    new_data[cmsModalValue?.key] = value
    dispatch(updateCMSKey(new_data))
    dispatch(setModalValue({ key: cmsModalValue.key, value }))
  }

  useEffect(() => {
    updateInputValue(cmsModalValue.value)
  }, [cmsModalValue])

  const onSubmit = () => {
    dispatch(
      setQueue({
        url: 'cms-update',
        payload: {
          data: { key: cmsModalValue.key, value: inputValue },
        },
      })
    )
    dispatch(closeModal())
  }

  const onCancel = () => {
    let new_data = {
      ...cmsData,
    }

    new_data[cmsEditDefaultValue?.key] = cmsEditDefaultValue?.value

    dispatch(updateCMSKey(new_data))
    dispatch(closeModal())
  }

  return (
    <Fragment>
      <Rodal visible={editModal} onClose={() => onCancel()} animation='zoom' showMask={false} style={{ zIndex: 4000 }}>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
            // dispatch(submitCmsModal())
          }}>
          <ModalHeader>Edit "{tidyName(cmsModalValue.key)}"</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input type='text' name='cms' value={inputValue || ''} onChange={(e) => handleCmsValue(e.target.value)} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button type='button' color='link' onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button type='submit' color='primary' id='edit-cms-btn'>
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Rodal>
    </Fragment>
  )
}

export default connect(select)(CMSModal)
