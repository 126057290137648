import React, { Fragment } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'

// import PageTitle from "../../../../Layout/AppMain/PageTitle";
import PageTitle from '../../../Layout/AppMain/pageTitle'
import { cms, editCMS, validateForAv } from '../../../helper'

import { SketchPicker, CompactPicker, SwatchesPicker, TwitterPicker, CirclePicker, HuePicker, AlphaPicker, BlockPicker } from 'react-color'

export default class FormColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    background: '#000000',
  }

  componentDidMount() {
    this.setState({ background: this.props.defaultValue })
    this.props?.onChange(this.props.data?.field_id, this.props.defaultValue)
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange2 = (colors) => {
    this.setState({ background: colors.hex })
  }
  handleChangeComplete = (colors) => {
    this.setState({ background: colors.hex })

    // this.setState({ color: colors.hex });
  }

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const { isForm, onChange } = this.props
    const data = this.props.data

    return (
      <Fragment>
        <label>{cms(data?.cms_key)}</label>
        {/* <PageTitle heading="Forms Color Picker"
          subheading="Color Pickers following the design from Photoshop, Sketch, Chrome, Twitter and more."
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"/> */}
        <CSSTransitionGroup
          component='div'
          transitionName='TabsAnimation'
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row className='mt-2'>
            <Col md='4'>
              {/* <Card className="main-card mb-3">
                <CardBody>
                  <Button color="primary btn-wide btn-shadow" onClick={this.handleClick}>
                    Pick Color
                  </Button>
                  {this.state.displayColorPicker ? (
                    <div style={popover}>
                      <div style={cover} onClick={this.handleClose} />
                      <TwitterPicker />
                    </div>
                  ) : null}
                </CardBody>
              </Card> */}
              <Card className='main-card mb-3'>
                <CardBody>
                  <SketchPicker
                    className='mx-auto mb-4'
                    color={this.state.background}
                    onChange={this.handleChange2}
                    onChangeComplete={(colors) => onChange(data?.field_id, colors.hex)}
                  />
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="4">
              <Card className="main-card mb-3">
                <CardBody>
                  <SwatchesPicker className="mx-auto" />
                </CardBody>
              </Card>
              <Card className="main-card mb-3">
                <CardBody>
                  <BlockPicker className="mx-auto" />
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col md="4">
              <Card className="main-card mb-3">
                <CardBody>
                  <CirclePicker className="mx-auto" />
                </CardBody>
              </Card>
              <Card className="main-card mb-3">
                <CardBody>
                  <CompactPicker className="mx-auto" />
                </CardBody>
              </Card>
              <Card className="main-card mb-3">
                <CardBody>
                  <HuePicker className="mx-auto mb-3" />
                  <AlphaPicker className="mx-auto" />
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}
