import React, { useState } from 'react'
import { Col, Row, Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { editMode as updateCMSEditMode } from '../../Redux/Reducers/CMS'
import { reduxLoad } from '../../Redux/Reducers'

import bg4 from '../../assets/utils/images/dropdown-header/abstract4.jpg'

import { can, cms, editCMS } from '../../helper'
import IconSet from '../icon'
import NoData from './noData'

const ButtonMenu = ({ page, CMSData }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [cmsEditMode, updateEditMode] = useState(CMSData?.editMode)

  const renderButtons = () => {
    return page.component_call_buttons.map((item, key) => {
      if (!item.component_call_actions && item.component_call_actions.length === 0) {
        return null
      } else {
        if (can(item.permission)) {
          switch (item.component_call_actions[0].component_name) {
            case 'open_page':
              return (
                <Col xl='4' sm='6' key={key}>
                  <Button
                    className='btn-icon-vertical btn-square btn-transition'
                    onClick={() => (cmsEditMode ? editCMS(item.cms_tab_key) : history.push('/' + item.component_call_actions[0].url))}
                    outline
                    color='link'>
                    <IconSet
                      className='icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3'
                      icon={item.icon || 'pe-7s-attention'}
                      type={item.type}
                    />
                    {cms(item.cms_tab_key)}
                  </Button>
                </Col>
              )
            case 'edit_cms':
              return (
                <Col xl='4' sm='6' key={key}>
                  <Button
                    onClick={() => handleEditCMS(!cmsEditMode)}
                    className='btn-icon-vertical btn-square btn-transition edit-cms-button'
                    outline
                    color='link'>
                    <IconSet className='icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3' icon={item.icon} type={item.type} />
                    {!cmsEditMode ? 'Edit CMS' : 'Close Edit CMS'}
                  </Button>
                </Col>
              )
            case 'refresh_cms':
              return (
                <Col xl='4' sm='6' key={key}>
                  <Button
                    onClick={() => (cmsEditMode ? editCMS(item.cms_tab_key) : dispatch(reduxLoad()))}
                    className='btn-icon-vertical btn-square btn-transition'
                    outline
                    color='link'>
                    <IconSet className='icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3' icon={item.icon} type={item.type} />
                    {cms(item.cms_tab_key)}
                  </Button>
                </Col>
              )
            default:
              return null
          }
        }
      }
    })
  }

  const handleEditCMS = (mode) => {
    dispatch(updateCMSEditMode(mode))
    updateEditMode(mode)
  }

  if (page.allowed && can(page.permission)) {
    return (
      <>
        <div className='dropdown-menu-header'>
          <div className='dropdown-menu-header-inner bg-plum-plate'>
            <div
              className='menu-header-image'
              style={{
                backgroundImage: 'url(' + bg4 + ')',
              }}
            />
            <div className='menu-header-content text-white'>
              <h5 className='menu-header-title' onClick={() => editCMS(page.cms_header_name)}>
                {cms(page.cms_header_name)}
              </h5>
              <h6 className='menu-header-subtitle' onClick={() => editCMS(page.cms_sub_header_name)}>
                {cms(page.cms_sub_header_name)}
              </h6>
            </div>
          </div>
        </div>
        <div className='grid-menu grid-menu-xl grid-menu-3col'>
          <Row className='no-gutters'>{renderButtons()}</Row>
        </div>
      </>
    )
  } else {
    return <NoData message={page.not_allowed_message ? page.not_allowed_message : 'You do not have permission to see this.'} />
  }
}
export default ButtonMenu
