import { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
//Drag and Drop.
import RLDD from 'react-list-drag-and-drop/lib/RLDD'

import Icon from '../../icon'
import { removeItemByName, submitRequest } from '../../../Redux/Reducers/CMS'
import ConfirmButton from '../../Common/confirmButton'
import { cms } from '../../../helper'
import FormToggleSwitch from '../../Forms/Fields/switchButton'
import Dropdown from './Fields/dropdown'
import CommonField from './Fields/commonField'
import SubmitButton from './Fields/submitButton'
import FileUpload from './Fields/fileUpload'
import HeaderSection from './Fields/headerSection'
import Spaccer from './Fields/spaccer'
import PrePostTime from './Fields/prepostTime'
import AdvancedMultiSelect from './Fields/advancedMultiSelect'
import MultipleInput from './Fields/multipleInput'

const CreateField = ({
  coreData,
  tab = false,
  hasReduxData,
  reduxDataValidate,
  manageDatabaseValidate,
  formName,
  pageName,
  setModal,
  section,
  isTab,
  setAddForm,
  source_table,
  isLayout,
  rowIndex,
  blockIndex,
  allFields,
  isCreatingFieldForRepeatingForm = false,
  selectedFieldType,
  grid_id,
  parentPageName,
  isAddDataForm,
}) => {
  const [selectedField, setSelectedField] = useState('dropdown')

  const blockFields = allFields
    ?.filter((field) => field?.row_index === rowIndex || field?.field_setting?.row_index === rowIndex)
    ?.filter((field) => field?.block_index === blockIndex || field?.field_setting?.block_index === blockIndex)
  const renderFields = () => {
    switch (selectedField) {
      case 'dropdown':
        return (
          <Dropdown
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            setAddForm={setAddForm}
            tab={tab}
            section={section}
            source_table={source_table}
            is_create={true}
            type='dropdown'
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            grid_id={grid_id}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )
      case 'multi_checkbox':
        return (
          <Dropdown
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            setAddForm={setAddForm}
            tab={tab}
            section={section}
            source_table={source_table}
            is_create={true}
            type='multi_checkbox'
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            grid_id={grid_id}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )
      case 'multi_radio':
        return (
          <Dropdown
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            setAddForm={setAddForm}
            tab={tab}
            section={section}
            source_table={source_table}
            is_create={true}
            type='multi_radio'
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            grid_id={grid_id}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )

      case 'submit_button':
        return (
          <SubmitButton
            tab={tab}
            section={section}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            type={selectedField}
            setAddForm={setAddForm}
            source_table={source_table}
            is_create={true}
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )
      case 'file_upload':
        return (
          <FileUpload
            tab={tab}
            section={section}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            type={selectedField}
            setAddForm={setAddForm}
            source_table={source_table}
            is_create={true}
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
            grid_id={grid_id}
          />
        )
      case 'section_heading':
        return (
          <HeaderSection
            tab={tab}
            section={section}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            type={selectedField}
            setAddForm={setAddForm}
            source_table={source_table}
            is_create={true}
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            grid_id={grid_id}
            selectedField={selectedField}
          />
        )
      case 'spacer':
        return (
          <Spaccer
            tab={tab}
            section={section}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            type={selectedField}
            setAddForm={setAddForm}
            source_table={source_table}
            is_create={true}
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )
      case 'post_time':
      case 'pre_time':
        return (
          <PrePostTime
            tab={tab}
            section={section}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            type={selectedField}
            setAddForm={setAddForm}
            source_table={source_table}
            is_create={true}
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            grid_id={grid_id}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )
      case 'advanced_multi_select':
        return (
          <AdvancedMultiSelect
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            setAddForm={setAddForm}
            tab={tab}
            section={section}
            source_table={source_table}
            is_create={true}
            type='advanced_multi_select'
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            grid_id={grid_id}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )
      case 'multiple_input':
        return (
          <MultipleInput
            tab={tab}
            section={section}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            type={selectedField}
            setAddForm={setAddForm}
            source_table={source_table}
            is_create={true}
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            grid_id={grid_id}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )

      default:
        return (
          <CommonField
            tab={tab}
            section={section}
            formName={formName}
            pageName={pageName}
            setModal={setModal}
            type={selectedField}
            setAddForm={setAddForm}
            source_table={source_table}
            is_create={true}
            isLayout={isLayout}
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            allFields={allFields}
            blockFields={blockFields}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            grid_id={grid_id}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            selectedField={selectedField}
          />
        )
    }
  }
  const handleValidSubmit = () => {}

  return (
    <div>
      <AvForm onValidSubmit={handleValidSubmit}>
        <Row>
          <Col md={12}>
            <AvField type='select' label='Field Type' name={'type'} onChange={(e) => setSelectedField(e.target.value)}>
              <option value={'dropdown'}>Dropdown</option>
              <option value={'input'}>Input</option>
              <option value={'date'}>Date</option>
              <option value={'time'}>Time</option>
              <option value={'static_text'}>Static Text</option>
              <option value={'rich_text'}>Rich Text</option>
              <option value={'submit_button'}>Submit Button</option>
              <option value={'chart_pill'}>Chart Pill</option>
              <option value={'toggle_switch'}>Toggle Switch</option>
              <option value={'multi_checkbox'}>Checkbox</option>
              <option value={'multi_radio'}>Radio</option>
              <option value={'color_picker'}>Color picker</option>
              <option value={'date_time_picker'}>Date time picker</option>
              <option value={'date_time_range_picker'}>Date time Range picker</option>
              <option value={'file_upload'}>File upload</option>
              <option value={'section_heading'}>Header Section</option>
              <option value={'spacer'}>Spacer</option>
              <option value={'pre_time'}>Pre Time</option>
              <option value={'post_time'}>Post Time</option>
              <option value={'advanced_multi_select'}>Advanced Multi Select</option>
              <option value={'multiple_input'}>Multiple Input</option>
            </AvField>
          </Col>
        </Row>
      </AvForm>
      <Row></Row>
      <div style={{ paddingLeft: '1rem', paddingRight: '2rem' }}>{renderFields()}</div>
    </div>
  )
}

export default CreateField
