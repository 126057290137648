import sideBar6 from '../../assets/utils/images/sidebar/city1.jpg'

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE'
export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU'
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL'
export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER'
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW'
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW'
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR'
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER'
export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON'
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING'
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT'
export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE'
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR'
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME'
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY'
export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR'
export const SET_BODY_COLOR = 'THEME_OPTIONS/SET_BODY_COLOR'
export const SET_TITLE_AREA_COLOR = 'THEME_OPTIONS/SET_TITLE_AREA_COLOR'
export const SET_FONT_FAMILY = 'THEME_OPTIONS/SET_FONT_FAMILY'
export const SET_HEADER_FONT_FAMILY = 'THEME_OPTIONS/SET_HEADER_FONT_FAMILY'
export const SET_SUB_HEADER_FONT_FAMILY = 'THEME_OPTIONS/SET_SUB_HEADER_FONT_FAMILY'

export const SET_LOGO_TEXT_COLOR = 'THEME_OPTIONS/SET_LOGO_TEXT_COLOR'
export const SET_LOGIN_TITLE = 'THEME_OPTIONS/SET_LOGIN_TITLE'
export const SET_SLIDER_TITLE = 'THEME_OPTIONS/SET_SLIDER_TITLE'
export const SET_SLIDER_SUB_TITLE = 'THEME_OPTIONS/SET_SLIDER_SUB_TITLE'
export const SET_LOGIN_SUB_TITLE = 'THEME_OPTIONS/SET_LOGIN_SUB_TITLE'
export const SET_FORGOT_TITLE = 'THEME_OPTIONS/SET_FORGOT_TITLE'
export const SET_FORGOT_SUB_TITLE = 'THEME_OPTIONS/SET_FORGOT_SUB_TITLE'
export const SET_SIGNUP_TITLE = 'THEME_OPTIONS/SET_SIGNUP_TITLE'
export const SET_SIGNUP_SUB_TITLE = 'THEME_OPTIONS/SET_SIGNUP_SUB_TITLE'
export const SET_AUTH_TITLE_COLOR = 'THEME_OPTIONS/SET_AUTH_TITLE_COLOR'
export const SET_AUTH_SUB_TITLE_COLOR = 'THEME_OPTIONS/SET_AUTH_SUB_TITLE_COLOR'

export const FETCH_THEME_OPTIONS = 'FETCH_THEME_OPTIONS'
export const SET_THEME_OPTIONS = 'SET_THEME_OPTIONS'
export const RESET_THEME_OPTIONS = 'RESET_THEME_OPTIONS'
export const SET_PRIMARY_COLOR = 'THEME_OPTIONS/SET_PRIMARY_COLOR'
export const SAVE_CUSTOM_COLOR = 'SAVE_CUSTOM_COLOR'
export const DELETE_CUSTOM_COLOR = 'DELETE_CUSTOM_COLOR'

export const TOGGLE_SIGNUP_ENALBLE_DISABLE = 'TOGGLE_SIGNUP_ENALBLE_DISABLE'

export const setEnableBackgroundImage = (enableBackgroundImage) => ({
  type: SET_ENABLE_BACKGROUND_IMAGE,
  enableBackgroundImage,
})

export const setEnableFixedHeader = (enableFixedHeader) => ({
  type: SET_ENABLE_FIXED_HEADER,
  enableFixedHeader,
})

export const setEnableHeaderShadow = (enableHeaderShadow) => ({
  type: SET_ENABLE_HEADER_SHADOW,
  enableHeaderShadow,
})

export const setEnableSidebarShadow = (enableSidebarShadow) => ({
  type: SET_ENABLE_SIDEBAR_SHADOW,
  enableSidebarShadow,
})

export const setEnablePageTitleIcon = (enablePageTitleIcon) => ({
  type: SET_ENABLE_PAGETITLE_ICON,
  enablePageTitleIcon,
})

export const setEnablePageTitleSubheading = (enablePageTitleSubheading) => ({
  type: SET_ENABLE_PAGETITLE_SUBHEADING,
  enablePageTitleSubheading,
})

export const setEnablePageTabsAlt = (enablePageTabsAlt) => ({
  type: SET_ENABLE_PAGE_TABS_ALT,
  enablePageTabsAlt,
})

export const setEnableFixedSidebar = (enableFixedSidebar) => ({
  type: SET_ENABLE_FIXED_SIDEBAR,
  enableFixedSidebar,
})

export const setEnableMobileMenu = (enableMobileMenu) => ({
  type: SET_ENABLE_MOBILE_MENU,
  enableMobileMenu,
})

export const setEnableMobileMenuSmall = (enableMobileMenuSmall) => ({
  type: SET_ENABLE_MOBILE_MENU_SMALL,
  enableMobileMenuSmall,
})

export const setEnableFixedFooter = (enableFixedFooter) => ({
  type: SET_ENABLE_FIXED_FOOTER,
  enableFixedFooter,
})

export const setBackgroundColor = (backgroundColor) => ({
  type: SET_BACKGROUND_COLOR,
  backgroundColor,
})

export const setBodyColor = (bodyColor) => ({
  type: SET_BODY_COLOR,
  bodyColor,
})

export const setTitleAreaColor = (titleAreaColor) => ({
  type: SET_TITLE_AREA_COLOR,
  titleAreaColor,
})

export const setFontFamily = (fontFamily) => ({
  type: SET_FONT_FAMILY,
  fontFamily,
})
export const setLogInTitle = (logInTitle) => ({
  type: SET_LOGIN_TITLE,
  logInTitle,
})

export const setSliderTitle = (sliderTitle) => ({
  type: SET_SLIDER_TITLE,
  sliderTitle,
})

export const setSliderSubTitle = (sliderSubTitle) => ({
  type: SET_SLIDER_SUB_TITLE,
  sliderSubTitle,
})

export const setLogInSubTitle = (logInSubTitle) => ({
  type: SET_LOGIN_SUB_TITLE,
  logInSubTitle,
})

export const setSignUpTitle = (signUpTitle) => ({
  type: SET_SIGNUP_TITLE,
  signUpTitle,
})

export const setSignUpSubTitle = (signUpSubTitle) => ({
  type: SET_SIGNUP_SUB_TITLE,
  signUpSubTitle,
})

export const setForgotTitle = (forgotTitle) => ({
  type: SET_FORGOT_TITLE,
  forgotTitle,
})

export const setForgotSubTitle = (forgotSubTitle) => ({
  type: SET_FORGOT_SUB_TITLE,
  forgotSubTitle,
})

export const setLogoTextColor = (color) => ({
  type: SET_LOGO_TEXT_COLOR,
  color,
})

export const setAuthTitleColor = (authTitleColor) => ({
  type: SET_AUTH_TITLE_COLOR,
  authTitleColor,
})

export const setAuthSubTitleColor = (authSubTitleColor) => ({
  type: SET_AUTH_SUB_TITLE_COLOR,
  authSubTitleColor,
})

export const setHeaderFontFamily = (headerFontFamily) => ({
  type: SET_HEADER_FONT_FAMILY,
  headerFontFamily,
})

export const setSubHeaderFontFamily = (subHeaderFontFamily) => ({
  type: SET_SUB_HEADER_FONT_FAMILY,
  subHeaderFontFamily,
})

export const setHeaderBackgroundColor = (headerBackgroundColor) => ({
  type: SET_HEADER_BACKGROUND_COLOR,
  headerBackgroundColor,
})

export const setColorScheme = (colorScheme) => ({
  type: SET_COLOR_SCHEME,
  colorScheme,
})

export const setBackgroundImageOpacity = (backgroundImageOpacity) => ({
  type: SET_BACKGROUND_IMAGE_OPACITY,
  backgroundImageOpacity,
})

export const setBackgroundImage = (backgroundImage) => ({
  type: SET_BACKGROUND_IMAGE,
  backgroundImage,
})

export const fetchThemeOptions = () => ({
  type: FETCH_THEME_OPTIONS,
})

export const setThemeOptions = (options) => ({
  type: SET_THEME_OPTIONS,
  options,
})

export const resetThemeOptions = () => ({
  type: RESET_THEME_OPTIONS,
})

export const setPrimaryColor = (primaryColor) => ({
  type: SET_PRIMARY_COLOR,
  primaryColor,
})

export const toggleSignUpEnableDisable = (enableRegisterButton) => ({
  type: TOGGLE_SIGNUP_ENALBLE_DISABLE,
  enableRegisterButton,
})

export const saveCustomColor = (customColor) => ({
  type: SAVE_CUSTOM_COLOR,
  customColor,
})

export const deleteCustomColor = (uuid) => ({
  type: DELETE_CUSTOM_COLOR,
  uuid,
})

const initialState = {
  backgroundColor: '',
  headerBackgroundColor: '',
  bodyColor: '',
  titleAreaColor: '',
  enableMobileMenuSmall: '',
  enableBackgroundImage: false,
  enableClosedSidebar: false,
  enableFixedHeader: true,
  enableHeaderShadow: true,
  enableSidebarShadow: true,
  enableFixedFooter: true,
  enableFixedSidebar: true,
  colorScheme: 'white',
  backgroundImage: sideBar6,
  backgroundImageOpacity: 'opacity-06',
  enablePageTitleIcon: true,
  enablePageTitleSubheading: true,
  enablePageTabsAlt: true,
  updatedAt: null,
  fontFamily: 'sans_sarif',
  headerFontFamily: 'sans_sarif',
  subHeaderFontFamily: 'sans_sarif',
  enableRegisterButton: true,
  customColors: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ENABLE_BACKGROUND_IMAGE:
      return {
        ...state,
        enableBackgroundImage: action.enableBackgroundImage,
      }

    case SET_ENABLE_FIXED_HEADER:
      return {
        ...state,
        enableFixedHeader: action.enableFixedHeader,
      }

    case SET_ENABLE_HEADER_SHADOW:
      return {
        ...state,
        enableHeaderShadow: action.enableHeaderShadow,
      }

    case SET_ENABLE_SIDEBAR_SHADOW:
      return {
        ...state,
        enableSidebarShadow: action.enableSidebarShadow,
      }

    case SET_ENABLE_PAGETITLE_ICON:
      return {
        ...state,
        enablePageTitleIcon: action.enablePageTitleIcon,
      }

    case SET_ENABLE_PAGETITLE_SUBHEADING:
      return {
        ...state,
        enablePageTitleSubheading: action.enablePageTitleSubheading,
      }

    case SET_ENABLE_PAGE_TABS_ALT:
      return {
        ...state,
        enablePageTabsAlt: action.enablePageTabsAlt,
      }

    case SET_ENABLE_FIXED_SIDEBAR:
      return {
        ...state,
        enableFixedSidebar: action.enableFixedSidebar,
      }

    case SET_ENABLE_MOBILE_MENU:
      return {
        ...state,
        enableMobileMenu: action.enableMobileMenu,
      }

    case SET_ENABLE_MOBILE_MENU_SMALL:
      return {
        ...state,
        enableMobileMenuSmall: action.enableMobileMenuSmall,
      }

    case SET_ENABLE_FIXED_FOOTER:
      return {
        ...state,
        enableFixedFooter: action.enableFixedFooter,
      }

    case SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.backgroundColor,
      }

    case SET_BODY_COLOR:
      return {
        ...state,
        bodyColor: action.bodyColor,
      }
    case SET_TITLE_AREA_COLOR:
      return {
        ...state,
        titleAreaColor: action.titleAreaColor,
      }

    case SET_HEADER_BACKGROUND_COLOR:
      return {
        ...state,
        headerBackgroundColor: action.headerBackgroundColor,
      }

    case SET_COLOR_SCHEME:
      return {
        ...state,
        colorScheme: action.colorScheme,
      }

    case SET_BACKGROUND_IMAGE:
      return {
        ...state,
        backgroundImage: action.backgroundImage,
      }

    case SET_BACKGROUND_IMAGE_OPACITY:
      return {
        ...state,
        backgroundImageOpacity: action.backgroundImageOpacity,
      }
    case SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      }
    case SET_LOGIN_TITLE:
      return {
        ...state,
        logInTitle: action.logInTitle,
      }
    case SET_SLIDER_TITLE:
      return {
        ...state,
        sliderTitle: action.sliderTitle,
      }
    case SET_SLIDER_SUB_TITLE:
      return {
        ...state,
        sliderSubTitle: action.sliderSubTitle,
      }
    case SET_LOGIN_SUB_TITLE:
      return {
        ...state,
        logInSubTitle: action.logInSubTitle,
      }
    case SET_SIGNUP_TITLE:
      return {
        ...state,
        signUpTitle: action.signUpTitle,
      }
    case SET_SIGNUP_SUB_TITLE:
      return {
        ...state,
        signUpSubTitle: action.signUpSubTitle,
      }
    case SET_FORGOT_TITLE:
      return {
        ...state,
        forgotTitle: action.forgotTitle,
      }
    case SET_FORGOT_SUB_TITLE:
      return {
        ...state,
        forgotSubTitle: action.forgotSubTitle,
      }
    case SET_LOGO_TEXT_COLOR:
      return {
        ...state,
        logoTextColor: action.color,
      }
    case SET_AUTH_TITLE_COLOR:
      return {
        ...state,
        authTitleColor: action.authTitleColor,
      }
    case SET_AUTH_SUB_TITLE_COLOR:
      return {
        ...state,
        authSubTitleColor: action.authSubTitleColor,
      }
    case SET_HEADER_FONT_FAMILY:
      return {
        ...state,
        headerFontFamily: action.headerFontFamily,
      }
    case SET_SUB_HEADER_FONT_FAMILY:
      return {
        ...state,
        subHeaderFontFamily: action.subHeaderFontFamily,
      }
    case SET_PRIMARY_COLOR:
      return {
        ...state,
        primaryColor: action.primaryColor,
      }
    case SET_THEME_OPTIONS:
      const { options } = action
      return {
        updatedAt: new Date(),
        ...options,
      }
    case TOGGLE_SIGNUP_ENALBLE_DISABLE:
      return {
        ...state,
        enableRegisterButton: action.enableRegisterButton,
      }

    case RESET_THEME_OPTIONS:
      return initialState
    default:
  }
  return state
}
