import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { Button, Col, Label, ListGroupItem, Row } from 'reactstrap'
import { tidyName } from '../../../../helper'
import { setLoader } from '../../../../Redux/Reducers/loader'
import { request } from '../../../../Redux/Sagas/requests/api'
import IconSet from '../../../icon'

const FilterBy = ({ source_table, filter_by, set_filter_by }) => {
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const [isExpanded, setIsExpanded] = useState(false)

  const dispatch = useDispatch()

  const [isAddFormOpen, setIsAddFormOpen] = useState(false)

  const [columnValue, setColumnValue] = useState()
  const [nameValue, setNameValue] = useState()

  const onSubmit = (value) => {
    const newFilterBy = filter_by ? [...filter_by, { name: nameValue, field_id: columnValue }] : [{ name: nameValue, field_id: columnValue }]

    set_filter_by(newFilterBy)
    setColumnValue()
    setNameValue()
    setIsAddFormOpen(false)
  }

  return (
    <div>
      <ListGroupItem
        style={{
          padding: '0.5rem 0.5rem 0.5rem 1rem',
          display: 'flex',
          marginBottom: 7,
          marginTop: 7,
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}>
        <div>Filter By</div>
        <IconSet
          icon={isExpanded ? 'far fa-arrow-down' : 'far fa-arrow-right'}
          color='primary'
          style={{ cursor: 'pointer', marginRight: '0.5rem' }}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </ListGroupItem>
      {isExpanded && (
        <>
          <Row>
            <Col md={12}>
              <div className='float-right'>
                <Button
                  color={isAddFormOpen ? 'danger' : 'success'}
                  onClick={() => {
                    setIsAddFormOpen(!isAddFormOpen)
                  }}
                  className='btn m-1 float-right'
                  style={{ marginBottom: '1rem' }}>
                  <IconSet icon={isAddFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
                </Button>
              </div>
            </Col>
            {isAddFormOpen && (
              <Col md={12}>
                <Form
                  grid_selector={grid_selector}
                  source_table={source_table}
                  columnValue={columnValue}
                  onChangeColumnValue={(value) => {
                    setColumnValue(value)
                  }}
                  nameValue={nameValue}
                  onChangeNameValue={(value) => {
                    setNameValue(value)
                  }}
                  isAddForm={true}
                  onSubmit={() => onSubmit()}></Form>
              </Col>
            )}
          </Row>
          {filter_by && filter_by?.length > 0 ? (
            <Columns filter_by={filter_by} set_filter_by={set_filter_by} grid_selector={grid_selector} source_table={source_table}></Columns>
          ) : (
            <div style={{ textAlign: 'center' }}>Filter not added!</div>
          )}
        </>
      )}
    </div>
  )
}

const Columns = ({ filter_by, set_filter_by, grid_selector, source_table }) => {
  const columns = filter_by
  const [expandedIndex, setExpandedIndex] = useState()

  const onNameChange = (value, index) => {
    const newFilterBy = filter_by?.map((col, i) => {
      if (i === index) {
        return {
          ...col,
          name: value,
        }
      } else {
        return {
          ...col,
        }
      }
    })
    set_filter_by(newFilterBy)
  }
  const onColumnChange = (value, index) => {
    const newFilterBy = filter_by?.map((col, i) => {
      if (i === index) {
        return {
          ...col,
          field_id: value,
        }
      } else {
        return {
          ...col,
        }
      }
    })
    set_filter_by(newFilterBy)
  }

  const onDeleteColumn = (index) => {
    const new_filter_by = [...filter_by]
    new_filter_by?.splice(index, 1)
    set_filter_by(new_filter_by)
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <td style={{ width: '5rem' }}></td>
          <td style={{ width: '5rem' }}></td>
          <td style={{ fontWeight: '500' }}>Name</td>
        </tr>
      </thead>
      <tbody>
        {columns?.map((item, index) => {
          return (
            <>
              <tr key={index}>
                <td>
                  {' '}
                  <IconSet
                    icon={index === expandedIndex ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                    color='primary'
                    style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                    onClick={() => {
                      if (index !== expandedIndex) {
                        setExpandedIndex(index)
                      } else {
                        setExpandedIndex()
                      }
                    }}
                  />
                </td>
                <td>
                  <Button className='btn-danger' onClick={() => onDeleteColumn(index)}>
                    <IconSet icon='pe-7s-trash' color='light' />
                  </Button>
                </td>
                <td>{item?.name}</td>
              </tr>
              {index === expandedIndex && (
                <tr key={index + 'expanded'}>
                  <td colSpan={4}>
                    <Form
                      grid_selector={grid_selector}
                      source_table={source_table}
                      columnValue={item?.field_id || item?.name}
                      onChangeColumnValue={(value) => {
                        onColumnChange(value, index)
                      }}
                      nameValue={item?.name}
                      onChangeNameValue={(value) => {
                        onNameChange(value, index)
                      }}
                    />
                  </td>
                </tr>
              )}
            </>
          )
        })}
      </tbody>
    </table>
  )
}

const Form = ({
  grid_selector,

  source_table,
  columnValue,
  onChangeColumnValue,
  nameValue,
  onChangeNameValue,
  isAddForm,
  onSubmit,
}) => {
  return (
    <AvForm style={{ marginBottom: '1rem' }}>
      <Row>
        <Col md={6}>
          <label>Select Column</label>
          <AvInput type='select' name='column' label='Select Column' value={columnValue} onChange={(e) => onChangeColumnValue(e.target.value)}>
            <option>Select Column</option>
            {grid_selector?.[source_table]?.columns?.map((item, index) => {
              return (
                <option key={index} value={item?.name || item?.field_id}>
                  {tidyName(item?.title || item?.name)}
                </option>
              )
            })}
          </AvInput>
        </Col>
        <Col md={6}>
          <AvField name='name' label='name' type='text' value={nameValue} onChange={(e) => onChangeNameValue(e.target.value)}></AvField>
        </Col>
        {isAddForm && (
          <Col md={12}>
            <Button color='primary' className='float-right' onClick={() => onSubmit()}>
              Save
            </Button>
          </Col>
        )}
      </Row>
    </AvForm>
  )
}

export default FilterBy
