import { Col, InputGroup, InputGroupAddon, Row } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Select from 'react-select'

const OPTIONS = [
  {
    title: '5 min',
    value: 5,
  },
  {
    title: '10 min',
    value: 10,
  },
  {
    title: '15 min',
    value: 15,
  },
  {
    title: '20 min',
    value: 20,
  },
  {
    title: '30 min',
    value: 30,
  },
  {
    title: '1hr',
    value: 60,
  },
  {
    title: '1hr 25min',
    value: 85,
  },
  {
    title: '1hr 30min',
    value: 90,
  },
  {
    title: '1hr 45min',
    value: 105,
  },
  {
    title: '2hrs',
    value: 120,
  },
  {
    title: '3hrs',
    value: 180,
  },
  {
    title: '4hrs',
    value: 240,
  },
  {
    title: '5hrs',
    value: 300,
  },
  {
    title: '6hrs',
    value: 360,
  },
  {
    title: '12hrs',
    value: 720,
  },
  {
    title: '18hrs',
    value: 1080,
  },
  {
    title: '24hrs',
    value: 1440,
  },
]

const getSelectedPrePostTimeValue = (val) => {
  if (!val) return null
  const ans = OPTIONS?.find((item) => item?.value === parseInt(val))
  return {
    label: ans?.title,
    value: ans?.value,
  }
}

const ItemsGlobalFields = ({ globalFieldsState = {}, setGlobalFieldsState, submittedFormData, setSubmittedFormData }) => {
  const handleChange = (data, field) => {
    let newData = {
      ...globalFieldsState,
    }
    newData[field] = data
    let times = 0
    let minGap = newData?.min_gap && parseInt(newData?.min_gap)
    let dataToUpdate = { ...submittedFormData }
    let templates = submittedFormData?.item_groups?.items?.map((item) => {
      
      const initialDateTime = submittedFormData?.item_groups?.items?.datetime ?  submittedFormData?.item_groups?.items?.datetime : new Date()

      const pre_time_to_add = newData?.pre_time
      const post_time_to_add = newData?.post_time
      const datetime = new Date(new Date(initialDateTime).getTime() + times * 60000)
      const pre_time = pre_time_to_add ? new Date(datetime.getTime()  - parseInt(pre_time_to_add) * 60000) : item?.pre_time
      const post_time = post_time_to_add ? new Date(datetime.getTime() + parseInt(post_time_to_add) * 60000) : item?.post_time
      times += minGap
      return {
        ...item,
        pre_time: pre_time?.toString(),
        post_time: post_time?.toString(),
        datetime: datetime?.toString(),
        date_time: datetime?.toString(),
        // datetime: new Date(),
      }
    })

    dataToUpdate['item_groups']['items'] = templates
    console.log('new data::::::::::', dataToUpdate)
   
    setSubmittedFormData(dataToUpdate)
    setGlobalFieldsState(newData)
   
  }
  {
    console.log('global fields:::::::::', globalFieldsState, submittedFormData)
  }
  const select_options = OPTIONS?.map((option) => {
    return {
      label: option?.title,
      value: option?.value,
    }
  })

  const selectedPreTime = getSelectedPrePostTimeValue(globalFieldsState?.pre_time)
  const selectedPostTime = getSelectedPrePostTimeValue(globalFieldsState?.post_time)
  const selectedRepeateGap = getSelectedPrePostTimeValue(globalFieldsState?.min_gap)
  return (
    <div>
      <AvForm>
        <Row className='mb-2'>
          <Col md={4}>
            <label>Pre time</label>
            <Select
              options={select_options}
              id={'second-column'}
              onChange={(val) => handleChange(val?.value, 'pre_time')}
              value={selectedPreTime}
              styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
              // isDisabled={!inlineEditActive}
              // placeholder=''
              // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
              // isSearchable={ inlineEditActive }
              // menuIsOpen={inlineEditActive}
              // {...props}
            />
            {/* <InputGroup>
              <DatePicker
                selected={globalFieldsState?.pre_time}
                onChange={(val) => handleChange(val, 'pre_time')}
                showTimeSelect
                className='form-control'
                timeFormat='HH:mm'
                timeIntervals={30}
                dateFormat='d MMMM , yyyy HH:mm'
                timeCaption='Time'
                // disabled={data?.field_id === 'created_at'}
              />
              <InputGroupAddon addonType='append'>
                <div className='input-group-text'>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
              </InputGroupAddon>
            </InputGroup> */}
          </Col>
          <Col md={4}>
            <label>Post time</label>
            <Select
              options={select_options}
              id={'second-column'}
              onChange={(val) => handleChange(val?.value, 'post_time')}
              value={selectedPostTime}
              styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
              isClearable
              // isDisabled={!inlineEditActive}
              // placeholder=''
              // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
              // isSearchable={ inlineEditActive }
              // menuIsOpen={inlineEditActive}
              // {...props}
            />
          </Col>
          <Col md={4}>
            <label>Minute Gap</label>
            <Select
              options={select_options}
              id={'min_gap'}
              onChange={(val) => handleChange(val?.value, 'min_gap')}
              value={selectedRepeateGap}
              styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
              isClearable
              // isDisabled={!inlineEditActive}
              // placeholder=''
              // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
              // isSearchable={ inlineEditActive }
              // menuIsOpen={inlineEditActive}
              // {...props}
            />
            {/* <AvField
              name='min_gap'
              label='Minute Gap'
              type='number'
              value={globalFieldsState?.min_gap}
              onChange={(e) => handleChange(e.target.value, 'min_gap')}></AvField> */}
          </Col>
        </Row>
      </AvForm>
    </div>
  )
}

export default ItemsGlobalFields
