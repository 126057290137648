import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { FileUploader } from 'react-drag-drop-files'
import cx from 'classnames'
import IconSet from '../../icon'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import { addSlide, getWelcomImages, updateSlide } from '../../../Redux/Reducers/slides'

const page_data = [
  {
    label: 'Log In',
    value: 'Log In',
  },
  {
    label: 'Sign Up',
    value: 'Sign Up',
  },
  {
    label: 'Forgot Password',
    value: 'Forgot Password',
  },
]
const fileTypes = ['JPG', 'JPEG', 'TIFF', 'PNG']

const AddEditSlide = ({ setIsAddFormOpen = () => {}, editItem, setEditItem }) => {
  const dispatch = useDispatch()
  const [form, updateForm] = useState({
    page: 'Log In',
  })
  const [file, updateFile] = useState()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (editItem?.open) {
      updateForm({
        page: editItem?.data?.page,
        title: editItem?.data?.title,
        sub_title: editItem?.data?.sub_title,
      })
      updateFile(editItem?.data?.image)
    }
  }, [editItem])

  const handleChange = (file) => {
    updateFile(file)
  }
  const handleValidSubmit = () => {
    if (!file) {
      dispatch(
        setNotification({
          type: 'error',
          message: 'Please upload image',
        })
      )
      return
    }
    setIsSaving(true)

    const formData = new FormData()
    // formData.append("domain_name",data.domain_name)
    formData.append('page', form?.page)
    formData.append('type', 'slide')
    formData.append('title', form?.title)
    // formData.append("logo_default",data.logo_default)
    formData.append('image', file)
    formData.append('sub_title', form?.sub_title)
    if (editItem?.data?.uuid) {
      formData.append('uuid', editItem?.data?.uuid)
    } else {
      formData.append('uuid', '')
    }

    request('save-brand', 'POST', formData).then((res) => {
      setIsAddFormOpen(false)

      updateForm({
        page: 'log_in',
      })

      updateFile()
      setIsSaving(false)

      if (editItem?.open) {
        setEditItem({ data: null, open: false })
        dispatch(updateSlide(res?.data?.brand))
      } else {
        dispatch(addSlide(res?.data?.brand))
      }

      // data.logo = res.data.path
    })
  }
  return (
    <Card>
      <CardBody>
        {editItem?.open && (
          <div className='mb-2 font-weight-bold' style={{ fontWeight: '1.3rem' }}>
            Update Slide
          </div>
        )}
        <AvForm onValidSubmit={handleValidSubmit}>
          <Row>
            <Col md={12}>
              <AvField
                name='page'
                label='page'
                type='select'
                value={form?.page}
                required
                onChange={(e) =>
                  updateForm({
                    ...form,
                    page: e.target.value,
                  })
                }>
                <option>Select page</option>
                {page_data?.map((item) => {
                  return (
                    <option key={item?.value} value={item?.value}>
                      {item?.label}
                    </option>
                  )
                })}
              </AvField>
            </Col>
            <Col md={6}>
              <AvField
                name='title'
                label='title'
                type='text'
                value={form?.title}
                onChange={(e) =>
                  updateForm({
                    ...form,
                    title: e.target.value,
                  })
                }></AvField>
            </Col>
            <Col md={6}>
              <AvField
                name='sub_title'
                label='sub title'
                type='text'
                value={form?.sub_title}
                onChange={(e) =>
                  updateForm({
                    ...form,
                    sub_title: e.target.value,
                  })
                }></AvField>
            </Col>
            {!file && (
              <Col md={12}>
                <FileUploader
                  handleChange={handleChange}
                  name='file'
                  types={fileTypes}
                  multiple={false}
                  fileOrFiles={file}
                  classes={'upload-file-form'}>
                  <div className='upload_container'>
                    <span style={{ marginRight: '1rem', fontSize: '2rem' }}>
                      <IconSet icon='fa-solid fa-folder-arrow-up' color='info' />
                    </span>
                    Upload or Drop image here!
                  </div>
                </FileUploader>
              </Col>
            )}

            {file && (
              <Col md={12}>
                <div className={'mb-2 position-relative'}>
                  <div className='bg-danger position-absolute rounded-circle cross-badge-sizing' onClick={() => updateFile()}>
                    <IconSet icon={'fa-solid fa-xmark'} color='light' />
                  </div>
                  <img
                    alt=' '
                    src={typeof file === 'string' ? process.env.REACT_APP_URL + '/' + file : URL.createObjectURL(file)}
                    width='80px'
                    height={'100px'}
                    style={{ borderRadius: '5px' }}
                  />
                </div>
              </Col>
            )}

            <Col md={12}>
              {editItem?.open && (
                <Button className='float-right ml-2' onClick={() => setEditItem({ data: null, open: false })}>
                  Cancel
                </Button>
              )}

              <Button className='float-right'>{isSaving ? 'Saving...' : 'Save'}</Button>
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card>
  )
}

export default AddEditSlide
