const HrBool = ({ isMore = false,row }) => {

  let start_date,end_date
  if(row?.row?.original){
    start_date = row?.row?.original?.raised_start_date_time
    end_date = row?.row?.original?.completed_end_date_time

  }



  let isMoreThanOneHour = false, dateNotAvailable

  if (start_date && end_date && !isNaN(Date.parse(end_date) && !isNaN(Date.parse(start_date)))) {
    if (new Date(end_date) - new Date(start_date) > 3600000) {
      isMoreThanOneHour = true
    }
  } else {
    dateNotAvailable = true
  }
  if (dateNotAvailable) return
   if (isMore === isMoreThanOneHour) {
     return <span className='badge badge-pill bg-success sidebar-text-light'>Yes</span>
   } else {
     return <span className='badge badge-pill bg-danger sidebar-text-light'>No</span>
   }

}

export default HrBool


