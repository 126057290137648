import { Fragment, useEffect, useState } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import CommonFields from './commonFields'

function Text({ item, answers, setAnswers, requiredError }) {
  const [value, setValue] = useState()

  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  useEffect(() => {
    if (item.item_answer_responses?.length > 0) {
      setValue(item.item_answer_responses[0].response_text)
    }
  }, [])

  const onHandleChange = (v) => {
    setValue(v)

    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          question_type: 'text',
          response_text: v,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    // console.log("current object:::::::",current_answer_object)
    setValue(current_answer_object?.response_text)
  }, [JSON.stringify(current_answer_object)])

  return (
    <Fragment>
      <div>
        <FormGroup>
          <Label for='exampleAddress'>{item.question ? `${item.question}?` : ''}</Label>
          <Input type='text' name='answer' placeholder='answer' value={value} onChange={(e) => onHandleChange(e.target.value)} />
        </FormGroup>
        <CommonFields item={item} selectedAnswer={value} answers={answers} setAnswers={setAnswers} current_answer_object={current_answer_object} />
        {item?.validations?.includes('required') &&
          requiredError &&
          (!current_answer_object?.response_text || current_answer_object?.response_text?.length === 0) && (
            <div style={{ color: 'red' }}>Please Complete</div>
          )}
      </div>
    </Fragment>
  )
}

export default Text
