import { useSelector } from 'react-redux'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { cms, editCMS } from '../../../../../helper'
import InfoBox from './infoBox'

const getSections = (data) => {
  const section_1 = data?.filter((item) => item?.completed_minute_duration <= 20)
  const section_2 = data?.filter((item) => item?.completed_minute_duration > 20 && item?.completed_minute_duration <= 40)
  const section_3 = data?.filter((item) => item?.completed_minute_duration > 40 && item?.completed_minute_duration <= 60)
  const section_4 = data?.filter((item) => item?.completed_minute_duration > 60)

  const section = [
    {
      title: '(0-20 minutes)',
      count: section_1?.reduce((total, item) => {
        return total + item?.item_count
      }, 0),
      completedPercentage:data?.length>0 ? Math.round((section_1?.length / data?.length) * 100):0,
    },
    {
      title: '(21-40 minutes)',
      count: section_2?.reduce((total, item) => {
        return total + item?.item_count
      }, 0),
      completedPercentage:data?.length>0? Math.round((section_2?.length / data?.length) * 100):0,
    },
    {
      title: '(41-60 minutes)',
      count: section_3?.reduce((total, item) => {
        return total + item?.item_count
      }, 0),
      completedPercentage:data?.length>0 ? Math.round((section_3?.length / data?.length) * 100):0,
    },
    {
      title: '(60+ minutes)',

      count: section_4?.reduce((total, item) => {
        return total + item?.item_count
      }, 0),
      completedPercentage:data?.length>0? Math.round((section_4?.length / data?.length) * 100):0,
    },
  ]

  return section
}

const ArTimeByMinute = ({ dashboardData }) => {
  const data = dashboardData?.ar_time_by_week

    const dashboard_cms_key = useSelector((state) => state.CMS?.dashboardCMSKey?.ar_time_by_week?.cms_key_heading)
    const editMode = useSelector((state) => state.CMS?.editMode)

  const section = getSections(data)

  return (
    <div style={{ marginTop: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='dashboard-chart-title' style={{ cursor: editMode ? 'pointer' : '' }} onClick={() => editCMS(dashboard_cms_key)}>
          {cms(dashboard_cms_key)}
        </div>
       
      </div>
      <ListGroup>
        {section?.map((item, index) => {
          
          return (
            <InfoBox
              index={index}
              key={index}
              bgColor={item?.color}
              title={item?.title}
              count={item?.count}
              completedPercentage={item?.completedPercentage}
            />
          )
        })}
      </ListGroup>
    </div>
  )
}

export default ArTimeByMinute
