import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { clearCurrentRowData, clearParentTableData } from '../../../Redux/Reducers/CMS'

const getNavStates = (indx, length) => {
  let styles = []
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push('done')
    } else if (i === indx) {
      styles.push('doing')
    } else {
      styles.push('todo')
    }
  }
  return { current: indx, styles: styles }
}

const checkNavState = (currentStep, stepsLength) => {
  if (currentStep > 0 && currentStep < stepsLength - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true,
    }
  } else if (currentStep === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true,
    }
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false,
    }
  }
}

class MultiStep extends React.Component {
  state = {
    showPreviousBtn: false,
    showNextBtn: true,
    compState: 0,
    navState: getNavStates(0, this.props.steps.length),
    showUnsavedModel: false,
  }

  setNavState = (next) => {
    this.setState({
      navState: getNavStates(next, this.props.steps.length),
    })
    if (next < this.props.steps.length) {
      this.setState({ compState: next })
    }
    this.setState(checkNavState(next, this.props.steps.length))
  }

  handleKeyDown = (evt) => {
    if (evt.which === 13) {
      this.next()
    }
  }

  handleOnClick = (evt) => {
    if (evt.currentTarget.value < this.props.currentStep || evt.currentTarget.value <= this.props.visitedStep || !this.props.isAddForm) {
      // this.props.onSetStep(evt.currentTarget.value)
      // this.setNavState(this.props.steps.length);
      if (document.getElementById('save-hidden-btn')) {
        document.getElementById('save-hidden-btn')?.click()
        this.props.onSetStep(evt.currentTarget.value)
      } else {
        this.props.onSetStep(evt.currentTarget.value)
      }
    }
  }

  next = () => {
    this.setNavState(this.state.compState + 1)
  }

  previous = () => {
    if (this.state.compState > 0) {
      this.setNavState(this.state.compState - 1)
    }
  }

  getClassName = (i) => {
    if (this.props.currentStep === i) {
      return 'active'
    } else if (this.props.currentStep > i) {
      return 'visited'
    } else if (!this.props.isAddForm) {
      return 'visited'
    } else if (i > this.props.visitedStep) {
      return 'unvisited'
    }
    // return 'unvisited'
  }
  getClassNameForNextButton = (i) => {
    if (i == 1) {
      return 'unvisited'
    }
    if (i === this.props.steps.length) {
      return 'unvisited'
    } else {
      return 'visited'
    }
    if (this.props.currentStep === i) {
      return 'active'
    } else if (this.props.currentStep > i) {
      return 'visited'
    } else if (!this.props.isAddForm) {
      return 'visited'
    } else if (i > this.props.visitedStep) {
      return 'unvisited'
    }
    return 'visited'
  }

  getTabName = (tab, form, data, visitedStepIndex, index) => {
    if (form?.grid_tab_repeatable) {
      const dataObj = data && data[Object.keys(data)?.[0]]
      const tabData = dataObj?.[form?.grid_tab_table] || dataObj?.[form?.grid_tab_method]
      if (tabData && tabData?.length > 0) {
        return (
          <>
            <span>{tab?.name}</span>
            <span class='badge badge-primary ml-2'>{tabData?.length}</span>
          </>
        )
      } else {
        return <span>{tab?.name}</span>
      }
    } else {
      if (index <= visitedStepIndex && !this.props.isAddForm) {
        return (
          <>
            {' '}
            <span>{tab?.name}</span>
            <span class='badge badge-success ml-2'>
              <i className='fa-solid fa-check'></i>
            </span>
          </>
        )
      }
      return <span>{tab?.name}</span>
    }
  }

  renderSteps = () => {
    return this.props.steps.map((s, i) => {
      return (
        <li className={this.getClassName(i)} onClick={this.handleOnClick} key={i} value={i}>
          {/* <em>{i + 1}</em> */}
          <span>{this.getTabName(s, s?.form, this.props.submittedFormData, this.props.visitedStep, i)}</span>
          {/* <span>{this.props.steps[i].name}</span> */}
        </li>
      )
    })
  }

  onNextClick = () => {
    const addBtn = document.querySelectorAll('.add-form-btn')
    const updateBtn = document.querySelectorAll('.update-form-btn')
    if (addBtn?.length > 0 || updateBtn?.length > 0) {
      this.setState({ showUnsavedModel: true })
    } else {
      this.props?.onNextStep()
    }

    //
  }

  saveUnsavedChanges = () => {
    const addBtn = document.querySelectorAll('.add-form-btn')
    const updateBtn = document.querySelectorAll('.update-form-btn')
    addBtn?.forEach((btn) => btn?.click())
    updateBtn?.forEach((btn) => btn?.click())
    this.setState({ showUnsavedModel: false })
    //
    setTimeout(() => {
      this.props?.onNextStep()
    }, 1000)
  }
  cancelUnsavedChanges = () => {
    this.props?.onNextStep()
    this.setState({ showUnsavedModel: false })
  }

  render() {
    const { addDataDetail } = this.props
    return (
      <div onKeyDown={this.handleKeyDown}>
        <ol className='wizard-tab'>{this.renderSteps()}</ol>
        {this.props.steps[this.props.currentStep].component}
        {/* <div className="divider" /> */}
        <div className='clearfix' style={{ marginTop: '5rem', marginBottom: '4rem' }}>
          <div style={this.props.showNavigation ? {} : { display: 'none' }}>
            {this.props.currentStep !== 0 && (
              <Button
                color='primary'
                className='btn-shadow float-left btn-wide '
                // outline
                disabled={this.props.currentStep === 0}
                // style={this.props.currentStep !== 0 ? {} : { display: 'none' }}
                onClick={() => this.props.onPreviousStep()}>
                Previous
              </Button>
            )}

            {this.props.currentStep === this.props.steps?.length - 1 && (
              <Button
                color='primary'
                // outline
                // color='primary'
                className='btn-shadow btn-wide float-right  btn-hover-shine'
                disabled={this.props.isUpdateButtonDisabled?.status}
                // style={this.props.currentStep === 0 || this.props.currentStep === this.props.steps.length - 1 ? { display: 'none' } : {}}
                // disabled={this.props.currentStep === 0 ? Object.keys(this.props.formResponseData)?.length === 0?true:false : false}
                onClick={() => {
                  this.props?.onSubmitForm(null, null, true)
                }}>
                Save & Close
              </Button>
            )}

            {this.getClassNameForNextButton(this.props.currentStep + 1) !== 'unvisited' && (
              <Button
                color='primary'
                // outline
                // color='primary'
                className='btn-shadow btn-wide float-right  btn-hover-shine'
                disabled={this.getClassNameForNextButton(this.props.currentStep + 1) === 'unvisited'}
                // style={this.props.currentStep === 0 || this.props.currentStep === this.props.steps.length - 1 ? { display: 'none' } : {}}
                // disabled={this.props.currentStep === 0 ? Object.keys(this.props.formResponseData)?.length === 0?true:false : false}
                onClick={() => this.onNextClick()}>
                Next
              </Button>
            )}
            {this.props.currentStep !== 0 && (
              <Button
                color='secondary'
                // size='lg'
                className={`btn-shadow float-right btn-wide  mr-2`}
                // style={{ marginBottom: '1rem' }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!addDataDetail?.isAddNew) {
                    this.props.clearCurrentRowData()
                    this.props.clearParentTableData()
                  }

                  this.props.onCloseForm()
                }}>
                Close
              </Button>
            )}
          </div>
        </div>
        {this.state.showUnsavedModel && (
          <Modal isOpen={this.state.showUnsavedModel} style={{ width: '300px' }} toggle={() => this.setState({ showUnsavedModel: false })}>
            <ModalHeader toggle={() => this.setState({ showUnsavedModel: false })}></ModalHeader>
            <ModalBody>
              <div className='text-align-center'>There is unsaved changes.</div>
              <div className='text-align-center'>Do you want to save it?</div>
            </ModalBody>
            <ModalFooter>
              <Button color='primary' onClick={() => this.saveUnsavedChanges()}>
                Save
              </Button>
              <Button color='secondary' onClick={() => this.cancelUnsavedChanges()}>
                Don't save
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    )
  }
}

MultiStep.defaultProps = {
  showNavigation: true,
}

const mapStateToProps = (state) => ({
  // enableClosedSidebar: state.StaticThemeOptions.enableClosedSidebar,
  // enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  // enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  // editPageMode: state.CMS.editPageMode,
  // domainLogo: state.CMS.coreData.domains,
  // userDomainLogo: state.User.user?.domain?.logo,
})

const mapDispatchToProps = (dispatch) => ({
  clearCurrentRowData: (enable) => dispatch(clearCurrentRowData(enable)),
  clearParentTableData: (enable) => dispatch(clearParentTableData(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MultiStep)
