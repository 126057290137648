import { useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'

import { Button } from 'reactstrap'
import DatePicker from 'react-datepicker'

import { Row, Col } from 'reactstrap'
import { tidyName } from '../../../helper'
import IconSet from '../../icon'
import cx from 'classnames'
import { TagsInput } from 'react-tag-input-component'

const RenderFields = ({
  type,
  onChangeValue,
  value,
  setShowDropDownOutSide,
  isCreatingField,
  grid_selector,
  main_source_table_for_field,
  onChangeBetweenValue,
  between_value,
}) => {
  const [showDropDown, setShowDropDown] = useState(false)
  switch (type) {
    case 'todays_date':
      return (
        <Col md={6}>
          <div>VALUE</div>
          <div style={{ width: '100%' }}>
            <DatePicker
              selected={new Date()}
              className='where-clause-date-picker'
              dateFormat='d MMMM , yyyy HH:mm'
              readOnly={true}
              //   onChange={(e) => {
              //     onChangeValue(count, e);
              //   }}
            />
          </div>
        </Col>
      )
    case 'fixed_date_value':
      return (
        <Col md={6}>
          <div>VALUE</div>
          <div style={{ width: '100%' }}>
            <DatePicker
              selected={value ? new Date(value) : null}
              className='where-clause-date-picker'
              dateFormat='d MMMM , yyyy'
              onChange={(e) => {
                onChangeValue(e)
              }}
            />
          </div>
        </Col>
      )
    case 'fixed_numeric_value':
      return (
        <Col md={6}>
          <AvField name='value' value={value ? value : ''} label='Value' type='number' onChange={(e) => onChangeValue(e.target.value)}></AvField>
        </Col>
      )
    case 'select_multiple_values':
      return (
        <Col md={6}>
          <label>Values:</label>
          <TagsInput separators={['Enter', 'Tab']} value={value} onChange={(value) => onChangeValue(value)} name='email' placeHolder='Values' />
        </Col>
      )
    case 'between_from_current_date_time':
      return (
        <Col md={6}>
          <AvField
            name='between_value'
            value={between_value ? between_value : null}
            label='Value'
            type='number'
            onChange={(e) => {
              onChangeBetweenValue(e.target.value)
            }}
            validate={{
              min: { value: 1, errorMessage: 'Minimum value 1 required.' },
              required: { value: true, errorMessage: 'This field is required.' },
            }}></AvField>
        </Col>
      )
    case 'dynamic':
      return (
        <>
          {isCreatingField && (
            <Col md={6}>
              <div>Show Column select</div>
              <div
                className='switch has-switch mb-2 mr-2'
                data-on-label='ON'
                data-off-label='OFF'
                onClick={() => {
                  setShowDropDown(!showDropDown)
                  setShowDropDownOutSide(!showDropDown)
                }}>
                <div
                  className={cx('switch-animate', {
                    'switch-on': showDropDown,
                    'switch-off': !showDropDown,
                  })}>
                  <input type='checkbox' />
                  <span className='switch-left bg-success'>ON</span>
                  <label>&nbsp;</label>
                  <span className='switch-right bg-success'>OFF</span>
                </div>
              </div>
            </Col>
          )}
          {showDropDown && (
            <Col md={6}>
              {/* <AvField name='value' value={value ? value : ''} label='Value' onChange={(e) => onChangeValue(e.target.value)}></AvField> */}
              <AvField
                type='select'
                label='Select Column'
                name='value'
                value={value}
                onChange={(e) => {
                  onChangeValue(e.target.value)
                }}>
                <option>Select Column Value</option>
                {grid_selector[main_source_table_for_field]?.columns?.map((item, i) => {
                  return (
                    <option key={item?.name} value={item?.value || item?.name}>
                      {tidyName(item?.title) || tidyName(item?.name)}
                    </option>
                  )
                })}
              </AvField>
            </Col>
          )}
          {!showDropDown && (
            <Col md={6}>
              <AvField name='value' value={value ? value : ''} label='Value' onChange={(e) => onChangeValue(e.target.value)}></AvField>{' '}
            </Col>
          )}
        </>
      )
    // if (isCreatingField) {
    //   return (
    //
    //   )
    // } else {
    //   return (
    //
    //   )
    // }

    default:
      return (
        <Col md={6}>
          <AvField name='value' label='Value' value={value} onChange={(e) => onChangeValue(e.target.value)}></AvField>
        </Col>
      )
  }
}

const ConditionCommonField = ({
  source_table,
  columnValue,
  onColumnChange,
  type,
  setShowDropDownOutSide,
  onTypeChange,
  condition,
  onConditionChange,
  value,
  onValueChange,
  add_or_sub,
  add_or_sub_type,
  add_or_sub_value,
  onChangeAddOrSub,
  onChangeAddOrSubType,
  onChangeAddOrSubValue,
  isCreatingField,
  setShowDropDown,
  main_source_table_for_field,
  onChangeBetweenType = () => {},
  between_type,
  onChangeBetweenPeriod = () => {},
  between_period,
  between_value,
  onChangeBetweenValue = () => {},
}) => {
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)

  return (
    <>
      {' '}
      <Col md={6}>
        <AvField type='select' label='Select Column' name='column' value={columnValue} onChange={(e) => onColumnChange(e.target.value)}>
          <option>Select Column</option>
          {grid_selector[source_table]?.columns?.map((item) => {
            return (
              <option key={item?.name} value={item?.value || item?.name}>
                {tidyName(item?.title) || tidyName(item?.name)}
              </option>
            )
          })}
        </AvField>
      </Col>
      <Col md={6}>
        <AvField
          type='select'
          name='type'
          label='Type'
          // required
          onChange={(e) => {
            onTypeChange(e.target.value)
          }}
          value={type}>
          <option>Select Type</option>
          <option value='todays_date'>Todays Date</option>
          <option value='fixed_numeric_value'>Numeric Value</option>
          <option value='select_multiple_values'>Multiple Value</option>
          <option value='fixed_date_value'>Date</option>
          <option value='dynamic'>Dynamic</option>
          <option value='between_from_current_date_time'>Between from current date/time</option>
          <option value='add_or_sub'>Add Or Subtract (days/minutes)</option>
          <option value='where_null'>Null</option>
          <option value='where_not_null'>Not Null</option>
          <option value='last_five_minutes'>Last Five minutes</option>
          {source_table === 'uploads' && <option value='all_uploaded_documents'>All Valid Uploaded Documents</option>}
          {/* <option value='db'>DB</option> */}
        </AvField>
      </Col>
      {type === 'between_from_current_date_time' && (
        <>
          <Col md={6}>
            <AvField
              type='select'
              name='between_period'
              label='Past / Future'
              value={between_period}
              required
              onChange={(e) => {
                onChangeBetweenPeriod(e.target.value)
              }}>
              <option>Select Period</option>
              <option value='past'>Past</option>
              <option value='future'>Future</option>
            </AvField>
          </Col>
          <Col md={6}>
            <AvField
              type='select'
              name='between_sub_type'
              label='Mins/Hours/Days'
              value={between_type}
              required
              onChange={(e) => {
                onChangeBetweenType(e.target.value)
              }}>
              <option>Select Mins/Hours/Days</option>
              <option value='mins'>Mins</option>
              <option value='hours'>Hours</option>
              <option value='days'>Days</option>
            </AvField>
          </Col>
          {/* <Col md={6}>
            <AvField name='value' label='Value' value={between_value} onChange={(e) => onChangeBetweenValue(e.target.value)}></AvField>
          </Col> */}
        </>
      )}
      {type === 'add_or_sub' && (
        <>
          <Col md={6}>
            <AvField
              type='select'
              name='add_or_sub'
              label='Add/Sub'
              value={add_or_sub}
              // required
              onChange={(e) => {
                onChangeAddOrSub(e.target.value)
              }}>
              <option>Select Add/Sub</option>
              <option value='add'>Add</option>
              <option value='sub'>Sub</option>
            </AvField>
          </Col>
          <Col md={6}>
            <AvField
              type='select'
              name='add_or_sub_type'
              label='Days or Mins'
              value={add_or_sub_type}
              // required
              onChange={(e) => {
                onChangeAddOrSubType(e.target.value)
              }}>
              <option>Select Days or Mins</option>
              <option value='days'>Days</option>
              <option value='hours'>Hours</option>
              <option value='minutes'>Minutes</option>
            </AvField>
          </Col>
        </>
      )}
      {type !== 'between_from_current_date_time' && type !== 'last_five_minutes' && (
        <Col md={6}>
          <AvField
            type='select'
            name='condition'
            label='Condition'
            value={condition}
            // required
            onChange={(e) => {
              onConditionChange(e.target.value)
            }}>
            <option>Select condition</option>
            <option value='equals'>Equals</option>
            {type !== 'db' && type !== 'dynamic' && (
              <>
                <option value='greater_than'>Greater Than</option>
                <option value='greater_than_and_equal'>Greater Than and Equal</option>
                <option value='less_than'>Less Than</option>
                <option value='less_than_and_equal'>Less Than and Equal</option>
              </>
            )}
          </AvField>
        </Col>
      )}
      {type === 'add_or_sub' && (
        <Col md={6}>
          <AvField name='value' label='Value' value={add_or_sub_value} onChange={(e) => onChangeAddOrSubValue(e.target.value)}></AvField>
        </Col>
      )}
      {type !== 'add_or_sub' && type !== 'last_five_minutes' && (
        <RenderFields
          type={type}
          onChangeValue={onValueChange}
          value={value}
          isCreatingField={isCreatingField}
          setShowDropDownOutSide={setShowDropDown}
          grid_selector={grid_selector}
          main_source_table_for_field={main_source_table_for_field}
          onChangeBetweenValue={onChangeBetweenValue}
          between_value={between_value}
        />
      )}
    </>
  )
}

export default ConditionCommonField
