import { request } from './api'

export function requestLogin(credentials) {
  return request('login', 'post', credentials)
}

export function requestRegister(credentials) {
  return request('register', 'post', credentials)
}

export function requestForgotPassword(email) {
  return request('forgot-password', 'post', email)
}

export function requestResetPassword(resetPassword) {
  return request('reset-password', 'post', resetPassword)
}

export function requestLogout() {
  return request('logout', 'get')
}

export function requestProfile() {
  return request('profile', 'get')
}
