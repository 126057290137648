import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { submitRequest } from '../../../../Redux/Reducers/CMS'
import FormToggleSwitch from '../../../Forms/Fields/switchButton'
import { cms } from '../../../../helper'
import AddCustomColumn from './addCustomColumn'

const defaultForm = {
  name: '',
  type: 'input',
  field_id: '',
  api_submit: '',
  validations: [],
  fileType: [],
  col: '1',
  manage_data_database: '',
  multiple: false,
  print: false,
  export: false,
}
const validations = ['required', 'string', 'max_50', 'max_100', 'email', 'min_5', 'confirm_password']
const fileTypes = ['PDF', 'EPS', 'JPG', 'JPEG', 'TIFF', 'GIF', 'PNG', 'ZIP']

const FileUpload = ({
  formName,
  pageName,
  setModal,
  type,
  field = null,
  setAddForm,
  tab = false,
  section,
  source_table,
  is_create,
  isLayout,
  rowIndex,
  blockIndex,
  blockFields,
  order,
  parentPageName,
  grid_id,
  isAddDataForm,
  selectedField,
}) => {
  const [form, updateForm] = useState(defaultForm)
  const [load, updateLoad] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    updateForm({ ...form, type: type })
  }, [])

  useEffect(() => {
    if (field) {
      const validationValueFromDb = field.component_call_validation?.map((item) => item?.validation_type)
      const field_setting = form?.field_setting
      updateForm({
        ...form,
        field_id: field.field_id,
        print: field_setting?.print,
        export: field_setting?.export,
        title: cms(field.cms_key),
        col: field_setting?.col || field?.col,
        validations: validationValueFromDb,
        fileType: field.custom_options.file_type,
        multiple: field.custom_options.multiple,
      })
    }
  }, [])

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    let data = {
      ...values,
      form_name: formName,
      page_name: pageName,
      parent_page_name: parentPageName,
      validations: form.validations,
      manage_data_database: form.manage_data_database,
      type: form.type,
      multiple: form.multiple,
      fileType: form.fileType,
      print: form?.print,
      export: form?.export,
      order,
    }
    if (tab) {
      data = {
        ...data,
        tab: true,
        section: section,
      }
    }
    if (isLayout) {
      data = {
        ...data,
        isLayout: true,
        rowIndex: rowIndex,
        blockIndex: blockIndex,
      }
    }
    if (is_create && isLayout) {
      data = {
        ...data,
        order: blockFields ? blockFields?.length + 1 : 0,
      }
    }
    if (isAddDataForm) {
      data = {
        ...data,
        grid_id,
      }
    }
    dispatch(submitRequest({ url: 'submit-form-field', data }))
    setModal(false)
    setAddForm(false)
    updateForm(defaultForm)
  }
  return (
    <>
      <AvForm onValidSubmit={handleValidSubmit} model={form}>
        <Row>
          {tab && is_create ? (
            <Col md={6}>
              <AddCustomColumn
                source_table={source_table}
                value={form.field_id}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
                selectedField={selectedField}
              />
            </Col>
          ) : (
            <Col md={6}>
              <AvField
                name='field_id'
                label='Field ID'
                type='text'
                required
                value={form.field_id}
                disabled={is_create ? false : true}
                validate={{
                  pattern: {
                    value: '^[A-Za-z0-9_]+$',
                    errorMessage: 'Your name must be composed only with letter and numbers',
                  },
                }}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
              />
            </Col>
          )}

          <Col md={6}>
            <label>Validations</label>
            <Multiselect
              data={validations}
              value={form.validations}
              onChange={(value) => updateForm({ ...form, validations: value })}
              textField='validations'
            />
          </Col>
          <Col md={6}>
            <label>File Type</label>
            <Multiselect
              data={fileTypes}
              value={form.fileType}
              onChange={(value) => updateForm({ ...form, fileType: value })}
              //   textField="validations"
            />
          </Col>
          <Col md={6}>
            <label>Multiple</label>
            <FormToggleSwitch value={form.multiple} onChange={(toggle_data) => updateForm({ ...form, multiple: toggle_data })} />
          </Col>
          <Col md={isLayout ? 3 : 6}>
            <label>Print</label>
            <FormToggleSwitch value={form.print} onChange={(toggle_data) => updateForm({ ...form, print: toggle_data })} />
          </Col>
          <Col md={isLayout ? 3 : 6}>
            <label>Export</label>
            <FormToggleSwitch value={form.export} onChange={(toggle_data) => updateForm({ ...form, export: toggle_data })} />
          </Col>
          <Col md={6}>
            <AvField
              name='title'
              label='title'
              type='text'
              value={form?.title}
              onChange={(e) => updateForm({ ...form, title: e.target.value })}></AvField>
          </Col>
          {!isLayout && (
            <Col md={6}>
              <AvField type='select' name='col' label='Column' value={form?.col} onChange={(e) => updateForm({ ...form, col: e.target.value })}>
                {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  )
                })}
              </AvField>
            </Col>
          )}

          <Col md={12}>
            <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default FileUpload
