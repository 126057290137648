import moment from 'moment'
import { Col, ListGroupItem, Row } from 'reactstrap'
import { triggerBase64Download } from 'common-base64-downloader-react'
import QR from '../../Table/Component/QR/QR'

const onDownLoadFile = (file) => {
  triggerBase64Download(file, (Math.random() + 1).toString(36).substring(7))
}

const getAnswer = (data) => {
  let answer = data?.response_text

  if (data?.question_type === 'multy-select' || data?.question_type === 'check') {
    const answers = data?.answer_id?.map((ans) => ans?.response_text)
    answer = answers?.join(', ')
  } else if (data?.question_type === 'date') {
    answer = answer && moment(answer).format('MMMM Do YYYY')
  } else if (data?.question_type === 'date-time') {
    answer = answer && moment(answer).format('MMMM Do YYYY, h:mm ')
  }
  if (data?.question_type === 'rich-text') {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: answer }} style={{ flex: 1 }}></div>
        {data?.upload_base64 && <QR data={data?.upload_base64} height={200} className='my-3' />}
      </>
    )
  }
  if (data?.question_type === 'signiture_select' || data?.question_type === 'signiture_name' || data?.question_type === 'signiture_basic') {
    return (
      <>
        {data?.image_base64 && (
          <div>
            <img
              src={data?.image_base64}
              alt='signature'
              style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
          </div>
        )}
        <div className='mb-1'>{data?.response_text}</div>
        {data?.upload_base64 && <QR data={data?.upload_base64} height={200} className='my-3' />}

        {data?.comment && <div dangerouslySetInnerHTML={{ __html: data?.comment }}></div>}
      </>
    )
  }
  if (data?.question_type === 'upload') {
    const file = data?.upload_base64
    const fileType = file?.slice(file?.indexOf('/') + 1, file?.indexOf(';'))
    let fileIsImage
    if (file && fileType && (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png')) {
      fileIsImage = true
    } else {
      fileIsImage = false
    }

    if (file && !fileIsImage) {
      return (
        <>
          <div
            className='text-primary cursor-pointer'
            onClick={() => {
              onDownLoadFile(file)
            }}>
            Click here to download the file
          </div>
        </>
      )
    }
    return (
      <>
        {' '}
        {file && (
          <div>
            <img src={file} alt='signature' style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
          </div>
        )}
      </>
    )
  }
  return (
    <>
      <div className='mb-1'>{answer}</div>

      {data?.comment && <div dangerouslySetInnerHTML={{ __html: data?.comment }} className='font-italic'></div>}
      {data?.upload_base64 && <QR data={data?.upload_base64} height={200} className='my-3' />}
    </>
  )
}

const QuestionnaireSummary = ({ answers }) => {
  return (
    <div>
      <div className='mb-3 font-weight-bold'>Summary</div>
      <ListGroupItem>
        <Row>
          <Col md={6} className='font-weight-bold'>
            {' '}
            Question
          </Col>
          <Col md={6} style={{ paddingLeft: '1rem' }} className='font-weight-bold'>
            Answer
          </Col>
        </Row>
      </ListGroupItem>
      {answers?.map((question, index) => {
        if(question?.question_type === 'heading'){
          return (
            <div className='my-3 card text-white card-body bg-info d-flex justify-content-between flex-row p-2 pl-3  align-items-center'>
              {question?.question}
            </div>
          )
        }
        return (
          <div key={index}>
            <ListGroupItem id={'question' + question?.id}>
              <Row>
                <Col md={6}>
                  {' '}
                  {index + 1}. {question?.question}?
                </Col>
                <Col md={6} style={{ paddingLeft: '1rem' }}>
                  {getAnswer(question)}
                </Col>
              </Row>
            </ListGroupItem>
          </div>
        )
      })}
    </div>
  )
}

export default QuestionnaireSummary
