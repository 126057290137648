import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { useSelector } from 'react-redux'
import { CardTitle, Col, Row } from 'reactstrap'
import { request } from '../../../Redux/Sagas/requests/api'
import Form from '../form'

const EmailCheckBox = () => {
  const [selectOptions, setSelectOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const selectedSendType = useSelector((state) => state.CMS?.selectedDropdownData?.send_type)

  const [selectedOptions, setSelectedOptions] = useState([])
  const currentRow = useSelector((state) => state.CMS.currentRow)

  useEffect(() => {
    if (selectedSendType !== 'report email') return
    let payload = {
      parent_table: 'item_groups',
      parent_table_row_id: currentRow?.[currentRow?.length - 1]?.id,
    }

    if (selectOptions?.length === 0) {
      setLoading(true)
      request('get-location-reports', 'POST', payload).then((res) => {
        const options = res?.data?.map((item) => item?.email)
        setSelectOptions(options)
        setLoading(false)
      })
    }
  }, [selectedSendType])

  const onChangeCheck = (value) => {
    const data = [...selectedOptions]

    if (!data?.includes(value)) {
      data?.push(value)
    } else {
      const i = data?.indexOf(value)
      data.splice(i, 1)
    }
    setSelectedOptions(data)
  }

  if (loading)
    return (
      <div className='text-center'>
        <Loader type='line-scale' color='#545CD8' />
      </div>
    )

  if (selectedSendType !== 'report email' || selectOptions?.length === 0) return
  return (
    <Row>
      <Col md={12}>
        <label>SELECT EMAIL</label>
      </Col>

      <Col md={12} style={{ paddingLeft: '2rem', maxHeight: '10rem', overflow: 'auto' }}>
        <AvForm>
          {/* <FormGroup check> */}
          <AvCheckboxGroup name={'email_select'}>
            {selectOptions?.map((item, key) => (
              <>
                <AvCheckbox
                  key={key}
                  label={item}
                  value={item}
                  checked={selectedOptions?.includes(item) ? true : false}
                  onChange={(e) => onChangeCheck(e.target.value)}
                />
              </>
            ))}
          </AvCheckboxGroup>
          {/* </FormGroup> */}
        </AvForm>
      </Col>
      <AvField name='emails' type='hidden' value={selectedOptions} />
    </Row>
  )
}

export default EmailCheckBox
