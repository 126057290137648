import { Button, Col, Row } from 'reactstrap'
import Switch from 'react-switch'
import { Multiselect } from 'react-widgets'

import { cms, tidyName } from '../../../../helper'
import IconSet from '../../../icon'
import { useEffect, useState } from 'react'
import { request } from '../../../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { reduxLoad } from '../../../../Redux/Reducers'

const GridButtonSetting = ({ buttons, buttonForm, setButtonForm, tableButtonList, tableButtonForm, setTableButtonForm, mainGrid, content }) => {
  const onButtonChange = (name, value) => {
    setButtonForm({ ...buttonForm, [name]: value })
  }
  const [action, setAction] = useState()
  const [orderedButtons, setOrderedButtons] = useState()
  const [isOrderedEnable, setIsOrderedEnable] = useState(false)
  const dispatch = useDispatch()
  const [addActionFormOpen, setAddActionFormOpen] = useState(false)

  useEffect(() => {
    setOrderedButtons(buttons)
  }, [buttons])

  const [draggingRowIndex, setDraggingRowIndex] = useState(null)

  const onDragStart = (index) => {
    setDraggingRowIndex(index)
  }

  const onDragOver = (index) => {
    if (draggingRowIndex === index) return
    const draggedRow = orderedButtons[draggingRowIndex]
    const remainingRows = orderedButtons.filter((_, idx) => idx !== draggingRowIndex)

    remainingRows.splice(index, 0, draggedRow)

    setDraggingRowIndex(index)
    const newRows = remainingRows?.map((item, index) => {
      return {
        ...item,
        order: index + 1,
      }
    })
    setOrderedButtons(newRows)
  }

  const onDrop = () => {
    setDraggingRowIndex(null)
  }

  let filteredActionTypes = ['ADD', 'EDIT', 'DELETE', 'RESTORE', 'FILTER', 'SEARCH', 'EXPORT', 'Preferences', 'Mail']
  if (content?.grid_settings?.display_report_type?.length === 0) {
    filteredActionTypes = ['ADD', 'EDIT', 'DELETE', 'RESTORE', 'FILTER', 'SEARCH', 'EXPORT', 'Preferences']
  }

  const onTableButtonChange = (name, value) => {
    setTableButtonForm({ ...tableButtonForm, [name]: value })
  }
  const onAddAction = () => {
    const action_types = action?.map((word) => (word === 'EXPORT' ? 'X' : word[0])).join('')

    request('create-grid_button', 'POST', { action_types, grid_id: mainGrid?.id }).then(() => {
      setAction()
      setAddActionFormOpen(false)
      dispatch(reduxLoad())
    })
  }

  const onSaveOrder = () => {
    
    const buttons = orderedButtons?.map((item)=>{
      return {
        name: item?.name,
        uuid: item?.uuid,
        order: item?.order
      }
    })
    request('set-grid_button-order', 'POST', { grid_id: mainGrid?.id, buttons }).then(() => {
      dispatch(reduxLoad())
    })
  }
  return (
    <>
      <Row>
        <Col className='float-right' md={12}>
          <Button
            color={addActionFormOpen ? 'danger' : 'success'}
            onClick={() => {
              setAddActionFormOpen(!addActionFormOpen)
            }}
            className='btn m-1 float-right'>
            <IconSet icon={addActionFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
          </Button>
        </Col>
        {addActionFormOpen && (
          <>
            <Col md={6}>
              {' '}
              <label>Action Types</label>
              <Multiselect data={filteredActionTypes} onChange={(value) => setAction(value)} textField='action_types' className='mb-4' />
            </Col>
            <Col md={6}></Col>
          </>
        )}
        {addActionFormOpen && (
          <Col md={6}>
            <Button color='primary' onClick={() => onAddAction()}>
              Add
            </Button>
          </Col>
        )}
      </Row>

      <div style={{ fontWeight: 800, fontSize: '1rem', textAlign: 'center', marginBottom: '1rem' }}>Main Grid Button</div>
      <div className='mb-4'>
        {!isOrderedEnable && (
          <Button
            color='info mr-3'
            onClick={() => {
              setIsOrderedEnable(true)
            }}>
            Enable Order
          </Button>
        )}

        {isOrderedEnable && (
          <>
            <Button
              color='success mr-3'
              onClick={() => {
                setIsOrderedEnable(false)
                setOrderedButtons(buttons)
              }}>
              Cancel Order
            </Button>
            <Button
              color='primary'
              onClick={() => {
                onSaveOrder()
              }}>
              Save Order
            </Button>
          </>
        )}
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th style={{ width: '3rem' }}></th>
            <th>Icon</th>
            <th>Name</th>
            <th>Disabled</th>
          </tr>
        </thead>
        <tbody>
          {orderedButtons?.map((item, index) => {
            return (
              <tr
                key={index}
                draggable={isOrderedEnable ? true : false}
                onDragStart={() => {
                  onDragStart(index)
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                  onDragOver(index)
                }}
                onDrop={onDrop}
                onDragEnd={onDrop}
                style={{
                  cursor: 'move',
                }}>
                <td></td>
                <td>
                  <Button
                    className='border-0 btn-icon btn-icon-only fsize-1 '
                    id={item.name}
                    color={item.background_color ? (item.background_color === 'link' ? 'secondary' : item.background_color) : 'secondary'}
                    // onClick={() => setDeleteModal(true)}
                    // disabled={gridSelectedRow?.length > 0?false:true}
                  >
                    <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
                    {/* {' ' + cms(item.cms_key)} */}
                  </Button>
                </td>
                <td>{tidyName(item?.name)}</td>
                <td>
                  <Switch
                    checked={buttonForm[item?.name] ? buttonForm[item?.name] : false}
                    onChange={(value) => onButtonChange(item?.name, value)}
                    // checked={preferanceForm[item.field_id]?.print}
                    // onChange={(value) =>
                    //   updatePreferanceForm({
                    //     ...preferanceForm,
                    //     [item.field_id]: {
                    //       ...preferanceForm[item.field_id],
                    //       print: value,
                    //     },
                    //   })
                    // }
                    uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
                    checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
                    className='d-inline'
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div style={{ fontWeight: 800, fontSize: '1rem', textAlign: 'center', marginBottom: '1rem' }}>Table button setting</div>
      <table className='table'>
        <thead>
          <tr>
            <th style={{ width: '3rem' }}></th>
            <th>Icon</th>
            <th>Name</th>
            <th>Disabled</th>
          </tr>
        </thead>
        <tbody>
          {tableButtonList?.map((item, index) => {
            return (
              <tr key={index}>
                <td></td>
                <td>
                  <Button
                    className='border-0 btn-icon btn-icon-only fsize-1 '
                    id={item.name}
                    color={item.background_color ? (item.background_color === 'link' ? 'secondary' : item.background_color) : 'secondary'}
                    // onClick={() => setDeleteModal(true)}
                    // disabled={gridSelectedRow?.length > 0?false:true}
                  >
                    <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
                    {/* {' ' + cms(item.cms_key)} */}
                  </Button>
                </td>
                <td>{tidyName(item?.name)}</td>
                <td>
                  <Switch
                    checked={tableButtonForm[item?.name] ? tableButtonForm[item?.name] : false}
                    onChange={(value) => onTableButtonChange(item?.name, value)}
                    // checked={preferanceForm[item.field_id]?.print}
                    // onChange={(value) =>
                    //   updatePreferanceForm({
                    //     ...preferanceForm,
                    //     [item.field_id]: {
                    //       ...preferanceForm[item.field_id],
                    //       print: value,
                    //     },
                    //   })
                    // }
                    uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
                    checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
                    className='d-inline'
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default GridButtonSetting
