import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap'

import Icon from '../../icon'
import { submitRequest } from '../../../Redux/Reducers/CMS'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import { setLoader } from '../../../Redux/Reducers/loader'
import { tidyName } from '../../../helper'

import PageWithGridForm from './Form/pageWithGridForm'
import CreateTemplateForm from './Form/template-form'
import DashboardLayout from '../../../Components/PageSpecificComponents/Dashboard/Component/Layout/dashboardLayoutSetting'

const select = (state) => ({
  page: state.CMS.coreData.page,
})

const CreatePage = (props) => {
  const [modalValue, setModal] = useState(false)
  return (
    <>
      <Button onClick={() => setModal(true)} color='' className='btn-wide mb-2 mr-2 btn-pill btn-gradient-info' size='lg'>
        <Icon icon={'fas fa-pen'} color='light' />
        {props?.isMainPageCreate ? 'Create main page' : 'Create Page'}
      </Button>
      <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: '100%' }}>
        <ModalHeader toggle={() => setModal(!modalValue)}>Create {tidyName(props.pageName)} page</ModalHeader>
        <ModalBody>
          {props?.isMainPageCreate ? (
            <DashboardLayout isCreatingMainPage={true} onCloseModal={() => setModal(false)} currentNavData={props.currentNavData} />
          ) : (
            <CreatePageForm {...props} />
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export const CreatePageForm = ({ pageName, page, tab_near_to, close = () => {} }) => {
  const dispatch = useDispatch()
  const defaultForm = {
    action_types: [],
    table: '',
    type: '',
  }

  const [load, updateLoad] = useState(false)
  const [columnForm, updateColumn] = useState([])
  const [tabForm, updateTab] = useState([])
  const [form, updateForm] = useState(defaultForm)
  const [tab, setTab] = useState('grid')
  const [whereClauseForm, updateWhereClauseForm] = useState()

  const onTabChange = (key) => {
    setTab(key)
  }

  const handleValidSubmit = (e, values) => {
    let payload = {
      ...values,
      page_name: pageName,
      tab_near_to,
      source_table: form.table,
      columnForm,
      tabForm,
      action_types: form.action_types.map((word) => (word === 'EXPORT' ? 'X' : word[0])).join(''),
    }
    if (whereClauseForm && whereClauseForm?.length > 0) {
      payload = {
        ...payload,
        where_clause: whereClauseForm,
      }
    }

    dispatch(setLoader(true))
    dispatch(
      submitRequest({
        url: 'create-page-route',
        data: {
          ...payload,
        },
      })
    )
    dispatch(setLoader(false))
    close()
  }

  useEffect(() => {
    dispatch(setLoader(false))
  }, [page])

  return (
    <Container>
      <Tabs defaultActiveKey={1} renderTabBar={() => <ScrollableInkTabBar onTabClick={onTabChange} />} renderTabContent={() => <TabContent />}>
        <TabPane tab='Page With Advance Grid' key='grid' id='test1'>
          <PageWithGridForm
            handleValidSubmit={handleValidSubmit}
            updateColumn={updateColumn}
            updateTab={updateTab}
            updateForm={updateForm}
            form={form}
            load={load}
            pageName={pageName}
            updateWhereClauseForm={updateWhereClauseForm}
          />
        </TabPane>
        <TabPane tab='Import Template' key='template' id='test2'>
          <CreateTemplateForm
            handleValidSubmit={handleValidSubmit}
            updateColumn={updateColumn}
            updateTab={updateTab}
            updateForm={updateForm}
            form={form}
            load={load}
          />
        </TabPane>
      </Tabs>
    </Container>
  )
}

export default connect(select)(CreatePage)
