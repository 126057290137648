import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
//Drag and Drop.
import RLDD from 'react-list-drag-and-drop/lib/RLDD'

import Icon from '../../icon'
import { removeItemByName, submitRequest } from '../../../Redux/Reducers/CMS'
import ConfirmButton from '../../Common/confirmButton'
import { cms } from '../../../helper'
import FormToggleSwitch from '../../Forms/Fields/switchButton'
import toggle from '../../Forms/Fields/toggle'
import CreateField from './createField'
import EditField from './editField'
import { tidyName } from '../../../helper'
import FieldSetting from './fieldSetting'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  hasReduxData: state.CMS.coreData.has_redux_data,
  coreData: state.CMS.coreData,
  updateApiData: state.CMS.updateApiData,
})

const SubmitField = ({ editPageMode, formName, pageName, hasReduxData, coreData, fields, updateApiData, parentPageName, form }) => {
  const dispatch = useDispatch()
  //Get a updateApiData from Redux.
  // const updateApiData = store.getState().CMS.updateApiData;

  const [modalValue, setModal] = useState(false)

  if (!editPageMode) return null
  return (
    <>
      <Button onClick={() => setModal(true)} color='link'>
        <Icon icon={'fas fa-pen'} color='info' className={'ml-3 pointer'} id='add_edit_field' />{' '}
        <UncontrolledTooltip placement='right' target='add_edit_field'>
          Add/Edit Field
        </UncontrolledTooltip>
      </Button>
      <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: '60rem' }}>
        <ModalHeader toggle={() => setModal(!modalValue)}>{formName} Field</ModalHeader>
        <ModalBody>
          <FieldSetting
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            fields={fields}
            fieldForm={form}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default connect(select)(SubmitField)
