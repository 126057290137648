import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Loader from 'react-loaders'
import { connect } from 'react-redux'

const select = (state) => ({
  isAuth: state.User.isAuth,
  defaultUrl: state.User.defaultUrl,
})

const WebRoute = ({ isAuth, defaultUrl, component: Component, ...rest }) => {
  return (
    <Route exact {...rest}>
      <Suspense
        fallback={
          <div className='loader-container'>
            <div className='loader-container-inner'>
              <div className='text-center'>
                <Loader type='line-scale-party' />
              </div>
              <h6 className='mt-3'>Please wait while we load all the Pages</h6>
            </div>
          </div>
        }>
        {!isAuth ? <Component /> : <Redirect to={defaultUrl} />}
      </Suspense>
    </Route>
  )
}

export default connect(select)(WebRoute)
