import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
//Drag and Drop.
import RLDD from 'react-list-drag-and-drop/lib/RLDD'

import Icon from '../../icon'
import { fieldCreatedStatus, removeItemByName, submitRequest, updatePageDataRedux } from '../../../Redux/Reducers/CMS'
import ConfirmButton from '../../Common/confirmButton'
import { checkCMSPermission, cms, updateGridFormInReduxByGridId } from '../../../helper'
import FormToggleSwitch from '../../Forms/Fields/switchButton'
import toggle from '../../Forms/Fields/toggle'
import CreateField from './createField'
import EditField from './editField'
import { tidyName } from '../../../helper'
import IconSet from '../../icon'
import FieldRowForm from './fieldRowForm'
import { request } from '../../../Redux/Sagas/requests/api'
import { setLoader } from '../../../Redux/Reducers/loader'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  hasReduxData: state.CMS.coreData.has_redux_data,
  coreData: state.CMS.coreData,
  // updateApiData: state.CMS.updateApiData,
})

const reduxDataValidate = (value, ctx) => {
  if (ctx.redux_data_name) {
    if (!ctx.redux_data_value || !ctx.redux_data_id) {
      return 'You need to select "Redux Data ID" and "Redux Data Value"'
    }
  }
  return true
}

const FieldSetting = ({
  editPageMode,
  formName,
  pageName,
  hasReduxData,
  coreData,
  fields,
  updateApiData,
  tab = false,
  section = null,
  setModal = () => {},
  source_table,
  fieldForm,
  isRepeatingForm,
  grid_id,
  activeTabData,
  parentPageName,
  isAddDataForm,
  setAddFormList,
  addDataDetail,
}) => {
  const dispatch = useDispatch()

  const [editedValidations, setEditedValidations] = useState({})

  const [allFields, setAllFields] = useState([])
  const [isEnableOrder, setIsEnableOrder] = useState(false)
  const [formFieldOrder, setFormFieldOrder] = useState([])
  const [addFormOpen, setAddFormOpen] = useState(false)
  const [updatedFieldOnLayout, setUpdatedFieldOnLayout] = useState([])

  const [row, setRow] = useState([])

  const layoutFields = isRepeatingForm
    ? allFields?.filter((field) => field?.field_setting?.block_index && field?.field_setting?.row_index)
    : allFields?.filter((field) => field?.is_layout === 1 && field?.block_index && field?.row_index)

  useEffect(() => {
    if (fieldForm?.layout && fieldForm?.layout?.length > 0) {
      setRow(JSON.parse(fieldForm?.layout))
    }
  }, [])

  let isLayoutSaved = false
  if (fieldForm?.layout && fieldForm?.layout?.length > 0 && row && row?.length > 0) {
    if (fieldForm?.layout === JSON.stringify(row)) {
      isLayoutSaved = true
    }
  }

  //refs for ordering field and layout
  const dragBlockItem = useRef()
  const dragOverBlockItem = useRef()
  const [dragOverBlock, setDragOverBlock] = useState()

  //block drag function
  const onBlockDragStart = (e) => {
    const type = e.target.getAttribute('data-drag-type')

    dragBlockItem.current = e.target
  }

  const onDragOverBlockItem = (e) => {
    e.preventDefault()
    const type = dragBlockItem.current.getAttribute('data-drag-type')

    //destination
    const row_index = e.target.getAttribute('data-row-index')
    const column_index = e.target.getAttribute('data-column-index')

    if (!column_index) return

    // source
    const dragged_block_row_index = dragBlockItem.current.getAttribute('data-row-index')
    const dragged_block_column_index = dragBlockItem.current.getAttribute('data-column-index')

    //check if field order in same block
    if (parseInt(row_index) === parseInt(dragged_block_row_index) && parseInt(column_index) === parseInt(dragged_block_column_index)) {
      dragOverBlockItem.current = null
      return
    }
    dragOverBlockItem.current = e.target
    setDragOverBlock({
      row_index: parseInt(row_index),
      block_index: parseInt(column_index),
    })

    //layout update

    if (type === 'block') {
      const new_r = []
      row?.map((r, i) => {
        if (i !== parseInt(row_index)) {
          return new_r?.push(r)
        } else {
          let new_row = {
            column: [],
          }

          //logic to change block index
          const new_column = r.column && [...r.column]
          const source = parseInt(dragBlockItem.current.getAttribute('data-column-index'))
          const dest = parseInt(column_index)
          const element1 = new_column[source]
          const element2 = new_column[dest]
          new_column.splice(source, 1, element2)
          new_column.splice(dest, 1, element1)

          new_row['column'] = new_column
          new_r?.push(new_row)
        }
      })
      const new_fields = []
      const updated_fields_payload = []

      //logic to replace field
      fields?.forEach((field) => {
        if (isRepeatingForm) {
          if (
            field?.field_setting?.block_index - 1 === parseInt(dragged_block_column_index) &&
            field?.field_setting?.row_index - 1 === parseInt(dragged_block_row_index)
          ) {
            new_fields?.push({
              ...field,
              field_setting: {
                ...field?.field_setting,
                block_index: parseInt(column_index) + 1,
                row_index: parseInt(row_index) + 1,
              },
            })
          } else {
            new_fields?.push({ ...field })
          }
        } else {
          if (field?.block_index - 1 === parseInt(dragged_block_column_index) && field?.row_index - 1 === parseInt(dragged_block_row_index)) {
            new_fields?.push({ ...field, block_index: parseInt(column_index) + 1 })
            updated_fields_payload?.push({ field_id: field?.field_id, block_index: parseInt(column_index) + 1, row_index: field?.row_index })
          } else if (field?.block_index - 1 === parseInt(column_index) && field?.row_index - 1 === parseInt(row_index)) {
            new_fields?.push({ ...field, block_index: parseInt(dragged_block_column_index) + 1 })
            updated_fields_payload?.push({
              field_id: field?.field_id,
              block_index: parseInt(dragged_block_column_index) + 1,
              row_index: field?.row_index,
            })
          } else {
            new_fields?.push({ ...field })
          }
        }
      })
      setRow(new_r)
      setAllFields(new_fields)
      setUpdatedFieldOnLayout(updated_fields_payload)
    }
  }

  const onBlockDragComplete = (rowIndex, blockIndex) => {
    const type = dragBlockItem.current?.getAttribute('data-drag-type')
    if (!dragOverBlockItem.current) return
    const updated_fields_payload = []
    if (type === 'field') {
      const column_index = dragOverBlockItem.current?.getAttribute('data-column-index')
      const field_id = dragBlockItem.current.getAttribute('field_id')
      const new_fields = []

      fields?.forEach((field) => {
        if (field?.field_id === field_id) {
          if (isRepeatingForm) {
            new_fields?.push({
              ...field,
              field_setting: {
                ...field?.field_setting,
                block_index: parseInt(column_index) + 1,
                row_index: field?.field_setting?.row_index || 1,
                is_layout: 1,
              },
            })
          } else {
            new_fields?.push({ ...field, block_index: parseInt(column_index) + 1, row_index: field?.row_index || 1, is_layout: 1 })
            updated_fields_payload?.push({
              field_id: field?.field_id,
              block_index: parseInt(column_index) + 1,
              row_index: field?.row_index || 1,
            })
          }
        } else {
          new_fields?.push({ ...field })
        }
      })

      setAllFields(new_fields)
      setUpdatedFieldOnLayout(updated_fields_payload)
    }
    onSaveLayout(true, type, updated_fields_payload)

    setDragOverBlock()
  }

  //field drag function
  const dragFieldItem = useRef()
  const dragOverFieldItem = useRef()
  const [dragOverField, setDragOverField] = useState()

  const onFieldDragStart = (e) => {
    dragFieldItem.current = e.target

    // e.preventDefault()
    // e.stopPropagation()
  }

  const onFieldDragOver = (e) => {
    if (dragFieldItem.current?.getAttribute('field_id') === e.target.getAttribute('field_id') && dragFieldItem.current.getAttribute('field_id'))
      return
    dragOverFieldItem.current = e.target

    const draggedFieldRowIndex = parseInt(dragFieldItem.current.getAttribute('data-row-index'))
    const draggedFieldColumnIndex = parseInt(dragFieldItem.current.getAttribute('data-column-index'))

    const dragOverFieldRowIndex = parseInt(e.target.getAttribute('data-row-index'))
    const dragOverFieldColumnIndex = parseInt(e.target.getAttribute('data-column-index'))
    const dragOverFieldId = e.target.getAttribute('field_id')

    if (dragOverFieldColumnIndex === draggedFieldColumnIndex && dragOverFieldRowIndex === draggedFieldRowIndex) {
      setDragOverField({
        row_index: dragOverFieldRowIndex,
        column_index: dragOverFieldColumnIndex,
        field_id: dragOverFieldId,
      })
    }
  }

  const onFieldDragEnd = (e) => {
    if (!dragFieldItem.current || !dragOverFieldItem.current) return

    const draggedFieldRowIndex = parseInt(dragFieldItem.current.getAttribute('data-row-index'))
    const draggedFieldColumnIndex = parseInt(dragFieldItem.current.getAttribute('data-column-index'))
    const field_id = dragFieldItem.current.getAttribute('field_id')

    const dragOverFieldRowIndex = parseInt(dragOverFieldItem.current.getAttribute('data-row-index'))
    const dragOverFieldColumnIndex = parseInt(dragOverFieldItem.current.getAttribute('data-column-index'))
    const dragOverFieldId = dragOverFieldItem.current.getAttribute('field_id')

    if (dragOverFieldColumnIndex === draggedFieldColumnIndex && dragOverFieldRowIndex === draggedFieldRowIndex) {
      const new_fields = []
      const updated_fields_payload = []
      const field_data = fields?.find((field) => field?.field_id === field_id)
      fields?.forEach((field) => {
        if (field?.field_id === dragOverFieldId) {
          new_fields?.push({ ...field_data })
          new_fields?.push({ ...field })
        } else if (field?.field_id !== field_id) {
          new_fields?.push({ ...field })
        }
      })
      setAllFields(new_fields)
      new_fields
        ?.filter((field, index) => {
          if (field?.block_index === dragOverFieldColumnIndex + 1 && field?.row_index === dragOverFieldRowIndex + 1) {
            return true
          }
        })
        ?.map((field, index) => {
          updated_fields_payload?.push({ name: field?.field_id, order: index })
        })
      let payload = {
        form_name: formName,
        grid_id,
        page_name: pageName,
        tab,
      }
      payload['data'] = [...updated_fields_payload]
      request('submit-form-field-order', 'POST', payload).then(() => {
        // dispatch(setLoader(true))
        request('get-grid-form', 'POST', { grid_id }).then((response) => {
          if (response?.data) {
            setAddFormList(response?.data)

            updateGridFormInReduxByGridId(response?.data, addDataDetail?.mainGrid?.id)
          }
        })
        request(`get-page/${parentPageName}`, 'GET').then((data) => {
          let pageData = {}
          if (data.status === 200) {
            pageData[parentPageName] = data.data
            dispatch(updatePageDataRedux(pageData))
          }
          // dispatch(setLoader(false))
        })
      })
    }
    setDragOverField()
  }

  const defaultForm = {
    name: '',
    type: 'dropdown',
    field_id: '',
    redux_data_name: '',
    redux_data_id: '',
    redux_data_value: '',
    manage_data_database: '',
    manage_data_field: '',
    manage_data_icon: '',
    manage_data_default_header: '',
    api_submit: '',
    validations: [],
    options: [],
    addedOptions: [],
    col: '1',
    searchable: true,
    is_multi_select: true,
  }

  const [form, updateForm] = useState(defaultForm)

  const manageDatabaseValidate = (value, ctx) => {
    if (form.manage_data_database !== 'none' && form.manage_data_database) {
      if (!ctx.manage_data_field) {
        return 'This field is required.'
      }
    }
    return true
  }

  const [orderSubmitted, setOrderSubmittedFormMainField] = useState(false)

  const submitOrdering = () => {
    setIsEnableOrder(!isEnableOrder)
    let payload = {
      data: formFieldOrder,
      form_name: formName,
      page_name: pageName,
    }
    if (tab) {
      payload = { ...payload, tab: true }
    }
    if (formFieldOrder.length > 0)
      dispatch(
        submitRequest({
          url: 'submit-form-field-order',
          data: payload,
        })
      )
    setOrderSubmittedFormMainField(true)
  }

  const validations = ['required', 'string', 'max_50', 'max_100', 'email', 'min_5', 'confirm_password', 'numeric']

  const validationFromDb = (fields) => {
    let updatedValidations = {}
    fields?.map((field, key) => {
      updatedValidations = {
        ...updatedValidations,
        [field.name]: field.component_call_validation?.map((item) => item.validation_type) || '',
      }
    })
    setEditedValidations(updatedValidations)
  }

  useEffect(() => {
    setAllFields(
      fields
        ? fields.map((item, key) => {
            item.id = item.order ? item.order : key
            return item
          })
        : []
    )

    validationFromDb(fields)
  }, [fields, updateApiData])

  const onSaveLayout = async (isUpdatingOnDrag, type, updatedFields) => {
    dispatch(fieldCreatedStatus(false))
    if (row?.length > 0 && !isAddDataForm && !isUpdatingOnDrag) {
      const data = {
        layout: JSON.stringify(row),
        form_name: formName,
        page_name: pageName,
        tab: true,
        parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
      }

      if (isRepeatingForm) {
        const data = {
          grid_id: grid_id,
          column_layout: row,
        }
        request('save-grid-layout', 'POST', data).then((data) => {
          dispatch(setLoader(true))
          request(`get-page/${parentPageName}`, 'GET').then((data) => {
            let pageData = {}
            if (data.status === 200) {
              pageData[parentPageName] = data.data
              dispatch(updatePageDataRedux(pageData))
            }
            dispatch(setLoader(false))
          })
        })

        // dispatch(submitRequest({ url: 'save-grid-layout', data, loadWithoutLoader: true, load: false }))
      } else {
        if (!isAddDataForm) {
          request('save-form', 'POST', data).then((data) => {
            dispatch(setLoader(true))
            request(`get-page/${parentPageName}`, 'GET').then((data) => {
              let pageData = {}
              if (data.status === 200) {
                pageData[parentPageName] = data.data
                dispatch(updatePageDataRedux(pageData))
              }
              dispatch(setLoader(false))
            })
          })
          // dispatch(submitRequest({ url: 'save-form', data, loadWithoutLoader: true, load: false }))
        }
      }

      //write logic to save layout
    }
    if (row?.length > 0 && isAddDataForm && !isUpdatingOnDrag) {
      const data = {
        layout: JSON.stringify(row),
        form_name: formName,
        page_name: pageName,
        grid_id: grid_id,
        //  tab: true,
        //  parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
      }
      dispatch(setLoader(true))
      request('save-form', 'POST', data).then((data) => {
        dispatch(fieldCreatedStatus(true))
        dispatch(setLoader(false))
      })
    }

    if (isUpdatingOnDrag) {
      const data_for_layout = {
        layout: JSON.stringify(row),
        form_name: formName,
        page_name: pageName,
        grid_id: grid_id,
        //  tab: true,
        //  parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
      }
      const data_for_field = {
        fields: updatedFieldOnLayout,
        form_name: formName,
        page_name: pageName,
        grid_id: grid_id,
        source_table,
        //  tab: true,
        //  parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
      }
      // dispatch(setLoader(true))
      if (type === 'block') {
        const layout_api = await request('save-form', 'POST', data_for_layout)
        const field_api = await request('update-form-fields', 'POST', data_for_field)
      } else if (type === 'field') {
        let payload = {
          form_name: formName,
          page_name: pageName,

          tab,
          //  parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
        }
        if (isRepeatingForm) {
          payload = {
            ...payload,
            grid_id,
          }
        }
        if (updatedFields && updatedFields?.length > 0) {
          const payload = {
            fields: updatedFields,
            form_name: formName,
            page_name: pageName,
            grid_id: grid_id,
            source_table,
            //  tab: true,
            //  parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
          }

          const field_block_change = await request('update-form-fields', 'POST', payload)
        }
      }
      request('get-grid-form', 'POST', { grid_id }).then((response) => {
        if (response?.data) {
          setAddFormList(response?.data)

          updateGridFormInReduxByGridId(response?.data, addDataDetail?.mainGrid?.id)
        }
      })
      const getPage = await request(`get-page/${parentPageName}`, 'GET')

      // dispatch(setLoader(false))
    }
  }

  if (!editPageMode) return null
  return (
    <>
      <Container>
        <div className='mb-4'>
          <Row>
            <Col
              md={{
                size: 12,
              }}>
              <>
                {/* {allFields.length > 0 && isEnableOrder && (
                  <Button className='btn btn-success float left m-2' onClick={() => submitOrdering()}>
                    Confirm Order
                  </Button>
                )}
                {allFields.length > 0 && checkCMSPermission('order_field') && (
                  <Button
                    className='btn btn-info float right m-2'
                    onClick={() => {
                      setIsEnableOrder(!isEnableOrder)
                      setOrderSubmittedFormMainField(false)
                    }}>
                    {isEnableOrder ? 'Disable' : 'Enable'} Order
                  </Button>
                )} */}
                {checkCMSPermission('add_edit_field') && (
                  <Button
                    color={addFormOpen ? 'danger' : 'primary'}
                    onClick={() => setAddFormOpen(!addFormOpen)}
                    className='btn-info m-1 float-right'>
                    <IconSet icon={addFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
                  </Button>
                )}
              </>

              {addFormOpen && (
                <div style={{ padding: '1rem' }}>
                  <CreateField
                    coreData={coreData}
                    hasReduxData={hasReduxData}
                    reduxDataValidate={reduxDataValidate}
                    manageDatabaseValidate={manageDatabaseValidate}
                    formName={formName}
                    pageName={pageName}
                    parentPageName={parentPageName}
                    setModal={setModal}
                    section={section}
                    tab={tab}
                    setAddForm={setAddFormOpen}
                    source_table={source_table}
                    allFields={allFields}
                    grid_id={grid_id}
                    isCreatingFieldForRepeatingForm={isRepeatingForm}
                    activeTabData={activeTabData}
                  />
                </div>
              )}

              <ListGroup>
                {allFields && isEnableOrder && (
                  <RLDD
                    cssClasses='example'
                    items={allFields
                      ?.filter((item) => item?.component_name !== 'hidden_data')
                      ?.filter((field) =>
                        isRepeatingForm
                          ? !field?.field_setting?.block_index && !field?.field_setting?.row_index
                          : !field?.block_index && !field?.row_index
                      )}
                    itemRenderer={(item, key) => {
                      return (
                        <ListGroupItem id={item.field_id || item?.name} key={key + item?.field_id}>
                          <span style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <i className='fa-sharp fa-solid fa-grid-horizontal'></i>
                          </span>
                          {cms(item.name)} <span style={{ fontSize: '0.7rem' }}>({tidyName(item?.component_name)})</span>
                          <div className='float-right'>
                            <ConfirmButton
                              style={{ padding: 0 }}
                              color='link'
                              id={item.field_id || item?.name}
                              clickEvent={() => {
                                if (isRepeatingForm) {
                                  dispatch(
                                    removeItemByName({
                                      name: item.field_id,
                                      // form_name: formName,
                                      page_name: pageName,
                                      type: 'field',
                                      tab: tab,
                                      grid_id: grid_id,
                                      source_table: source_table,
                                      section: section,
                                    })
                                  )
                                } else if (isAddDataForm) {
                                  const data = {
                                    grid_id: grid_id,
                                    form_name: formName,
                                    page_name: pageName,
                                    name: item?.field_id,
                                  }
                                  dispatch(submitRequest({ url: 'remove/grid-form-field', data }))
                                } else {
                                  dispatch(
                                    removeItemByName({
                                      name: item.field_id,
                                      form_name: formName,
                                      page_name: pageName,
                                      type: 'field',
                                      tab: tab,
                                      section: section,
                                    })
                                  )
                                }
                              }}>
                              <Icon icon={'fa-solid fa-xmark'} color='danger' />
                            </ConfirmButton>
                          </div>
                        </ListGroupItem>
                      )
                    }}
                    onChange={(e) => {
                      setAllFields(e)
                      let requestData = []
                      e &&
                        e.map((item, key) => {
                          requestData.push({
                            name: item.field_id || item.name,
                            order: key,
                          })
                        })
                      setFormFieldOrder(requestData)
                    }}
                  />
                )}
              </ListGroup>
              {allFields?.filter((field) =>
                isRepeatingForm ? !field?.field_setting?.block_index && !field?.field_setting?.row_index : !field?.block_index && !field?.row_index
              ) &&
                !isEnableOrder && (
                  <Row>
                    {allFields
                      ?.filter((field) =>
                        isRepeatingForm
                          ? !field?.field_setting?.block_index && !field?.field_setting?.row_index
                          : !field?.block_index && !field?.row_index
                      )
                      ?.filter((item) => item?.component_name !== 'hidden_data')
                      .map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <Col md={item?.field_setting?.col || item?.col || 12} style={{ marginBottom: '1rem' }}>
                              <ListGroupItem
                                id={item.field_id?.replace('.', '') || item?.name?.replace('.', '')}
                                draggable
                                data-drag-type='field'
                                // data-row-index={item_row_index - 1}
                                // data-column-index={item_block_index - 1}
                                field_id={item?.field_id}
                                onDragStart={(e) => {
                                  // onFieldDragStart(e)
                                  onBlockDragStart(e)
                                }}
                                // onDragOver={(e) => onFieldDragOver(e)}
                                onDragEnd={() => onBlockDragComplete()}>
                                {item?.component_name === 'section_heading' && <span>Section Heading</span>}
                                {item?.component_name === 'spacer' && <span>Spacer</span>}
                                {item?.component_name !== 'section_heading' && item?.component_name !== 'spacer' && cms(item.name)}{' '}
                                {item?.component_name !== 'section_heading' && item?.component_name !== 'spacer' && (
                                  <span style={{ fontSize: '0.7rem' }}>({tidyName(item?.component_name)})</span>
                                )}
                                <div className='float-right'>
                                  <ConfirmButton
                                    style={{ padding: 0 }}
                                    color='link'
                                    id={item.field_id?.replace('.', '') || item?.name?.replace('.', '')}
                                    clickEvent={() => {
                                      if (isRepeatingForm) {
                                        dispatch(
                                          removeItemByName({
                                            name: item.field_id,
                                            // form_name: formName,
                                            page_name: pageName,
                                            type: 'field',
                                            tab: tab,
                                            grid_id: grid_id,
                                            source_table: source_table,
                                            section: section,
                                          })
                                        )
                                      } else if (isAddDataForm) {
                                        const data = {
                                          grid_id: grid_id,
                                          form_name: formName,
                                          page_name: pageName,
                                          name: item?.field_id || item?.name,
                                        }
                                        request('remove/grid-form-field', 'POST', data).then((response) => {
                                          request('get-grid-form', 'POST', data).then((response) => {
                                            if (response?.data) {
                                              setAddFormList(response?.data)
                                            }
                                          })
                                        })

                                        // dispatch(submitRequest({ url: 'grid-form-field', data }))
                                      } else {
                                        dispatch(
                                          removeItemByName({
                                            name: item.field_id,
                                            form_name: formName,
                                            page_name: pageName,
                                            type: 'field',
                                            tab: tab,
                                            section: section,
                                          })
                                        )
                                      }
                                    }}>
                                    <Icon icon={'fa-solid fa-xmark'} color='danger' />
                                  </ConfirmButton>
                                </div>
                              </ListGroupItem>

                              <UncontrolledCollapse toggler={'#' + (item.field_id?.replace('.', '') || item?.name?.replace('.', ''))}>
                                <div className='border border-danger'></div>
                                <div className='p-2 border'>
                                  <EditField
                                    item={item}
                                    coreData={coreData}
                                    hasReduxData={hasReduxData}
                                    reduxDataValidate={reduxDataValidate}
                                    manageDatabaseValidate={manageDatabaseValidate}
                                    formName={formName}
                                    pageName={pageName}
                                    parentPageName={parentPageName}
                                    setModal={setModal}
                                    section={section}
                                    setAddFormOpen={setAddFormOpen}
                                    tab={tab}
                                    allFields={allFields}
                                    grid_id={grid_id}
                                    isCreatingFieldForRepeatingForm={isRepeatingForm}
                                    activeTabData={activeTabData}
                                  />
                                </div>
                              </UncontrolledCollapse>
                            </Col>
                          </Fragment>
                        )
                      })}
                  </Row>
                )}
            </Col>

            <Col md={12}>
              {row?.map((activeRow, rowIndex) => {
                return (
                  <React.Fragment key={rowIndex}>
                    <FieldRowForm
                      rowIndex={rowIndex}
                      key={rowIndex}
                      setRow={setRow}
                      row={row}
                      activeRow={activeRow}
                      coreData={coreData}
                      hasReduxData={hasReduxData}
                      reduxDataValidate={reduxDataValidate}
                      manageDatabaseValidate={manageDatabaseValidate}
                      formName={formName}
                      pageName={pageName}
                      setModal={setModal}
                      section={section}
                      tab={tab}
                      setAddForm={setAddFormOpen}
                      source_table={source_table}
                      fields={layoutFields?.filter((field) =>
                        isRepeatingForm ? field?.field_setting?.row_index - 1 === rowIndex : field?.row_index - 1 === rowIndex
                      )}
                      isLayoutSaved={isLayoutSaved}
                      isEnableOrder={isEnableOrder}
                      orderSubmitted={orderSubmitted}
                      onSaveLayout={onSaveLayout}
                      allFieldsInLayout={allFields}
                      grid_id={grid_id}
                      isCreatingFieldForRepeatingForm={isRepeatingForm}
                      activeTabData={activeTabData}
                      parentPageName={parentPageName}
                      isAddDataForm={isAddDataForm}
                      setAddFormList={setAddFormList}
                      onBlockDragStart={onBlockDragStart}
                      onBlockDragComplete={onBlockDragComplete}
                      onDragOverBlockItem={onDragOverBlockItem}
                      onFieldDragStart={onFieldDragStart}
                      onFieldDragOver={onFieldDragOver}
                      onFieldDragEnd={onFieldDragEnd}
                      dragOverFieldItem={dragOverField}
                      dragOverBlock={dragOverBlock}
                    />
                  </React.Fragment>
                )
              })}
            </Col>
            <Col md={12}>
              {!isEnableOrder && (
                <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                  <span
                    style={{ border: '1px dashed #788f7f', color: '#788f7f', padding: '0.3rem 0.8rem', borderRadius: '5px', cursor: 'pointer' }}
                    onClick={() => setRow([...row, { column: [] }])}>
                    Add Row
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default connect(select)(React.memo(FieldSetting))
