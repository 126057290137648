import { useEffect, useState } from 'react'
import React from 'react'
import { Button, Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'
import { Fragment } from 'react'
import { UncontrolledCollapse } from 'reactstrap'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'

import { cms } from '../../../helper'
import { tidyName } from '../../../helper'

import Icon from '../../icon'
import { useDispatch, useSelector } from 'react-redux'

import { removeItemByName, submitRequest } from '../../../Redux/Reducers/CMS'
import GridSetting from '../../Grid/Component/GridSetting/gridSetting'
import FieldSetting from './fieldSetting'

const defaultForm = {
  name: '',
  type: 'dropdown',
  field_id: '',
  redux_data_name: '',
  redux_data_id: '',
  redux_data_value: '',
  manage_data_database: '',
  manage_data_field: '',
  manage_data_icon: '',
  manage_data_default_header: '',
  api_submit: '',
  validations: [],
  options: [],
  addedOptions: [],
  col: '1',
  searchable: true,
  is_multi_select: true,
}

const RenderSection = ({
  section,
  pageName,
  formName,
  title = null,
  sectionName = null,
  source_table,
  fieldForm,
  isRepeatingForm,
  grid_id,
  activeTabData,
  parentPageName,
}) => {
  const [addFormOpen, setAddFormOpen] = useState(false)
  const [form, updateForm] = useState(defaultForm)
  const [isEnableOrder, setIsEnableOrder] = useState(false)
  const [allFields, setAllFields] = useState([])
  const [formFieldOrder, setFormFieldOrder] = useState([])
  const coreData = useSelector((state) => state.CMS.coreData)

  const hasReduxData = useSelector((state) => state.CMS.coreData.has_redux_data)

  useEffect(() => {
    setAllFields(
      section
        ? section?.map((item, key) => {
            item.id = item.order ? item.order : key
            return item
          })
        : []
    )
  }, [section])

  const submitOrdering = () => {
    setIsEnableOrder(!isEnableOrder)
    if (formFieldOrder.length > 0)
      dispatch(
        submitRequest({
          url: 'submit-form-field-order',
          data: { data: formFieldOrder, form_name: formName, page_name: pageName, tab: true },
        })
      )
  }

  const dispatch = useDispatch()
  const reduxDataValidate = (value, ctx) => {
    if (ctx.redux_data_name) {
      if (!ctx.redux_data_value || !ctx.redux_data_id) {
        return 'You need to select "Redux Data ID" and "Redux Data Value"'
      }
    }
    return true
  }
  const manageDatabaseValidate = (value, ctx) => {
    if (form.manage_data_database !== 'none' && form.manage_data_database) {
      if (!ctx.manage_data_field) {
        return 'This field is required.'
      }
    }
    return true
  }

  return (
    <>
      {/* <ListGroup>
        <Col></Col>
        <Col>
          <>
            {isEnableOrder && (
              <Button className='btn btn-success float left m-2' onClick={() => submitOrdering()}>
                Confirm Order
              </Button>
            )}

            <Button className='btn btn-info float right m-2' onClick={() => setIsEnableOrder(!isEnableOrder)}>
              {isEnableOrder ? 'Disable' : 'Enable'} Order
            </Button>
          </>
          <Button color='primary' onClick={() => setAddFormOpen(!addFormOpen)} className='btn-info m-1 float-right'>
            <IconSet icon={addFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
          </Button>
        </Col>
      </ListGroup> */}
      <FieldSetting
        formName={formName}
        pageName={pageName}
        fields={section}
        tab={true}
        section={title}
        source_table={source_table}
        fieldForm={fieldForm}
        isRepeatingForm={isRepeatingForm}
        grid_id={grid_id}
        activeTabData={activeTabData}
        parentPageName={parentPageName}
      />

      {/* {addFormOpen && (
        <CreateField
          tab={true}
          coreData={coreData}
          hasReduxData={hasReduxData}
          reduxDataValidate={reduxDataValidate}
          manageDatabaseValidate={manageDatabaseValidate}
          formName={formName}
          pageName={pageName}
          // setModal={setModal}
        />
      )} */}

      {/* <ListGroup>
        {isEnableOrder && section ? (
          <RLDD
            cssClasses='example'
            items={allFields}
            itemRenderer={(item, key) => {
              return (
                <ListGroupItem id={item.field_id || item.name}>
                  {cms(item.name)}
                  <div className='float-right'>
                    <ConfirmButton
                      style={{ padding: 0 }}
                      color='link'
                      id={item.field_id || item.name}
                      clickEvent={() =>
                        dispatch(
                          removeItemByName({
                            name: item.field_id,
                            form_name: formName,
                            page_name: pageName,
                            tab: true,
                            type: 'field',
                          })
                        )
                      }>
                      <Icon icon={'fa-solid fa-xmark'} color='danger' />
                    </ConfirmButton>
                  </div>
                </ListGroupItem>
              )
            }}
            onChange={(e) => {
              setAllFields(e)
              let requestData = []
              e &&
                e.map((item, key) => {
                  requestData.push({
                    name: item.field_id || item.name,
                    order: key,
                  })
                })
              setFormFieldOrder(requestData)
            }}
          />
        ) : (
          <>
            {section?.map((item, key) => {
              return (
                <Fragment key={key}>
                  <ListGroupItem id={item.field_id + title}>
                    {cms(item.name)}
                    <div className='float-right'>
                      <ConfirmButton
                        style={{ padding: 0 }}
                        color='link'
                        id={item?.field_id + title}
                        clickEvent={() =>
                          dispatch(
                            removeItemByName({
                              name: item.field_id,
                              form_name: formName,
                              page_name: pageName,
                              tab: true,
                              type: 'field',
                            })
                          )
                        }>
                        <Icon icon={'fa-solid fa-xmark'} color='danger' />
                      </ConfirmButton>
                    </div>
                  </ListGroupItem>
                  <UncontrolledCollapse toggler={'#' + item.field_id + title}>
                    <div className='border border-danger'></div>
                    <div className='p-2 border' style={{ padding: '1rem' }}>
                      <EditField
                        item={item}
                        coreData={coreData}
                        hasReduxData={hasReduxData}
                        reduxDataValidate={reduxDataValidate}
                        manageDatabaseValidate={manageDatabaseValidate}
                        formName={formName}
                        pageName={pageName}
                        // setModal={setModal}
                      />
                    </div>
                  </UncontrolledCollapse>
                </Fragment>
              )
            })}
          </>
        )}
      </ListGroup> */}
    </>
  )
}

export const RenderCommonFields = ({
  fields,
  check,
  pageName,
  formName,
  source_table,
  form,
  isRepeatingForm,
  grid_id,
  activeTabData,
  parentPageName,
}) => {
  if (Object.keys(fields).length === 0) {
    return (
      <Col md={12}>
        <ListGroup>
          <RenderSection
            section={[]}
            pageName={pageName}
            formName={formName}
            source_table={source_table}
            fieldForm={form}
            isRepeatingForm={isRepeatingForm}
            grid_id={grid_id}
            activeTabData={activeTabData}
            parentPageName={parentPageName}
          />
        </ListGroup>
      </Col>
    )
  }
  return Object.keys(fields)?.map((title, index) => {
    const section = fields[title]
    if (title !== '0') {
      return (
        <React.Fragment key={title}>
          <Col md={6}>
            {
              <h4 className='card-header mb-3 field-section' style={{ justifyContent: 'space-between' }}>
                {tidyName(title)}
              </h4>
            }
            <RenderSection
              section={section}
              pageName={pageName}
              formName={formName}
              title={title}
              source_table={source_table}
              fieldForm={form}
              isRepeatingForm={isRepeatingForm}
              grid_id={grid_id}
              activeTabData={activeTabData}
              parentPageName={parentPageName}
            />
          </Col>
        </React.Fragment>
      )
    } else {
      return (
        <Col md={12} key={index}>
          {/* <div>{title}</div> */}
          <ListGroup>
            <RenderSection
              section={section}
              pageName={pageName}
              formName={formName}
              source_table={source_table}
              fieldForm={form}
              isRepeatingForm={isRepeatingForm}
              grid_id={grid_id}
              activeTabData={activeTabData}
              parentPageName={parentPageName}
            />
          </ListGroup>
        </Col>
      )
    }
  })
}

const EditTabPage = ({ activeTabData, pageName, parentPageName, showTitle, gridData }) => {
  const [modal, setModal] = useState(false)

  let fields = []

  activeTabData?.component_call_form[0]?.component_call_fields?.map((item, key) => {
    if (fields[0]) {
      fields[0].push(item)
    } else {
      fields[0] = [item]
    }
  })

  const formName = activeTabData?.component_call_form[0]?.name

  // const pageName = activeTabData?.name

  return (
    <>
      <Button onClick={() => setModal(true)} color='link' className='edit-form-page-button no-print'>
        {showTitle ? (
          'Edit Page'
        ) : (
          <>
            <Icon icon={'fas fa-pen'} bgcolor='green' color='info' navheadericon='true' id='add_form' />
            <UncontrolledTooltip placement='right' target='add_form'>
              Add Form
            </UncontrolledTooltip>
          </>
        )}
        <Modal isOpen={modal} toggle={() => setModal(!modal)} style={{ width: fields?.length === 1 ? 1500 : 1200 }} scrollable={true}>
          <ModalHeader toggle={() => setModal(!modal)}>Edit {cms(activeTabData?.cms_tab_key)}</ModalHeader>
          <ModalBody>
            {activeTabData?.is_grid === 1 ? (
              <GridSetting
                content={activeTabData?.component_call_form[0]}
                source_table={activeTabData?.source_table}
                pageName={pageName}
                parentPageName={parentPageName}
                activeTabData={activeTabData}
                gridData={gridData}
              />
            ) : (
              <Row>
                <RenderCommonFields
                  fields={fields}
                  formName={formName}
                  pageName={activeTabData?.name ? activeTabData?.name : pageName}
                  source_table={activeTabData?.source_table}
                  form={activeTabData?.component_call_form[0]}
                  parentPageName={parentPageName}
                />
              </Row>
            )}
          </ModalBody>
        </Modal>
      </Button>
    </>
  )
}

export default EditTabPage
