import { AvField } from "availity-reactstrap-validation"
import moment from "moment"
import { connect } from "react-redux"
import { cms, editCMS, tidyName } from "../../../helper"

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
})



const getReadOnlyValue = (value)=>{
  
  try{
    if (value && new Date(value) !== 'Invalid Date' &&  !isNaN(new Date(value))) {
      return moment(value).format('D MMM YYYY h:mm a')
    } else {
      return value
    }
  }catch(err){
    return value
  }
}

const InputReadOnly = ({ data, value, cmsEditMode, inputLabel }) => {


  return (
    <>
      <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)} className='font-weight-bold'>
        {tidyName(cms(data.cms_key)) || tidyName(data?.title)} :
      </label>
      <div>{getReadOnlyValue(inputLabel || value)}</div>
      <AvField
        type={'hidden'}
        name={data.field_id || data?.name || 'no_name'}
        id={data?.field_id}
        placeholder={tidyName(cms(data.cms_key))}
        value={value}
        // className={isFieldInSync ? 'blue-border' : ''}
        // value={fieldValue}
        onClick={() => (cmsEditMode ? editCMS(data.cms_key) : null)}
        disabled={true}
      />
    </>
  )
}


export default connect(select)(InputReadOnly)