import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'

import CMSField from '../../../../../Forms/Fields/index'
import { callInsertRepeatingGridData, cms, getConnectTemplateReduxDataAfterInsert } from '../../../../../../helper'
import { setConnectTemplateDataRedux } from '../../../../../../Redux/Reducers/CMS'
import { setNotification } from '../../../../../../Redux/Reducers/notificationHandling'
import { setQueue } from '../../../../../../Redux/Reducers/queue'
import { request } from '../../../../../../Redux/Sagas/requests/api'
import { fetchUpdateApiData } from '../../../../../../Redux/Reducers/CMS'

const AddUserInMessage = ({ pageData, addDataDetail, parentPageName, toggle }) => {
  const dispatch = useDispatch()
  let main_fields = addDataDetail?.mainGrid?.component_call_cols
    ?.filter((col) => col?.component_name === 'text_data_col')
    ?.filter((field) => field?.field_id === 'user_ids')
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const currentUserData = useSelector((state) => state.User.user)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)

  const [message, setMessage] = useState()

  const handleValidSubmit = (e, values) => {
    const source_table = addDataDetail?.tableGrid?.source_table
    const { message, ...rest } = values
    let data = {}
    data[source_table] = { ...rest }

    const messages = [
      {
        body: message,
        user_id: currentUserData?.id,
      },
    ]
    data[source_table]['messages'] = messages
    const dataToUpdate = getConnectTemplateReduxDataAfterInsert(
      connectTemplateDataRedux,
      parentPageName,
      parentPageName,
      source_table,
      [],
      {
        ...data[source_table],
        last_message: message,
        user_name: data[source_table]?.user_ids_label,
      },
      false
    )
    dispatch(setConnectTemplateDataRedux(dataToUpdate))

    let payload = {
      is_nested: true,
      domain_id,
      clause_data: addDataDetail?.tableGrid?.main_grid?.where_clause,
      data: {},
    }
    payload['data'][source_table] = [{ ...data[source_table] }]
    request('insert-repeating-grid-data', 'POST', payload).then(() => {
      dispatch(fetchUpdateApiData())
    })
    // callInsertRepeatingGridData(data, source_table, addDataDetail, false, domain_id)
    // dispatch(
    //   setQueue({
    //     url: 'insert-repeating-grid-data',
    //     payload: {
    //       data: {
    //         data: data,
    //         is_nested: true,
    //         domain_id,
    //         clause_data: addDataDetail?.tableGrid?.main_grid?.where_clause,
    //       },
    //     },
    //   })
    // )
    toggle()
  }

  if (main_fields?.length === 0)
    return <div style={{ textAlign: 'center', marginTop: '3rem', marginBottom: '1rem', fontSize: '1.3rem' }}> Form not added yet!</div>

  return (
    <AvForm onValidSubmit={handleValidSubmit}>
      <Row>
        {main_fields
          ?.filter((item) => item?.field_id !== 'id')
          ?.map((field, key) => {
            return (
              <Col md={field?.col || 6} key={key + field.field_id}>
                <div className='mb-2'>
                  <CMSField
                    id={'fieldToolTip' + key + field.field_id}
                    fieldKey={key}
                    data={field}
                    isModel={false}
                    allFieldsInForm={main_fields}
                    parentPageName={pageData?.name}
                    pageName={pageData?.name}
                    source_table={addDataDetail?.tableGrid?.source_table}
                    formData={addDataDetail?.tableGrid}
                    noDefaultValue={true}
                  />
                  {field.cms_tooltip && field.cms_tooltip !== null && (
                    <UncontrolledTooltip placement='left' target={'fieldToolTip' + key + field.field_id}>
                      {cms(field.cms_tooltip)}
                    </UncontrolledTooltip>
                  )}
                </div>
              </Col>
            )
          })}
        <Col md={6}>
          <AvField
            name='message'
            label='Message'
            value={message}
            setMessage={(e) => setMessage(e.target.value)}
            placeholder='Type message here...'
            required></AvField>
        </Col>
        <Col md={12} className='d-flex justify-content-center'>
          <Button color='primary' className='px-4 rounded-3' style={{ borderRadius: '1rem' }}>
            Send
          </Button>
        </Col>
      </Row>
    </AvForm>
  )
}

export default AddUserInMessage
