import { useEffect, useState } from 'react'
import { Modal, Table } from 'reactstrap'


export const PreviewForExisitingTemplate = ({report}) =>{
  //main details
  const [mainDetails, setMainDetails] = useState({ title: '', user_type: '', frequency: '', add_summary: false })

  //body data
  const [bodyRows, setBodyRows] = useState([])
  const [bodyForm, setBodyForm] = useState({
    field_id: '',
    title: '',
  })

  //body header
  const [bodyHeaderTitle, setBodyHeaderTitle] = useState('')
  const [bodyHeaderDescription, setBodyHeaderDescription] = useState('')

  //body
  const [bodyTitle, setBodyTitle] = useState('')
  const [bodyDescription, setBodyDescription] = useState()

  //body info
  const [selectedInfo, setSelectedInfo] = useState([])
  const [bodyInfo, setBodyInfo] = useState({ title: '', sub_title: '' })

  const [footer, setFooter] = useState('')

    useEffect(() => {
      const default_data = report
      if (report) {
        setMainDetails({
          title: default_data?.title,
          user_type: default_data?.user_type,
          frequency: default_data?.frequency,
          add_summary: default_data?.show_summary,
        })
        setBodyHeaderTitle(default_data?.header_title)
        setBodyHeaderDescription(default_data?.header_description)
        setBodyTitle(default_data?.body_title)
        setBodyDescription(default_data?.body_description)
        setBodyInfo({
          title: default_data?.body_info_data?.title,
          sub_title: default_data?.body_info_data?.sub_title,
        })
        setFooter(default_data?.footer)

        const default_selected_info = []
        if (default_data?.body_info_data?.data) {
          Object.keys(default_data?.body_info_data?.data)?.map((key) => {
            if (default_data?.body_info_data?.data?.[key]) {
              const data = {
                title: default_data?.body_info_data?.data?.[key],
                header: key,
              }
              default_selected_info?.push(data)
            }
          })
        }
        setSelectedInfo(default_selected_info)

        const default_body_rows = []

        default_data?.grid_columns?.map((item) => {
          default_body_rows?.push({
            title: item?.title,
            field_id: item?.field_id,
          })
        })
        setBodyRows(default_body_rows)
      }
    }, [])
  return (
    <Preview
      mainDetails={mainDetails}
      bodyRows={bodyRows}
      bodyHeaderDescription={bodyHeaderDescription}
      bodyHeaderTitle={bodyHeaderTitle}
      selectedInfo={selectedInfo}
      bodyTitle={bodyTitle}
      bodyDescription={bodyDescription}
      footer={footer}
      bodyInfo={bodyInfo}></Preview>
  )
}



const Preview = ({ mainDetails, bodyRows, bodyHeaderDescription, bodyHeaderTitle, selectedInfo, bodyTitle, bodyDescription, footer, bodyInfo }) => {
  return (
    <div className='mt-3 mb-1'>
      <div className='mb-1'>
        <div className='font-weight-bold h5'>{bodyHeaderTitle}</div>
        <div dangerouslySetInnerHTML={{ __html: bodyHeaderDescription }}></div>
      </div>
      <div className='mb-4 w-50'>
        {bodyInfo?.title && <div className='bg-light p-2'>{bodyInfo?.title}</div>}
        {(bodyInfo?.sub_title || selectedInfo?.length > 0) && (
          <div className='p-4 bg-light mt-1'>
            <div className='font-weight-bold mb-2'>{bodyInfo?.sub_title}</div>
            {selectedInfo?.map((info, index) => {
              return (
                <div key={index} className='d-flex flex-row justify-content-between'>
                  <div>{info?.title}</div>
                  <div>12</div>
                </div>
              )
            })}
          </div>
        )}
      </div>
      <div className='mb-4' style={{ overflow: 'auto' }}>
        <div dangerouslySetInnerHTML={{ __html: bodyTitle }} className='mb-2'></div>
        <div dangerouslySetInnerHTML={{ __html: bodyDescription }} className='mb-2'></div>
        {bodyRows?.length > 0 && (
          <Table striped>
            <thead>
              <tr>
                {bodyRows?.map((col, index) => {
                  return <th key={index}>{col?.title}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 10 }, () => Math.floor(Math.random() * 100) + 1)?.map((item, i) => {
                return (
                  <tr key={i}>
                    {bodyRows?.map((col, index) => {
                      return <td key={index}>{col?.title}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      </div>
      <div className='mb-4'>
        <div dangerouslySetInnerHTML={{ __html: footer }}></div>
      </div>
    </div>
  )
}

export default Preview
