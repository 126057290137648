import { AvField } from 'availity-reactstrap-validation'
import { Button, Col, Row, Table } from 'reactstrap'
import CMSRichText from '../../../../Forms/Fields/richText'
import { tidyName } from '../../../../../helper'
import { useEffect, useState } from 'react'
import IconSet from '../../../../icon'

const getFieldByFieldId = (columns, field_id) => {
  const field = columns?.find((item) => item?.field_id === field_id || item?.name === field_id)

  return field?.title || field?.name
}

const BodyColumn = ({ bodyRows, columns, setBodyRows }) => {
  const [bodyForm, setBodyForm] = useState({ field_id: '', title: '' })
  const [expandedRow, setExpandedRow] = useState()

  const onDeleteBodyRows = (index) => {
    const newData = [...bodyRows]
    newData?.splice(index, 1)
    setBodyRows(newData)
  }

  const onUpdateForm = () => {
    let data = [...bodyRows]
    data[expandedRow] = { ...bodyForm }
    setBodyRows(data)
    setBodyForm({ field_id: '', title: '' })
    setExpandedRow()
  }

  if (bodyRows?.length === 0) return <div style={{ textAlign: 'center' }}>Column not added yet!</div>
  return (
    <>
      <div className='table-wrapper-scroll-y'>
        <Table striped style={{ maxHeight: '20rem', overflow: 'auto' }}>
          <thead>
            <tr>
              <th style={{ width: '1rem' }}></th>
              <th style={{ width: '5rem' }}></th>
              <th>Title</th>
              <th>Field</th>
            </tr>
          </thead>
          <tbody>
            {bodyRows?.map((item, index) => {
              return (
                <>
                  <tr key={item?.field_id}>
                    <td>
                      <IconSet
                        icon={index === expandedRow ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                        color='primary'
                        style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                        onClick={() => {
                          if (expandedRow === index) {
                            setExpandedRow()
                          } else {
                            setExpandedRow(index)
                            setBodyForm({
                              title: item?.title,
                              field_id: item?.field_id,
                            })
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Button className='btn-danger' onClick={() => onDeleteBodyRows(index)}>
                        <IconSet icon='pe-7s-trash' color='light' />
                      </Button>
                    </td>
                    <td>{item?.title}</td>
                    <td>{tidyName(getFieldByFieldId(columns, item?.field_id))}</td>
                  </tr>
                  {index === expandedRow && (
                    <tr key={'expanded' + item?.field_id}>
                      <td colSpan={5}>
                        <Row style={{ marginBottom: '1rem' }}>
                          <Col md={6}>
                            <AvField
                              type='select'
                              name='field_id'
                              label='Select Field'
                              value={bodyForm?.field_id}
                              onChange={(e) =>
                                setBodyForm({
                                  ...bodyForm,
                                  field_id: e.target.value,
                                })
                              }>
                              <option>Select Field</option>
                              {columns?.map((item, index) => {
                                return (
                                  <option key={index} value={item?.field_id || item?.name}>
                                    {tidyName(item.title) || tidyName(item.name)}
                                  </option>
                                )
                              })}
                            </AvField>
                          </Col>
                          <Col md={6}>
                            <AvField
                              name='field_title'
                              label={'Title'}
                              value={bodyForm?.title}
                              onChange={(e) =>
                                setBodyForm({
                                  ...bodyForm,
                                  title: e.target.value,
                                })
                              }></AvField>
                          </Col>
                          <Col md={12}>
                            <Button color='primary' className='float-right' onClick={() => onUpdateForm()}>
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  )}
                </>
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}
export default BodyColumn
