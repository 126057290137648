import { useEffect, useState } from 'react'

const Uploads = ({ data }) => {
  const [files, setFiles] = useState([])


  useEffect(() => {
    const files_array = []

    if (data?.uploads) {
      data?.uploads?.map((upload) => {
        if (upload?.upload_files) {
          upload?.upload_files?.map((file) => {
            files_array?.push(file?.file_name)
          })
        }
      })
    }else{
      setFiles(data)
    }
    setFiles(files_array)
  }, [])

  return <>
  {files && files?.map((file,key)=>{
    return <a key={key} href={`${process.env.REACT_APP_URL_FILES}${file}`} target='_blank' style={{background:'transparent'}}>{file} &nbsp;&nbsp;</a>
  })}
  </>
}

export default Uploads
