import React from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Card, CardBody, CardTitle, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { tidyName } from '../../../helper'
import EditTab from './editTab'
import EditColumn from './editColumn'
import { Multiselect } from 'react-widgets'
import WhereClause from './whereClause'
import ActionTypeField from '../../Common/actionType'

const FORM_TYPE = [
  {
    title: 'Repeating Form',
    value: 'REPEATING_FORM',
  },
  {
    title: 'Grid',
    value: 'GRID',
  },
]

function CreateAdvanceGrid({ updateForm, form, grid_selector, actionTypes, updateColumn, updateTab, updateWhereClauseForm }) {
  return (
    <>
      <Col md={4}>
        <AvField type='select' label='Table' name={'table'} required onChange={(e) => updateForm({ ...form, table: e.target.value })}>
          <option value=''>Select Table</option>
          {Object.keys(grid_selector).map(function (key, index) {
            return (
              <option value={key} key={key}>
                {tidyName(key)}
              </option>
            )
          })}
        </AvField>
      </Col>
      <Col md={4}>
        <ActionTypeField onChange={(value) => updateForm({ ...form, action_types: value })} value={form?.action_types} />
      </Col>
      <Col md={4}>
        <AvField type='select' label='Form Type' name={'form_type'} required onChange={(e) => updateForm({ ...form, type: e.target.value })}>
          <option value=''>Select Form Type</option>
          {FORM_TYPE.map(function (key, index) {
            return (
              <option value={key.value} key={index}>
                {key.title}
              </option>
            )
          })}
        </AvField>
      </Col>

      <EditColumn form={form} updateColumn={updateColumn} isInlineEditDisabled={form?.type === 'REPEATING_FORM' ? true : false} />
      <EditTab form={form} updateTab={updateTab} grid_selector={grid_selector} />

      {form?.table && (
        <Col md={12}>
          <WhereClause source_table={form?.table} onChange={updateWhereClauseForm} />
        </Col>
      )}
    </>
  )
}

export default CreateAdvanceGrid
