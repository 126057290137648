import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearTableDropDown,
  fieldCreatedStatus,
  setTableDropDown,
  setTableDropDownFullData,
  submitRequest,
  updatePageDataRedux,
} from '../../../../Redux/Reducers/CMS'
import FormToggleSwitch from '../../../Forms/Fields/switchButton'
import { cms } from '../../../../helper'
import AddCustomColumn from './addCustomColumn'
import AddDependentField from './addDependentField'
import { request } from '../../../../Redux/Sagas/requests/api'

const defaultForm = {
  name: '',
  type: 'input',
  field_id: '',
  api_submit: '',
  validations: [],
  col: 6,
  manage_data_database: '',
  title: '',
  print: false,
  export: false,
  hide_from_grid: false,
}

const validations = ['required', 'string', 'max_50', 'max_100', 'email', 'min_5', 'confirm_password', 'numeric']

const CommonField = ({
  formName,
  pageName,
  setModal,
  type,
  field = null,
  tab = false,
  setAddForm,
  section,
  source_table,
  is_create = false,
  isLayout,
  rowIndex,
  blockIndex,
  allFields,
  blockFields,
  isCreatingFieldForRepeatingForm,
  grid_id,
  parentPageName,
  isAddDataForm,
  order,
  selectedField,
}) => {
  const [form, updateForm] = useState(defaultForm)
  const [load, updateLoad] = useState(false)
  const dispatch = useDispatch()
  const [dependentFormData, updateDependentFormData] = useState([])

  useEffect(() => {
    updateForm({ ...form, type: type })
    updateDependentFormData(field?.field_setting?.depend_fields)
  }, [])

  useEffect(() => {
    if (field) {
      const validationValueFromDb = field.component_call_validation?.map((item) => item?.validation_type)

      const field_setting = field?.field_setting
      updateForm({
        ...form,
        field_id: field.field_id,
        print: field_setting?.print,
        export: field_setting?.export,
        title: cms(field.cms_key),
        col: field_setting?.col || field?.col,
        validations: validationValueFromDb,
        type: field?.type || field?.field_setting?.field || field?.field || field?.component_name,
        hide_from_grid: field?.hide_from_grid,
      })
    }
  }, [])

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    let data = {
      ...values,
      form_name: formName,
      page_name: pageName,
      print: form?.print,
      export: form?.export,
      validations: form.validations,
      manage_data_database: form.manage_data_database,
      type: form.type,
      depend_fields: dependentFormData,
      hide_from_grid: form?.hide_from_grid,
      parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
      order,
    }
    if (tab) {
      data = {
        ...data,
        tab: true,
        section: section,
      }
    }

    if (isLayout) {
      data = {
        ...data,
        is_layout: true,
        row_index: rowIndex,
        block_index: blockIndex,
      }
      if (is_create && isLayout) {
        data = {
          ...data,
          order: blockFields ? blockFields?.length + 1 : 0,
        }
      }
    }
    dispatch(setTableDropDown({}))
    dispatch(clearTableDropDown())
    dispatch(setTableDropDownFullData({}))
    dispatch(fieldCreatedStatus(false))

    if (isCreatingFieldForRepeatingForm) {
      const { form_name, ...rest } = data
      const payload = {
        ...rest,
        grid_id: grid_id,
        source_table: source_table,
      }
      if (parentPageName) {
        request(`submit-form-field`, 'POST', payload).then((data) => {
          request(`get-page/${parentPageName}`, 'GET').then((data) => {
            let pageData = {}
            if (data.status === 200) {
              pageData[parentPageName] = data.data

              dispatch(updatePageDataRedux(pageData))
            }

            // dispatch(setLoader(false))
          })
        })
      }

      // dispatch(submitRequest({ url: 'submit-form-field', data: payload }))
    } else if (isAddDataForm) {
      const payload = {
        ...data,
        grid_id: grid_id,
      }
      request(`submit-form-field`, 'POST', payload).then((data) => {
        if (parentPageName) {
          request(`get-page/${parentPageName}`, 'GET').then((data) => {
            let pageData = {}
            if (data.status === 200) {
              pageData[parentPageName] = data.data

              dispatch(updatePageDataRedux(pageData))
            }

            // dispatch(setLoader(false))
          })
        }
      })
      //  dispatch(submitRequest({ url: 'submit-form-field', data: payload }))
    } else {
      request(`submit-form-field`, 'POST', data).then((data) => {
        if (parentPageName) {
          request(`get-page/${parentPageName}`, 'GET').then((data) => {
            let pageData = {}
            if (data.status === 200) {
              pageData[parentPageName] = data.data

              dispatch(updatePageDataRedux(pageData))
            }

            // dispatch(setLoader(false))
          })
        }
      })
      // dispatch(submitRequest({ url: 'submit-form-field', data }))
    }
    setTimeout(() => {
      dispatch(fieldCreatedStatus(true))
    }, 500)

    setModal(false)
    setAddForm(false)
    updateForm(defaultForm)
  }
  return (
    <>
      <AvForm onValidSubmit={handleValidSubmit} model={form}>
        <Row>
          {tab && is_create ? (
            <Col md={6}>
              <AddCustomColumn
                source_table={source_table}
                value={form.field_id}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
                selectedField={selectedField}
              />
            </Col>
          ) : (
            <Col md={6}>
              <AvField
                name='field_id'
                label='Field ID'
                type='text'
                required
                disabled={is_create ? false : true}
                value={form.field_id}
                validate={{
                  pattern: {
                    value: '^[A-Za-z0-9_]+$',
                    errorMessage: 'Your name must be composed only with letter and numbers',
                  },
                }}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
              />
            </Col>
          )}

          <Col md={6}>
            <label>Validations</label>
            <Multiselect
              data={validations}
              value={form.validations}
              onChange={(value) => updateForm({ ...form, validations: value })}
              textField='validations'
            />
          </Col>
          <Col md={isLayout ? 3 : 6}>
            <label>Print</label>
            <FormToggleSwitch value={form.print} onChange={(toggle_data) => updateForm({ ...form, print: toggle_data })} />
          </Col>
          <Col md={isLayout ? 3 : 6}>
            <label>Export</label>
            <FormToggleSwitch value={form.export} onChange={(toggle_data) => updateForm({ ...form, export: toggle_data })} />
          </Col>
          <Col md={6}>
            <AvField
              name='title'
              label='title'
              type='text'
              value={form?.title}
              onChange={(e) => updateForm({ ...form, title: e.target.value })}></AvField>
          </Col>
          <Col md={6}>
            <label>Hide from Grid</label>
            <FormToggleSwitch value={form.hide_from_grid} onChange={(toggle_data) => updateForm({ ...form, hide_from_grid: toggle_data })} />
          </Col>

          {!isLayout && (
            <Col md={6}>
              <AvField type='select' name='col' label='Column' value={form?.col} onChange={(e) => updateForm({ ...form, col: e.target.value })}>
                {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  )
                })}
              </AvField>
            </Col>
          )}
          <Col md={12}>
            <AddDependentField
              allFields={allFields}
              updateDependentFormData={updateDependentFormData}
              dependentFormData={dependentFormData}
              field={field}
            />
          </Col>

          <Col md={12}>
            <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default CommonField
