import { AvField, AvForm } from "availity-reactstrap-validation"
import { useEffect, useState } from "react"
import { Button, ButtonDropdown, Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Rodal from "rodal"
import IconSet from "../../../../icon"

const getArray = (number)=>{
  let a = []

  for(let i=0;i<number;i++){
    a.push(i+1)
  }
  return a
}

const TextTimeFilter = ({ value, updateValue, triggerSearch, column, handleSubmit, isMonthFilter }) => {
  const [openModal, setOpenModal] = useState(false)

  const [hours, setHours] = useState()
  const [mins, setMins] = useState()
  const [days, setDays] = useState()
  const [months, setMonths] = useState()
  const [years, setYears] = useState()

  useEffect(() => {
    setYears(value?.years)
 
    setMonths(value?.months)
    
    if(!isMonthFilter){
      setMins(value?.minutes)
      setHours(value?.hours)
      setDays(value?.days)
    }
  }, [])

  const onSave = () => {
    if (!years && !months && !days && !hours && !mins) {
      onClear()
    } else {
      let data = {
        hours,
        days,
        years,
        months,
        minutes: mins,
      }
      updateValue(data)
      triggerSearch(data)
      setOpenModal(false)
    }
  }

  const onClear = () => {
    setHours()
    setMins()
    setDays()
    setYears()
    setMonths()
    updateValue('')
    setOpenModal(false)
    triggerSearch()
  }

  let selectedText = ''
  if (years) {
    selectedText += `${years} Years, `
  }
  if (months) {
    selectedText += `${months} Months, `
  }
  if (days) {
    selectedText += `${days} Days, `
  }
  if (hours) {
    selectedText += `${hours} Hours, `
  }
  if (mins) {
    selectedText += `${mins} Minutes, `
  }

  return (
    <div>

      <Dropdown
        isOpen={openModal}
        direction='bottom'
        toggle={() => setOpenModal(!openModal)}
        // className='border-0 btn-icon btn-icon-only fsize-1 m-2'
        id={'text_time_dropdown'}
        className='text-time-filter-button-container'>
        <DropdownToggle className='text-time-filter-button'>{selectedText?.length > 0 ? selectedText : 'Select time'}</DropdownToggle>
        <DropdownMenu className='text-time-dropdown-menu'>
          <AvForm>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AvField type='number' name='years' value={years} onChange={(e) => setYears(e.target.value)} style={{ width: '4rem' }}></AvField>
              <span className='title'>Year</span>
              <AvField type='select' name='months' value={months} onChange={(e) => setMonths(e.target.value)} style={{ width: '4rem' }}>
                <option></option>
                {getArray(12)?.map((item,i) => {
                  return <option key={i} value={item}> {item}</option>
                })}
              </AvField>
              <span className='title'>Month</span>
            </div>
            {!isMonthFilter && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                  <AvField type='select' name='days' value={days} onChange={(e) => setDays(e.target.value)} style={{ width: '4rem' }}>
                    <option></option>
                    {getArray(31)?.map((item,i) => {
                      return <option key={i} value={item}> {item}</option>
                    })}
                  </AvField>
                  <span className='title'>Days</span>
                  <AvField type='select' name='hours' value={hours} onChange={(e) => setHours(e.target.value)} style={{ width: '4rem' }}>
                    <option></option>
                    {getArray(24)?.map((item,i) => {
                      return <option key={i} value={item}> {item}</option>
                    })}
                  </AvField>
                  <span className='title'>Hour</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', width: '1rem' }}>
                  <AvField type='select' name='minutes' value={mins} onChange={(e) => setMins(e.target.value)} style={{ width: '4rem' }}>
                    <option></option>
                    {getArray(60)?.map((item,i) => {
                      return <option key={i} value={item}> {item}</option>
                    })}
                  </AvField>
                  <span className='title'>Minutes</span>
                </div>
              </>
            )}
          </AvForm>
          <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'row-reverse' }}>
            <Button color='primary' onClick={() => onSave()}>
              Save
            </Button>
            <Button onClick={() => onClear()} style={{ marginRight: '1rem' }}>
              Clear
            </Button>
          </div>
        </DropdownMenu>
      </Dropdown>

    </div>
  )
}

export default TextTimeFilter;