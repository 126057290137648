import { select } from 'redux-saga/effects'
import { toast } from 'react-toastify'

const defaultConfig = {
  position: 'top-right',
  autoClose: 9000,
}

export function* showNotification() {
  const { NotificationHandling } = yield select()
  toast[NotificationHandling.type](NotificationHandling.message, defaultConfig)
}
