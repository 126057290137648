import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, UncontrolledCollapse, ListGroupItem } from 'reactstrap'
import CreateField from './createField'

import React, { Fragment, useEffect } from 'react'

import Icon from '../../icon'
import { fieldCreatedStatus, removeItemByName, submitRequest, updatePageDataRedux } from '../../../Redux/Reducers/CMS'
import ConfirmButton from '../../Common/confirmButton'
import { checkCMSPermission, cms } from '../../../helper'

import EditField from './editField'
import { tidyName } from '../../../helper'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'
import { request } from '../../../Redux/Sagas/requests/api'
import { setLoader } from '../../../Redux/Reducers/loader'

const RenderEditField = ({
  item,
  formName,
  pageName,
  tab,
  section,
  coreData,
  hasReduxData,
  reduxDataValidate,
  manageDatabaseValidate,
  isEnableOrder,
  row,
  activeRow,
  key,
  allFields,
  grid_id,
  isCreatingFieldForRepeatingForm,
  source_table,
  activeTabData,
  parentPageName,
  isAddDataForm,
  setAddFormList,
  order,
  onBlockDragStart,
  onFieldDragStart,
  onFieldDragOver,
  onFieldDragEnd,
  dragOverFieldItem,
}) => {
  const dispatch = useDispatch()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const current_row_column = activeRow?.column?.length
  const total_row_length = row?.length

  const item_block_index = isCreatingFieldForRepeatingForm ? item?.field_setting?.block_index : item?.block_index
  const item_row_index = isCreatingFieldForRepeatingForm ? item?.field_setting?.row_index : item?.row_index

  const onMoveLeft = (e) => {
    e.stopPropagation()

    let payload = {}
    if (isCreatingFieldForRepeatingForm) {
      payload = {
        page_name: pageName,
        tab: tab,
        field_id: item?.field_id,
        grid_id: grid_id,
        source_table: source_table,
      }
    } else {
      payload = {
        page_name: pageName,
        form_name: formName,
        tab: tab,
        field_id: item?.field_id,
      }
    }
    if (item_block_index > 0) {
      const new_block_index = item_block_index - 1
      const data = {
        ...payload,
        block_index: new_block_index,
        row_index: item_row_index,
        is_layout: true,
      }
      dispatch(setLoader(true))
      request('update-form-field', 'POST', data).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(submitRequest({ url: 'update-form-field', data }))
    } else if (item_block_index === current_row_column && item_row_index > 0) {
      const new_row_index = item?.row_index - 1
      const data = {
        ...payload,
        block_index: item_block_index,
        row_index: new_row_index,
        is_layout: true,
      }
      dispatch(setLoader(true))
      request('update-form-field', 'POST', data).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(submitRequest({ url: 'update-form-field', data }))
    }
  }
  const onMoveRight = (e) => {
    e.stopPropagation()

    let payload = {}
    if (isCreatingFieldForRepeatingForm) {
      payload = {
        page_name: pageName,
        tab: tab,
        field_id: item?.field_id,
        grid_id: grid_id,
        source_table: source_table,
      }
    } else {
      payload = {
        page_name: pageName,
        form_name: formName,
        tab: tab,
        field_id: item?.field_id,
      }
    }

    if (item_block_index < current_row_column) {
      const new_block_index = item_block_index + 1
      const data = {
        ...payload,
        block_index: new_block_index,
        row_index: item_row_index,
        is_layout: true,
      }
      dispatch(setLoader(true))
      request('update-form-field', 'POST', data).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(submitRequest({ url: 'update-form-field', data }))
    } else if (item_block_index === current_row_column && item_row_index < total_row_length) {
      const new_row_index = item_row_index - 1
      const data = {
        ...payload,
        block_index: item_block_index,
        row_index: new_row_index,
        is_layout: true,
      }
      dispatch(setLoader(true))
      request('update-form-field', 'POST', data).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(submitRequest({ url: 'update-form-field', data }))
    }
  }

  let isDraggingOnField = false
  if (dragOverFieldItem && dragOverFieldItem?.field_id === item?.field_id) {
    isDraggingOnField = true
  }

  return (
    <Col md={12} key={key}>
      <div
        draggable
        data-drag-type='field'
        data-row-index={item_row_index - 1}
        data-column-index={item_block_index - 1}
        field_id={item?.field_id}
        onDragStart={(e) => onFieldDragStart(e)}
        onDragOver={(e) => onFieldDragOver(e)}
        onDragEnd={() => onFieldDragEnd()}
        className='list-group-item'
        onClick={() => setIsEditModalOpen(!isEditModalOpen)}
        style={{ border: isDraggingOnField && '1px solid red' }}>
        {isEnableOrder && (
          <span style={{ marginRight: '1rem', cursor: 'pointer' }} className='float-left'>
            <i className='fa-sharp fa-solid fa-grid-horizontal'></i>
          </span>
        )}

        <span
          style={{ display: '-webkit-inline-box', maxWidth: '10rem' }}
          data-drag-type='field'
          data-row-index={item_row_index - 1}
          data-column-index={item_block_index - 1}
          field_id={item?.field_id}>
          {item?.component_name === 'section_heading' && <span>Section Heading</span>}
          {item?.component_name === 'spacer' && <span>Spacer</span>}
          {item?.component_name !== 'section_heading' && item?.component_name !== 'spacer' && (
            <span data-drag-type='field' data-row-index={item_row_index - 1} data-column-index={item_block_index - 1} field_id={item?.field_id}>
              {tidyName(cms(item?.cms_key || item.name))}
            </span>
          )}
          {item?.component_name !== 'section_heading' && item?.component_name !== 'spacer' && (
            <span
              style={{ fontSize: '0.7rem' }}
              data-drag-type='field'
              data-row-index={item_row_index - 1}
              data-column-index={item_block_index - 1}
              field_id={item?.field_id}>
              ({tidyName(item?.component_name)})
            </span>
          )}
        </span>

        <div className='float-right' style={{ cursor: 'hover' }}>
          {!isEnableOrder && checkCMSPermission('delete_field') && (
            <ConfirmButton
              style={{ padding: 0 }}
              color='link'
              id={item.field_id?.replace('.', '') || item?.name?.replace('.', '')}
              clickEvent={() => {
                if (isCreatingFieldForRepeatingForm) {
                  const data = {
                    name: item.field_id,
                    // form_name: formName,
                    page_name: pageName,
                    type: 'field',
                    tab: tab,
                    grid_id: grid_id,
                    source_table: source_table,
                    section: section,
                  }
                  request('remove/field', 'POST', data).then(() => {
                    request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
                      let pageData = {}
                      pageData[parentPageName] = data

                      dispatch(updatePageDataRedux(pageData))
                      dispatch(setLoader(false))
                    })
                  })
                  // dispatch(
                  //   removeItemByName({
                  //     name: item.field_id,
                  //     // form_name: formName,
                  //     page_name: pageName,
                  //     type: 'field',
                  //     tab: tab,
                  //     grid_id: grid_id,
                  //     source_table: source_table,
                  //     section: section,
                  //   })
                  // )
                } else if (isAddDataForm) {
                  const data = {
                    grid_id: grid_id,
                    form_name: formName,
                    page_name: pageName,
                    name: item?.field_id,
                  }
                  request('remove/grid-form-field', 'POST', data).then((response) => {
                    request('get-grid-form', 'POST', data).then((response) => {
                      if (response?.data) {
                        setAddFormList(response?.data)
                      }
                    })
                  })

                  // dispatch(submitRequest({ url: 'grid-form-field', data }))
                } else {
                  const data = {
                    name: item.field_id,
                    form_name: formName,
                    page_name: pageName,
                    type: 'field',
                    tab: tab,
                    section: section,
                  }
                  request('remove/field', 'POST', data).then(() => {
                    request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
                      let pageData = {}
                      pageData[parentPageName] = data

                      dispatch(updatePageDataRedux(pageData))
                      dispatch(setLoader(false))
                    })
                  })
                  // dispatch(
                  //   removeItemByName({
                  //     name: item.field_id,
                  //     form_name: formName,
                  //     page_name: pageName,
                  //     type: 'field',
                  //     tab: tab,
                  //     section: section,
                  //   })
                  // )
                }
              }}>
              <Icon icon={'fa-solid fa-xmark'} color='danger' />
            </ConfirmButton>
          )}
        </div>
      </div>
      <Modal isOpen={isEditModalOpen} toggle={() => setIsEditModalOpen(!isEditModalOpen)} scrollable={true}>
        <ModalHeader toggle={() => setIsEditModalOpen(!isEditModalOpen)}>Edit Field</ModalHeader>
        <ModalBody>
          <EditField
            item={item}
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            // pageName={pageName}
            setModal={setIsEditModalOpen}
            section={section}
            setAddFormOpen={setIsEditModalOpen}
            tab={tab}
            isLayout={true}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            pageName={pageName}
            parentPageName={parentPageName}
            isAddDataForm={isAddDataForm}
            order={order + 1}
          />
        </ModalBody>
      </Modal>
    </Col>
  )
}

const FieldBlock = ({
  col,
  index,
  coreData,
  hasReduxData,
  reduxDataValidate,
  manageDatabaseValidate,
  formName,
  pageName,
  setModal,
  section,
  tab,
  setAddForm,
  source_table,
  rowIndex,
  fields,
  isLayoutSaved,
  isEnableOrder,
  row,
  activeRow,
  orderSubmitted,
  allFieldsInLayout,
  grid_id,
  isCreatingFieldForRepeatingForm,
  blockIndex,
  setRow,
  activeTabData,
  parentPageName,
  isAddDataForm,
  setAddFormList,
  onBlockDragStart,
  onBlockDragComplete,
  onDragOverBlockItem,
  onFieldDragStart,
  onFieldDragOver,
  onFieldDragEnd,
  dragOverFieldItem,
  dragOverBlock,
}) => {
  const [isAddFieldModalOpen, setIsAddFieldModalOpen] = useState(false)
  const dispatch = useDispatch()
  const [allFields, setAllFields] = useState(fields)
  const [formFieldOrder, setFormFieldOrder] = useState([])
  const [render, ReRender] = useState(false)

  useEffect(() => {
    // ReRender(!render)
    setAllFields(fields)
  }, [fields])

  useEffect(() => {
    let payload = {
      data: formFieldOrder,
      form_name: formName,
      page_name: pageName,
      grid_id: grid_id,
    }
    if (true) {
      payload = { ...payload, tab: true }
    }
    if (formFieldOrder.length > 0 && orderSubmitted) {
      if (setFormFieldOrder) {
        request('submit-form-field-order', 'POST', {
          ...payload,
        }).then((response) => {
          request('get-grid-form', 'POST', {
            grid_id,
          }).then((response) => {
            if (response?.data) {
              setAddFormList(response?.data)
            }
          })
        })
      } else {
        dispatch(
          submitRequest({
            url: 'submit-form-field-order',
            data: payload,
          })
        )
      }
    }
  }, [orderSubmitted])

  const onRemoveBlock = () => {
    const old_row = [...row]
    const old_row_block = old_row[rowIndex]?.column
    old_row_block?.splice(blockIndex, 1)
    setRow(old_row)
    const data = {
      layout: JSON.stringify(old_row),
      form_name: formName,
      page_name: pageName,
      parent_page_name: parentPageName,
      tab: true,
    }
    dispatch(fieldCreatedStatus(false))
    if (isCreatingFieldForRepeatingForm && !isAddDataForm) {
      const data = {
        grid_id: grid_id,
        column_layout: row,
      }
      dispatch(submitRequest({ url: 'save-grid-layout', data, loadWithoutLoader: true, load: false }))
    } else {
      if (!isAddDataForm) {
        dispatch(submitRequest({ url: 'save-form', data, loadWithoutLoader: true, load: false }))
      }
    }
    if (row?.length > 0 && isAddDataForm) {
      const data = {
        layout: JSON.stringify(row),
        form_name: formName,
        page_name: pageName,
        grid_id: grid_id,
        //  tab: true,
        //  parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
      }
      dispatch(submitRequest({ url: 'save-form', data, loadWithoutLoader: true, load: false }))
      setTimeout(() => {
        dispatch(fieldCreatedStatus(true))
      }, 500)
    }

    //write logic to save layout
  }

  let isDraggingOnBlock = false

  if (dragOverBlock?.row_index === rowIndex && dragOverBlock?.block_index === index) {
    isDraggingOnBlock = true
  }
  return (
    <>
      <Col md={col?.col} style={{ padding: '0' }} data-drag-type='block' data-row-index={rowIndex} data-column-index={index}>
        <div
          className='layout-setting-block'
          data-drag-type='block'
          data-row-index={rowIndex}
          data-column-index={index}
          draggable
          onDragStart={(e) => onBlockDragStart(e)}
          onDragEnd={() => onBlockDragComplete(rowIndex, index)}
          onDragEnter={(e) => onDragOverBlockItem(e)}
          style={{ border: isDraggingOnBlock && '1px solid red' }}>
          <Row>
            {!isEnableOrder &&
              allFields?.map((item, key) => {
                return (
                  <Fragment key={key}>
                    <RenderEditField
                      item={item}
                      order={key}
                      formName={formName}
                      tab={tab}
                      pageName={pageName}
                      section={section}
                      isEnableOrder={isEnableOrder}
                      row={row}
                      activeRow={activeRow}
                      allFields={allFieldsInLayout}
                      grid_id={grid_id}
                      isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
                      source_table={source_table}
                      activeTabData={activeTabData}
                      parentPageName={parentPageName}
                      isAddDataForm={isAddDataForm}
                      setAddFormList={setAddFormList}
                      onBlockDragStart={onBlockDragStart}
                      onFieldDragStart={onFieldDragStart}
                      onFieldDragOver={onFieldDragOver}
                      onFieldDragEnd={onFieldDragEnd}
                      dragOverFieldItem={dragOverFieldItem}
                    />
                  </Fragment>
                )
              })}
            {isEnableOrder && (
              <RLDD
                cssClasses='example col-md-12'
                items={allFields?.filter((item) => item?.component_name !== 'hidden_data')}
                itemRenderer={(item, key) => {
                  return (
                    <RenderEditField
                      item={item}
                      formName={formName}
                      tab={tab}
                      pageName={pageName}
                      section={section}
                      isEnableOrder={isEnableOrder}
                      row={row}
                      activeRow={activeRow}
                      key={key}
                      grid_id={grid_id}
                      isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
                      source_table={source_table}
                      activeTabData={activeTabData}
                      parentPageName={parentPageName}
                      isAddDataForm={isAddDataForm}
                      setAddFormList={setAddFormList}
                    />
                  )
                }}
                onChange={(e) => {
                  setAllFields(e)
                  let requestData = []
                  e &&
                    e.map((item, key) => {
                      requestData.push({
                        name: item.field_id || item.name,
                        order: key,
                      })
                    })
                  setFormFieldOrder(requestData)
                }}
              />
            )}
          </Row>
          {!isEnableOrder && (
            <div style={{ textAlign: 'center' }}>
              <span
                style={{ color: '#788f7f', padding: '0.3rem 0.8rem', borderRadius: '5px', cursor: 'pointer' }}
                onClick={() => {
                  setIsAddFieldModalOpen(true)
                }}>
                {isLayoutSaved ? 'Add field' : 'Layout is not updated'}
                {/* in Block {index + 1} */}
              </span>
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            <ConfirmButton
              style={{ padding: 0 }}
              color='link'
              id={'this_block'}
              clickEvent={() => {
                onRemoveBlock()
              }}>
              <span style={{ color: 'red', padding: '0.3rem 0.8rem', borderRadius: '5px', cursor: 'pointer' }}>
                Remove Block
                {/* in Block {index + 1} */}
              </span>
            </ConfirmButton>
          </div>
        </div>
      </Col>
      <Modal isOpen={isAddFieldModalOpen} toggle={() => setIsAddFieldModalOpen(!isAddFieldModalOpen)} scrollable={true}>
        <ModalHeader toggle={() => setIsAddFieldModalOpen(!isAddFieldModalOpen)}>Add Field</ModalHeader>
        <ModalBody>
          <CreateField
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            section={section}
            tab={tab}
            setAddForm={setIsAddFieldModalOpen}
            source_table={source_table}
            isLayout={true}
            rowIndex={rowIndex + 1}
            blockIndex={index + 1}
            allFields={allFieldsInLayout}
            blockFields={allFieldsInLayout?.filter((field) => field?.row_index === rowIndex)?.filter((field) => field?.block_index === index)}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            activeTabData={activeTabData}
            isAddDataForm={isAddDataForm}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

const FieldRowForm = ({
  rowIndex,
  setRow,
  row,
  activeRow,
  coreData,
  hasReduxData,
  reduxDataValidate,
  manageDatabaseValidate,
  formName,
  pageName,
  setModal,
  section,
  tab,
  setAddForm,
  source_table,
  fields,
  isLayoutSaved,
  isEnableOrder,
  orderSubmitted,
  onSaveLayout,
  allFieldsInLayout,
  grid_id,
  isCreatingFieldForRepeatingForm,
  activeTabData,
  parentPageName,
  isAddDataForm,
  setAddFormList,
  onBlockDragStart,
  onBlockDragComplete,
  onDragOverBlockItem,
  onFieldDragStart,
  onFieldDragOver,
  onFieldDragEnd,
  dragOverFieldItem,
  dragOverBlock,
}) => {
  const [isAddBlockFormOpen, setIsAddBlockFormOpen] = useState(false)
  const [colWidth, setColWidth] = useState()

  const onAddBlock = () => {
    const row_array = [...row]
    row_array[rowIndex]['column'] = [...row_array[rowIndex]['column'], { col: colWidth }]
    setRow(row_array)
    setColWidth()
    setIsAddBlockFormOpen(false)
    setTimeout(() => {
      onSaveLayout()
    }, 500)
  }

  return (
    <Col md={12} className='layout-setting-row'>
      <Row>
        {activeRow?.column?.map((col, index) => {
          return (
            <FieldBlock
              col={col}
              index={index}
              key={index}
              coreData={coreData}
              hasReduxData={hasReduxData}
              reduxDataValidate={reduxDataValidate}
              manageDatabaseValidate={manageDatabaseValidate}
              formName={formName}
              pageName={pageName}
              setModal={setModal}
              section={section}
              tab={tab}
              source_table={source_table}
              rowIndex={rowIndex}
              fields={fields?.filter((field) =>
                isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index - 1 === index : field?.block_index - 1 === index
              )}
              isLayoutSaved={isLayoutSaved}
              isEnableOrder={isEnableOrder}
              row={row}
              activeRow={activeRow}
              orderSubmitted={orderSubmitted}
              allFieldsInLayout={allFieldsInLayout}
              grid_id={grid_id}
              isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
              blockIndex={index}
              setRow={setRow}
              activeTabData={activeTabData}
              parentPageName={parentPageName}
              isAddDataForm={isAddDataForm}
              setAddFormList={setAddFormList}
              onBlockDragStart={onBlockDragStart}
              onBlockDragComplete={onBlockDragComplete}
              onDragOverBlockItem={onDragOverBlockItem}
              onFieldDragStart={onFieldDragStart}
              onFieldDragOver={onFieldDragOver}
              onFieldDragEnd={onFieldDragEnd}
              dragOverFieldItem={dragOverFieldItem}
              dragOverBlock={dragOverBlock}
            />
          )
        })}
      </Row>

      {isAddBlockFormOpen && (
        <Col md={12}>
          <AvForm>
            <Row style={{ marginTop: '1rem', border: '1px dashed', borderRadius: '5px', paddingTop: '1rem' }}>
              <Col md={6}>
                <AvField type='select' name='width' value={colWidth} onChange={(e) => setColWidth(e.target.value)}>
                  <option value=''>Select width</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]?.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    )
                  })}
                </AvField>
              </Col>
              <Col md={6}>
                <Button onClick={() => onAddBlock()}>Add</Button>
              </Col>
            </Row>
          </AvForm>
        </Col>
      )}
      {!isAddBlockFormOpen && !isEnableOrder && (
        <Col md={12} style={{ margin: '1rem' }}>
          <div style={{ textAlign: 'center' }}>
            <span
              style={{
                border: '1px dashed #788f7f',
                color: '#788f7f',
                padding: '0.3rem 0.8rem',
                borderRadius: '5px',
                cursor: 'pointer',
                marginLeft: '-2rem',
              }}
              onClick={() => setIsAddBlockFormOpen(true)}>
              Add Block
            </span>
          </div>
          {/* <div onClick={() => setIsAddBlockFormOpen(true)}>Add Block</div> */}
        </Col>
      )}
    </Col>
  )
}

export default FieldRowForm
