import React, { Fragment, Component, useState, useEffect } from 'react'
import cx from 'classnames'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import MailSidebar from './mailSidebar'
import MailContent from './mailContent'
import AddMail from './addMail'
import {
  clearConnectTemplateReduxData,
  getPayloadToLoadGridData,
  getPayloadToLoadGridDataForEmail,
} from '../../../CMSComponent/Grid/Component/MainGrid/gridHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setConnectTemplateDataRedux } from '../../../Redux/Reducers/CMS'
import AddGridData from '../../../CMSComponent/Grid/Component/AddGridData/addGridData'
const MailBox = ({ page }) => {
  const [isElasticActive, setIsElasticActive] = useState(false)
  const [addDataDetail, setAddDataDetail] = useState()
  const [addDataDetailToEdit, setAddDataDetailToEdit] = useState()
  const [searchData, setSearchData] = useState('')
  const [currentPageData, setCurrentPageData] = useState()
  const [gridUpdated, updateGridUpdated] = useState()
  const [gridNotSyncWithDB, setGridNotSyncWithDB] = useState(false)
  const [isChildTabSelected, setIsChildTabSelected] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const [selectedGridData, setSelectedGridData] = useState({})
  const [content, setContent] = useState([])
  const [selectedRow, setSelectedRow] = useState([])

  const dispatch = useDispatch()
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const updateApiData = useSelector((state) => state.CMS?.updateApiData?.updated_data)
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const hidden_tab = page?.component_call_buttons?.find((tab) => tab.component_name === 'hidden_tab')
  const show_tabs_on_load = page?.component_call_buttons?.filter((tab) => tab.component_name === 'show_tabs_on_load')

  const clearConnectTemplateDataReduxByPagename = () => {
    const parentPageName = selectedGridData?.parentPageName
    const pageName = selectedGridData?.pageName
    let connectTemplateDataReduxToUpdate = {
      ...connectTemplateDataRedux,
    }
    connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }
    if (connectTemplateDataReduxToUpdate[parentPageName] && connectTemplateDataRedux?.[parentPageName]?.[pageName]) {
      connectTemplateDataRedux[parentPageName][pageName] = {}
      dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxToUpdate))
    }
  }

  const updateDataInRedux = (res) => {
    const parentPageName = selectedGridData?.parentPageName
    const pageName = selectedGridData?.pageName
    let connectTemplateDataReduxToUpdate = {
      ...connectTemplateDataRedux,
    }
    connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

    let newData = {}
    if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
      newData = {
        ...connectTemplateDataRedux[parentPageName]?.[pageName],
      }
    }
    console.log('update mail box::::::', res, newData, connectTemplateDataRedux[parentPageName]?.[pageName])
    newData[res?.data?.meta?.current_page] = {
      ...res,
      reduxUpdatedTime: new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' }),
    }
    updateGridUpdated(new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' }))
    connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
    // dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxToUpdate))
  }

  const fetchData = () => {
    if (!selectedGridData?.tableGrid) return

    let payload = getPayloadToLoadGridData(selectedGridData)
    if (searchData) {
      payload = {
        ...payload,
        '-filter-search': searchData,
      }
    }
    if (currentRow?.length === 0) {
      clearConnectTemplateDataReduxByPagename()
    }
    setIsDataLoading(true)
    request('/connect-template', 'POST', payload).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data
        const { note, ...rest } = data
        const main_content = []
        Object.keys(rest)?.map((key) => {
          main_content?.push(rest[key])
        })
        if (!searchData && currentRow?.length === 0 && selectedGridData?.selectedGrid !== 'restore_grid') {
          updateDataInRedux(res)
        }
        setContent(main_content)

        setCurrentPageData(res?.data?.meta)
      }
      setIsDataLoading(false)
    })
  }

  const fetchMoreData = (url) => {
    const parentPageName = selectedGridData?.parentPageName
    const pageName = selectedGridData?.pageName
    const nextPage = url?.slice(url?.indexOf('page='))?.split('=')[1]
    let payload = getPayloadToLoadGridData(selectedGridData)
    if (searchData) {
      payload = {
        ...payload,
        '-filter-name': searchData,
      }
    }
    if (
      connectTemplateDataRedux &&
      connectTemplateDataRedux[parentPageName]?.[pageName]?.[nextPage] &&
      !searchData &&
      currentRow?.length === 0 &&
      selectedGridData?.selectedGrid !== 'restore_grid'
    ) {
      const res = connectTemplateDataRedux[parentPageName]?.[pageName]?.[nextPage]
      const data = res?.data?.data
      const { note, ...rest } = data
      const content_data = []
      Object.keys(rest)?.map((key) => {
        content_data?.push(rest[key])
      })
      setContent(content_data)

      setCurrentPageData(res?.data?.meta)
    } else {
      setIsDataLoading(true)
      request(url, 'POST', payload).then((res) => {
        if (res?.status === 200) {
          const data = res?.data?.data
          const { note, ...rest } = data
          // const side_bar_data = [...sidebarData]
          if (!searchData && currentRow?.length === 0 && selectedGridData?.selectedGrid !== 'restore_grid') {
            updateDataInRedux(res)
          }

          const content_data = []
          Object.keys(rest)?.map((key) => {
            content_data?.push(rest[key])
          })
          setContent(content_data)

          setCurrentPageData(res?.data?.meta)
        }
        setIsDataLoading(false)
      })
    }
  }

  useEffect(() => {
    setSelectedGridData({
      tableGrid: hidden_tab?.component_call_form?.[0],
      selectedGrid: 'main_grid',
      page_data: page,
      currentTabData: hidden_tab,
      parentPageName: hidden_tab?.name,
      pageName: hidden_tab?.name,
      content: hidden_tab?.component_call_form?.[0],
    })
  }, [])

  useEffect(() => {
    const parentPageName = selectedGridData?.parentPageName
    const pageName = selectedGridData?.pageName
    if (
      updateApiData[selectedGridData?.tableGrid?.source_table] &&
      updateApiData[selectedGridData?.tableGrid?.source_table]?.length > 0 &&
      gridUpdated
    ) {
      const gridUpdatedDate = gridUpdated && new Date(gridUpdated)
      var offset = gridUpdatedDate.getTimezoneOffset()
      const utcGridUpdated = new Date(gridUpdatedDate.getTime() + offset * 60000)
      const lastSyncDate = gridUpdated && new Date(utcGridUpdated)
      const lastUpdatedDateInDB = new Date(updateApiData[selectedGridData?.tableGrid?.source_table])
      if (lastSyncDate - lastUpdatedDateInDB < 0) {
        setGridNotSyncWithDB(true)
        clearConnectTemplateReduxData({ pageName, parentPageName })
      } else {
        setGridNotSyncWithDB(false)
      }
    }
  }, [updateApiData, gridUpdated])

  useEffect(() => {
    if (selectedGridData?.tableGrid?.main_grid) {
      const parentPageName = selectedGridData?.parentPageName
      const pageName = selectedGridData?.pageName
      if (
        connectTemplateDataRedux &&
        connectTemplateDataRedux[parentPageName]?.[pageName]?.[1] &&
        !searchData &&
        currentRow?.length === 0 &&
        selectedGridData?.selectedGrid !== 'restore_grid'
      ) {
        const res = connectTemplateDataRedux && connectTemplateDataRedux[parentPageName]?.[pageName]?.[1]
        const data = res?.data?.data
        const { note, ...rest } = data
        const side_bar_data = []
        Object.keys(rest)?.map((key) => {
          side_bar_data?.push(rest[key])
        })
        const main_content = []
        Object.keys(rest)?.map((key) => {
          main_content?.push(rest[key])
        })
        if (!searchData && selectedGridData?.selectedGrid !== 'restore_grid' && currentRow?.length === 0) {
          updateDataInRedux(res)
        }
        setContent(main_content)
        updateGridUpdated(res?.reduxUpdatedTime)

        // setSelectedUserId(side_bar_data?.[0])

        setCurrentPageData(res?.data?.meta)
      } else {
        console.log(
          'fetch data::::::::',
          connectTemplateDataRedux,
          connectTemplateDataRedux[parentPageName]?.[pageName]?.[1],
          !searchData,
          currentRow?.length === 0,
          selectedGridData?.selectedGrid !== 'restore_grid'
        )
        fetchData()
      }
    }
  }, [JSON.stringify(selectedGridData), JSON.stringify(connectTemplateDataRedux), gridNotSyncWithDB])

  return (
    <Fragment>
      {addDataDetail?.open ? (
        <div style={{ marginBottom: '10rem' }}>
          <AddMail addDataDetail={addDataDetail} setAddDataDetail={setAddDataDetail} />
        </div>
      ) : (
        <CSSTransitionGroup
          component='div'
          transitionName='TabsAnimation'
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            <div
              className={cx('app-inner-layout', {
                'open-mobile-menu': isElasticActive,
              })}>
              {console.log('child form:::::::::', addDataDetailToEdit, isChildTabSelected)}
              <div className='app-inner-layout__wrapper' style={{ minHeight: 'calc(100vh - 13rem)', height: 'calc(100vh - 13rem)' }}>
                {addDataDetailToEdit?.open && !isChildTabSelected ? (
                  <div className='p-4 mb-4' style={{ overflow: 'auto' }}>
                    <AddGridData
                      addDataDetail={addDataDetailToEdit}
                      setAddGridDataDetail={setAddDataDetailToEdit}
                      hideTitle={true}
                      clearCurrentRowOnBack={false}
                      onGoBack={() => {
                        setSelectedGridData({
                          tableGrid: hidden_tab?.component_call_form?.[0],
                          selectedGrid: 'main_grid',
                          page_data: page,
                          currentTabData: hidden_tab,
                          parentPageName: hidden_tab?.name,
                          pageName: hidden_tab?.name,
                          content: hidden_tab?.component_call_form?.[0],
                        })
                      }}
                    />
                  </div>
                ) : (
                  <MailContent
                    isElasticActive={isElasticActive}
                    setIsElasticActive={setIsElasticActive}
                    content={content}
                    fetchMoreData={fetchMoreData}
                    currentPageData={currentPageData}
                    fetchData={fetchData}
                    setAddDataDetailToEdit={setAddDataDetailToEdit}
                    selectedGridData={selectedGridData}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setIsChildTabSelected={setIsChildTabSelected}
                    isDataLoading={isDataLoading}
                    isChildTabSelected={isChildTabSelected}
                    setSearchData={setSearchData}
                    searchData={searchData}
                  />
                )}

                <MailSidebar
                  page={page}
                  addDataDetail={addDataDetail}
                  setAddDataDetail={setAddDataDetail}
                  hidden_tab={hidden_tab}
                  show_tabs_on_load={show_tabs_on_load}
                  setSelectedGridData={setSelectedGridData}
                  selectedGridData={selectedGridData}
                  setAddDataDetailToEdit={setAddDataDetailToEdit}
                  setIsChildTabSelected={setIsChildTabSelected}
                />
              </div>
            </div>
          </div>
        </CSSTransitionGroup>
      )}
    </Fragment>
  )
}

export default MailBox
