import { ListGroupItem } from 'reactstrap';
import { Progress } from 'react-sweet-progress'
import CountUp from 'react-countup'

import '../../dashboard.scss'


const InfoBox = ({  completedPercentage=0, title,count, index }) => {
  return (
    <ListGroupItem key={index} fontSize={14}>
      <div className='widget-content p-0'>
        <div className='widget-content-wrapper'>
          <div className='widget-content-left mr-3'>
            <div className='icon-wrapper m-0'>
              <div className='progress-circle-wrapper'>
                <Progress
                  type='circle'
                  percent={completedPercentage ? completedPercentage : 0}
                  width='100%'
                  theme={{
                    active: {
                      trailColor: '#e1e1e1',
                      color: '#3ac47d',
                    },
                    success: {
                      symbol: completedPercentage + '%',
                      trailColor: '#e1e1e1',
                      color: '#3ac47d',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className='widget-content-left'>
            <div className='widget-heading'>{title}</div>
            <div className='widget-subheading'>{completedPercentage ? completedPercentage : 0}% response of this week</div>
          </div>
          <div className='widget-content-right'>
            <div className='widget-numbers widget-numbers-sm text-primary'>
              <CountUp start={0} end={count} separator='' decimals={0} decimal=',' prefix='' duration='2' />
            </div>
          </div>
        </div>
      </div>
    </ListGroupItem>
  )
}

export default InfoBox;