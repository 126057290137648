import React, { Fragment, Component, useState } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import cx from 'classnames'

import PageTitle from '../../../Layout/AppMain/pageTitle'
import ChatSideBar from './sideBar'
import ChatContent from './chatContent'
import { cms, editCMS } from '../../../helper'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { setDomain, setDropdownOptionsByPage } from '../../../Redux/Reducers/CMS'
import { Col, Row } from 'reactstrap'
import AddNewTabOnPage from '../../../CMSComponent/EditCMS/ContentForms/addTabHere'
import EditTabPage from '../../../CMSComponent/EditCMS/ContentForms/editTabPage'
import SelectDomainField from '../../../CMSComponent/Common/selectDomain'

const ChatPage = ({ page }) => {
  const [selectedUserId, setSelectedUserId] = useState()
  const tabs = page?.component_call_buttons?.filter((tab) => tab?.component_name === 'hidden_tab')
  const dispatch = useDispatch()

  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const coreData = useSelector((state) => state.CMS.coreData)
  const editPageMode = useSelector((state) => state.CMS.editPageMode)

  return (
    <Fragment>
      <AvForm>
        <Row>
          <Col md={6}>
            <SelectDomainField />
          </Col>
          <Col md={6}>
            {editPageMode && (
              <>
                <AddNewTabOnPage page={page} parentPageName={page?.name} />
                <span className='verticle-devider'></span>

                <EditTabPage activeTabData={tabs[0]} pageName={tabs[0]?.name} parentPageName={page?.name} showTitle gridData={{}} />
              </>
            )}
          </Col>
        </Row>
      </AvForm>

      <CSSTransitionGroup
        component='div'
        transitionName='TabsAnimation'
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <div>
          <div
            className={cx('app-inner-layout chat-layout', {
              'open-mobile-menu': false,
            })}>
            <div className='app-inner-layout__wrapper' style={{ minHeight: 'calc(100vh - 13rem)', height: 'calc(100vh - 13rem)' }}>
              <ChatContent pageData={page} selectedUserId={selectedUserId} />
              <ChatSideBar pageData={page} selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
            </div>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default ChatPage
