import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ExpandableRowItemGroups } from '../ExpandableRow/expandableRow'

const GroupQuestionnairePreview = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const items = currentRow?.[0]?.all_questions_answers

  if (items?.length === 0) return
  return (
    <div>
      <div className='font-weight-bold mb-3 text-primary' style={{ fontSize: '1.2rem' }}>
        Preview
      </div>
      <ExpandableRowItemGroups items={items} />
    </div>
  )
}

export default GroupQuestionnairePreview
