export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const CLEAR_LOADER = 'CLEAR_LOADER'
export const SET_LOADER = 'SET_LOADER'

const initialSate = {
  loader: false,
}

export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
})

export const setLoader = (loader) => {
  return {
    type: SET_LOADER,
    loader,
  }
}

export const clearLoader = () => ({
  type: CLEAR_LOADER,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case TOGGLE_LOADER:
      return { loader: !state.loader }
    case CLEAR_LOADER:
      return initialSate
    case SET_LOADER:
      const { loader } = action
      return { loader }
    default:
  }
  return state
}
