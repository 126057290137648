import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

// Set PDF.js worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PdfViewer = ({ base64Content }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfData, setPdfData] = useState(null)

  useEffect(() => {
    // Decode base64 content
    const decodedContent = atob(base64Content)
    setPdfData(decodedContent)
  }, [base64Content])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <div>
      {pdfData && (
        <Document file={{ data: pdfData }} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      )}
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  )
}

export default PdfViewer
