import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const DomainFilter = ({ value, updateValue, triggerSearch, column, handleSubmit }) => {
  const [selectedValue,setSelectedValue] = useState(value)
  useEffect(()=>{
    if(value){
      setSelectedValue(parseInt(value))
    }
  },[])
  const coreData = useSelector((state) => state.CMS.coreData)

  return (
    <AvForm>
      <AvField
        type='select'
        name='domain'
        value={selectedValue}
        onChange={(e) => {
          setSelectedValue(e.target.value)
          updateValue(e.target.value)
          triggerSearch(e.target.value)
        }}>
        <option value=''>Select</option>
        {coreData.domains
          ?.filter((domain) => domain.selected)
          ?.map((option, key) => {
            return (
              <option value={option.domain_id} key={key}>
                {option.domain_name}
              </option>
            )
          })}
      </AvField>
    </AvForm>
  )
}

export default DomainFilter
