import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import { Col } from "reactstrap";
import { getAggregateColumnHeader, tidyName } from "../../../helper";

const AGGREGATE_COLUMNS = ['hr_under_bool', 'hr_more_bool', 'hr_under_percent','hr_more_percent', 'text_time','completed_min','0_20_min','21_40_min','41_60_min','60_+_min','count','month']

const AggregateCheckBox = ({ aggregate_column , set_aggregate_column}) => {
  const onChangeColumn = (item,checked)=>{
    const new_aggregate_column = [...aggregate_column]
    if(!checked){
      const index = new_aggregate_column?.indexOf(item)
      new_aggregate_column?.splice(index,1)
    }else{
      new_aggregate_column?.push(item)
    }
    set_aggregate_column(new_aggregate_column)
  }
  return (
    <Col style={{ padding: '1rem' }} md={12}>
      <table className='table'>
        <thead>
          <tr>
            <th>Column</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {AGGREGATE_COLUMNS?.map((item, key) => {
            return (
              <tr key={key}>
                <td>{getAggregateColumnHeader(item)}</td>
                <td>
                  <input type='checkbox' checked={aggregate_column?.includes(item)} onChange={(e) => onChangeColumn(item, e.target.checked)}></input>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Col>
  )
}

export default AggregateCheckBox;