import { AvField } from 'availity-reactstrap-validation'
import { useState } from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import { tidyName } from '../../../../../helper'
import IconSet from '../../../../icon'
import NoDataImage from '../../../../Common/NoData/noDataImage'

const BodyInfoBox = ({ bodyInfo, setBodyInfo, columns, selectedInfo, setSelectedInfo, content }) => {
  const [seletcedField, updateSelectedField] = useState()
  const [addInfoOpen, setAddInfoOpen] = useState(false)
  const [addInfoDetails, setAddInfoDetails] = useState({ header: '', title: '' })
  const [expandedRow, setExpandedRow] = useState()

  const updateDynamicValue = () => {
    if (!seletcedField) return
    let title = bodyInfo?.title

    title = title + ' [' + seletcedField + ']'
    setBodyInfo({ ...bodyInfo, title: title })
    updateSelectedField()
  }

  const onSubmitBodyInfoForm = () => {
    const data = [...selectedInfo]
    data?.push(addInfoDetails)
    setAddInfoDetails({ header: '', title: '' })
    setAddInfoOpen(false)
    setSelectedInfo(data)
  }

  const onDeleteSelectedInfo = (index) => {
    const data = [...selectedInfo]
    data?.splice(index, 1)
    setSelectedInfo(data)
  }

  const addedData = selectedInfo?.map((item) => item?.header)

  const summary_field = content?.grid_settings?.summary_fields

  return (
    <Row>
      <Col md={6}>
        <AvField
          name='body_info_header'
          label='Title'
          type='text'
          value={bodyInfo?.title}
          onChange={(e) => {
            setBodyInfo({ ...bodyInfo, title: e.target.value })
          }}></AvField>
      </Col>
      <Col
        md={0}
        style={{ flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => updateDynamicValue()}>
        <i className='fa-solid fa-circle-arrow-left fa-2x'></i>
      </Col>
      <Col md={5}>
        <AvField type='select' name='row_group_field' label='Select Placeholder' onChange={(e) => updateSelectedField(e.target.value)}>
          <option value=''>Select column</option>
          <option value='title'>Title</option>
          <option value='report_date'>Report Date</option>
          <option value='todays_date'>Today's Date</option>
          <option value='current_week'>This Week</option>
          <option value='current_month'>This Month</option>
          <option value='current_year'>This Year</option>
          <optgroup label='Others'>
            {columns?.map((col, i) => {
              return (
                <option key={i} value={col?.name || col?.field_id}>
                  {tidyName(col?.title) || tidyName(col?.name)}
                </option>
              )
            })}
          </optgroup>
        </AvField>
      </Col>
      <Col md={6}>
        <AvField
          name='body_info_title'
          label='Subtitle'
          type='text'
          value={bodyInfo?.sub_title}
          onChange={(e) => {
            setBodyInfo({ ...bodyInfo, sub_title: e.target.value })
          }}></AvField>
      </Col>
      <Col md={12}>
        <Row>
          <Col md={12} className='float-right'>
            <Button color={addInfoOpen ? 'danger' : 'success'} onClick={() => setAddInfoOpen(!addInfoOpen)} className='btn m-1 float-right'>
              <IconSet icon={addInfoOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
            </Button>
          </Col>
          <Col md={12}>
            {addInfoOpen && (
              <Row style={{ marginBottom: '1rem' }}>
                <Col md={6}>
                  <AvField
                    type='select'
                    name='add_info_header'
                    label='Select Field'
                    value={addInfoDetails?.header}
                    onChange={(e) =>
                      setAddInfoDetails({
                        ...addInfoDetails,
                        header: e.target.value,
                      })
                    }>
                    <option>Select Field</option>
                    {summary_field
                      ?.filter((item) => !addedData?.includes(item))
                      .map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {tidyName(item)}
                          </option>
                        )
                      })}
                  </AvField>
                </Col>
                <Col md={6}>
                  <AvField
                    name='add_details_title'
                    label={'Title'}
                    value={addInfoDetails?.title}
                    onChange={(e) =>
                      setAddInfoDetails({
                        ...addInfoDetails,
                        title: e.target.value,
                      })
                    }></AvField>
                </Col>
                <Col md={12}>
                  <Button color='primary' className='float-right' onClick={() => onSubmitBodyInfoForm()}>
                    Save
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {selectedInfo?.length === 0 ? (
          <div style={{ textAlign: 'center' }}>
            <NoDataImage></NoDataImage>
          </div>
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th style={{ width: '1rem' }}></th>
                <th style={{ width: '5rem' }}></th>
                <th>Title</th>
                <th>Field</th>
              </tr>
            </thead>
            <tbody>
              {selectedInfo?.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>
                        <IconSet
                          icon={index === expandedRow ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                          color='primary'
                          style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                          onClick={() => {
                            if (expandedRow === index) {
                              setExpandedRow()
                            } else {
                              setExpandedRow(index)
                              setAddInfoDetails({
                                title: item?.title,
                                header: item?.header,
                              })
                            }
                          }}
                        />
                      </td>
                      <td>
                        <Button className='btn-danger' onClick={() => onDeleteSelectedInfo(index)}>
                          <IconSet icon='pe-7s-trash' color='light' />
                        </Button>
                      </td>
                      <td>{item?.title}</td>
                      <td>{tidyName(item?.header)}</td>
                    </tr>

                    {expandedRow === index && (
                      <tr>
                        <td colSpan={5}>
                          <UpdateBodyInfoData
                            addInfoDetails={addInfoDetails}
                            setAddInfoDetails={setAddInfoDetails}
                            expandedRow={expandedRow}
                            addedData={addedData}
                            selectedInfo={selectedInfo}
                            setSelectedInfo={setSelectedInfo}
                            setExpandedRow={setExpandedRow}
                            summary_field={summary_field}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                )
              })}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  )
}

const UpdateBodyInfoData = ({
  addInfoDetails,
  setAddInfoDetails,
  addedData,
  expandedRow,
  setExpandedRow,
  selectedInfo,
  setSelectedInfo,
  summary_field,
}) => {
  const onSubmitBodyInfoForm = () => {
    let data = [...selectedInfo]
    data[expandedRow] = { ...addInfoDetails }
    setSelectedInfo(data)
    setExpandedRow(false)
    setAddInfoDetails(false)
  }

  return (
    <Row style={{ marginBottom: '1rem' }}>
      <Col md={6}>
        <AvField
          type='select'
          name='add_info_header'
          label='Select Field'
          value={addInfoDetails?.header}
          onChange={(e) =>
            setAddInfoDetails({
              ...addInfoDetails,
              header: e.target.value,
            })
          }>
          <option>Select Field</option>
          {summary_field
            ?.filter((item) => !addedData?.includes(item))
            .map((item, index) => {
              return (
                <option key={index} value={item}>
                  {tidyName(item)}
                </option>
              )
            })}
        </AvField>
      </Col>
      <Col md={6}>
        <AvField
          name='add_details_title'
          label={'Title'}
          value={addInfoDetails?.title}
          onChange={(e) =>
            setAddInfoDetails({
              ...addInfoDetails,
              title: e.target.value,
            })
          }></AvField>
      </Col>
      <Col md={12}>
        <Button color='primary' className='float-right' onClick={() => onSubmitBodyInfoForm()}>
          Save
        </Button>
      </Col>
    </Row>
  )
}
export default BodyInfoBox
