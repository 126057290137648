import { useEffect } from "react"





const PrivacyPolicy = () =>{
  useEffect(()=>{
    
  },[])
  return (
    <div>
      <iframe src='/privacyPolicyContent.html' style={{ width: '100vw', height: '100vh', border: 'none' }} title='Privacy Policy' />
    </div>
  )
}


export default PrivacyPolicy