import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { Button } from 'reactstrap'

import IconSet from '../../../icon'
import { submitRequest, updatePageDataRedux } from '../../../../Redux/Reducers/CMS'
import { AddColorGroup } from './gridSetting'
import { getPageNameForCoreDataFromUrl, tidyName } from '../../../../helper'
import moment from 'moment'
import { setLoader } from '../../../../Redux/Reducers/loader'
import { request } from '../../../../Redux/Sagas/requests/api'

const RowForm = ({ row }) => {
  const [isExpand, setIsExpand] = useState(false)
  const dispatch = useDispatch()
  return (
    <>
      <tr className={`bg-${row?.color}`}>
        <td onClick={() => setIsExpand(!isExpand)} className='text-align-center'>
          {isExpand ? <IconSet icon='far fa-arrow-down' color='light' /> : <IconSet icon='far fa-arrow-right' color='light' />}
        </td>
        <td>
          <Button
            className='btn-danger'
            onClick={() => {
              const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
              dispatch(setLoader(true))
              request('remove-grid-color/group', 'POST', { id: row.id }).then((res) => {
                request(`get-page/${pageNameFormCoreData}`, 'GET').then(({ data }) => {
                  let pageData = {}
                  pageData[pageNameFormCoreData] = data

                  dispatch(updatePageDataRedux(pageData))
                  dispatch(setLoader(false))
                })
              })
              // dispatch(
              //   submitRequest({
              //     url: 'remove-grid-color/group',
              //     data: { id: row.id },
              //   })
              // )
            }}>
            <IconSet icon='pe-7s-trash' color='light' />
          </Button>
        </td>
        <td>{row?.name}</td>
        <td>{row?.color}</td>
        {<td></td>}
        <td>{row?.note}</td>
      </tr>
      {isExpand && <AddColorGroup isFilter={true} />}
    </>
  )
}

const RenderRow = ({ row, source_table, grid_id, content, pageName, isFilter }) => {
  const gridColors = useSelector((state) => state.CMS.coreData.grid_colors)
  const dispatch = useDispatch()
  const [isExpand, setIsExpand] = useState(false)

  return (
    <>
      <tr style={{ backgroundColor: 'transparent !important' }}>
        <td onClick={() => setIsExpand(!isExpand)} className='text-align-center'>
          {isExpand ? <IconSet icon='far fa-arrow-down' color='black' /> : <IconSet icon='far fa-arrow-right' color='black' />}
        </td>
        <td>
          <Button
            className='btn-danger'
            onClick={() => {
              const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
              dispatch(setLoader(true))
              request('remove-grid-filter', 'POST', { id: row.id }).then((res) => {
                request(`get-page/${pageNameFormCoreData}`, 'GET').then(({ data }) => {
                  let pageData = {}
                  pageData[pageNameFormCoreData] = data

                  dispatch(updatePageDataRedux(pageData))
                  dispatch(setLoader(false))
                })
              })
              // dispatch(
              //   submitRequest({
              //     url: 'remove-grid-filter',
              //     data: { id: row.id },
              //   })
              // )
            }}>
            <IconSet icon='pe-7s-trash' color='light' />
          </Button>
          {/* <AvForm style={{ textAlign: 'left', fontSize: 13 }}>
            <Col col='6'>
              <AvField type='select' name='select-multiple' label='Color'>
                {gridColors?.map((item,key) => (
                  <option value={item} key={key}>{item}</option>
                ))}
              </AvField>
            </Col>
          </AvForm> */}
        </td>
        <td>{row?.name}</td>
        <td>{row?.is_filter_out === 0 ? 'Include' : 'Exclude'}</td>
        <td>{tidyName(row.condition)}</td>
        <td>{tidyName(row.type)}</td>

        <td>
          {row.type === 'todays_date' || row.type === 'fixed_date_value'
            ? row.date_value?.length > 0
              ? moment(row.date_value).format('D MMM YYYY h:mm a')
              : ''
            : row.numeric_value || row.dynamic_value}
        </td>
      </tr>
      {isExpand && (
        <tr style={{ backgroundColor: 'transparent !important' }}>
          <td colSpan={7} style={{ color: 'black !important' }}>
            <AddColorGroup isFilter={true} source_table={source_table} content={content} isUpdate={true} row={row} />
          </td>
        </tr>
      )}
    </>
  )
}

const GridFilterTable = ({ content, source_table, pageName }) => {
  const table_data = content?.main_grid?.grid_filters

  return (
    <div className='gridFilterTable'>
      <table>
        <thead>
          <tr>
            <th style={{ width: '3rem' }}></th>
            <th style={{ width: '3rem' }}></th>
            <th>Name</th>
            <th>Filter Type</th>
            <th>Condition</th>
            <th>Condition Type</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {table_data &&
            table_data?.map((row, key) => {
              return (
                <RenderRow key={key} content={content} row={row} source_table={source_table} grid_id={content?.main_grid?.id} pageName={pageName} />
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default GridFilterTable
