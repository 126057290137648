import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitRequest, updatePageDataRedux } from '../../../../Redux/Reducers/CMS'
import FormToggleSwitch from '../../../Forms/Fields/switchButton'
import { cms } from '../../../../helper'
import AddCustomColumn from './addCustomColumn'
import { request } from '../../../../Redux/Sagas/requests/api'

const getColorName = (type) => {
  switch (type) {
    case 'primary':
      return 'Blue'
    case 'secondary':
      return 'Gray'
    case 'danger':
      return 'Red'
    case 'success':
      return 'Green'
    case 'warning':
      return 'Yellow'
    case 'info':
      return 'Light Blue'
    case 'db':
      return 'Dynamic value'
    default:
      return cms(type)
  }
}

const defaultForm = {
  sub_heading: '',
  type: 'section_heading',
  field_id: '',
  color: '',
  col: 6,
  heading: '',
  print: false,
  export: false,
}

const HeaderSection = ({
  formName,
  pageName,
  setModal,
  type,
  field = null,
  tab = false,
  setAddForm,
  section,
  source_table,
  is_create = false,
  isEdit,
  isLayout,
  rowIndex,
  blockIndex,
  blockFields,
  isCreatingFieldForRepeatingForm,
  grid_id,
  isAddDataForm,
  parentPageName,
  order,
  selectedField,
}) => {
  const [form, updateForm] = useState(defaultForm)
  const [load, updateLoad] = useState(false)
  const dispatch = useDispatch()
  const gridColors = useSelector((state) => state.CMS.coreData.grid_colors)

  useEffect(() => {
    updateForm({ ...form, type: type })
  }, [])

  useEffect(() => {
    if (field) {
      // const validationValueFromDb = field.component_call_validation?.map((item) => item?.validation_type)

      const field_setting = field?.field_setting
      updateForm({
        ...form,
        field_id: field.field_id,

        heading: cms(field.cms_key_heading),
        sub_heading: cms(field?.cms_key_sub_heading),
        col: field_setting?.col || field?.col,
        color: field?.background_color,
      })
    }
  }, [])

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    let data = {
      ...values,
      form_name: formName,
      page_name: pageName,
      type: form.type,
      field_id: form.field_id,
      parent_page_name: parentPageName,
      order,
    }
    if (tab) {
      data = {
        ...data,
        tab: true,
        section: section,
      }
    }
    if (isLayout) {
      data = {
        ...data,
        is_layout: true,
        row_index: rowIndex,
        block_index: blockIndex,
      }
    }
    if (is_create && isLayout) {
      data = {
        ...data,
        order: blockFields ? blockFields?.length + 1 : 0,
      }
    }
    if (isCreatingFieldForRepeatingForm && !isAddDataForm) {
      const { form_name, ...rest } = data
      const payload = {
        ...rest,
        grid_id: grid_id,
        source_table: source_table,
      }
      request(`submit-form-field`, 'POST', payload).then((data) => {
        request(`get-page/${parentPageName}`, 'GET').then((data) => {
          let pageData = {}
          if (data.status === 200) {
            pageData[parentPageName] = data.data

            dispatch(updatePageDataRedux(pageData))
          }

          // dispatch(setLoader(false))
        })
      })
      //  dispatch(submitRequest({ url: 'submit-form-field', data: payload }))
    } else if (isAddDataForm) {
      const payload = {
        ...data,
        grid_id: grid_id,
      }
      request(`submit-form-field`, 'POST', payload).then((data) => {
        request(`get-page/${parentPageName}`, 'GET').then((data) => {
          let pageData = {}
          if (data.status === 200) {
            pageData[parentPageName] = data.data

            dispatch(updatePageDataRedux(pageData))
          }

          // dispatch(setLoader(false))
        })
      })
      //  dispatch(submitRequest({ url: 'submit-form-field', data: payload }))
    } else {
      request(`submit-form-field`, 'POST', data).then((data) => {
        request(`get-page/${parentPageName}`, 'GET').then((data) => {
          let pageData = {}
          if (data.status === 200) {
            pageData[parentPageName] = data.data

            dispatch(updatePageDataRedux(pageData))
          }

          // dispatch(setLoader(false))
        })
      })
      //  dispatch(submitRequest({ url: 'submit-form-field', data }))
    }
    // dispatch(submitRequest({ url: 'submit-form-field', data }))
    setModal(false)
    setAddForm(false)
    updateForm(defaultForm)
  }
  return (
    <>
      <AvForm onValidSubmit={handleValidSubmit} model={form}>
        <Row>
          {!isEdit && (
            <Col md={6}>
              <AvField
                name='field_id'
                label='Field ID'
                type='text'
                required
                disabled={is_create ? false : true}
                value={form.field_id}
                validate={{
                  pattern: {
                    value: '^[A-Za-z0-9_]+$',
                    errorMessage: 'Your name must be composed only with letter and numbers',
                  },
                }}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
              />
            </Col>
          )}

          <Col md={6}>
            <AvField type='select' name='color' label='Color' value={form?.color} onChange={(e) => updateForm({ ...form, color: e.target.value })}>
              <option value=''>Select Color</option>
              {gridColors?.map((item, i) => (
                <option value={item} key={i}>
                  <div>{getColorName(item)}</div>
                </option>
              ))}
            </AvField>
          </Col>

          <Col md={6}>
            <AvField
              name='heading'
              label='Header'
              type='text'
              value={form?.heading}
              onChange={(e) => updateForm({ ...form, heading: e.target.value })}></AvField>
          </Col>
          <Col md={6}>
            <AvField
              name='sub_heading'
              label='Sub Header'
              type='text'
              value={form?.sub_heading}
              onChange={(e) => updateForm({ ...form, sub_heading: e.target.value })}></AvField>
          </Col>
          {!isLayout && (
            <Col md={6}>
              <AvField type='select' name='col' label='Column' value={form?.col} onChange={(e) => updateForm({ ...form, col: e.target.value })}>
                {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  )
                })}
              </AvField>
            </Col>
          )}

          <Col md={12}>
            <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default HeaderSection
