import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import cx from 'classnames'

import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../../Redux/Sagas/requests/api'
import { setQueue } from '../../../../Redux/Reducers/queue'
import './emailModal.scss'
import ScheduleEmailReports from '../GridSetting/schedule-email-report/scheduleEmailReport'
import { tidyName } from '../../../../helper'
import { AsyncPaginate } from 'react-select-async-paginate'
import { TagsInput } from 'react-tag-input-component'
import { store } from '../../../../Redux/Config/configureStore'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { getDate } from 'date-fns'
import { PreviewForExisitingTemplate } from '../GridSetting/schedule-email-report/preview'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSystemUser from './creatableSystemUser'

const checkValidationForInstantEmail = (data) => {
  if (data?.user?.length === 0 && data?.system_user?.length === 0 && data?.email?.length === 0) {
    store.dispatch(setNotification({ type: 'error', message: 'Please select email, user or system user' }))
    return false
  } else if (!data?.grouping) {
    store.dispatch(setNotification({ type: 'error', message: 'Grouping is required' }))
    return false
  } else if (data?.body_data?.columns?.length === 0) {
    store.dispatch(setNotification({ type: 'error', message: 'Please add body data' }))
    return false
  } else {
    return true
  }
}

export const getDurationColumns = (columns) => {
  const allowedType = ['datetime', 'date-time', 'date', 'timestamp']
  let filteredColumns = columns?.filter((column) => allowedType?.includes(column?.type) || allowedType?.includes(column?.field))
  return filteredColumns
}

const EmailModal = ({ set_email_modal_open, mainGrid, pageName, parentPageName, tableGrid }) => {
  const currentRowData = useSelector((state) => state.CMS.currentRow)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  const dispatch = useDispatch()

  const content = tableGrid

  const [selected_duration, set_selected_duration] = useState()
  const [startDate, set_start_date] = useState()
  const [endDate, set_end_date] = useState()
  const [email, setEmail] = useState([])
  const [grouping, set_grouping] = useState('one_report_for_all_days')
  const [mainDetails, setMainDetails] = useState({ frequency: '', user: [], system_user: [] })
  const [selectedTemplateId, setSelectedTemplateId] = useState()
  const [customize, setCustomize] = useState(false)
  const [showPreview, setShowPreview] = useState()
  const [date_field, set_date_field] = useState()

  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)

  const [paginationDataForUserGroup, setPaginationDataForUserGroup] = useState({
    pageNo: 1,
    hasMore: true,
  })
  const [paginationDataForSystemUser, setPaginationDataForSystemUser] = useState({
    pageNo: 1,
    hasMore: true,
  })

  useEffect(() => {
    setSelectedTemplateId(content?.grid_email_report?.[0]?.id)
  }, [content?.grid_email_report])

  useEffect(() => {
    if (selected_duration) {
      set_grouping()
    }
  }, [selected_duration])
  const onSubmit = (data) => {
    let start_date = new Date()
    let end_date = new Date()
    let d = new Date()
    switch (selected_duration) {
      case 'last_day':
        start_date.setDate(start_date.getDate() - 1)

        break
      case 'last_week':
        start_date.setDate(start_date.getDate() - 7)
        break
      case 'last_month':
        start_date.setDate(start_date.getDate() - 30)
        break
      case 'last_year':
        start_date.setDate(start_date.getDate() - 365)
        break
      case 'custom':
        var offset = new Date(startDate)
        start_date = new Date(startDate)
        end_date = new Date(endDate)

        break
    }

    let payload = {
      ...data?.data,
      ...mainDetails,
      grouping,
      start_date: `${start_date?.getDate()}-${start_date?.getMonth() + 1}-${start_date?.getFullYear()}`,
      end_date: `${end_date?.getDate()}-${end_date?.getMonth() + 1}-${end_date?.getFullYear()}`,
      grid_id: mainGrid?.id,
      email,
      duration_column: date_field,
    }
    if (currentRowData?.length > 0) {
      payload = {
        ...payload,
        parent_page: parentPageName,
        parent_row_id: currentRowData[currentRowData?.length - 1]?.id,
      }
    }

    if (checkValidationForInstantEmail(payload)) {
      dispatch(
        setQueue({
          url: 'send-grid-email-report',
          payload: {
            data: {
              ...payload,
            },
          },
        })
      )
      set_email_modal_open(false)
    }
  }
  async function loadOptionsForUser(search, loadedOptions) {
    if (!selectedTemplate?.user_type) {
      return {
        hasMore: false,
        options: [],
      }
    }
    let payload = {
      source_table: content?.source_table,
      report_type: selectedTemplate?.user_type,
      domain_id,
      page: paginationDataForUserGroup?.pageNo ? paginationDataForUserGroup?.pageNo : 1,
    }
    if (search && search?.length > 0) {
      payload = {
        ...payload,
        searchKey: search,
      }
    }
    const response = await request('get-user-group', 'POST', { ...payload })
    setPaginationDataForUserGroup({
      pageNo: response?.data?.meta?.current_page + 1,
      hasMore: response?.data?.links?.next ? true : false,
    })
    const options = response?.data?.data?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      }
    })
    return {
      options: options,
      hasMore: response?.data?.links?.next ? true : false,
    }
  }
  const [inputUserValue, onInputUserChange] = useState('')
  const onInputUserValueChange = (e) => {
    onInputUserChange(e)

    setPaginationDataForUserGroup({
      pageNo: 1,
      hasMore: true,
    })
  }
  async function loadOptionsForSystemUser(search, loadedOptions) {
    let payload = {
      source_table: content?.source_table,

      domain_id,
      page: paginationDataForSystemUser?.pageNo ? paginationDataForSystemUser?.pageNo : 1,
    }
    const response = await request('get-system-user', 'POST', { ...payload })
    setPaginationDataForSystemUser({
      pageNo: response?.data?.meta?.current_page + 1,
      hasMore: response?.data?.links?.next ? true : false,
    })
    const options = response?.data?.data?.map((item) => {
      return {
        value: item?.id,
        label: item?.username || item?.name || item?.first_name || item?.full_name || item?.id,
      }
    })

    return options
    return {
      options: options,
      hasMore: response?.data?.links?.next ? true : false,
    }
  }

  let report_type = content?.grid_settings?.display_report_type

  const isInstantEmail = true

  let selectedTemplate = content?.grid_email_report?.find((report) => report?.id === parseInt(selectedTemplateId))

  let grouping_options = [
    {
      title: 'Send single report for all days',
      value: 'one_report_for_all_days',
    },
    {
      title: 'Send separate report for each days',
      value: 'one_report_for_each_days',
    },
  ]

  if (selected_duration === 'last_day' || (selected_duration === 'custom' && new Date(startDate).getDate() === new Date(endDate).getDate())) {
    grouping_options = [
      {
        title: 'One report for each days',
        value: 'one_report_for_each_days',
      },
    ]
  }

  return (
    <Modal isOpen={true} style={{ width: 1000 }} toggle={() => set_email_modal_open(false)}>
      <ModalHeader toggle={() => set_email_modal_open(false)}>Send Reports</ModalHeader>
      <ModalBody style={{ padding: '1rem' }}>
        <AvForm className='email-modal'>
          <Row className='pt-4 pl-4 pr-4 pb-0'>
            <Col md={6}>
              <AvField
                type='select'
                name='template'
                label='Select Template'
                value={selectedTemplateId}
                onChange={(e) => setSelectedTemplateId(e.target.value)}>
                {/* <option value=''>Select Template</option> */}
                {content?.grid_email_report?.map((report) => {
                  return (
                    <option key={report?.id} value={report?.id}>
                      {report?.title}
                    </option>
                  )
                })}
              </AvField>
            </Col>
            <Col md={6}>
              {' '}
              <Col md={1.5} style={{ marginLeft: '1rem' }}>
                <div style={{ marginBottom: '0.7rem' }}>Customize Report</div>
                <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => setCustomize(!customize)}>
                  <div
                    className={cx('switch-animate', {
                      'switch-on': customize,
                      'switch-off': !customize,
                    })}>
                    <input type='checkbox' />
                    <span className='switch-left bg-success'>ON</span>
                    <label>&nbsp;</label>
                    <span className='switch-right bg-success'>OFF</span>
                  </div>
                </div>
              </Col>
            </Col>
            <Col md={12}>
              <hr></hr>
            </Col>
            {/* <Col md={6}>
              <AvField
                name='type'
                label='What group of record you want to send?'
                type='select'
                value={mainDetails?.user_type}
                onChange={(e) => setMainDetails({ ...mainDetails, user_type: e.target.value })}>
                <option value=''>Select Report Type</option>
                {report_type?.map((report) => {
                  return (
                    <option value={report} key={report}>
                      {tidyName(report)}
                    </option>
                  )
                })}
              </AvField>
            </Col> */}
            <Col md={6} className='mb-3'>
              <label>Select Record Groups</label>
              <AsyncPaginate
                value={mainDetails?.user}
                loadOptions={loadOptionsForUser}
                onInputChange={(e) => onInputUserValueChange(e)}
                onChange={(val) => setMainDetails({ ...mainDetails, user: val })}
                cacheUniqs={[selectedTemplate?.user_type, domain_id, inputUserValue]}
                styles={{
                  menu: (base) => ({ ...base, zIndex: 15 }),
                }}
                isMulti={true}
              />
            </Col>
            {isInstantEmail && (
              <Col md={6}>
                <CreatableSystemUser
                  content={content}
                  domain_id={domain_id}
                  mainDetails={mainDetails}
                  setMainDetails={setMainDetails}
                  email={email}
                  setEmail={setEmail}
                />
                {/* <label>Email to</label> */}
                {/* <AsyncCreatableSelect
                  value={mainDetails?.system_user}
                  loadOptions={loadOptionsForSystemUser}
                  onChange={(val) => setMainDetails({ ...mainDetails, system_user: val })}
                  cacheUniqs={[domain_id]}
                  styles={{
                    menu: (base) => ({ ...base, zIndex: 15 }),
                  }}
                  // isMulti={true}
                /> */}
                {/* <TagsInput separators={['Enter', 'Tab']} value={email} onChange={setEmail} name='email' placeHolder='Enter email' /> */}
              </Col>
            )}
            {/* <Col md={6} className='mb-3'>
              <label>Email to System User {'(optional)'}</label>
              <AsyncPaginate
                value={mainDetails?.system_user}
                loadOptions={loadOptionsForSystemUser}
                onChange={(val) => setMainDetails({ ...mainDetails, system_user: val })}
                cacheUniqs={[domain_id]}
                styles={{
                  menu: (base) => ({ ...base, zIndex: 15 }),
                }}
                isMulti={true}
              />
            </Col> */}
            <Col md={12}>
              <hr></hr>
            </Col>
            {isInstantEmail && (
              <Col md={6}>
                <AvField
                  type='select'
                  name='duration'
                  label='Select Duration'
                  value={selected_duration}
                  onChange={(e) => set_selected_duration(e.target.value)}>
                  <option>Select duration</option>
                  <option value='last_day'>Last day</option>
                  <option value='last_week'>Last week</option>
                  <option value='last_month'>Last month</option>
                  <option value='custom'>Custom</option>
                </AvField>
              </Col>
            )}
            {/* <Col md={6}>
              <AvField
                type='select'
                name='duration_column'
                label='Group by column'
                value={date_field}
                onChange={(e) => set_date_field(e.target.value)}>
                <option>Select Field</option>
                {getDurationColumns(grid_selector?.[content?.source_table]?.columns)?.map((item, index) => {
                  return (
                    <option key={index} value={item?.field_id || item?.name}>
                      {tidyName(item.title) || tidyName(item.name)}
                    </option>
                  )
                })}
              </AvField>
            </Col> */}

            {selected_duration === 'custom' && (
              <>
                <Col md={6}></Col>
                <Col md={6}>
                  <label>Start Date</label>
                  <DatePicker
                    selectsEnd
                    className='form-control'
                    maxDate={new Date()}
                    style={{ flex: '1' }}
                    selected={startDate}
                    onChange={(d) => set_start_date(d)}
                  />
                </Col>
                <Col md={6}>
                  <label>End Date</label>
                  <DatePicker
                    selectsEnd
                    className='form-control'
                    maxDate={new Date()}
                    style={{ flex: '1' }}
                    selected={endDate}
                    onChange={(d) => set_end_date(d)}
                  />
                </Col>
              </>
            )}
            <Col md={12}>
              <hr></hr>
            </Col>

            {isInstantEmail && (
              <Col md={6}>
                <AvField
                  type='select'
                  name='grouping'
                  label='How you want to send the report?'
                  value={grouping ? grouping : ''}
                  onChange={(e) => set_grouping(e.target.value)}>
                  <option value=''>Select Value</option>
                  {grouping_options?.map((option, i) => {
                    return (
                      <option key={i} value={option?.value}>
                        {option?.title}
                      </option>
                    )
                  })}
                </AvField>
              </Col>
            )}
          </Row>
          <ScheduleEmailReports
            isInstantEmail={true}
            report={selectedTemplate}
            content={tableGrid}
            parentPageName={parentPageName}
            onSubmit={onSubmit}
            // isAddingNew={customize || (!customize && !selectedTemplate)}
            isAddingNew={false}
            user_type={mainDetails?.user_type}
            showOnlyPreview={!customize}
          />
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default EmailModal
