import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'
import Loader from 'react-loaders'

import Icon from '../../icon'
import { submitRequest } from '../../../Redux/Reducers/CMS'
import GridSetting from '../../Grid/Component/GridSetting/gridSetting'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  templates: state.CMS.coreData.templates,
  page: state.CMS.coreData.page,
})

const FormPage = ({ editPageMode, tabName, pageName, templates, content, source_table, page, profileForm = null, parentPageName }) => {
  const dispatch = useDispatch()
  const [load, updateLoad] = useState(false)
  const [modalValue, setModal] = useState(false)
  const currentRowData = useSelector((state) => state.CMS.currentRow)
  const [form, updateForm] = useState({
    name: '',
    form_type: 'create_form',
    permission_type: 'add',
    col: '1',
  })

  let templates_array = []
  for (let i in templates) {
    const new_data = {
      ...templates[i],
      id: i,
    }
    templates_array.push(new_data)
  }

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    dispatch(
      submitRequest({
        url: 'submit-page-form',
        data: { ...values, tab_name: tabName, page_name: parentPageName },
      })
    )
    setModal(false)
  }

  useEffect(() => {}, [])

  if (!editPageMode) return null
  return (
    <>
      {currentRowData?.length === 0 && (
        <Button onClick={() => setModal(true)} color='link' className='edit-form-page-button'>
          <Icon icon={'fas fa-pen'} bgcolor='green' color='info' navheadericon='true' id='add_form' />
          <UncontrolledTooltip placement='right' target='add_form'>
            Add Form
          </UncontrolledTooltip>
        </Button>
      )}
      <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: content ? 1200 : 600 }} scrollable={true}>
        <ModalHeader toggle={() => setModal(!modalValue)}>{content ? `Grid Setting` : `${tabName} forms`}</ModalHeader>
        <ModalBody>
          <Container>
            {content ? (
              <GridSetting content={content} source_table={source_table} pageName={pageName} parentPageName={parentPageName} />
            ) : profileForm ? (
              <div>profileForm</div>
            ) : (
              <AvForm onValidSubmit={handleValidSubmit} model={form}>
                <Row>
                  <Col md={6}>
                    <AvField
                      name='name'
                      label='Name'
                      type='text'
                      required
                      validate={{
                        pattern: {
                          value: '^[A-Za-z0-9_]+$',
                          errorMessage: 'Your name must be composed only with letter and numbers',
                        },
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <AvField type='select' label='Form Type' name={'form_type'} onChange={(e) => updateForm({ ...form, form_type: e.target.value })}>
                      <option value={'create_form'}>Form</option>
                      <option value={'template'}>Select from Template</option>
                    </AvField>
                  </Col>
                  <Col md={6}>
                    <AvField type='select' label='Permission Type' name={'permission_type'}>
                      <option value={'add'}>Add</option>
                      <option value={'view'}>View</option>
                      <option value={'edit'}>Edit</option>
                      <option value={'allow'}>Allow</option>
                    </AvField>
                  </Col>
                  {form.form_type === 'template' && (
                    <Col md={6}>
                      <AvField type='select' label='Template List' name={'selected_template'}>
                        {templates_array?.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.name}
                            </option>
                          )
                        })}
                      </AvField>
                    </Col>
                  )}
                  <Col md={6}>
                    <AvField type='select' label='Col' name={'col'}>
                      <option value={'1'}>1</option>
                      <option value={'2'}>2</option>
                      <option value={'3'}>3</option>
                      <option value={'4'}>4</option>
                    </AvField>
                  </Col>
                  <Col md={12}>
                    <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                      {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Edit'}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}
          </Container>
        </ModalBody>
      </Modal>
    </>
  )
}

export default connect(select)(FormPage)
