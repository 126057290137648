import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, HashRouter } from 'react-router-dom'

import { createBrowserHistory } from 'history'

import './Vendor/fontawesome-pro/css/all.min.css'
import './polyfills'
import './assets/base.scss'
import Main from './Pages'
import * as serviceWorker from './serviceWorker'
import { persistor, store } from './Redux/Config/configureStore'

const rootElement = document.getElementById('root')

const history = createBrowserHistory()

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <Component />
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    rootElement
  )
}

renderApp(Main)

serviceWorker.unregister()
