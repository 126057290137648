import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'reactstrap'
import { cms, editCMS, getFromUpdateApi } from '../../../helper'

const select = (state) => ({
  cmsData: state.CMS.cmsData,
})
const ChartPill = ({ data, id, cmsData }) => {
  return (
    <NavLink
      href='#'
      className='font-weight-normal'
      onClick={() => {
        if (data.cms_key) {
          editCMS(data.cms_key)
        }
      }}>
      {cms(data.cms_key)}
      <div className={'ml-auto badge badge badge-' + data.color} id={id}>
        {data.component_call_data_connection && getFromUpdateApi(data.component_call_data_connection[0])}
      </div>
    </NavLink>
  )
}

export default connect(select)(ChartPill)
