import { AvField } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { checkIsDate, cms, diffMinutesBetweenDates, tidyName, validateForAv } from '../../../helper'

const OPTIONS = [
  {
    title: '5 min',
    value: 5,
  },
  {
    title: '10 min',
    value: 10,
  },
  {
    title: '15 min',
    value: 15,
  },
  {
    title: '20 min',
    value: 20,
  },
  {
    title: '30 min',
    value: 30,
  },
  {
    title: '1hr',
    value: 60,
  },
  {
    title: '1hr 25min',
    value: 85,
  },
  {
    title: '1hr 30min',
    value: 90,
  },
  {
    title: '1hr 45min',
    value: 105,
  },
  {
    title: '2hrs',
    value: 120,
  },
  {
    title: '3hrs',
    value: 180,
  },
  {
    title: '4hrs',
    value: 240,
  },
  {
    title: '5hrs',
    value: 300,
  },
  {
    title: '6hrs',
    value: 360,
  },
  {
    title: '12hrs',
    value: 720,
  },
  {
    title: '18hrs',
    value: 1080,
  },
  {
    title: '24hrs',
    value: 1440,
  },
]

const PreTime = ({ time_field_id, data, onChange, item, defaultValue, formFieldValue, setFormFieldValue }) => {
  const [value, setValue] = useState()

  useEffect(() => {

    let val = defaultValue
    if(!defaultValue){
      val = 15
    }
    const a = OPTIONS?.find((option) => {
      if (option?.value === parseInt(val)) {
        return true
      }
    })

    setValue({
      label: a?.title,
      value: a?.value,
    })
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = a?.value
      return updatedValue
    })
  }, [defaultValue])

  // useEffect(() => {
  //   if (checkIsDate(defaultValue) && !value && date_time_value && checkIsDate(date_time_value)) {
  //     setValueInMin(defaultValue)
  //     let diff_min = diffMinutesBetweenDates(new Date(date_time_value), new Date(defaultValue))
  //     setValue(diff_min)
  //     setFormFieldValue((formValue) => {
  //       let updatedValue = {
  //         ...formValue,
  //       }
  //       updatedValue[data?.field_id] = defaultValue
  //       return updatedValue
  //     })
  //   }
  // }, [date_time_value])

  const onOptionChange = (selectedOption) => {
    const option_value = selectedOption?.value
    setValue(selectedOption)
    // setValueInMin('')
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = option_value
      return updatedValue
    })
    // if (option_value) {
    //   if (date_time_value && checkIsDate(date_time_value)) {
    //     setValue(option_value)
    //     let new_value = new Date(new Date(date_time_value).getTime() - parseInt(option_value) * 60000)
    //     setValueInMin(new_value)
    //     setFormFieldValue((formValue) => {
    //       let updatedValue = {
    //         ...formValue,
    //       }
    //       updatedValue[data?.field_id] = option_value
    //       return updatedValue
    //     })
    //   }
    // } else {
    //   setValue(option_value)
    //   setValueInMin('')
    //   setFormFieldValue((formValue) => {
    //     let updatedValue = {
    //       ...formValue,
    //     }
    //     updatedValue[data?.field_id] = ''
    //     return updatedValue
    //   })
    // }
  }

  const select_options = OPTIONS?.map((option) => {
    return {
      label: option?.title,
      value: option?.value,
    }
  })
  return (
    <>
      <label>{tidyName(cms(data.cms_key))}</label>
      <Select
        options={select_options}
        id={'second-column'}
        onChange={(e) => onOptionChange(e)}
        value={value}
        styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
        isClearable
        // isDisabled={!inlineEditActive}
        // placeholder=''
        // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
        // isSearchable={ inlineEditActive }
        // menuIsOpen={inlineEditActive}
        // {...props}
      />
      {/* <AvField
        type='select'
        name={data?.field_id}
        value={value}
        label={`${cms(data?.cms_key) || tidyName(data?.name)} (mins)`}
        validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
        onChange={(e) => onOptionChange(e.target.value)}>
        <option value=''>Select Pre Time</option>
        {OPTIONS?.map((item, i) => {
          return (
            <option value={item.value} key={i}>
              {item.title}
            </option>x
          )
        })}
      </AvField> */}
      <AvField
        type='hidden'
        value={value?.value}
        name={data?.field_id}
        validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}></AvField>
    </>
  )
}

export default PreTime
