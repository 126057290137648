import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Row, UncontrolledCollapse, UncontrolledDropdown } from 'reactstrap'
import Loader from 'react-loaders'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { cms, getColor } from '../../../helper'
import { removeItemByName, submitPageContent } from '../../../Redux/Reducers/CMS'
import Icon from '../../../CMSComponent/icon'
import ConfirmButton from '../../Common/confirmButton'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
})

const ButtonMenu = ({ mainPage, editPageMode }) => {
  const dispatch = useDispatch()
  const [load, updateLoad] = useState(false)
  const [count, updateCount] = useState(0)
  const initialForm = {
    name: '',
    permission_type: 'view',
    icon: '',
    default_tab_name: '',
    tab_color: 'primary',
    component_name: '',
  }
  const [form, updateForm] = useState([initialForm])
  const [colors, updateColors] = useState({ 0: 'primary' })

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    dispatch(submitPageContent({ ...values, page_name: mainPage.name, component_name: mainPage.component_name }))
  }

  const onChange = (e, i, name) => {
    const updatedForm = form
    updatedForm[i] = { ...form[i], [name]: e.target.value }
    updateForm(updatedForm)
  }

  useEffect(() => {}, [])

  if (!editPageMode) return null
  return (
    <PerfectScrollbar style={{ maxHeight: 600 }}>
      <div className='mb-4'>
        <Row>
          <Col
            md={{
              size: 12,
            }}>
            <ListGroup>
              {mainPage.component_call_buttons &&
                mainPage.component_call_buttons.map((item, key) => {
                  return (
                    <>
                      <ListGroupItem key={key} id={item.name}>
                        <Icon icon={item.icon || 'pe-7s-attention'} type={item.type} color='primery' className={'mr-4'} />
                        {cms(item.cms_tab_key)}
                        <div className='float-right'>
                          <ConfirmButton
                            style={{ padding: 0 }}
                            color='link'
                            id={item.name}
                            clickEvent={() => dispatch(removeItemByName({ name: item.name, type: 'page-button' }))}>
                            <Icon icon={'fa-solid fa-xmark'} color='danger' />
                          </ConfirmButton>
                        </div>
                      </ListGroupItem>
                      <UncontrolledCollapse toggler={'#' + item.name}>
                        <div className='border border-danger'></div>
                        <div className='p-2 border'>
                          <AvForm onValidSubmit={handleValidSubmit} model={form}>
                            <Row>
                              <Col md={6}>
                                <AvField name={'default_tab_name'} label={'Button Name'} type='text' value={item.default_tab_name} />
                              </Col>
                              <Col md={6}>
                                <label>Tab Color</label>
                                <AvField type='select' name={'tab_color'} value={item.color}>
                                  <option value={'primary'}>primary</option>
                                  <option value={'secondary'}>secondary</option>
                                  <option value={'accent'}>accent</option>
                                  <option value={'dark'}>dark</option>
                                  <option value={'danger'}>danger</option>
                                  <option value={'info'}>info</option>
                                  <option value={'success'}>success</option>
                                  <option value={'warning'}>warning</option>
                                  <option value={'light'}>light</option>
                                </AvField>
                              </Col>
                              <Col md={4}>
                                <AvField
                                  name={'name'}
                                  label='Name'
                                  value={item.name}
                                  type='text'
                                  disabled
                                  validate={{
                                    pattern: {
                                      value: '^[A-Za-z0-9_]+$',
                                      errorMessage: 'Your name must be composed only with letter and numbers',
                                    },
                                  }}
                                />
                              </Col>
                              <Col md={4}>
                                <AvField
                                  type='select'
                                  label='Permission Type'
                                  name={'permission_type'}
                                  value={item.permission ? item.permission.split('_')[0] : 'view'}>
                                  <option value={'view'}>View</option>
                                  <option value={'edit'}>Edit</option>
                                  <option value={'allow'}>Allow</option>
                                </AvField>
                              </Col>
                              <Col md={4}>
                                <AvField name={'icon'} label='Icon' type='text' value={item.icon} />
                              </Col>
                              <Col md={12}>
                                <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                                  {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'EDIT'}
                                </Button>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </UncontrolledCollapse>
                    </>
                  )
                })}
            </ListGroup>
          </Col>
        </Row>
      </div>
      <AvForm onValidSubmit={handleValidSubmit} model={form}>
        {form.map((item, i) => {
          return (
            <div className='add-form' key={i}>
              <p className='text-left'>
                {i + 1 + '.'}
                <span
                  className='text-danger cursor-pointer'
                  onClick={() => {
                    const updatedForm = form
                    delete updatedForm[i]
                    updateForm(updatedForm)
                    updateCount(count - 1)
                  }}>
                  Remove
                </span>
              </p>
              <Row>
                <Col md={6}>
                  <AvField name={'default_tab_name[' + i + ']'} label={'Button Name'} type='text' />
                </Col>
                <Col md={6}>
                  <label>
                    Tab Color{' '}
                    <div
                      title='#f44336'
                      className='select-color'
                      style={{
                        boxShadow: getColor(colors[i]) + ' 0px 0px 0px 14px inset',
                      }}></div>
                  </label>
                  <AvField
                    type='select'
                    name={'tab_color[' + i + ']'}
                    onChange={(e) => {
                      onChange(e, i - 1, 'tab_color')
                      updateColors({ ...colors, [i]: e.target.value })
                    }}>
                    <option value={'primary'}>primary</option>
                    <option value={'secondary'}>secondary</option>
                    <option value={'accent'}>accent</option>
                    <option value={'dark'}>dark</option>
                    <option value={'danger'}>danger</option>
                    <option value={'info'}>info</option>
                    <option value={'success'}>success</option>
                    <option value={'warning'}>warning</option>
                    <option value={'light'}>light</option>
                  </AvField>
                </Col>
                <Col md={4}>
                  <AvField
                    name={'name[' + i + ']'}
                    label='Name'
                    type='text'
                    required
                    validate={{ pattern: { value: '^[A-Za-z0-9_]+$', errorMessage: 'Your name must be composed only with letter and numbers' } }}
                  />
                </Col>
                <Col md={4}>
                  <AvField type='select' label='Permission Type' name={'permission_type[' + i + ']'} value={form[i].permission_type}>
                    <option value={'view'}>View</option>
                    <option value={'edit'}>Edit</option>
                    <option value={'allow'}>Allow</option>
                  </AvField>
                </Col>
                <Col md={4}>
                  <AvField name={'icon[' + i + ']'} label='Icon' type='text' value={form[i].icon || ''} />
                </Col>
              </Row>
              <hr />
            </div>
          )
        })}
        <div className='float-right'>
          <Button
            color='info'
            onClick={() => {
              const updatedForm = form
              updatedForm[count + 1] = initialForm
              updateForm(updatedForm)
              updateCount(count + 1)
            }}>
            + Add More Button
          </Button>
        </div>
        <br />
        <Row>
          <Col md={12}>
            <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </PerfectScrollbar>
  )
}

export default connect(select)(ButtonMenu)
