import moment from 'moment'
import { useSelector } from 'react-redux'
import BarChart from '../../../../../CMSComponent/Common/Chart/barChart'
import { ChartDatasetOptions, ChartOptions } from '../../../../../CMSComponent/Common/Chart/chartHelper'
import { cms, getDatesInRange } from '../../../../../helper'
import { editCMS } from '../../../../../helper'

const DailyARTime = ({ dashboardData, startDate, endDate }) => {
  const dashboard_cms_key = useSelector((state) => state.CMS?.dashboardCMSKey?.ar_time_daily?.cms_key_heading)
  const editMode = useSelector((state) => state.CMS?.editMode)
  const completedByTaskTypeData = dashboardData?.ar_time_daily
  const dateRanges = getDatesInRange(startDate, endDate)
  let label = completedByTaskTypeData?.map((item) => item?.day)
  let value = completedByTaskTypeData?.map((item) => item.completed_minute_duration)
  // let value = dateRanges?.map((date) => {
  //   let min = 0
  //   completedByTaskTypeData?.map((item) => {
  //     if (
  //       new Date(item?.completed_end_date_time).getDate() === new Date(date).getDate() &&
  //       new Date(item?.completed_end_date_time).getMonth() === new Date(date).getMonth()
  //     ) {
  //       min = item.completed_minute_duration
  //     }
  //   })
  //   return min
  // })

  const options = {
    ...ChartOptions,
    plugins: {
      ...ChartOptions?.plugins,
    },
    scales: {
      ...ChartOptions.scales,
      y: {
        ...ChartOptions.scales.y,
        title: {
          display: true,
          text: 'MINUTES',
          color: 'black',
        },
      },
      x: {
        ...ChartOptions.scales.x,
        title: {
          display: true,
          text: 'DATES',
          color: 'black',
        },
      },
    },
  }

  const data = {
    labels: label,
    datasets: [
      {
        ...ChartDatasetOptions,
        label: 'Daily Average Time',
        data: value,
        maxBarThickness: 40,
      },
    ],
  }

  return (
    <div style={{ padding: '1rem', height: '100%' }}>
      <div className='dashboard-chart-title' style={{ cursor: editMode ? 'pointer' : '' }} onClick={() => editCMS(dashboard_cms_key)}>
        {cms(dashboard_cms_key)}
      </div>
      <BarChart options={options} data={data} />
    </div>
  )
}

export default DailyARTime
