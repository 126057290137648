import { call, put } from 'redux-saga/effects'

import {
  clearCMS,
  clearCoreData,
  fetchCoreData,
  getCMS,
  fetchUpdateApiData,
  fetchScheduleApiData,
  clearMenuList,
  clearConnectTemplateReduxData,
  clearGridFormInRedux,
  setThemeColors,
  addThemeColor,
  removeThemeColor,
} from '../../Reducers/CMS'
import { setLoader } from '../../Reducers/loader'
import { setNotification } from '../../Reducers/notificationHandling'
import { clearPermissions } from '../../Reducers/permissions'
import { clearCommonQueue, clearGridQueue } from '../../Reducers/queue'
import { fetchThemeOptions, resetThemeOptions, setThemeOptions } from '../../Reducers/themeOptions'
import { resetUser, fetchUser } from '../../Reducers/user'

import { addCustomColor, delCustomColor, requestThemeOptions, updateThemeOption } from '../requests/themeOptions'
import { v4 as uuidv4 } from 'uuid'

export function* shootReduxLoad() {
  yield put(setLoader(true))
  yield put(fetchThemeOptions())
  yield put(getCMS(false))
  yield put(fetchCoreData(false))
  yield put(fetchUser())
  yield put(fetchUpdateApiData())
  yield put(fetchScheduleApiData())
  yield put(clearMenuList())
  yield put(setLoader(false))
  yield put(clearConnectTemplateReduxData())
  yield put(clearGridFormInRedux())
}

export function* shootReduxUnload() {
  yield put(resetThemeOptions())
  yield put(resetUser())
  yield put(clearCMS())
  yield put(clearCommonQueue())
  yield put(clearGridQueue())
  yield put(clearMenuList())
  yield put(clearCoreData())
  yield put(clearPermissions())
}

export function* getThemeOptions() {
  try {
    yield put(setLoader(true))
    const response = yield call(requestThemeOptions)
    if (response.status === 200) {
      let updatedData = {}
      if (response.data) {
        response.data?.theme_options.map(({ name, value }) => {
          let updatedValue = value
          if (value === '1') {
            updatedValue = true
          } else if (value === '0') {
            updatedValue = false
          }
          updatedData = { [name.trim()]: updatedValue, ...updatedData }
        })
      }

      if (response?.data?.colors) {
        yield put(setThemeColors(response.data?.colors))
      }
      yield put(setThemeOptions(updatedData))
    } else {
      yield put(setNotification({ type: 'error', message: response.message, status: response.status }))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* handleThemeOption(action) {
  try {
    const data = action
    delete data.type
    // yield put(setLoader(true))
    const response = yield call(updateThemeOption, { name: Object.keys(data)[0], value: Object.values(data)[0] })

    if (response.data.status !== 1) {
      yield put(setNotification({ type: 'error', message: response.data.message, status: response.data.status }))
    } else {
      yield put(setNotification({ type: 'success', message: 'data updated successfully!', status: response.data.status }))
    }
    // yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* handleSaveCustomColor(action) {
  try {
    let customColor = action.customColor
    const response = yield call(addCustomColor, customColor)
    if (!customColor.uuid) {
      const uuid = uuidv4()
      customColor.uuid = uuid
      yield put(addThemeColor(customColor))
    } else {
      yield put(removeThemeColor(customColor.uuid))
      yield put(addThemeColor(customColor))
    }
    if (response.status === 200) {
      yield put(setNotification({ type: 'success', message: response.data.message, status: response.data.status }))
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* handleDeleteCustomColor(action) {
  try {
    let data = action.uuid
    const response = yield call(delCustomColor, data)

    yield put(removeThemeColor(data.uuid))
    if (response.status === 202) {
      yield put(setNotification({ type: 'success', message: response.data.message, status: response.data.status }))
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}
