export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

const initialSate = {
  status: 0,
  type: 'success',
  message: '',
  errors: [],
}

export const setNotification = (notification) => ({
  type: SET_NOTIFICATION,
  notification,
})

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      const { notification } = action
      return notification
    case CLEAR_NOTIFICATION:
      return initialSate
    default:
  }
  return state
}
