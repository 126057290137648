import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { downloadImageFromLink } from '../../../../helper'

const QR = ({ data, height, className }) => {
  const [isOpen, setIsOpen] = useState(false)
  let image_src
  if (data?.includes('base64,')) {
    // image_src = process.env.REACT_APP_URL_FILES + data
    image_src = data
  } else if (data) {
    image_src = process.env.REACT_APP_STORAGE + data
  } else return

  const onDownload = (link, filename) => {
    downloadImageFromLink(link, filename)
  }

  return (
    <div className={className}>
      <img
        src={image_src}
        height={height ? height : 50}
        style={{ maxWidth: '100%', overflow: 'hidden' }}
        onClick={() => {
          setIsOpen(true)
        }}></img>{' '}
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} centered size='lg' className='no-box-shadow'>
        <ModalHeader toggle={() => setIsOpen(false)}></ModalHeader>
        <ModalBody className='d-flex justify-content-center' toggle={() => setIsOpen(false)}>
          <div>
            <div>
              {' '}
              <img src={image_src} height='100%' width={'100%'}/>
            </div>
            <div
              className='text-align-center text-primary cursor-pointer'
              onClick={() => {
                onDownload(data?.replace('user-files/', ''), data)
              }}>
              Download image
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default QR
