import React, { Fragment, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import NavHeaderBtn from '../../../CMSComponent/NavHeaderBtn'
import Icon from '../../../CMSComponent/icon'
import AddTopNavButton from '../../../CMSComponent/EditCMS/TopNav/addTopNavButton'
import { UNLOCK_ITEM_BUTTON } from '../../../helper'
import HeaderNavButton from '../../../CMSComponent/NavHeaderBtn/navHeaderButton'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  coreData: state.CMS.coreData,
})

const TopNavButtons = ({ coreData, editPageMode }) => {
  const items = useSelector((state) => state.CMS.updateApiData?.unlock_items)

  const [navHeader, updateNavHeader] = useState([])

  const [dropdownOpen, updateDropdownOpen] = useState(false)

  useEffect(() => {
    if (coreData.header_navigation) {
      updateNavHeader(coreData.header_navigation)
    }
  }, [coreData.header_navigation])

  if (!navHeader) return null
  return (
    <Fragment>
      <div className='header-dots ml-2'>
        {editPageMode && (
          <Dropdown isOpen={dropdownOpen} toggle={() => updateDropdownOpen(!dropdownOpen)}>
            <DropdownToggle className='p-0 mr-2' color='link'>
              <Icon bgcolor='success' color='primary' type='Pe7' icon='pe-7s-plus' navheadericon='true' style={{ fontWeight: '600' }} />
            </DropdownToggle>
            <DropdownMenu right className={'dropdown-menu-xl rm-pointers dropdown-menu-form'} style={{ padding: '0 0 0.65rem' }}>
              <AddTopNavButton close={() => updateDropdownOpen(false)} />
            </DropdownMenu>
          </Dropdown>
        )}
        {items && items?.length > 0 && (
          <div style={{ position: 'relative' }}>
            <HeaderNavButton nav={UNLOCK_ITEM_BUTTON} isUnlockJob={true} />
            {items && items?.length > 0 && (
              <span
                style={{ position: 'absolute', top: 0, right: 10, fontSize: '10px', borderRadius: '50%', width: '1rem', height: '1rem' }}
                className='bg-info text-light d-flex justify-content-center'>
                {items?.length}
              </span>
            )}
          </div>
        )}

        <NavHeaderBtn navHeader={navHeader} />
      </div>
    </Fragment>
  )
}

export default connect(select)(TopNavButtons)
