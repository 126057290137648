// import DataTableCustomComps from '../../DemoPages/Tables/DataTables/Examples/CustomComps'

import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card } from 'reactstrap'
import IconSet from '../../CMSComponent/icon'
import {
  clearPagePermissionData,
  cms,
  getNavFilterList,
  getPagePermissionDataByUserId,
  getPagePermissionUpdatedTimeByUserId,
  setPagePermissionDataByUserIdInRedux,
} from '../../helper'
import { setQueue } from '../../Redux/Reducers/queue'
import { request } from '../../Redux/Sagas/requests/api'
import Pages from './page'
import './permission.scss'
import Theme from './themePermission'

const Permission = ({ parent_source_table }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const updateApiData = useSelector((state) => state.CMS.updateApiData.updated_data)

  const [permissionPage, setPermissionPage] = useState([])
  const [expandedMainPageId, setExpandedMainPageId] = useState()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isPermissionNeedToSync, setIsPermissionNeedToSync] = useState(false)
  const [apiCalled, setAPiCalled] = useState(false)

  const dispatch = useDispatch()
  const reduxData = getPagePermissionDataByUserId(parent_source_table, currentRow?.[currentRow?.length - 1]?.id)
  const lastUpdatedTimeInRedux = getPagePermissionUpdatedTimeByUserId(parent_source_table, currentRow?.[currentRow?.length - 1]?.id)

  useEffect(() => {
    if (updateApiData['permissions'] && updateApiData['permissions']?.length > 0 && lastUpdatedTimeInRedux) {
      const gridUpdatedDate = lastUpdatedTimeInRedux && new Date(lastUpdatedTimeInRedux)
      var offset = gridUpdatedDate.getTimezoneOffset()
      const utcGridUpdated = new Date(gridUpdatedDate.getTime() + offset * 60000)
      const lastSyncDate = lastUpdatedTimeInRedux && new Date(utcGridUpdated)
      const lastUpdatedDateInDB = new Date(updateApiData['permissions'])
      if (lastSyncDate - lastUpdatedDateInDB < 0) {
        // clearPagePermissionData()
        setIsPermissionNeedToSync(true)
      } else {
        setIsPermissionNeedToSync(false)
      }
    } else {
      setIsPermissionNeedToSync(false)
    }
  }, [updateApiData, lastUpdatedTimeInRedux])

  useEffect(() => {
    if (currentRow && currentRow?.[currentRow?.length - 1]?.id && (!reduxData || isPermissionNeedToSync) && !apiCalled) {
      if (!isPermissionNeedToSync) {
        setIsDataLoading(true)
      }
      setAPiCalled(true)
      request('get-cms-pages', 'POST', {
        parent_row_table: parent_source_table,
        parent_row_id: currentRow?.[currentRow?.length - 1]?.id,
      }).then((response) => {
        setPermissionPage(response?.data)
        setAPiCalled(false)
        setPagePermissionDataByUserIdInRedux(response?.data, parent_source_table, currentRow?.[currentRow?.length - 1]?.id)
        setIsDataLoading(false)
      })
    } else if (currentRow && currentRow?.[currentRow?.length - 1]?.id && reduxData && !apiCalled) {
      setPermissionPage(reduxData)
    }
  }, [currentRow?.length, isPermissionNeedToSync])

  const onChangeMainPagePermission = (id, action, value, isThemeSetting) => {
    let oldData = isThemeSetting ? [...permissionPage?.theme_settings] : [...permissionPage?.pages]
    const data = oldData?.map((item) => {
      if (item?.id === id || item?.key === id) {
        let newData = { ...item }
        newData[action] = value
        let { child, ...dataWithoutChild } = newData
        dataWithoutChild = {
          ...dataWithoutChild,
          type: isThemeSetting ? 'theme_setting' : 'page',
        }
        dispatch(
          setQueue({
            url: 'update-permissions',
            payload: {
              data: { paren_row_table: parent_source_table, parent_row_id: currentRow[currentRow?.length - 1]?.id, data: [dataWithoutChild] },
            },
          })
        )
        return newData
      } else {
        return item
      }
    })
    let newPermissionPages

    if (isThemeSetting) {
      newPermissionPages = {
        pages: [...permissionPage?.pages],
        theme_settings: data,
      }
    } else {
      newPermissionPages = {
        pages: data,
        theme_settings: [...permissionPage?.theme_settings],
      }
    }
    setPermissionPage(newPermissionPages)
    setPagePermissionDataByUserIdInRedux(newPermissionPages, parent_source_table, currentRow?.[currentRow?.length - 1]?.id)
  }
  const onChangeChildPagePermission = (id, parentId, action, value, isThemeSetting) => {
    let oldData = isThemeSetting ? [...permissionPage?.theme_settings] : [...permissionPage?.pages]
    const data = oldData?.map((item) => {
      if (item?.id === parentId || item?.key === parentId) {
        let newData = { ...item }

        newData['child'] = newData?.child?.map((item) => {
          if (item?.child_id === id || item?.key === id) {
            let newChildData = { ...item }
            newChildData[action] = value
            let { child, ...dataWithType } = newChildData
            dataWithType = {
              ...dataWithType,
              type: isThemeSetting ? 'theme_setting' : 'page',
            }
            dispatch(
              setQueue({
                url: 'update-permissions',
                payload: {
                  data: { paren_row_table: parent_source_table, parent_row_id: currentRow[currentRow?.length - 1]?.id, data: [dataWithType] },
                },
              })
            )
            return newChildData
          } else {
            return item
          }
        })
        return newData
      } else {
        return item
      }
    })

    let newPermissionPages

    if (isThemeSetting) {
      newPermissionPages = {
        pages: [...permissionPage?.pages],
        theme_settings: data,
      }
    } else {
      newPermissionPages = {
        pages: data,
        theme_settings: [...permissionPage?.theme_settings],
      }
    }
    setPermissionPage(newPermissionPages)
    setPagePermissionDataByUserIdInRedux(newPermissionPages, parent_source_table, currentRow?.[currentRow?.length - 1]?.id)
  }

  if (isDataLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 5rem)', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  return (
    <div className='permission'>
      <Card className='text-white' body inverse color={'info'} style={{ padding: '0.5rem', marginTop: '0.5rem' }}>
        Pages
      </Card>
      {permissionPage?.pages?.length > 0 && (
        <Pages
          data={permissionPage?.pages}
          isMainPage={true}
          expandedMainPageId={expandedMainPageId}
          setExpandedMainPageId={setExpandedMainPageId}
          onChangeMainPagePermission={onChangeMainPagePermission}
          onChangeChildPagePermission={onChangeChildPagePermission}
        />
      )}
      <Card className='text-white' body inverse color={'info'} style={{ padding: '0.5rem', marginTop: '2rem' }}>
        Theme & CMS
      </Card>

      {permissionPage?.theme_settings?.length > 0 && (
        <Theme
          data={permissionPage?.theme_settings}
          isMainPage={true}
          expandedMainPageId={expandedMainPageId}
          setExpandedMainPageId={setExpandedMainPageId}
          onChangeMainPagePermission={onChangeMainPagePermission}
          onChangeChildPagePermission={onChangeChildPagePermission}
        />
      )}
    </div>
  )
}

export default Permission
