import { useEffect, useState } from 'react'
import { cms, editCMS, setCompletedQuestionnaireRowByGridId, tidyName } from '../../helper'
import PageTitle from '../../Layout/AppMain/pageTitle'
import { request } from '../../Redux/Sagas/requests/api'
import Questionnaire, { checkAllAnswerCompleted } from './questionnaire'
import cx from 'classnames'
import { Button } from 'reactstrap'
import { clearPageTab } from '../../Redux/Reducers/page'
import { useDispatch } from 'react-redux'
import { clearCurrentRowData, setCurrentUrl } from '../../Redux/Reducers/CMS'
import { useHistory } from 'react-router-dom'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import Loader from 'react-loaders'
import { clearConnectTemplateReduxData } from '../Grid/Component/MainGrid/gridHelper'
import { fetchUpdateApi } from '../../Redux/Sagas/rootSaga'

const QuestionnaireMain = ({ questionnaireDetail, setQuestionnaireAction = () => {}, isInsideTab }) => {
  const { cms_key } = questionnaireDetail
  const [allAnswers, setAllAnswers] = useState([])
  const [allAdditionalInformation, setAllAdditionalInformation] = useState([])
  const [itemsId, setItemsId] = useState()

  const [showFullQuestion, setShowFullQuestion] = useState([])

  const [questionGroup, setQuestionGroup] = useState([])
  const [submitAllAnswerStatus, setSubmitAllAnswerStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const additionalInfo = questionGroup?.map((item) => {
      return item?.additional_information
    })
    const items_id = questionGroup?.map((item) => {
      return item?.id
    })
    setItemsId(items_id)
    setAllAdditionalInformation(additionalInfo)
  }, [JSON.stringify(questionGroup)])

  const onAnswerChangeInMain = (answers, item_id) => {
    const all_answers_from_main = [...allAnswers]
    const new_answers_from_main = all_answers_from_main?.map((data) => {
      if (data?.item_id === item_id) {
        return {
          ...data,
          q: answers,
        }
      } else {
        return data
      }
    })
  }

  useEffect(() => {
    let payload = {
      grid_id: questionnaireDetail?.grid_id,
    }
    if (questionnaireDetail?.source_table === 'item_groups') {
      const ids = questionnaireDetail?.activeRowData?.all_questions_answers?.map((item) => item?.id)
      payload = {
        ...payload,
        item_ids: ids,
      }
    } else {
      payload = {
        ...payload,
        item_ids: questionnaireDetail?.ids,
      }
    }

    setLoading(true)
    request('get-questions', 'post', { ...payload }).then((response) => {
      if (response.data.data) {
        setQuestionGroup(response.data.data)
        const additional_info = response.data.data?.map((item) => item?.additional_information)
        setAllAdditionalInformation(additional_info)
      }
      if (response.data.show_all_questions === 1) {
        setShowFullQuestion(true)
      } else {
        setShowFullQuestion(false)
      }
      setLoading(false)
    })
  }, [])

  const onChangeShowFullQuestion = () => {
    const status = showFullQuestion

    request('save-questionnaire-setting', 'POST', { show_all_questions: !showFullQuestion, grid_id: questionnaireDetail?.grid_id }).then((res) => {})
    setShowFullQuestion(!showFullQuestion)
  }

  const onSubmitAllAnswers = () => {
    const items = allAnswers?.map((item, index) => {
      if (item) {
        // setCompletedQuestionnaireRowByGridId
        if (checkAllAnswerCompleted(item)) {
          setCompletedQuestionnaireRowByGridId(questionnaireDetail?.grid_id, itemsId[index])
        }
        return {
          q: item,
          completed_all: checkAllAnswerCompleted(item),
          additional_information: allAdditionalInformation[index],
          item_id: itemsId[index],
        }
      }
    })
    clearConnectTemplateReduxData({ pageName: questionnaireDetail?.pageName, parentPageName: questionnaireDetail?.parentPageName })
    request('complete-all-job/web', 'POST', { items: items }).then(() => {
      dispatch(setNotification({ type: 'success', message: 'Answer Saved Successfully' }))
      setQuestionnaireAction({ open: false, detail: null })
      setCompletedQuestionnaireRowByGridId(questionnaireDetail?.grid_id, questionnaireDetail?.activeRowData?.id)
      dispatch(fetchUpdateApi())
    })
    // setSubmitAllAnswerStatus(true)
  }

  const onSubmitEmptyQuestionnaire = () => {
    questionGroup?.map((questionnaire, index) => {
      let payload = {
        item_id: questionnaire?.id,
        q: [],
        completed_all: true,
      }
      request('complete-job/web', 'POST', payload).then(() => {
        if (questionGroup?.length - 1 === index) {
          setQuestionnaireAction({ open: false, detail: null })
          dispatch(setNotification({ type: 'success', message: `${tidyName(questionnaireDetail?.source_table)} completed successfully!` }))
        }
      })
    })
    //
  }

  const redirectToGroupPage = () => {
    const submitted_ids = questionnaireDetail?.item_group_id

    dispatch(clearCurrentRowData())
    setQuestionnaireAction({ open: false, detail: null })
    dispatch(setCurrentUrl(`outstanding-tasks-list?selected_ids=${submitted_ids}`))
    history?.push(`/outstanding-tasks-list?selected_ids=${submitted_ids}`)
  }

  let isQuestionAvailable = false
  questionGroup?.map((item) => {
    if (item?.item_questions && item?.item_questions?.length > 0 && !isQuestionAvailable) {
      isQuestionAvailable = true
    }
  })

  if (loading)
    return (
      <div style={{ height: '10rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )
  return (
    <>
      {/* <PageTitle onTitleClick={() => editCMS(cms_key)} heading={cms(cms_key)} icon='lnr-map text-info' hideBreadCrumbs /> */}
      {!isInsideTab && (
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '15px', marginTop: '-1rem' }}>
          <div>
            <span>
              <i
                className='fa-solid fa-arrow-left bg-light'
                style={{ padding: '0.5rem', cursor: 'pointer', borderRadius: '20px', fontSize: '15px', marginTop: '5px', border: '1px solid' }}
                onClick={() => {
                  // dispatch(clearPageTab())
                  setQuestionnaireAction({ open: false, detail: null })
                }}></i>
            </span>
          </div>
          <div>
            <div>Show All Question</div>
            <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => onChangeShowFullQuestion()}>
              <div
                className={cx('switch-animate', {
                  'switch-on': showFullQuestion,
                  'switch-off': !showFullQuestion,
                })}>
                <input type='checkbox' />
                <span className='switch-left bg-success'>ON</span>
                <label>&nbsp;</label>
                <span className='switch-right bg-success'>OFF</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: '-2rem' }}>
        {/* <div style={{ maxHeight: '70vh', overflow: 'auto' }}> */}
        {isQuestionAvailable &&
          !loading &&
          questionGroup?.map((item, index) => {
            return (
              <Questionnaire
                key={index}
                questionnaireDetail={questionnaireDetail}
                item_id={item?.id}
                isGroupedQuestionnaire={questionnaireDetail?.source_table === 'item_groups' ? true : false}
                allAnswers={allAnswers}
                setAllAnswers={setAllAnswers}
                onAnswerChangeInMain={onAnswerChangeInMain}
                questionDetail={item}
                showFullQuestion={showFullQuestion}
                submitAllAnswerStatus={submitAllAnswerStatus}
                questionnaireIndex={index}
                setQuestionnaireAction={setQuestionnaireAction}
                questionnaireName={item?.item_name}
                allAdditionalInformation={allAdditionalInformation}
                setAllAdditionalInformation={setAllAdditionalInformation}
              />
            )
          })}
        {questionnaireDetail?.source_table === 'item_groups' && isQuestionAvailable && questionGroup && questionGroup?.length > 1 && (
          <div style={{ paddingLeft: '46px', marginBottom: '3rem' }}>
            <Button color='success' size='lg' className='btn-shadow btn-wide mb-3' onClick={() => onSubmitAllAnswers()}>
              Submit All Answer
            </Button>
          </div>
        )}
        {questionnaireDetail?.source_table === 'items' && isQuestionAvailable && questionnaireDetail?.item_group_id && (
          <div style={{ paddingLeft: '46px', marginTop: '-1rem' }}>
            <Button
              color='primary'
              // outline
              size='lg'
              className='btn-shadow mb-3'
              onClick={() => {
                redirectToGroupPage()
              }}>
              Go to Outstanding
            </Button>
          </div>
        )}
      </div>
      {!isQuestionAvailable && !loading && isInsideTab && <div className='text-center mt-4 font-weight-bold'>Questions not available!</div>}
      {!isQuestionAvailable && !loading && !isInsideTab && (
        <div style={{ textAlign: 'center', marginTop: '1rem', fontSize: '1.2rem' }}>
          <Button onClick={() => onSubmitEmptyQuestionnaire()} color='success'>
            Complete {tidyName(questionnaireDetail?.source_table)}
          </Button>
        </div>
      )}
    </>
  )
}

export default QuestionnaireMain
