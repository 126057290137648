import React, { useState } from 'react'

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import { tidyName } from '../../helper'

const ConfirmButton = (props) => {
  const { id, clickEvent, children, color, style = {} } = props
  const [modalValue, setModal] = useState(false)
  const button_id = id && id?.replace('.', '')?.replace('***', '')?.replace(')','')?.replace('(','')

  return (
    <>
      <Button
        color={color}
        id={'removeButton' + button_id}
        onClick={(e) => {
          e.stopPropagation()
          setModal(true)
        }}
        style={{ fontSize: '11px', marginLeft: '5px', marginRight: '5px', ...style }}>
        {children}
      </Button>
      <UncontrolledTooltip placement='right' target={'removeButton' + button_id}>
        Remove {id && id.replace('_', ' ')}
      </UncontrolledTooltip>
      <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: 300 }}>
        <ModalHeader toggle={() => setModal(!modalValue)}>{props?.modalTitle ? props?.modalTitle : 'Remove Top Nav Button'}</ModalHeader>
        <ModalBody>
          {props?.modalBodyText ? props?.modalBodyText : `are you sure you want to delete ${id ? tidyName(id.replace(/_/g, ' ')) : 'this'}?`}
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              clickEvent()
              setModal(false)
            }}>
            Yes
          </Button>{' '}
          <Button color='secondary' onClick={() => setModal(false)}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConfirmButton
