import moment from 'moment'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { useSelector } from 'react-redux'
import { cms, getExpandTableDataByFilter, setExpandTableDataByFilterInRedux } from '../../../../helper'
import { request } from '../../../../Redux/Sagas/requests/api'
import NoDataImage from '../../../Common/NoData/noDataImage'

const getCellValue = (data, field, grid_selector, table) => {
  const column = grid_selector?.[table]?.columns?.filter((item) => item?.field_id === field || item?.name === field)?.[0]
  switch (column?.field) {
    case 'dropdown':
      return data[`${field}_label`]
    case 'date':
      return !isNaN(Date.parse(data[field])) ? moment(data[field]).format('D MMM YYYY') : ''
    case 'date-time':
    case 'datetime':
      return !isNaN(Date.parse(data[field])) ? moment(data[field]).format('D MMM YYYY h:mm a') : ''
    default:
      return data[field]
  }
}

const ExpandedTable = ({ row, main_grid, tableGrid }) => {
  return (
    <div className='expanded_column_container'>
      {main_grid?.grid_expand_table?.map((table, i) => {
        return <Table tableData={table} row={row} tableGrid={tableGrid} key={i}></Table>
      })}
    </div>
  )
}

const Table = ({ tableData, row, tableGrid }) => {
  const columns = tableData?.component_call_cols
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const filter_by = useSelector((state) => state.CMS.filterBy)
  const [table_row, set_table_row] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let payload = {
      expand_from_source: tableGrid?.source_table,
      expand_method: tableData?.name,
      expand_columns: tableData?.component_call_cols?.map((item) => item?.field_id),
      filter_by_field: filter_by?.[tableGrid?.name],
      filter_by_field_value: row[filter_by?.[tableGrid?.name]],
    }

    if (getExpandTableDataByFilter(tableGrid?.name, filter_by?.[tableGrid?.name], row.id)) {
      set_table_row(getExpandTableDataByFilter(tableGrid?.name, filter_by?.[tableGrid?.name], row.id))
    } else {
      setIsLoading(true)
      request('connect-template-expand-data', 'POST', payload).then((res) => {
        if (res.status === 200) {
          set_table_row(res?.data?.data?.data)
          setExpandTableDataByFilterInRedux(tableGrid?.name, filter_by?.[tableGrid?.name], row.id, res?.data?.data?.data)
        }
        setIsLoading(false)
      })
    }
  }, [])

  if (isLoading)
    return (
      <div style={{ textAlign: 'center' }}>
        {' '}
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  // const table_row = rows[tableData?.['name']]
  if (table_row?.length === 0) return <div style={{ textAlign: 'center', padding: '1rem' }}><NoDataImage></NoDataImage></div>
  return (
    <table className='table'>
      <thead>
        <tr>
          {columns?.map((item, i) => {
            return <th key={i}>{cms(item?.cms_key)}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {table_row?.map((item, index) => {
          return (
            <tr key={index}>
              {columns?.map((col) => {
                return <td>{getCellValue(item, col?.field_id || col?.name, grid_selector, tableData?.['name'])}</td>
              })}
            </tr>
          )
        })}
        <tr></tr>
      </tbody>
    </table>
  )
}

export default ExpandedTable
