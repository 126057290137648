import React, { Fragment } from 'react'

import CMSDownloadDrawer from '../../../CMSComponent/downloadDrawer'

class HeaderRightDrawer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Fragment>
        <CMSDownloadDrawer />
      </Fragment>
    )
  }
}

export default HeaderRightDrawer
