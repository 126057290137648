import { useState } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { request } from '../../../../Redux/Sagas/requests/api'

const CreatableSystemUser = ({ mainDetails, setMainDetails, domain_id, content, email, setEmail }) => {
  const [paginationDataForSystemUser, setPaginationDataForSystemUser] = useState()
  const [inputValue, onInputChange] = useState('')

  async function loadOptionsForSystemUser(search, loadedOptions) {
    let payload = {
      source_table: content?.source_table,
      search_key: search,
      domain_id,
      page: paginationDataForSystemUser?.pageNo ? paginationDataForSystemUser?.pageNo : 1,
      pageSize: 30,
    }
    const response = await request('get-system-user', 'POST', { ...payload })
    setPaginationDataForSystemUser({
      pageNo: response?.data?.meta?.current_page + 1,
      hasMore: response?.data?.links?.next ? true : false,
    })
    const options = response?.data?.data?.map((item) => {
      return {
        value: item?.id,
        label: item?.username || item?.name || item?.first_name || item?.full_name || item?.id,
      }
    })

    return options
    return {
      options: options,
      hasMore: response?.data?.links?.next ? true : false,
    }
  }

  const onInputValueChange = (e) => {
    onInputChange(e)

    setPaginationDataForSystemUser({
      pageNo: 1,
      hasMore: true,
    })
  }

  const onChangeValue = (val) => {
    const old_email = email ? [...email] : []
    old_email?.push({
      value: val,
      label: val,
    })
    setEmail(old_email)

    console.log('email::::::', old_email)
  }

  return (
    <>
      <label>Email to</label>
      <AsyncCreatableSelect
        value={email ? email : null}
        loadOptions={loadOptionsForSystemUser}
        onChange={(val) => setEmail(val)}
        cacheUniqs={[domain_id, inputValue]}
        styles={{
          menu: (base) => ({ ...base, zIndex: 15 }),
        }}
        inputValue={inputValue}
        onInputChange={(e) => onInputValueChange(e)}
        defaultOptions
        onCreateOption={(e) => onChangeValue(e)}
        isMulti={true}
      />
    </>
  )
}

export default CreatableSystemUser
