import { v4 as uuidv4 } from 'uuid'
import { checkAnswersAddedOrNot } from '../../submitPreview'

export const getSignFormSubmitButtonTitle = (addDataDetail) => {
  if (addDataDetail?.tableGrid?.source_table === 'item_reports' && addDataDetail?.parentPageName === 'send_reports') {
    return 'Send & Close'
  } else {
    return 'Save & Close'
  }
}

export const checkRepeatableFormVisible = (form, data) => {
  if (form?.grid_tab_method === 'template_questions') {
    if (data && data?.['template_groups']?.can_add_questions) {
      return true
    } else {
      return false
    }
  } else if (form?.grid_tab_method === 'item_questions') {
    if (data && data?.['item_groups']?.can_add_questions) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export const getPayloadFormTemplatesGroup = (data) => {
  const newData = {}

  const templateSchedules = data?.template_groups?.template_schedules
  const newTemplates = data?.template_groups?.templates?.map((template) => {
    const template_id = template?.id || template?.temporary_id
    const schedules_to_add = []
    templateSchedules?.forEach((schedule) => {
      const ids = schedule?.templates?.map((item) => item?.id || item?.value)
      if (ids?.includes(template_id)) {
        const new_temporary_id = uuidv4()
        schedules_to_add?.push({ ...schedule, temporary_id: new_temporary_id })
      }
    })
    return {
      ...template,
      template_schedules: schedules_to_add,
    }
  })
  newData['template_groups'] = {
    ...data?.template_groups,
    templates: newTemplates,
  }
  return newData
}

export const getColumnsForTableInWizardForm = (form) => {
  const mapped_data = {
    items: ['order', 'item_type_id', 'item_name', 'ref'],
    templates: ['order', 'item_type_id', 'item_name', 'pre_time_mins', 'post_time_mins'],
    included_table: ['items', 'templates'],
  }
  const columns_data = mapped_data[form?.grid_tab_table] || mapped_data[form?.grid_tab_method]
  const columns = form?.component_call_fields?.filter((item) => {
    if (item?.component_name !== 'section_heading' && !item?.hide_from_grid) {
      if (mapped_data.included_table?.includes(form?.grid_tab_table) || mapped_data.included_table?.includes(form?.grid_tab_method)) {
        if (columns_data?.includes(item?.field_id) || columns_data?.includes(item?.name)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  })

  return columns
}

export const checkUpdateButtonDisabled = (addDataDetail, submittedFormData) => {
  let isAnswerAdded = true
  let isUpdateButtonDisabled = {
    status: false,
    error: '',
  }
  let isItemsAvailable = true
  let table = addDataDetail?.tableGrid?.source_table
  if (table === 'items') {
    table = 'item_groups'
  }
  isAnswerAdded = checkAnswersAddedOrNot(submittedFormData, table)

  if (table === 'item_groups') {
    if (!submittedFormData['item_groups']?.['items'] || submittedFormData['item_groups']?.['items']?.length === 0) {
      isUpdateButtonDisabled = {
        status: true,
        error: 'Please add atleast one task',
      }
      isItemsAvailable = false
    }
  } else if (table === 'template_groups') {
    if (!submittedFormData['template_groups']?.['templates'] || submittedFormData['template_groups']?.['templates']?.length === 0) {
      isUpdateButtonDisabled = {
        status: true,
        error: 'Please add atleast one template',
      }
      isItemsAvailable = false
    }
  }

  if (!isAnswerAdded) {
    isUpdateButtonDisabled = {
      status: true,
    }
  }

  return isUpdateButtonDisabled
}