import React, { Fragment, useEffect, useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { Button, Col, Row } from 'reactstrap'
import { tidyName } from '../../../helper'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import IconSet from '../../icon'
import FormToggleSwitchExample from '../../Forms/Fields/switchButton'

import TabListEdit from './tabList'
const select = (state) => ({
  grid_selector: state.CMS.coreData.grid_selector,
})

const EditTab = ({
  form,
  updateTab,
  col,
  isTabPage,
  onSaveTab = () => {
    {
    }
  },
  tabList,
  parentPageName,
  pageName,
}) => {
  const initialTabForm = {
    icon: '',
    connection: '',
    type: 0,
    default_header: '',
  }

  const [tabForm, updateTabForm] = useState([])
  const [count, updateCount] = useState(1)
  const [stateChanged, setStateChanged] = useState(false)
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)

  const onChange = (value, i, name) => {
    updateTabForm((tabForm) => {
      tabForm[i] = { ...tabForm[i], [name]: value }
      updateTab(tabForm)
      setStateChanged(!stateChanged)
      return tabForm
    })
  }

  const renderGridOption = (item) => {
    return (
      grid_selector[form.table]?.methods?.filter((method) => method.table === item.connection && method.repeatable).length > 0 && (
        <option value={1}>Grid</option>
      )
    )
  }

  useEffect(() => {}, [tabForm])
  return (
    <Fragment>
      <Col md={12}>
        <hr />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div> Add Edit tabs for {tidyName(form.table) || 'table'}.</div>

          <div className='float-right'>
            <Button
              color='success'
              onClick={() => {
                updateCount(count + 1)
                updateTabForm([...tabForm, ...[initialTabForm]])
              }}>
              <IconSet icon={'fa-solid fa-plus'} color='light' />
            </Button>
          </div>
        </div>

        <hr />
      </Col>

      {tabForm.map((item, i) => {
        return (
          <Col md={12} key={i}>
            <p className='text-left'>
              {i + 1 + '.'}{' '}
              <span
                className='text-danger cursor-pointer'
                onClick={() => {
                  const updatedTabForm = tabForm
                  updatedTabForm.splice(i, 1)
                  updateCount(count - 1)
                  updateTabForm(updatedTabForm)

                  updateTab(updatedTabForm)
                }}>
                Remove
              </span>
            </p>
            <Row>
              <Col md={col || 3}>
                <AvField
                  type='select'
                  label='Select Connection'
                  name={'connection'}
                  value={item?.connection}
                  onChange={(e) => {
                    onChange(e.target.value, i, 'connection')
                  }}
                  required>
                  <option value=''>Select field or column</option>
                  {form.table &&
                    grid_selector[form.table] &&
                    grid_selector[form.table].methods.map((item, i) => {
                      if (item.table)
                        return (
                          <option value={item.table} key={i}>
                            {tidyName(item.title) || tidyName(item.name)}
                          </option>
                        )
                    })}
                </AvField>
              </Col>
              <Col md={col || 3}>
                <AvField
                  type='select'
                  label='Select Type'
                  name={'type'}
                  value={item?.type}
                  onChange={(e) => {
                    onChange(e.target.value, i, 'type')
                  }}>
                  <option value={0}>Form</option>
                  {/* {renderGridOption(item)} */}
                  {grid_selector[form.table]?.methods?.filter((method) => method.table === item.connection && method.repeatable).length > 0 && (
                    <option value={1}>Grid</option>
                  )}
                </AvField>
              </Col>
              {/* <Col md={col || 3}>
                <AvField label='Icon' name='icon' value={item?.icon} onChange={(e) => onChange(e.target.value, i, 'icon')} />
              </Col> */}
              <Col md={col || 6}>
                <AvField
                  label='Default Header'
                  name='default_header'
                  value={item?.default_header ? item?.default_header : ''}
                  onChange={(e) => onChange(e.target.value, i, 'default_header')}
                />
              </Col>
              {/* <Col md={col || 3}>
                <label>Repeating Form</label>
                <FormToggleSwitchExample value={item?.repeating_form} onChange={(toggle_data) => onChange(toggle_data,i,'repeating_form')} />
              </Col> */}
            </Row>
          </Col>
        )
      })}
      {tabList && tabList?.length > 0 && (
        <Col md={12} style={{ marginBottom: '1rem' }}>
          <TabListEdit tabList={tabList} source_table={form?.table} parentPageName={parentPageName} />
        </Col>
      )}

      {/* <Col md={12}>
        {isTabPage && (
          <Button color='primary' onClick={() => onSaveTab()}>
            Save
          </Button>
        )}
      </Col> */}
    </Fragment>
  )
}

export default connect(select)(EditTab)
