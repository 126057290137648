import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submitRequest } from '../../../../Redux/Reducers/CMS'
import FormToggleSwitch from '../../../Forms/Fields/switchButton'
import { cms } from '../../../../helper'
import AddCustomColumn from './addCustomColumn'

const defaultForm = {
  type: 'spacer',
  col: '6',
  height: '1',
  field_id: 'spaccer',
}

const Spaccer = ({
  formName,
  pageName,
  setModal,
  type,
  field = null,
  tab = false,
  setAddForm,
  section,
  source_table,
  is_create = false,
  isLayout,
  rowIndex,
  blockIndex,
  blockFields,
  isCreatingFieldForRepeatingForm,
  grid_id,
  parentPageName,
  order,
}) => {
  const [form, updateForm] = useState(defaultForm)
  const [load, updateLoad] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    updateForm({ ...form, type: type })
  }, [])

  useEffect(() => {
    if (field) {
      // const validationValueFromDb = field.component_call_validation?.map((item) => item?.validation_type)

      const field_setting = field?.field_setting
      let format = field?.format
      if (format) {
        format = JSON.parse(format)
      }
      updateForm({
        ...form,
        height: format?.height,

        col: field_setting?.col || field?.col,
        field_id: field?.field_id,
      })
    }
  }, [])

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    let data = {
      ...values,
      form_name: formName,
      page_name: pageName,
      type: form.type,
      field_id: form?.field_id,
      parent_page_name: parentPageName,
      order,
    }
    if (tab) {
      data = {
        ...data,
        tab: true,
        section: section,
      }
    }
    if (isLayout) {
      data = {
        ...data,
        isLayout: true,
        rowIndex: rowIndex,
        blockIndex: blockIndex,
      }
    }
    if (is_create && isLayout) {
      data = {
        ...data,
        order: blockFields ? blockFields?.length + 1 : 0,
      }
    }
    if (isCreatingFieldForRepeatingForm) {
      const { form_name, ...rest } = data
      const payload = {
        ...rest,
        grid_id: grid_id,
        source_table: source_table,
      }
      dispatch(submitRequest({ url: 'submit-form-field', data: payload }))
    } else {
      dispatch(submitRequest({ url: 'submit-form-field', data }))
    }
    // dispatch(submitRequest({ url: 'submit-form-field', data }))
    setModal(false)
    setAddForm(false)
    updateForm(defaultForm)
  }
  return (
    <>
      <AvForm onValidSubmit={handleValidSubmit} model={form}>
        <Row>
          <Col md={6}>
            <AvField
              type='number'
              name='height'
              label='Height'
              value={form?.height}
              onChange={(e) => updateForm({ ...form, height: e.target.value })}></AvField>
          </Col>
          {!isLayout && (
            <Col md={6}>
              <AvField type='select' name='col' label='Column' value={form?.col} onChange={(e) => updateForm({ ...form, col: e.target.value })}>
                {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  )
                })}
              </AvField>
            </Col>
          )}

          <Col md={12}>
            <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default Spaccer
