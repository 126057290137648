import BarChart from '../../../../../../CMSComponent/Common/Chart/barChart'
import { ChartDatasetOptions, ChartOptions } from '../../../../../../CMSComponent/Common/Chart/chartHelper'

const JobsPercentageChart = ({ question }) => {
  let label = question?.answers?.map((item) => item?.answer)
  let value = question?.answers?.map((item) => item?.response)

  const options = {
    ...ChartOptions,
    plugins: {
      ...ChartOptions?.plugins,
    },
    scales: {
      ...ChartOptions.scales,
      y: {
        ...ChartOptions.scales.y,
        title: {
          display: true,
          text: 'Percentage',
          color: 'black',
        },
      },
      x: {
        ...ChartOptions.scales.x,
        title: {
          display: true,
          text: 'Options',
          color: 'black',
        },
      },
    },
  }

  const data = {
    labels: label,
    datasets: [
      {
        ...ChartDatasetOptions,
        label: 'Daily Average Time',
        data: value,
        maxBarThickness: 40,
      },
    ],
  }
  return <BarChart options={options} data={data} />
}

export default JobsPercentageChart
