import { getQueueAfterUpdatingPermission } from '../../Services/Helper/userPermissionHelper'

export const SET_QUEUE = 'SET_QUEUE'
export const SET_QUEUE_AFTER_CRUD_OP = 'SET_QUEUE_AFTER_CRUD_OP'
export const SET_GRID = 'SET_GRID'
export const SET_LOADING = 'SET_LOADING'
export const CLEAR_QUEUE = 'CLEAR_QUEUE'
export const CLEAR_GRID_QUEUE = 'CLEAR_GRID_QUEUE'
export const CLEAR_COMMON_QUEUE = 'CLEAR_COMMON_QUEUE'

const initialSate = {
  grid: {},
  queues: [],
  loading: false,
}

export const setQueue = (queue) => ({
  type: SET_QUEUE,
  queue,
})

export const setQueueAfterCRUDops = (queues) => ({
  type: SET_QUEUE_AFTER_CRUD_OP,
  queues,
})

export const setGrid = (data) => ({
  type: SET_GRID,
  data,
})

export const setQueueLoading = (data) => ({
  type: SET_LOADING,
  data,
})

export const clearCommonQueue = () => ({
  type: CLEAR_COMMON_QUEUE,
})

export const clearGridQueue = () => ({
  type: CLEAR_GRID_QUEUE,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case SET_QUEUE:
      const { queue } = action
      let isDeleteRowQueue
      if (queue.url === 'connect-template-delete') {
        let deletedQueueIndex, appendDataInExistingQueue
        state.queues.map((item, index) => {
          if (queue.url === 'connect-template-delete' && item.payload?.data?.source_table === queue.payload?.data?.source_table) {
            deletedQueueIndex = index
            appendDataInExistingQueue = true
          }
        })

        if (appendDataInExistingQueue) {
          const newQueues = [...state.queues]

          newQueues[deletedQueueIndex] = {
            ...newQueues[deletedQueueIndex],
            payload: {
              ...newQueues[deletedQueueIndex]?.['payload'],
              data: {
                ...newQueues[deletedQueueIndex]?.['payload']?.['data'],
                ids: [...newQueues[deletedQueueIndex]?.['payload']?.['data']?.['ids'], ...queue?.payload?.data?.ids],
              },
            },
          }
          return { ...state, queues: newQueues }
        } else {
          return { ...state, queues: [...state.queues, queue] }
        }
      } else if (queue.url === 'update-permissions') {
        let appendDataInExistingQueue, updatedQueueIndex, updatedDataIndex, isUpdatingData
        state.queues.map((items, index) => {
          if (items?.payload?.data?.parent_row_table === queue.payload?.data?.parent_row_table) {
            appendDataInExistingQueue = true
            updatedQueueIndex = index
            items?.payload?.data?.data?.map((item, i) => {
              if (
                (item?.name === queue?.payload?.data?.data?.[0]?.page_name && queue?.payload?.data?.data?.[0]?.page_name) ||
                (item?.name === queue?.payload?.data?.data?.[0]?.child_page_name && queue?.payload?.data?.data?.[0]?.child_page_name) ||
                (item?.name === queue?.payload?.data?.data?.[0]?.key && queue?.payload?.data?.data?.[0]?.key)
              ) {
                updatedDataIndex = i
                isUpdatingData = true
              }
            })
          }
        })

        if (appendDataInExistingQueue) {
          const newQueues = [...state.queues]
          let newQueueData = { ...queue?.payload?.data?.data?.[0] }
          let { id, child_id, page_name, child_page_name, default_name, cms_key, route, key, ...dataToUpdate } = newQueueData
          dataToUpdate = {
            ...dataToUpdate,
            name: page_name || child_page_name || key,
          }

          if (isUpdatingData) {
            newQueues[updatedQueueIndex]['payload']['data']['data'][updatedDataIndex] = dataToUpdate
            return { ...state, queues: newQueues }
          } else {
            const newQueues = [...state.queues]
            newQueues[updatedQueueIndex]['payload']['data']['data'] = [...newQueues[updatedQueueIndex]['payload']['data']['data'], dataToUpdate]

            return { ...state, queues: newQueues }
          }
        } else {
          let newQueueData = { ...queue }
          let { id, child_id, page_name, child_page_name, default_name, cms_key, route, key, ...dataToUpdate } = queue?.payload?.data?.data?.[0]
          dataToUpdate = {
            ...dataToUpdate,
            name: page_name || child_page_name || key,
          }
          newQueueData['payload']['data']['data'] = [dataToUpdate]

          const newQueues = [...state.queues, newQueueData]

          return { ...state, queues: newQueues }
        }
      } else if (queue.url === 'update-user-permissions') {
        const newQueues = getQueueAfterUpdatingPermission(state.queues, queue)
        return { ...state, queues: newQueues }
      } else {
        return { ...state, queues: [...state.queues, queue] }
      }
      break

    case SET_GRID:
      const { data } = action
      const updatedGrid = state.grid
      if (updatedGrid[data.source_table]) {
        const i = updatedGrid[data.source_table].findIndex((_element) => {
          return _element.name === data.data.name && _element.id === data.data.id
        })
        if (i > -1) updatedGrid[data.source_table][i] = data.data // (2)
        else updatedGrid[data.source_table].push(data.data)
      } else {
        updatedGrid[data.source_table] = [data.data]
      }
      return { ...state, grid: updatedGrid }
    case CLEAR_GRID_QUEUE:
      return {
        ...state,
        grid: {},
        loading: false,
      }
    case CLEAR_COMMON_QUEUE:
      return {
        ...state,
        queues: [],
        loading: false,
      }
    case SET_QUEUE_AFTER_CRUD_OP:
      return { ...state, queues: action.queues }
    case SET_LOADING:
      return { ...state, loading: action.data }
    default:
  }
  return state
}
