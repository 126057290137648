import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import { removeItemByName } from '../../Redux/Reducers/CMS'

const select = (state) => ({
  CMS: state.CMS,
  editPageMode: state.CMS.editPageMode,
  page: state.CMS.coreData.page,
})

const RemoveTopNavButton = (props) => {
  const { nav, basicButton } = props
  const dispatch = useDispatch()
  const [modalValue, setModal] = useState(false)
  if (!nav) return null
  return (
    <>
      {basicButton ? (
        <Button
          color='danger'
          id={'removeButton' + nav.name?.replace('.', '')?.replace('***', '')}
          onClick={() => setModal(true)}
          style={{ width: 90, height: 38, fontSize: '15px', marginLeft: '5px' }}>
          Remove
        </Button>
      ) : (
        <Badge
          color='danger'
          id={'removeButton' + nav.name?.replace('.', '')?.replace('***', '')}
          pill
          className='remove-button'
          onClick={() => setModal(true)}>
          -
        </Badge>
      )}
      <UncontrolledTooltip placement='right' target={'removeButton' + nav.name?.replace('.', '')?.replace('***', '')}>
        Remove {nav.name && nav.name.replace('_', ' ')}
      </UncontrolledTooltip>
      <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: 300 }}>
        <ModalHeader toggle={() => setModal(!modalValue)}>Remove Top Nav Button</ModalHeader>
        <ModalBody>are you sure you want to delete {nav.name ? nav.name.replace('_', ' ') : 'this'} button?</ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              dispatch(removeItemByName({ name: nav.name, type: 'nav-button' }))
              setModal(false)
            }}>
            Yes
          </Button>{' '}
          <Button color='secondary' onClick={() => setModal(false)}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default connect(select)(RemoveTopNavButton)
