export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS'

const initialSate = []

export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  permissions,
})
export const clearPermissions = () => ({
  type: CLEAR_PERMISSIONS,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      const { permissions } = action
      return permissions
    case CLEAR_PERMISSIONS:
      return initialSate
    default:
  }
  return state
}
