import { AvField } from 'availity-reactstrap-validation'
import { Col, Row } from 'reactstrap'
import CMSRichText from '../../../../Forms/Fields/richText'
import { tidyName } from '../../../../../helper'
import { useState } from 'react'

const Body = ({ bodyTitle, setBodyTitle, setBodyDescription, bodyDescription, columns, isAddingNew, reportIndex }) => {
  const [seletcedField, updateSelectedField] = useState()

  const updateDynamicValue = () => {
    if (!seletcedField) return
    let title = bodyTitle

    title = title + ' [' + seletcedField + ']'
    setBodyTitle(title)
    updateSelectedField()
  }


  return (
    <Row>
      <Col md={6}>
        <AvField name='body_title' label='title' value={bodyTitle} onChange={(e) => setBodyTitle(e.target.value)}></AvField>
      </Col>
      <Col
        md={0}
        style={{ flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => updateDynamicValue()}>
        <i className='fa-solid fa-circle-arrow-left fa-2x'></i>
      </Col>
      <Col md={5}>
        <AvField type='select' name='row_group_field' label='Select Placeholder' onChange={(e) => updateSelectedField(e.target.value)}>
          <option value=''>Select column</option>
          <option value='title'>Title</option>
          <option value='report_date'>Report Date</option>
          <option value='todays_date'>Today's Date</option>
          <option value='current_week'>This Week</option>
          <option value='current_month'>This Month</option>
          <option value='current_year'>This Year</option>
          <optgroup label='Others'>
            {columns?.map((col, i) => {
              return (
                <option key={i} value={col?.name || col?.field_id}>
                  {tidyName(col?.title) || tidyName(col?.name)}
                </option>
              )
            })}
          </optgroup>
        </AvField>
      </Col>
      <Col md={12}>
        <label>Description</label>
        <CMSRichText
          id={`body_description${isAddingNew ? '_new_' : reportIndex}`}
          data={{}}
          value={bodyDescription}
          onChange={(value) => setBodyDescription(value)}></CMSRichText>
      </Col>
    </Row>
  )
}

export default Body
