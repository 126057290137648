import { useEffect, useState } from "react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { ListGroupItem } from "reactstrap";
import { cms } from "../../../helper";

const OrderSideNavBar = ({nav,isOrdering,setIsOrdering,group,setOrderedGroup,setGroup}) =>{

  if(!group) return
  
  return (
    <RLDD
      cssClasses='example'
      items={group}
        
      itemRenderer={(item, key) => {
        return (
          <ListGroupItem id={item.field_id || item?.name}>
            <span style={{ marginRight: '1rem', cursor: 'pointer' }}>
              <i className='fa-sharp fa-solid fa-grid-horizontal'></i>
            </span>
            {cms(item?.name)} 
           
          </ListGroupItem>
        )
      }}
      onChange={(e) => {
        setGroup(e)
        let requestData = []
        e &&
          e.map((item, key) => {
            requestData.push({
              name:  item.name,
              order: key,
            })
          })
        setOrderedGroup(requestData)
      }}
    />
  )
};


export default OrderSideNavBar