export const SET_SLIDES = 'SET_SLIDES'
export const GET_SLIDES = 'GET_SLIDES'
export const SET_LOGO = 'SET_LOGO'
export const CLEAR_SLIDES = 'CLEAR_SLIDES'
export const GET_WELCOME_IMAGES = 'GET_WELCOME_IMAGES'
export const ADD_SLIDE = 'ADD_SLIDE'
export const UPDATE_SLIDE = 'UPDATE_SLIDE'
export const DELETE_SLIDE = 'DELETE_SLIDE'

const initialSate = {
  slides: null,
  logo: '',
}

export const setSlides = (slides) => ({
  type: SET_SLIDES,
  slides,
})

export const setLogo = (logo) => ({
  type: SET_LOGO,
  logo,
})

export const getWelcomImages = () => ({
  type: GET_WELCOME_IMAGES,
})

export const getSlides = () => ({
  type: GET_SLIDES,
})

export const clearSlides = () => ({
  type: CLEAR_SLIDES,
})

export const addSlide = (data) => ({
  type: ADD_SLIDE,
  data,
})

export const updateSlide = (data) => ({
  type: UPDATE_SLIDE,
  data,
})

export const deleteSlide = (id) => ({
  type: DELETE_SLIDE,
  id,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case SET_SLIDES:
      const { slides } = action
      return { ...state, slides }
    case SET_LOGO:
      const { logo } = action
      return { ...state, logo }
    case ADD_SLIDE:
      const { data } = action
      let slides_before_add = []
      if (state.slides) {
        slides_before_add = [...state.slides]
      }
      slides_before_add?.push(data)
      return { ...state, slides: slides_before_add }
    case UPDATE_SLIDE:
      const { data:dataToUpdate } = action
      let slides_before_update = []
      if (state.slides) {
        slides_before_update = [...state.slides]
      }
      const slides_after_update = slides_before_update?.map((item)=>{
        if(item?.uuid === dataToUpdate?.uuid){
          return {...dataToUpdate}
        }else{
          return {...item}
        }
      })
      return { ...state, slides: slides_after_update }

    case DELETE_SLIDE:
      const { id } = action
      let slides_before_delete = []
      if (state.slides) {
        slides_before_delete = [...state.slides]
      }
      const slides_after_delete = slides_before_delete?.filter((item) => {
        if (item?.uuid !== id) {
          return true
        }
      })
    
      return { ...state, slides: slides_after_delete }

    case CLEAR_SLIDES:
      return { ...state, slides: [] }
    default:
  }
  return state
}
