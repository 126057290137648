import React, { Fragment } from 'react'

import cx from 'classnames'

import Switch from 'react-switch'

class FormToggleSwitchExample extends React.Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      isToggleOn: true,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  // componentDidMount(){
  //   const {value } = this.props;
  //   setTimeout(()=>{
  //     this.setState({
  //       isToggleOn:value
  //     })
  //   },100)
    
  // }

  handleChange(checked) {
    this.setState({ checked })
  }

  handleClick() {
    if(this.props.onChange){
      this.props.onChange(!this.state.isToggleOn)
    }
    this.setState(function (prevState) {
      return { isToggleOn: !prevState.isToggleOn }
    })
   
  }
  

  render() {
   
    return (
      <div className="">
      <div className='switch has-switch mr-2 mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={this.handleClick}>
        <div
          className={cx('switch-animate', {
            'switch-on': this.props.value,
            'switch-off': !this.props.value,
          })}>
          <input type='checkbox' />
          <span className='switch-left bg-danger'>ON</span>
          <label>&nbsp;</label>
          <span className='switch-right bg-danger'>OFF</span>
        </div>
      </div>
      </div>
    )
  }
}

export default FormToggleSwitchExample
