import ExpandableQuestion from './expandableQuestion'
import ExpandedTable from './expandedTable'

export const ExpandableRowItemGroups = ({ items = [1, 2], }) => {
  return (
    <div>
      {items?.map((item,key) => {
        return <ExpandableQuestion item={item} key={key}/>
      })}
    </div>
  )
}

const ExpandableRow = ({ row, isQuestionnaireExpand, isGridExpandTable,tableGrid }) => {
  //neeed to add logic for related to source table, as of now its for items
  

  if(isQuestionnaireExpand){
    return <ExpandableRowItemGroups items={row?.all_questions_answers || row?.items} />
  }else{
    return <ExpandedTable row={row} main_grid={tableGrid?.main_grid} tableGrid={tableGrid}/>
  }
  
}

export default ExpandableRow
