import { useCallback, useEffect, useRef, useState } from 'react'
import RedactorX from '../../../Vendor/redactorx/redactorx'
import CMSRichText from '../../Forms/Fields/richTextQuestionnaire'
import CommonFields from './commonFields'
import { AvForm } from 'availity-reactstrap-validation'

const RichText = ({ item, answers, setAnswers, requiredError }) => {
  // const [enteredValue,setEnteredValue] = useState();
  const [a, setA] = useState([])

  useEffect(() => {
    setA(a)
  }, [answers])
  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}
  const enteredValue = current_answer_object?.response_text



  const answersRef = useRef(answers)

    useEffect(() => {
      answersRef.current = answers
    }, [answers])

  const onHandleChange = useCallback((value) => {
    const old_answers = [...answersRef.current]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          question_type: 'rich-text',
          response_text: value,
        }
      } else {
        return question
      }
    })

    setAnswers(updated_answers)
  }, [])

  return (
    <div>
      <AvForm>
        <CMSRichText id='text_area' data={{}} value={enteredValue} onChange={(value) => onHandleChange(value)}></CMSRichText>
      </AvForm>

      <CommonFields
        item={item}
        selectedAnswer={current_answer_object?.answer_id}
        answers={answers}
        setAnswers={setAnswers}
        current_answer_object={current_answer_object}
        requiredError={requiredError}
      />
      {item?.validations?.includes('required') &&
        requiredError &&
        (!current_answer_object?.response_text || current_answer_object?.response_text?.length === 0) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
    </div>
  )
}

export default RichText
