import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import Drawer from 'react-motion-drawer'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Elastic } from 'react-burgers'

import { can } from '../helper'

import DrawerPage from './PageVariation/drawerPage'
import { removeItemByName } from '../Redux/Sagas/requests/CMS'
import { request } from '../Redux/Sagas/requests/api'
import { updatePageDataRedux } from '../Redux/Reducers/CMS'
import Loader from 'react-loaders'

const select = (state) => ({
  page: state.CMS.coreData.page,
})

const CMSDownloadDrawer = ({ page }) => {
  const [drawer, updateDrawer] = useState({
    active: false,
    openLeft: false,
    openRight: false,
  })
  const dispatch = useDispatch()
  const [downloadPage, updateDownloadPage] = useState(undefined)
  const [pageRequested, setPageRequested] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!page?.draw) {
      setPageRequested(true)
      request(`get-page/draw`, 'GET').then((data) => {
        let pageData = {}
        setPageRequested(false)
        if (data.status === 200) {
          pageData['draw'] = data.data
          updateDownloadPage(data.data)
          dispatch(updatePageDataRedux(pageData))
        } else if (data.status === 401) {
          history.push('/logout')
        }
        // dispatch(setLoader(false))
        // setLoadingPage(false)
      })
    }
  }, [drawer.openRight])

  useEffect(() => {
    if (page && page.draw) {
      updateDownloadPage(page.draw)
    }
  }, [page?.draw])

  if (downloadPage && (downloadPage.allowed !== 1 || !can(downloadPage.permission))) return null

  return (
    <>
      <Drawer
        right
        className='drawer-content-wrapper p-0 sidebar-text-dark'
        width={600}
        open={drawer.openRight}
        onChange={(open) => updateDrawer({ ...drawer, openRight: open })}
        noTouchOpen={false}
        noTouchClose={false}>
        <PerfectScrollbar>
          <div className='drawer-nav-btn'>
            <Elastic
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color='#6c757d'
              padding='5px'
              active={true}
              onClick={() =>
                updateDrawer({
                  openRight: false,
                  openLeft: false,
                  active: !drawer.active,
                })
              }
            />
          </div>
          <div className='mt-3'>
            {pageRequested ? <div style={{ fontSize: '1.2rem', fontWeight: '500' }}>Loading...</div> : <DrawerPage data={downloadPage} />}
          </div>
          {/* <a onClick={() => dispatch(removeItemByName({ name: 'draw', type: 'form' }))}>Remove This Drawer</a> */}
        </PerfectScrollbar>
      </Drawer>
      <div className='header-btn-lg'>
        <Elastic
          width={26}
          lineHeight={2}
          lineSpacing={5}
          color='#6c757d'
          padding='5px'
          active={false}
          onClick={() =>
            updateDrawer({
              openRight: !drawer.openRight,
              openLeft: false,
              active: !drawer.active,
            })
          }
        />
      </div>
    </>
  )
}

export default connect(select)(withRouter(CMSDownloadDrawer))
