import { triggerBase64Download } from 'common-base64-downloader-react'
import { downloadFiles } from '../../../helper'

const imageType = ['png', 'jpg', 'jpeg', 'svg']

const DownloadUploadedFiles = ({ row }) => {
  console.log('rowas::::::::::', row)
  const onDownLoadImage = (item) => {
    if (item?.isBase64) {
      triggerBase64Download(item?.file, item?.file_name)
    } else {
      downloadFiles(`${process.env.REACT_APP_API_URL}/download-upload/${item?.id}`, item?.file_name)
    }
  }
  return (
    <div>
      {row?.original?.upload_files?.map((item, index) => {
        return (
          <div className='mb-1 cursor-pointer line-clamp-1 text-primary' key={index} onClick={() => onDownLoadImage(item)}>
            {item?.file_name}
          </div>
        )
      })}
    </div>
  )
}

export default DownloadUploadedFiles
