const CompletedMin = ({row}) =>{
  let min = 0
  const min_string = row?.row?.original?.completed_minute_duration_concat
  if(min_string?.length>0){
    const min_array = min_string?.split(",")
    const unique_min_array = new Set(min_array)

    unique_min_array?.forEach(ele => {
      let value = ele.split(" :: ")?.[1]
      min +=parseInt(value)

    });

  }
  if(min){
    return min
  }else{
    return
  }
  
}

export default CompletedMin;