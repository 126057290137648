import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import PageTitle from '../../../Layout/AppMain/pageTitle'
import { tidyName } from '../../../helper'
import CreatePage from '../ContentForms/createPage'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  page: state.CMS.coreData.page,
})

const CreatePageRoute = ({ editPageMode, pageName, page, isMainPageCreate, currentNavData }) => {
  useEffect(() => {}, [page])

  return (
    <Fragment>
      <PageTitle heading={tidyName(pageName)} icon='pe-7s-browser icon-gradient bg-happy-fisher' hideBreadCrumbs />
      <CSSTransitionGroup
        component='div'
        transitionName='TabsAnimation'
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md='12'>
            <Card className='main-card mb-3'>
              <CardBody>
                <CardTitle>{tidyName(pageName)}</CardTitle>
                <div className='text-center'>
                  <div className='font-10rem'>
                    <div className='mx-auto mb-0 font-size-lg text-capitalize font-weight-normal card-title'>No page or data found!</div>
                    <i className='fa-solid fa-face-frown-open icon-gradient bg-mean-fruit' />
                  </div>
                  {editPageMode && <CreatePage pageName={pageName} isMainPageCreate={isMainPageCreate} currentNavData={currentNavData} />}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default connect(select)(CreatePageRoute)
