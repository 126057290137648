import { useState } from 'react'
import { Button, Col, ListGroupItem, Row } from 'reactstrap'
import cx from 'classnames'
import { FileUploader } from 'react-drag-drop-files'
import IconSet from '../../icon'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { addSidebar, deleteSidebarById } from '../../../Redux/Reducers/CMS'

const fileTypes = ['JPG', 'JPEG', 'TIFF', 'PNG']

const SidebarMain = ({ backgroundImage, setBackgroundImage, slides_option }) => {
  const [file, updateFile] = useState()
  const [isSaving, setIsSaving] = useState(false)

  const side_bar_data = useSelector((state) => state.CMS.coreData.brands)
  const handleChange = (file) => {
    updateFile(file)
  }

  const dispatch = useDispatch()

  const onSaveFile = () => {
    const formData = new FormData()
    setIsSaving(true)

    formData.append('type', 'sidebar')

    // formData.append("logo_default",data.logo_default)
    formData.append('image', file)
    formData.append('page', 'main_app')

    request('save-brand', 'POST', formData).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(addSidebar(res?.data?.brand))
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.message,
          })
        )
      }
      setIsSaving(false)
      updateFile()
    })
  }

  const onDeleteSideBar = (id) => {
    dispatch(deleteSidebarById(id))
    request('delete-brand', 'POST', { uuid: id })
  }
  return (
    <>
      {' '}
      <ListGroupItem className={'theme-settings-swatches'}>
        <h5>Sidebar Image Background</h5>
        <div className='divider' />
        {side_bar_data &&
          side_bar_data.map((item, key) => {
            return (
              <div
                key={key}
                className={cx('swatch-holder swatch-holder-img', {
                  active: backgroundImage === item?.image,
                })}>
                <button className='img-holder switch-trigger position-relative' onClick={() => setBackgroundImage(item?.image)}>
                  <div
                    className='bg-danger position-absolute rounded-circle cross-badge-sizing'
                    onClick={(e) => {
                      onDeleteSideBar(item?.uuid)
                      e.preventDefault()
                      e.stopPropagation()
                    }}>
                    <IconSet icon={'fa-solid fa-xmark'} color='light' />
                  </div>
                  <img alt=' ' src={`${process.env.REACT_APP_URL}${item?.image}`} />
                </button>
              </div>
            )
          })}
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          {!file && (
            <Col md={12}>
              <FileUploader
                handleChange={handleChange}
                name='file'
                types={fileTypes}
                multiple={false}
                fileOrFiles={file}
                classes={'upload-file-form'}>
                <div className='upload_container'>
                  <span style={{ marginRight: '1rem', fontSize: '2rem' }}>
                    <IconSet icon='fa-solid fa-folder-arrow-up' color='info' />
                  </span>
                  Upload sidebar image here!
                </div>
              </FileUploader>
            </Col>
          )}

          {file && (
            <Col md={12}>
              <div className={'mb-2 position-relative'}>
                <div className='bg-danger position-absolute rounded-circle cross-badge-sizing' onClick={() => updateFile()}>
                  <IconSet icon={'fa-solid fa-xmark'} color='light' />
                </div>
                <img
                  alt=' '
                  src={typeof file === 'string' ? process.env.REACT_APP_URL + '/' + file : URL.createObjectURL(file)}
                  width='80px'
                  height={'100px'}
                  style={{ borderRadius: '5px' }}
                />
              </div>
            </Col>
          )}

          {file && (
            <Col md={12}>
              <Button className='float-right ml-2' onClick={() => updateFile()}>
                Cancel
              </Button>
              <Button className='float-right' onClick={() => onSaveFile()}>
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </Col>
          )}
        </Row>
      </ListGroupItem>
    </>
  )
}

export default SidebarMain
