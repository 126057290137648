import AddSlider from './addEditSlide'

import React, { useState } from 'react'
import { Button, Card, CardBody, Table } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'

import ConfirmButton from '../../Common/confirmButton'
import IconSet from '../../icon'
import { request } from '../../../Redux/Sagas/requests/api'
import { deleteSlide } from '../../../Redux/Reducers/slides'

const SlideList = ({ editItem, setEditItem, setIsAddFormOpen }) => {
  const [showEditCard, setShowEditCard] = useState(false)

  const dispatch = useDispatch()

  const slides = useSelector((state) => state.Slides.slides)

  const onDeleteBrand = (id) => {
    dispatch(deleteSlide(id))
    request('delete-brand', 'POST', { uuid: id })
  }
  return (
    <>
      <>
        {showEditCard || editItem?.open ? (
          <AddSlider editItem={editItem} setEditItem={setEditItem} setIsAddFormOpen={setIsAddFormOpen} />
        ) : (
          <Card>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Page</th>
                    <th>Title</th>
                    <th>Subtitle</th>
                  </tr>
                </thead>
                <tbody>
                  {slides?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.page}</td>
                      <td>{item?.title}</td>
                      <td>{item?.sub_title}</td>

                      <td>
                        <Button
                          className='p-0 mr-1 btn-icon btn-icon-only btn-pill'
                          color='link'
                          onClick={() => {
                            setEditItem({
                              data: item,
                              open: true,
                            })
                          }}>
                          <i className='far fa-edit btn-icon-wrapper' style={{ fontSize: '14px' }} />
                        </Button>

                        <ConfirmButton
                          color='link'
                          id={'custom_color'}
                          clickEvent={() => {
                            onDeleteBrand(item?.uuid)
                          }}
                          style={{ padding: 0 }}>
                          <i className='pe-7s-trash btn-icon-wrapper text-danger' style={{ fontSize: '14px' }} />
                        </ConfirmButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </>
    </>
  )
}

export default SlideList
