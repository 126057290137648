export const LOCATION_DROPDOWN_LIST = {
  component_type: 'fields',
  component_name: 'select',
  name: 'location_id',
  is_layout: 1,
  row_index: 1,
  block_index: 1,
  col: 0,
  section_rowspan: null,
  field_setting: {
    import: true,
    export: true,
    is_copy_allowed: null,
    depend_fields: null,
    disable_if: [],
  },
  cms_value: null,
  permission: 'no_permission',
  field_id: 'location_id',
  cms_key: 'items.location_id',
  cms_tooltip: null,
  component_call_data_connection: {
    component_type: 'data_connection',
    component_name: 'template_data',
    source_table: 'locations',
    fields: 'id,name',
    clause_data: [
      {
        field: 'active',
        type: 'dynamic',
        value: '1',
        condition: 'equals',
      },
    ],
  },
  selected_value: null,
  disabled: false,
  hidden: false,
  search: false,
  hide_from_grid: false,
  background_color: false,
  component_call_validation: [
    {
      component_type: 'validation',
      component_name: 'inline_validation',
      validation_type: 'required',
      message: 'Please complete',
    },
  ],
  component_call_actions: null,
  component_call_fields: [],
  format: {
    size: 12,
    searchable: false,
    is_multi_select: false,
  },
}