import { useSelector } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, Row } from 'reactstrap'
import { useState } from 'react'
import TemplateGrid from './TemplatePreview/grid'
import Templatetabs from './TemplatePreview/tabs'
import Templateform from './TemplatePreview/form'
import Loader from 'react-loaders'

const CreateTemplateForm = ({ handleValidSubmit, updateForm, form, load, onlyGrid = false, updateLoad }) => {
  const templates = useSelector((state) => state.CMS.coreData.templates)
  const [selectedTemplateData, setSelectedTemplateData] = useState()

  const setSelectedTemplate = (value) => {
    updateForm({ ...form, template_key: value })
    setSelectedTemplateData(templates[value])
  }

  const renderTemplate = (data) => {
    if (data) {
      switch (data?.type) {
        case 'GRID':
          return <TemplateGrid data={data?.grid_columns} />
        case 'INLINE_GRID':
          return <TemplateGrid data={data?.grid_columns} />
        case 'FORM':
          return <Templateform data={data?.forms} />
        case 'REPEATING_FORM':
          const a = [data?.form]
          return <Templateform data={a} />
        default:
          return
      }
    }
  }

  const templates_array = []

  for (let i in templates) {
    const data = {
      ...templates[i],
      template_id: i,
    }
    templates_array.push(data)
  }

  return (
    <>
      <AvForm onValidSubmit={handleValidSubmit}>
        <Row>
          {!onlyGrid && (
            <>
              <Col md={12}>
                <hr />
                Create a page with pre defined details by selecting template you want to go with.
                <hr />
              </Col>
              <Col md={4}>
                <AvField name='Icon' label='Icon' type='text' />
              </Col>
              <Col md={4}>
                <AvField name='default_header_name' label='Default Header Name' type='text' />
              </Col>
              <Col md={4}>
                <AvField name='default_sub_header_name' label='Default Sub Header Name' type='text' />
              </Col>
            </>
          )}
          <Col md={6}>
            <AvField type='select' label='Select Template' name={'template_key'} onChange={(e) => setSelectedTemplate(e.target.value)}>
              <option>Select Template</option>
              {templates_array?.map((item, key) => {
                return (
                  <option value={item.template_id} key={key}>
                    {item.name}
                  </option>
                )
              })}
            </AvField>
          </Col>
          <Col md={6} className='text-right'>
            <Button
              color='primary'
              style={{
                height: 38,
                marginTop: 27,
                fontSize: '15px',
              }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Import Template'}
            </Button>
          </Col>
        </Row>
        <Row>
          {renderTemplate(selectedTemplateData)}
          {selectedTemplateData?.tabs && <Templatetabs data={selectedTemplateData?.tabs} />}
        </Row>
      </AvForm>
    </>
  )
}

export default CreateTemplateForm
