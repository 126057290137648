import { AvForm } from 'availity-reactstrap-validation'
import CMSRichText from '../Forms/Fields/richText'

const AdditionalInfo = ({ additional_information, set_additional_information, id }) => {
  return (
    <AvForm>
      <CMSRichText
        data={{ cms_key: 'additional_information' }}
        value={additional_information}
        id={id}
        onChange={(value) => set_additional_information(value)}></CMSRichText>
    </AvForm>
  )
}
export default AdditionalInfo
