import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ListGroupItem, Table } from 'reactstrap'
import cx from 'classnames'
import { cms } from '../../../helper'
import { removeTabByName, updatePageDataRedux } from '../../../Redux/Reducers/CMS'

import ConfirmButton from '../../Common/confirmButton'
import IconSet from '../../icon'
import { request } from '../../../Redux/Sagas/requests/api'
import { setLoader } from '../../../Redux/Reducers/loader'

const RenderTabRow = ({ tab, source_table, parentPageName }) => {
  const dispatch = useDispatch()

  const [showCount, setShowCount] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [enablePrint, setEnablePrint] = useState(false)

  useEffect(() => {
    setShowCount(tab?.show_count)
    setEnablePrint(tab?.print_enabled)
    setShowProgress(tab?.show_data_progress)
  }, [])

  const onHideChange = (tab, v) => {
    setShowCount(!v)
    request('tab-progress-status', 'POST', { tab_name: tab?.name, show_count: !v, show_data_progress: showProgress, page_name: parentPageName }).then(
      (res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data
          dispatch(updatePageDataRedux(pageData))
        })
      }
    )
  }

  const onHideProgress = (tab, v) => {
    setShowProgress(!v)
    request('tab-progress-status', 'POST', { tab_name: tab?.name, show_count: showCount, show_data_progress: !v, page_name: parentPageName }).then((res) => {
      request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
        let pageData = {}
        pageData[parentPageName] = data
        dispatch(updatePageDataRedux(pageData))
      })
    })
  }

  const onEnablePrintChange = (tab, v) => {
    setEnablePrint(!v)
    request('connect-template-print-enable-disable', 'POST', { uuid: tab?.uuid, print_enabled: !v }).then((res) => {
      request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
        let pageData = {}
        pageData[parentPageName] = data
        dispatch(updatePageDataRedux(pageData))
      })
    })
  }

  return (
    <tr>
      <td>{cms(tab.cms_tab_key)}</td>
      <td>
        {tab?.is_grid ? (
          <div>
            <div
              className='switch has-switch mb-2 mr-2'
              data-on-label='ON'
              data-off-label='OFF'
              onClick={() => {
                onHideChange(tab, showCount)
              }}>
              <div
                className={cx('switch-animate', {
                  'switch-on': showCount,
                  'switch-off': !showCount,
                })}>
                <input type='checkbox' />
                <span className='switch-left bg-success'>ON</span>
                <label>&nbsp;</label>
                <span className='switch-right bg-success'>OFF</span>
              </div>
            </div>
          </div>
        ):<></>}
      </td>
      <td>
        {' '}
        <div>
          <div
            className='switch has-switch mb-2 mr-2'
            data-on-label='ON'
            data-off-label='OFF'
            onClick={() => {
              onHideProgress(tab, showProgress)
            }}>
            <div
              className={cx('switch-animate', {
                'switch-on': showProgress,
                'switch-off': !showProgress,
              })}>
              <input type='checkbox' />
              <span className='switch-left bg-success'>ON</span>
              <label>&nbsp;</label>
              <span className='switch-right bg-success'>OFF</span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div>
          <div
            className='switch has-switch mb-2 mr-2'
            data-on-label='ON'
            data-off-label='OFF'
            onClick={() => {
              onEnablePrintChange(tab, enablePrint)
            }}>
            <div
              className={cx('switch-animate', {
                'switch-on': enablePrint,
                'switch-off': !enablePrint,
              })}>
              <input type='checkbox' />
              <span className='switch-left bg-success'>ON</span>
              <label>&nbsp;</label>
              <span className='switch-right bg-success'>OFF</span>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}

const TabList = ({ tabList, source_table, parentPageName }) => {
  const dispatch = useDispatch()

  return (
    <Table striped>
      <thead>
        <th>Tab</th>
        <th>Show Count?</th>
        <th>Show Progress?</th>
        <th>Enable Print?</th>
      </thead>
      <tbody>
        {tabList?.map((item, index) => (
          <RenderTabRow tab={item} parentPageName={parentPageName} key={index} />
        ))}
      </tbody>
    </Table>
  )
}
export default TabList
