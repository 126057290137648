import { useEffect, useState } from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import { tidyName } from '../../../helper'
import IconSet from '../../icon'
import ConditionCommonField from './conditionCommonField'

const WhereClauseOR = ({
  source_table,
  select_column_option,
  isCreatingField,
  main_source_table_for_field,
  onSaveWhereClauseOR,
  whereClauseIndex,
  whereClauseForm,
  onDeleteWhereClauseOr,
}) => {
  const [openForm, setOpenForm] = useState(false)
  const [expandedRow, setExpandedRow] = useState()

  return (
    <div className='mt-3 mb-3'>
      <hr></hr>
      <div>
        <span style={{ fontSize: '1rem' }}>Add where clause OR</span>
        <Button
          className='float-right'
          onClick={() => {
            setOpenForm(!openForm)
          }}
          style={{ marginTop: '-5px' }}
          color={openForm ? 'danger' : 'success'}>
          <IconSet icon={openForm ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
        </Button>
      </div>
      {openForm && (
        <WhereClauseORForm
          source_table={source_table}
          select_column_option={select_column_option}
          isCreatingField={isCreatingField}
          main_source_table_for_field={main_source_table_for_field}
          onSaveWhereClauseOR={onSaveWhereClauseOR}
          whereClauseIndex={whereClauseIndex}
          onCloseForm={() => setOpenForm(false)}
        />
      )}
      <div className='mt-1 mb-1'>
        <WhereClauseORTable
          expandedRow={expandedRow}
          setExpandedRow={setExpandedRow}
          source_table={source_table}
          select_column_option={select_column_option}
          isCreatingField={isCreatingField}
          main_source_table_for_field={main_source_table_for_field}
          onSaveWhereClauseOR={onSaveWhereClauseOR}
          whereClauseIndex={whereClauseIndex}
          onDeleteWhereClauseOr={onDeleteWhereClauseOr}
          whereClauseForm={whereClauseForm}
        />
      </div>
      <hr></hr>
    </div>
  )
}

const WhereClauseORTable = ({
  whereClauseIndex,
  whereClauseForm,
  expandedRow,
  setExpandedRow,
  onDeleteWhereClauseOr,
  source_table,
  select_column_option,
  isCreatingField,
  main_source_table_for_field,
  onSaveWhereClauseOR,
}) => {
  return (
    <Table striped className='mt-2'>
      <thead>
        <tr>
          <th style={{ width: '1rem' }}></th>
          <th style={{ width: '5rem' }}></th>
          <th>Type</th>
          <th>Condition</th>
          <th>Field</th>
        </tr>
      </thead>
      <tbody>
        {whereClauseForm?.where_clause_or?.map((item, index) => {
          return (
            <>
              <tr key={index}>
                <td>
                  <IconSet
                    icon={index === expandedRow ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                    color='primary'
                    style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                    onClick={() => {
                      if (expandedRow === index) {
                        setExpandedRow()
                      } else {
                        setExpandedRow(index)
                      }
                    }}
                  />
                </td>
                <td>
                  <Button
                    className='btn-danger'
                    onClick={() => {
                      setExpandedRow()
                      onDeleteWhereClauseOr(whereClauseIndex, index)
                    }}>
                    <IconSet icon='pe-7s-trash' color='light' />
                  </Button>
                </td>
                <td>{tidyName(item?.type)}</td>
                <td>{item?.condition}</td>
                <td>{tidyName(item?.field)}</td>
              </tr>

              {expandedRow === index && (
                <tr>
                  <td colSpan={5}>
                    <WhereClauseORForm
                      source_table={source_table}
                      select_column_option={select_column_option}
                      isCreatingField={isCreatingField}
                      main_source_table_for_field={main_source_table_for_field}
                      onSaveWhereClauseOR={onSaveWhereClauseOR}
                      whereClauseIndex={whereClauseIndex}
                      whereClauseORIndex={index}
                      isEditing={true}
                      defaultData={item}
                      onCloseForm={() => setExpandedRow()}
                    />
                  </td>
                </tr>
              )}
            </>
          )
        })}
      </tbody>
    </Table>
  )
}

const WhereClauseORForm = ({
  source_table,
  select_column_option,
  isCreatingField,
  main_source_table_for_field,
  onSaveWhereClauseOR,
  whereClauseIndex,
  isEditing,
  defaultData,
  whereClauseORIndex,
  onCloseForm = () => {},
}) => {
  const [form, updateForm] = useState()

  useEffect(() => {
    if (isEditing) {
      updateForm(defaultData)
    }
  }, [])

  console.log('form:::::::::::::',form)
  return (
    <Row className='mt-3'>
      <ConditionCommonField
        source_table={source_table}
        columnValue={select_column_option?.filter((column) => column?.name === form?.field)[0]?.name}
        onColumnChange={(value) => updateForm({ ...form, field: value })}
        type={form?.type}
        onTypeChange={(value) => updateForm({ ...form, type: value })}
        setShowDropDown={(value) => {}}
        condition={form?.condition}
        onConditionChange={(value) => updateForm({ ...form, condition: value })}
        onValueChange={(value) => updateForm({ ...form, value })}
        value={form?.value}
        isCreatingField={isCreatingField}
        main_source_table_for_field={main_source_table_for_field}
        onChangeBetweenType={(value) => updateForm({ ...form, between_type: value })}
        between_type={form?.between_type}
        onChangeBetweenPeriod={(value) => updateForm({ ...form, between_period: value })}
        between_period={form?.between_period}
        between_value={form?.between_value}
        onChangeBetweenValue={(value) => updateForm({ ...form, between_value: value })}
      />
      <Col md={12}>
        {' '}
        <Button
          color='primary'
          className='float-left'
          onClick={() => {
            if (isEditing) {
              onSaveWhereClauseOR(form, whereClauseIndex, whereClauseORIndex, true)
            } else {
              onSaveWhereClauseOR(form, whereClauseIndex)
            }
            updateForm()
            onCloseForm()
          }}>
          Save
        </Button>
      </Col>
    </Row>
  )
}
export default WhereClauseOR
