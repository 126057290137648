import React from 'react'
import { Row, Col, Alert } from 'reactstrap'

const NotAllowed = ({ message }) => {
  return (
    <div className='grid-menu grid-menu-xl grid-menu-3col'>
      <Row className='no-gutters'>
        <Col>
          <Alert color='dark'>{message ? message : 'No data available.'}</Alert>
        </Col>
      </Row>
    </div>
  )
}
export default NotAllowed
