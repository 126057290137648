import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Button as ReactButton } from 'reactstrap'
import { cms, editCMS } from '../../../../helper'
import IconSet from '../../../icon'
import QRPrint from './qrPrint'

const getFieldValue = (value, field_type, field_id, tableOptions) => {
  if (field_type === 'dropdown') {
    const options = tableOptions[field_id]
    const selected_option = options?.filter((option) => option.value === value)
    let result = ''
    if (selected_option?.length > 0) {
      result = selected_option[0]?.label || selected_option[0]?.value
    }
    return result
  } else if (field_type === 'rich_text') {
    return <div dangerouslySetInnerHTML={{ __html: value }} style={{ flex: 1 }}></div>
  } else if (field_type === 'date' || field_type === 'date-time' || field_type === 'datetime') {
    return value && <div>{moment(value).format('D MMM YYYY h:mm a')}</div>
  } else if (field_type === 'toggle_switch' || field_type === 'switch') {
    if (value) {
      return <span className='badge badge-success'>Yes</span>
    } else {
      return <span className='badge badge-danger'>No</span>
    }
  } else {
    return value
  }
}

const RenderFormData = ({ form, row, editMode, tableOptions, cmsTabKey }) => {
  return (
    <>
      <table className='mb-2'>
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{ cursor: editMode ? 'pointer' : '' }}
              onClick={() => {
                if (editMode) {
                  editCMS(cmsTabKey)
                }
              }}>
              {cms(cmsTabKey)}
            </th>
          </tr>
        </thead>
        <tbody>
          {form?.component_call_fields?.map((field, i) => {
            return (
              <tr key={i}>
                <td
                  style={{ width: '30%', cursor: editMode ? 'pointer' : '' }}
                  onClick={() => {
                    if (editMode) {
                      editCMS(field?.cms_key)
                    }
                  }}>
                  {cms(field?.cms_key)}
                </td>
                <td>
                  {getFieldValue(row[`${field?.field_id}_label`] || row[field?.field_id], field?.field_setting?.field, field?.field_id, tableOptions)}
                </td>
              </tr>
            )
          })}
          {/* {!form?.component_call_fields && } */}
        </tbody>
      </table>
    </>
  )
}

const RenderChildTabData = ({ form, row, editMode, tableOptions, cmsTabKey }) => {
  switch (form?.component_name) {
    case 'static_form':
      return <RenderFormData form={form} row={row} editMode={editMode} tableOptions={tableOptions} cmsTabKey={cmsTabKey} />
    default:
      return
  }
}

const RenderSignleRow = ({ row, page_data, main_grid_columns, mainGrid, editMode }) => {
  const child_table = page_data?.component_call_buttons?.filter((item) => item?.component_name === 'show_tabs_on_load')
  const tableOptions = useSelector((state) => state.CMS.tableDropDown)

  return (
    <div className='gridSettigTable'>
      <table className='mb-2'>
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{ cursor: editMode ? 'pointer' : '' }}
              onClick={() => {
                if (editMode) {
                  editCMS(mainGrid?.cms_header_key)
                }
              }}>
              {cms(mainGrid?.cms_header_key)}
            </th>
          </tr>
        </thead>
        <tbody>
          {main_grid_columns?.map((col, i) => {
            return (
              <tr key={i}>
                <td
                  style={{ width: '30%', cursor: editMode ? 'pointer' : '' }}
                  onClick={() => {
                    if (editMode) {
                      editCMS(col?.cms_key)
                    }
                  }}>
                  {cms(col?.cms_key)}
                </td>
                <td>{getFieldValue(row[`${col?.field_id}_label`] || row[col?.field_id], col?.field_setting?.field, col?.field_id, tableOptions)}</td>
                {/* <td>{row[col?.field_id]}</td> */}
              </tr>
            )
          })}
        </tbody>
      </table>

      {child_table?.map((tab, i) => {
        return (
          <React.Fragment key={i}>
            {' '}
            {tab?.component_call_form?.map((form, i) => {
              return <RenderChildTabData key={i} form={form} row={row} editMode={editMode} tableOptions={tableOptions} cmsTabKey={tab?.cms_tab_key} />
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const GridPrintPage = ({ gridSelectedRow, page_data_main, mainGrid }) => {
  const location = useLocation()
  const history = useHistory()
  const pages = useSelector((state) => state.CMS.coreData.page)
  const page_data = pages[mainGrid?.name] ? pages[mainGrid?.name] : page_data_main
  const editMode = useSelector((state) => state.CMS.editMode)

  const main_grid_columns = mainGrid?.component_call_cols?.filter((col) => col?.component_name === 'text_data_col')

  const grid_column_setting = JSON.parse(mainGrid?.grid_column_setting)
  // for (let i in grid_column_setting) {
  //   if (grid_column_setting[i]['title']) {
  //     default_column_id = i
  //     break
  //   }
  // }

  const onBackToGrid = () => {
    history.replace({ pathname: location.pathname, search: '' })
  }

  let isQRPriview, default_column_id

  if (mainGrid?.name?.includes('qr_reports')) {
    isQRPriview = true
  }
  return (
    <>
      <div className='no-print'>
        <ReactButton className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`} onClick={() => window.print()}>
          <IconSet icon='fa-regular fa-print' color='light' />
        </ReactButton>
        <ReactButton className={`btn-success border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`} onClick={onBackToGrid}>
          <IconSet icon='fa-light fa-arrow-left' color='light' />
        </ReactButton>
      </div>
      {isQRPriview ? (
        <QRPrint rows={gridSelectedRow} />
      ) : (
        <div>
          {gridSelectedRow?.map((row, i) => {
            return (
              <React.Fragment key={i}>
                {console.log('default column id:::::::::', default_column_id, grid_column_setting)}
                <div style={{ fontSize: '1.2rem', marginBottom: '1rem', backgroundColor: '#ccd6c3', padding: '0.5rem 1rem', borderRadius: '5px' }}>
                  {default_column_id ? (
                    <>{row?.original[default_column_id] ? row?.original[default_column_id] : row?.original.id}</>
                  ) : (
                    <>{row?.original?.name || row?.original?.group_name || row?.original?.item_type_id_label}</>
                  )}
                </div>
                <RenderSignleRow
                  page_data={page_data}
                  mainGrid={mainGrid}
                  main_grid_columns={main_grid_columns}
                  row={row?.original}
                  editMode={editMode}
                />
                <br></br>
                <hr></hr>
                <br></br>
              </React.Fragment>
            )
          })}
        </div>
      )}
    </>
  )
}

export default GridPrintPage
