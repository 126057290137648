import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { cms, editCMS, getFromRedux, renderBlockColorClass, renderBlockColorStyle, renderBlockStyle, renderTextColorClass } from '../../helper'

const DiscussionLogs = ({ rows, pagination, setting, meta, getByUrl,  changeRowsPerPage, recPerPage }) => {
  const [rowsPerPage, updateRowsPerPage] = useState(recPerPage?.value)
  const primaryColor = useSelector((theme) => theme.ThemeOptions.primaryColor)
  const linkClassName = renderTextColorClass(primaryColor)?.split(' ')[0] + '-text'
  return (
    <div>
      {rows?.map((data, index) => {
        return (
          <div key={index} className='mb-2 p-4' style={{ borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.5)' }}>
            <div className='font-weight-bold mb-1'>Spoke to</div>
            {data?.spoke_to && <div className='mb-2'>{data?.spoke_to}</div>}
            {/* <div dangerouslySetInnerHTML={{ __html: '<div>jbnnm</div>' }}></div> */}
            <div className='font-weight-bold mb-1'>Message</div>
            {data?.message && <div dangerouslySetInnerHTML={{ __html: data?.message }} className='mb-2'></div>}
            {data?.created_at && <div>{moment(data?.created_at).format('MMMM Do YYYY, h:mm')}</div>}
            {data?.user_id_label && <div>{data?.user_id_label}</div>}
          </div>
        )
      })}
      <div className='mt-2 ReactTable'>
        {pagination && (
          <div className='pagination-bottom'>
            <div className='-pagination'>
              <div className='-previous'></div>
              {pagination.page_control && (
                <div className='-center'>
                  <span onClick={() => editCMS(setting.page_before_cms_value)}>{cms(setting.page_before_cms_value)}</span>
                  <Pagination className='mt-3 mr-2 ml-2'>
                    {meta?.links?.map((item, key) => {
                      return (
                        <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                          <PaginationLink
                            first
                            onClick={() => getByUrl(item.url)}
                            className={item.active ? renderBlockColorClass(primaryColor) : linkClassName}
                            style={item.active ? { ...renderBlockStyle(primaryColor) } : { ...renderBlockColorStyle(primaryColor) }}>
                            {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    })}
                  </Pagination>
                  {getFromRedux(pagination.redux_name) && (
                    <>
                      <span onClick={() => editCMS(setting.page_after_cms_value)}>{cms(setting.page_after_cms_value)}</span>
                      <span className='m-2'>{meta?.last_page}</span>
                      <span
                        onClick={() => editCMS(setting.page_end_cms_value)}
                        className='pl-2'
                        style={{ borderLeft: '1px solid #00000066', padding: '0.7rem' }}>
                        {cms(setting.page_end_cms_value)}
                      </span>
                      <span className='select-wrap -pageSizeOptions'>
                        <select
                          aria-label='rows per page'
                          selected={rowsPerPage}
                          value={rowsPerPage}
                          onChange={(e) => {
                            changeRowsPerPage(e.target.value)
                            updateRowsPerPage(e.target.value)
                          }}>
                          {getFromRedux(pagination.redux_name).map((item, key) => {
                            return (
                              <option key={key} value={item.value}>
                                {item.value} Rows
                              </option>
                            )
                          })}
                        </select>
                      </span>
                    </>
                  )}
                </div>
              )}
              <div className='-next'></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DiscussionLogs
