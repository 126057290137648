import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, Row } from 'reactstrap'

import { cms, editCMS } from '../../helper'
import PageTitle from '../../Layout/AppMain/pageTitle'

const select = (state) => ({
  coreData: state.CMS.coreData,
})

const CMSGrid = ({ allCols, coreData, dataConnection, isModel }) => {
  const dispatch = useDispatch()
  const [cols, updateCols] = useState(allCols)

  const renderCols = (col, key) => {}

  useEffect(() => {
    // updateFields(allFields || []))
  }, [coreData])

  return (
    <Fragment>
      <Row form>
        {cols?.map((itemCol, key) => {
          return (
            <React.Fragment key={key}>
              <PageTitle
                onTitleClick={() => editCMS(itemCol.cms_header_name)}
                onSubTitleClick={() => editCMS(itemCol.cms_sub_header_name)}
                heading={cms(itemCol.cms_header_name)}
                subheading={cms(itemCol.cms_sub_header_name)}
                // icon={icon}
                hideBreadCrumbs
              />
              <Col md={6} key={key} style={{ display: itemCol.hidden === 1 ? 'none' : 'block' }}>
                {renderCols(itemCol, key)}
              </Col>
            </React.Fragment>
          )
        })}
      </Row>
    </Fragment>
  )
}
export default connect(select)(CMSGrid)
