import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button } from 'reactstrap'
import IconSet from '../../../CMSComponent/icon'
import { tidyName } from '../../../helper'
import { setQueue } from '../../../Redux/Reducers/queue'
import { request } from '../../../Redux/Sagas/requests/api'
import {
  addUserPermissionInReduxById,
  deleteUserPermissionInReduxById,
  getUserPermissionByIdFromRedux,
  setUserPermissionInReduxById,
} from '../../../Services/Helper/userPermissionHelper'

const UserPermission = () => {
  const permissions = useSelector((state) => state.Permissions)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const permissionsFromRedux = useSelector((state) => state.CMS.permissionByUserId)

  const dispatch = useDispatch()

  const [permissionsOptions, setPermissionsOptions] = useState([])
  const [permissionLoading, setPermissionLoading] = useState(false)
  const [userPermissions, setUserPermissions] = useState([])
  const [selectedOption, setSelectedOption] = useState()

  const currentUserId = currentRow?.[currentRow?.length - 1]?.id

  useEffect(() => {
    if (currentRow && currentRow?.length > 0) {
      if (currentUserId && getUserPermissionByIdFromRedux(currentUserId)) {
        const data = getUserPermissionByIdFromRedux(currentUserId)
        setUserPermissions(data)
      } else {
        setPermissionLoading(true)
        request('get-user-permissions', 'POST', { parent_row_id: currentRow?.[currentRow?.length - 1]?.id }).then((res) => {
          setUserPermissions(res?.data)
          setUserPermissionInReduxById(currentUserId, res?.data)
          setPermissionLoading(false)
        })
      }
    }
  }, [currentRow?.length, permissionsFromRedux])

  useEffect(() => {
    const options = permissions?.map((item) => {
      return {
        value: item,
        label: tidyName(item),
      }
    })
    setPermissionsOptions(options)
  }, [permissions?.length])

  const onAddPermission = () => {
    addUserPermissionInReduxById(currentUserId, selectedOption)
    setSelectedOption(null)
    const data = {
      parent_row_id: currentRow[currentRow?.length - 1]?.id,
      data: [{ name: selectedOption, type: 'add' }],
    }
    dispatch(
      setQueue({
        url: 'update-user-permissions',
        payload: { data },
      })
    )
    // request('update-user-permissions', 'POST',).then((res) => {
    //   setPermissionLoading(true)
    //   setSelectedOption(null)
    //   request('get-user-permissions', 'POST', { parent_row_id: currentRow?.[currentRow?.length - 1]?.id }).then((res) => {
    //     setUserPermissions(res?.data)
    //     setPermissionLoading(false)
    //   })
    // })
  }

  const onDeletePermission = (permission) => {
    deleteUserPermissionInReduxById(currentUserId, permission)
    const data = {
      parent_row_id: currentRow[currentRow?.length - 1]?.id,
      data: [{ name: permission, type: 'remove' }],
    }
    dispatch(
      setQueue({
        url: 'update-user-permissions',
        payload: { data },
      })
    )
    // request('update-user-permissions', 'POST', {
    //   parent_row_id: currentRow[currentRow?.length - 1]?.id,
    //   data: [{ name: permission, type: 'remove' }],
    // }).then((res) => {
    //   setPermissionLoading(true)

    //   request('get-user-permissions', 'POST', { parent_row_id: currentRow?.[currentRow?.length - 1]?.id }).then((res) => {
    //     setUserPermissions(res?.data)
    //     setPermissionLoading(false)
    //   })
    // })
  }
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
        <div style={{ flex: 1 }}>
          <Select
            options={permissionsOptions}
            id={'second-column'}
            onChange={(e) => setSelectedOption(e.value)}
            // value={selectedSecondOption}
            styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
            // isDisabled={!inlineEditActive}
            placeholder='Select permission'
            // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
            // isSearchable={ inlineEditActive }
            // menuIsOpen={inlineEditActive}
            // {...props}
          />
        </div>

        <div>
          <Button color='primary' style={{ marginLeft: '1rem' }} disabled={!selectedOption} onClick={() => onAddPermission()}>
            Add
          </Button>
        </div>
      </div>

      {permissionLoading && (
        <div style={{ textAlign: 'center', marginTop: '3rem' }}>
          <Loader type='ball-pulse-rise' color='blue' />
        </div>
      )}
      {!permissionLoading && userPermissions?.length === 0 && (
        <div style={{ fontSize: '1.3rem', textAlign: 'center', marginTop: '1rem' }}>Permission not added!</div>
      )}

      {!permissionLoading && (
        <div className='permission'>
          <table className='table'>
            <thead className='thead-light'>
              <tr>
                <th style={{ width: '5rem' }}></th>
                <th>Permission</th>
              </tr>
            </thead>
            <tbody>
              {userPermissions?.map((permission, key) => {
                return <RenderRow key={key} permission={permission} onDeletePermission={onDeletePermission} />
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

const RenderRow = ({ permission, onDeletePermission }) => {
  return (
    <tr>
      <td style={{ textAlign: 'center' }}>
        {' '}
        <Button className='btn-danger' onClick={() => onDeletePermission(permission)}>
          <IconSet icon='pe-7s-trash' color='light' />
        </Button>
      </td>
      <td>{tidyName(permission)}</td>
    </tr>
  )
}

export default UserPermission
