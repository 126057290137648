export const getTemplateGroupLocationOptions = (data) => {
  const options = data?.template_group_locations?.map((item) => {
    return {
      value: item?.location?.id || item?.location_id,
      label: item?.location?.name || item?.location_id_label,
    }
  })

  return options
}

export const getItemGroupLocationOptions = (templateGroups) => {
  const options = templateGroups?.item_groups?.template_group_locations
    ?.map((item) => {
      if (!item?.location?.id || !item?.location?.name) return null
      return {
        value: item?.location?.id,
        label: item?.location?.name || item?.location_name || item?.location?.id,
      }
    })
    ?.filter((item) => item)
  return options
}

export const getDependDataPayload = (allFields, data_connection, value) => {
  let payload = {
    field_data: data_connection?.data,
    source_field: data_connection?.source_field,
    depend_field_value: typeof value === 'number' ? value : value.value,
  }

  allFields?.forEach((field) => {
    if (field?.field_id === data_connection?.source_field) {
      if (field?.component_call_data_connection?.source_table) {
        payload = {
          ...payload,
          depend_field_source_table: field?.component_call_data_connection?.source_table,
        }
      }
    }
  })
  return payload
}

export const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else if (!compareValue && !formValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

export const getDropdownOptionsToUpdateInRedux = (
  dropdownOptionsByPageRedux,
  parentPageName,
  field_id,
  source_table,
  options,
  paginationData,
  hasMore,
  optionsData
) => {
  let dropdownOptionsByPageReduxToUpdate = { ...dropdownOptionsByPageRedux }
  dropdownOptionsByPageReduxToUpdate[parentPageName] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table] = {
    ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table],
  }

  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table] = {
    ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table],

    // options: [...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id]['options'], ...options],
    paginationData: paginationData,
    hasMore: hasMore,
  }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['updatedAt'] = new Date().toLocaleString('en-GB', {
    timeZone: 'Europe/London',
  })
  if (dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options']) {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'] = [
      ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'],
      ...options,
    ]
  } else {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'] = [...options]
  }
  if (dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data']) {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data'] = [
      ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data'],
      ...optionsData,
    ]
  } else {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data'] = [...optionsData]
  }

  return dropdownOptionsByPageReduxToUpdate
}

export const getDropdownOptionsToUpdateInReduxAfterClearCurrentDropdown = (dropdownOptionsByPageRedux, parentPageName, field_id, source_table) => {
  let dropdownOptionsByPageReduxToUpdate = { ...dropdownOptionsByPageRedux }
  dropdownOptionsByPageReduxToUpdate[parentPageName] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table] = {
    ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table],
  }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'] = []
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['paginationData'] = {}
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['hasMore'] = true
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['updatedAt'] = null
  return dropdownOptionsByPageReduxToUpdate
}

export const checkScoreFieldVisible = (formFieldValue) => {
  const quetionType = ['select', 'radio', 'signiture_select']
  if (formFieldValue?.type && quetionType?.includes(formFieldValue?.type)) {
    return true
  } else {
    return false
  }
}

export const checkScoreAnswerFieldVisible = (parentForm) => {
  if (!parentForm?.score_grade || parentForm?.score_grade === 'none') {
    return false
  } else {
    return true
  }
}



export const getLocationForTemplateSchedule = (data) =>{
  let options = []

  data?.template_group_locations?.map((item)=>{
    options?.push({
      value: item?.location_id || item?.location?.id,
      label: item?.location_id_label || item?.location?.name
    })
  })
  return options

}