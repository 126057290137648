const Spacer = ({data})=>{

    const field_setting = data?.field_setting
    let format = data?.format
    if (format) {
      format = JSON.parse(format)
    }
  return <div style={{minHeight:`${format?.height || 5}px`}}></div>
};

export default Spacer