import { useState } from 'react'
import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { CreatePageForm } from './createPage'
import PageTitle from '../../../Layout/AppMain/pageTitle'
import { cms } from '../../../helper'

const AddNewTabOnPage = ({ parentPageName, page, reload = () => {} }) => {
  const [modal, setModal] = useState(false)
  return (
    <>
      <Button onClick={() => setModal(true)} color='link' className='edit-form-page-button no-print'>
        Add New Tab Here
        <Modal isOpen={modal} toggle={() => setModal(!modal)} style={{ width: 1500 }} scrollable={true}>
          <ModalHeader toggle={() => setModal(!modal)}>Add New Tab</ModalHeader>
          <ModalBody>
            <PageTitle
              enablePageTitleIcon
              hideBreadCrumbs
              heading='Add New Tab'
              subheading={'This new tab will be showen after ' + cms(page?.cms_header_name) + ' tab.'}
              icon='pe-7s-graph icon-gradient bg-ripe-malin'
            />
            <div className='m-4'>
              <CreatePageForm
                tab_near_to={parentPageName}
                close={() => {
                  setModal(false)
                  reload()
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </Button>
    </>
  )
}

export default AddNewTabOnPage
