import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'

import FormToggleSwitch from '../../../Forms/Fields/switchButton'
import { fieldCreatedStatus, setTableDropDown, setTableDropDownFullData, submitRequest, updatePageDataRedux } from '../../../../Redux/Reducers/CMS'
import { cms, tidyName } from '../../../../helper'
import AddCustomColumn from './addCustomColumn'
import WhereClause from '../../Grid/whereClause'
import AddDependentField from './addDependentField'
import { request } from '../../../../Redux/Sagas/requests/api'

const RenderDependentData = ({ selectedField, source_table, updateForm, form }) => {
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const services = useSelector((state) => state?.CMS?.coreData?.services)
  let options = []

  if (selectedField?.component_call_data_connection?.component_name === 'template_data') {
    const methods = grid_selector[selectedField?.component_call_data_connection?.source_table]?.methods?.filter(
      (method) => method?.data_driven === true
    )
    options = methods
  }

  if (selectedField?.component_call_data_connection?.component_name === 'redux_data') {
    let new_options = []
    Object.keys(services[0])?.map((key) => {
      if (Array.isArray(services[0][key])) {
        let optionsFromKeys = services[0][key]?.map((item) => {
          return { name: item }
        })
        new_options = [...new_options, ...optionsFromKeys]
      }
    })
    options = new_options
  }
  return (
    <AvField
      type='select'
      name='dependent_data'
      label='Dependent Data'
      value={form?.with}
      onChange={(e) => updateForm({ ...form, with: e.target.value })}>
      <option>Select field</option>
      {options?.map((option, i) => {
        return (
          <option value={option?.name} key={i}>
            {tidyName(option?.name)}
          </option>
        )
      })}
    </AvField>
  )
}

const defaultForm = {
  name: '',
  type: 'dropdown',
  field_id: '',
  redux_data_name: '',
  redux_data_id: '',
  redux_data_value: '',
  manage_data_database: '',
  manage_data_field: '',
  manage_data_icon: '',
  manage_data_default_header: '',
  api_submit: '',
  validations: [],
  options: [],
  addedOptions: [],
  col: 6,
  title: '',
  searchable: false,
  is_multi_select: false,
  print: true,
  export: true,
  data_type: '',
  manage_table_name: '',
  toggle_field: false,
  toggle_field_name: '',
  dependent_field: '',
  with: '',
  show_add_item: true,
  default_options: [],
}
const validations = ['required', 'string', 'max_50', 'max_100', 'email', 'min_5', 'confirm_password', 'numericr']

const Dropdown = ({
  coreData,
  hasReduxData,
  manageDatabaseValidate,
  formName,
  pageName,
  reduxDataValidate,
  setModal,
  field = null,
  setAddForm,
  tab = false,
  section,
  source_table,
  is_create,
  type = 'dropdown',
  isLayout,
  blockIndex,
  rowIndex,
  allFields = [],
  blockFields,
  isCreatingFieldForRepeatingForm,
  grid_id,
  parentPageName,
  isAddDataForm,
  order,
  selectedField,
}) => {
  const [form, updateForm] = useState(defaultForm)
  const [reduxDataId, upateReduxDataId] = useState([])
  const [whereClauseForm, updateWhereClauseForm] = useState([])

  const [reduxDataValue, upateReduxDataValue] = useState([])
  const [load, updateLoad] = useState(false)
  const dispatch = useDispatch()
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const pages = useSelector((state) => state.CMS.coreData.page)

  const [dependentFormData, updateDependentFormData] = useState([])

  useEffect(() => {
    updateForm({ ...form, type: type })
  }, [type])

  useEffect(() => {
    if (field) {
      const validationValueFromDb = field.component_call_validation?.map((item) => item?.validation_type)

      let component_call_data_connection

      if (field?.component_call_data_connection) {
        component_call_data_connection = field?.component_call_data_connection
      } else if (field?.field_setting?.component_call_data_connection) {
        component_call_data_connection = field?.field_setting?.component_call_data_connection
      }

      let redux_name, redux_id, redux_value, data_type, table, data_label, data_value
      if (component_call_data_connection) {
        redux_id = component_call_data_connection.data
        redux_value = component_call_data_connection.value
        redux_name = component_call_data_connection.redux_data
        data_type = component_call_data_connection?.component_type
        table = component_call_data_connection?.source_table
      }
      let isShow
      if (field?.field_id === 'test_dropdown_database') {
        isShow = true
      } else {
        isShow = false
      }
      if (component_call_data_connection?.fields && component_call_data_connection?.fields.length > 0) {
        const field_array = component_call_data_connection?.fields?.split(',')

        if (field_array && field_array?.length == 2) {
          const grid_selector_column_label = grid_selector[table]?.columns?.filter((item) => item?.name === field_array[1])
          const grid_selector_column_value = grid_selector[table]?.columns?.filter((item) => item?.name === field_array[0])

          if (grid_selector_column_label && grid_selector_column_label?.length > 0) {
            data_label = grid_selector_column_label[0]?.name
          }
          if (field_array && field_array?.length > 0 && field_array[1] === 'id') {
            data_label = 'id'
          }
          if (grid_selector_column_value && grid_selector_column_value?.length > 0) {
            data_value = grid_selector_column_value[0]?.name
          }
          if (field_array && field_array?.length > 0 && field_array[0] === 'id') {
            data_value = 'id'
          }
        }
        if (field_array && field_array?.length == 1) {
          const grid_selector_column_label = grid_selector[table]?.columns?.filter((item) => item?.name === field_array[0])
          // const grid_selector_column_value = grid_selector[table]?.columns?.filter((item) => item?.name === field_array[0])

          if (grid_selector_column_label && grid_selector_column_label?.length > 0) {
            data_label = grid_selector_column_label[0]?.name
          }
          if (field_array && field_array?.length > 0 && field_array[1] === 'id') {
            data_label = 'id'
          }
          data_value = 'id'
        }
      }

      let searchable, is_multi_select
      if (field?.format) {
        searchable = field?.format?.searchable
        is_multi_select = field?.format?.is_multi_select
      }
      const field_setting = form?.field_setting

      const toggle_field = field?.component_call_fields?.filter(
        (field) => field?.component_call_actions && field?.component_call_actions?.length === 2
      )

      let toggle_field_detail = { available: false, id: '' }
      if (toggle_field && toggle_field?.length > 0) {
        toggle_field[0]?.component_call_actions?.map((field) => {
          if (field?.hide_field) {
            toggle_field_detail = {
              available: true,
              id: field?.field_id,
            }
          }
        })
      }
      let icon = ''
      let manageDataPageName = ''
      if (field?.component_call_fields && field?.component_call_fields?.length > 0) {
        field.component_call_fields.map((field) => {
          field.component_call_actions?.map((action) => {
            if (action?.component_name === 'open_modal') {
              icon = pages[action.page_name] ? pages[action.page_name].icon : ''
              manageDataPageName = pages[action.page_name] ? cms(pages[action.page_name].cms_header_name) : ''
            }
          })
        })
      }

      updateDependentFormData(field?.field_setting?.depend_fields)
      updateForm({
        ...form,
        field_id: field.field_id,
        print: field_setting?.print,
        export: field_setting?.export,
        title: cms(field.cms_key),
        col: field_setting?.col,
        addedOptions: field.custom_options,
        options: field.custom_options,
        validations: validationValueFromDb,
        redux_data_id: redux_id,
        redux_data_name: redux_name,
        redux_data_value: redux_value,
        searchable: searchable,
        is_multi_select: is_multi_select,
        data_type:
          data_type === 'data_connection'
            ? component_call_data_connection?.component_name === 'dependent_data'
              ? 'dependent'
              : component_call_data_connection?.component_name === 'manual'
              ? 'manual'
              : 'database'
            : data_type,
        dependent_field:
          component_call_data_connection?.component_name === 'dependent_data' ? field?.component_call_data_connection?.source_field : '',
        with: component_call_data_connection?.component_name === 'dependent_data' ? field?.component_call_data_connection?.data : '',
        table: table,
        data_label: data_label,
        data_value: data_value,
        toggle_field_name: toggle_field_detail?.id,
        toggle_field: toggle_field_detail?.available,
        manage_table_icon: icon,
        manage_table_default_header: manageDataPageName,
        col: field?.col,
      })

      if (component_call_data_connection?.clause_data && component_call_data_connection?.clause_data?.length > 0) {
        updateWhereClauseForm(component_call_data_connection?.clause_data)
      }
    }
  }, [])

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    let data = {
      ...values,
      form_name: formName,
      page_name: pageName,
      validations: form.validations,
      print: form?.print,
      export: form?.export,
      type: type,
      toggle_field_name: form?.toggle_field_name,
      toggle_field: form?.toggle_field,
      searchable: form?.searchable,
      dependent_field: form?.dependent_field,
      with: form?.with,
      format: {
        is_multi_select: form?.is_multi_select,
        searchable: form?.searchable,
        size: form?.col,
      },
      depend_fields: dependentFormData,
      show_add_item: form?.show_add_item,
      parent_page_name: parentPageName ? parentPageName : window.location.hash.split('/')[1]?.split('-')[0],
      order,
    }
    if (whereClauseForm?.length > 0) {
      data['where_clause'] = whereClauseForm
    }
    //We allow option attribute in below array fields
    const fieldTypes = ['multi_checkbox', 'multi_select', 'multi_radio']

    // pass an options attribute, if type is available in  fieldTypes
    if (fieldTypes.includes(values.type) === true) {
      data.custom_options = form.options
    }
    if (values.type === 'dropdown') {
      data['searchable'] = form.searchable
      data['is_multi_select'] = form.is_multi_select
    }
    if (tab) {
      data = {
        ...data,
        tab: true,
        section: section,
      }
    }
    if (isLayout) {
      data = {
        ...data,
        is_layout: true,
        row_index: rowIndex,
        block_index: blockIndex,
      }
    }
    if (is_create && isLayout) {
      data = {
        ...data,
        order: blockFields ? blockFields?.length + 1 : 0,
      }
    }
    if (source_table) {
      data = { ...data, form_source_table: source_table }
    }
    if (isAddDataForm) {
      data = {
        ...data,

        grid_id: grid_id,
      }
    }
    dispatch(fieldCreatedStatus(false))
    if (isCreatingFieldForRepeatingForm) {
      const { form_name, ...rest } = data

      const payload = {
        ...rest,
        grid_id: grid_id,
        source_table: source_table,
        page_name: pageName,
      }
      request(`submit-form-field`, 'POST', payload).then((data) => {
        if (parentPageName) {
          request(`get-page/${parentPageName}`, 'GET').then((data) => {
            let pageData = {}
            if (data.status === 200) {
              pageData[parentPageName] = data.data

              dispatch(updatePageDataRedux(pageData))
            }

            // dispatch(setLoader(false))
          })
        }
      })

      // dispatch(submitRequest({ url: 'submit-form-field', data: payload }))
    } else {
      request(`submit-form-field`, 'POST', data).then((data) => {
        if (parentPageName) {
          request(`get-page/${parentPageName}`, 'GET').then((data) => {
            let pageData = {}
            if (data.status === 200) {
              pageData[parentPageName] = data.data

              dispatch(updatePageDataRedux(pageData))
            }

            // dispatch(setLoader(false))
          })
        }
      })
      // dispatch(submitRequest({ url: 'submit-form-field', data }))
    }
    dispatch(setTableDropDown({}))
    dispatch(setTableDropDownFullData({}))
    setTimeout(() => {
      dispatch(fieldCreatedStatus(true))
    }, 1000)

    // dispatch(submitRequest({ url: 'submit-form-field', data }))
    setModal(false)
    setAddForm(false)
    updateForm(defaultForm)
  }

  useEffect(() => {
    if (form?.redux_data_name) {
      const list = coreData[form?.redux_data_name]
      let listOfKeys = []
      if (list && list.length > 0) {
        listOfKeys = Object.keys(list[0])
      }
      upateReduxDataValue(listOfKeys)
      upateReduxDataId(listOfKeys)
    }
  }, [form?.redux_data_name])

  const onDefaultOptionAdd = (option) => {
    const options = [...form?.default_options]
    options?.push(option)
    updateForm({ ...form, default_options: options })
  }

  // useEffect(() => {
  //   const list = coreData[form?.redux_data_name]
  //   let listOfKeys = []
  //   if (list && list.length > 0) {
  //     listOfKeys = Object.keys(list[0])
  //   }
  //   upateReduxDataValue(listOfKeys)
  //   upateReduxDataId(listOfKeys)
  // }, [form?.redux_data_name])

  return (
    <>
      <AvForm onValidSubmit={handleValidSubmit} model={form}>
        <Row>
          {tab && is_create ? (
            <Col md={6}>
              <AddCustomColumn
                source_table={source_table}
                value={form.field_id}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
                selectedField={selectedField}
              />
            </Col>
          ) : (
            <Col md={6}>
              <AvField
                name='field_id'
                label='Field ID'
                type='text'
                required
                value={form.field_id}
                disabled={is_create ? false : true}
                validate={{
                  pattern: {
                    value: '^[A-Za-z0-9_]+$',
                    errorMessage: 'Your name must be composed only with letter and numbers',
                  },
                }}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
              />
            </Col>
          )}

          {form.type === 'dropdown' && (
            <Col md={6}>
              <label>Validations</label>
              <Multiselect
                data={validations}
                value={form.validations}
                onChange={(value) => updateForm({ ...form, validations: value })}
                textField='validations'
              />
              <AvField type='hidden' value={form?.default_options} name='default_option' />
            </Col>
          )}
          {/* {form.type !== "chart_pill" && (
            <Col md={6}>
              <label>Validations</label>
              <Multiselect
                data={validations}
                value={form.validations}
                onChange={(value) =>
                  updateForm({ ...form, validations: value })
                }
                textField="validations"
              />
            </Col>
          )} */}

          {form?.type === 'dropdown' && (
            <>
              <Col md={3}>
                <label>Is Searchable?</label>
                <FormToggleSwitch onChange={(data) => updateForm({ ...form, searchable: data })} value={form.searchable} />
              </Col>
              <Col md={3}>
                <label>Is Multi Select?</label>
                <FormToggleSwitch value={form.is_multi_select} onChange={(toggle_data) => updateForm({ ...form, is_multi_select: toggle_data })} />
              </Col>
            </>
          )}
          <Col md={2}>
            <label>Hide from Grid</label>
            <FormToggleSwitch value={form.hide_from_grid} onChange={(toggle_data) => updateForm({ ...form, hide_from_grid: toggle_data })} />
          </Col>
          <Col md={2}>
            <label>Print</label>
            <FormToggleSwitch value={form.print} onChange={(toggle_data) => updateForm({ ...form, print: toggle_data })} />
          </Col>
          <Col md={2}>
            <label>Export</label>
            <FormToggleSwitch value={form.export} onChange={(toggle_data) => updateForm({ ...form, export: toggle_data })} />
          </Col>
          <Col md={6}>
            <AvField
              name='title'
              label='title'
              type='text'
              value={form?.title}
              onChange={(e) => updateForm({ ...form, title: e.target.value })}></AvField>
          </Col>
          {!isLayout && (
            <Col md={6}>
              <AvField type='select' name='col' label='Column' value={form?.col} onChange={(e) => updateForm({ ...form, col: e.target.value })}>
                {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  )
                })}
              </AvField>
            </Col>
          )}

          <Col md={6}>
            <AvField
              type='select'
              name='data_type'
              label='Data type'
              value={form?.data_type}
              onChange={(e) => updateForm({ ...form, data_type: e.target.value })}>
              <option value=''>Select Data Type</option>
              {['redux', 'database', 'dependent']?.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {tidyName(item)}
                  </option>
                )
              })}
            </AvField>
          </Col>

          <Col md={12}>
            <AddDependentField
              allFields={allFields}
              updateDependentFormData={updateDependentFormData}
              dependentFormData={dependentFormData}
              field={field}
            />
          </Col>

          {form?.data_type?.length > 0 && (
            <Col md={12}>
              <hr />
              Manage Data.
              <hr />
            </Col>
          )}

          {form?.data_type === 'manual' && (
            <Col md={6} style={{ marginBottom: '1rem' }}>
              <label>Values</label>
              <Multiselect
                // allowCreate={true}
                // open={false}
                onChange={(e) => updateForm({ ...form, default_options: e })}
                allowCreate={true}
                onCreate={(name) => onDefaultOptionAdd(name)}
                data={[]}
                value={[...form?.default_options]}
                // onChange={(value) => updateForm({ ...form, validations: value })}
                textField='validations'
              />
            </Col>
          )}

          {form?.data_type === 'redux' && (
            <>
              <Col md={4}>
                <AvField
                  type='select'
                  label='Data Name'
                  name='redux_data_name'
                  value={form.redux_data_name}
                  onChange={(e) => {
                    updateForm({ ...form, redux_data_name: e.target.value })
                  }}>
                  <option value=''>Select Redux Data</option>
                  {hasReduxData?.map((item, key) => (
                    <option key={key} value={item}>
                      {tidyName(item)}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col md={4}>
                <AvField
                  type='select'
                  label='Data ID'
                  name='redux_data_id'
                  validate={{ reduxData: reduxDataValidate }}
                  value={form.redux_data_id}
                  onChange={(e) => updateForm({ ...form, redux_data_id: e.target.value })}>
                  <option value=''>Select Redux Data ID</option>
                  {reduxDataId.map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col md={4}>
                <AvField
                  type='select'
                  label='Data Value'
                  name='redux_data_value'
                  validate={{ reduxData: reduxDataValidate }}
                  value={form.redux_data_value}
                  onChange={(e) => updateForm({ ...form, redux_data_value: e.target.value })}>
                  <option value=''>Select Redux Data Value</option>
                  {reduxDataValue.map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </AvField>
              </Col>
            </>
          )}

          {form?.data_type === 'database' && (
            <>
              <Col md={6}>
                <AvField
                  type='select'
                  label='Table'
                  name={'table'}
                  value={form?.table}
                  required
                  onChange={(e) => updateForm({ ...form, table: e.target.value })}>
                  <option value=''>Select Table</option>
                  {Object.keys(grid_selector).map(function (key, index) {
                    return (
                      <option value={key} key={key}>
                        {tidyName(key)}
                      </option>
                    )
                  })}
                </AvField>
              </Col>
              {/* <Col md={6}>
                <AvField
                  name='manage_table_name'
                  label='Table Name'
                  type='text'
                  required
                  value={form.manage_table_name}
                  validate={{
                    pattern: {
                      value: '^[A-Za-z0-9_]+$',
                      errorMessage: 'Your name must be composed only with letter and numbers',
                    },
                  }}
                  onChange={(e) => updateForm({ ...form, manage_table_name: e.target.value })}
                />
              </Col> */}{' '}
              <Col md={6}>
                <AvField
                  name='manage_table_default_header'
                  label='Manage Table Default Header'
                  type='text'
                  value={form.manage_table_default_header}
                />
              </Col>
              {/* <Col md={6}>
                <AvField name='manage_table_icon' label='Manage Table Icon' type='text' value={form?.manage_table_icon} />
              </Col> */}
              <Col md={6}>
                <AvField
                  type='select'
                  label='Data Label'
                  name={'data_label'}
                  value={form?.data_label}
                  required
                  onChange={(e) => updateForm({ ...form, data_label: e.target.value })}>
                  <option value=''>Select Data Label</option>
                  <option value='id'>Id</option>
                  {grid_selector[form?.table]?.columns?.map(function (item, index) {
                    return (
                      <option value={item?.name} key={index}>
                        {tidyName(item?.name)}
                      </option>
                    )
                  })}
                </AvField>
              </Col>
              <Col md={6}>
                <AvField
                  type='select'
                  label='Data Value'
                  name={'data_value'}
                  value={form?.data_value}
                  required
                  onChange={(e) => updateForm({ ...form, data_value: e.target.value })}>
                  <option value=''>Select Data Label</option>
                  <option value='id'>Id</option>
                  {grid_selector[form?.table]?.columns?.map(function (item, index) {
                    return (
                      <option value={item?.name} key={index}>
                        {tidyName(item?.title) || tidyName(item?.name)}
                      </option>
                    )
                  })}
                </AvField>
              </Col>
              <Col md={6}>
                <div style={{ marginBottom: '1rem' }}>TOGGLE FIELD</div>
                <div
                  className='switch has-switch mb-2 mr-2'
                  data-on-label='ON'
                  data-off-label='OFF'
                  onClick={() => updateForm({ ...form, toggle_field: !form?.toggle_field })}>
                  <div
                    className={cx('switch-animate', {
                      'switch-on': form?.toggle_field,
                      'switch-off': !form?.toggle_field,
                    })}>
                    <input type='checkbox' />
                    <span className='switch-left bg-success'>ON</span>
                    <label>&nbsp;</label>
                    <span className='switch-right bg-success'>OFF</span>
                  </div>
                </div>
              </Col>
              {form?.toggle_field && (
                <Col md={6}>
                  <AvField
                    name='toggle_field_name'
                    label='Toggle Field Name'
                    type='text'
                    required
                    value={form?.toggle_field_name}
                    validate={{
                      pattern: {
                        value: '^[A-Za-z0-9_]+$',
                        errorMessage: 'Name must be composed only with letter and numbers',
                      },
                    }}
                    onChange={(e) => updateForm({ ...form, toggle_field_name: e.target.value })}
                  />
                </Col>
              )}
              <Col md={6}>
                <div style={{ marginBottom: '1rem' }}>ADD ITEM BUTTON</div>
                <div
                  className='switch has-switch mb-2 mr-2'
                  data-on-label='ON'
                  data-off-label='OFF'
                  onClick={() => updateForm({ ...form, show_add_item: !form?.show_add_item })}>
                  <div
                    className={cx('switch-animate', {
                      'switch-on': form?.show_add_item,
                      'switch-off': !form?.show_add_item,
                    })}>
                    <input type='checkbox' />
                    <span className='switch-left bg-success'>ON</span>
                    <label>&nbsp;</label>
                    <span className='switch-right bg-success'>OFF</span>
                  </div>
                </div>
              </Col>
              {form?.table?.length > 0 && (
                <Col md={12}>
                  <WhereClause
                    source_table={form?.table}
                    onChange={updateWhereClauseForm}
                    form={whereClauseForm}
                    isCreatingField={true}
                    main_source_table_for_field={source_table}
                    isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
                  />
                </Col>
              )}
            </>
          )}

          {form.data_type === 'dependent' && (
            <Col md={6}>
              <AvField
                type='select'
                label='Dependent Field'
                name={'dependent_field'}
                onChange={(e) => updateForm({ ...form, dependent_field: e.target.value })}>
                <option value=''>Select Dependent Field</option>
                {allFields?.map((field, i) => {
                  if (field?.component_call_data_connection) {
                    return (
                      <option key={i} value={field?.field_id}>
                        {cms(field?.cms_key)}
                      </option>
                    )
                  }
                })}
              </AvField>
            </Col>
          )}

          {form?.data_type === 'dependent' &&
            allFields?.filter((field) => field?.field_id === form?.dependent_field) &&
            allFields?.filter((field) => field?.field_id === form?.dependent_field)?.length > 0 &&
            allFields?.filter((field) => field?.field_id === form?.dependent_field)[0]?.component_call_data_connection && (
              <RenderDependentData
                selectedField={allFields?.filter((field) => field?.field_id === form?.dependent_field)[0]}
                source_table={source_table}
                updateForm={updateForm}
                form={form}
              />
            )}

          <Col md={12}>
            <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default Dropdown
