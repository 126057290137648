import moment from 'moment'
import { useSelector } from 'react-redux'
import BarChart from '../../../../../CMSComponent/Common/Chart/barChart'
import { ChartDatasetOptions, ChartOptions } from '../../../../../CMSComponent/Common/Chart/chartHelper'
import { cms, diffMonthsBetweenDates, editCMS } from '../../../../../helper'
import { DASHBOARD_DUMMY_DATA } from '../../dummy-data'

const total_months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const DailyARTimeByMonth = ({ dashboardData, startDate, endDate }) => {
  const dashboard_cms_key = useSelector((state) => state.CMS?.dashboardCMSKey?.ar_time_months?.cms_key_heading)
  const editMode = useSelector((state) => state.CMS?.editMode)
  const completedByTaskTypeData = dashboardData?.ar_time_months
  // let label = diffMonthsBetweenDates(startDate, endDate)

  let label = completedByTaskTypeData?.map((item) => item?.month)
  let value = completedByTaskTypeData?.map((item) => item.completed_minute_duration)
  // let label = total_months?.slice(0,new Date().getMonth()+1)
  // let value_hr_under = label?.map((month) => {
  //   let value
  //   completedByTaskTypeData?.map((data) => {
  //     if (new Date(data['month']).getMonth() === new Date(month)?.getMonth()) {
  //       value = data?.completed_minute_duration
  //     }
  //   })
  //   return value
  // })
  // let value_hr_more = completedByTaskTypeData?.map((item) => {
  //   if (item?.completed_minute_duration > 60) {
  //     return item?.completed_minute_duration
  //   }
  // })

  let colors = completedByTaskTypeData?.map((item) => {
    if (item?.completed_minute_duration <= 60) {
      return 'rgba(255, 159, 64, 0.2)'
    } else {
      return 'rgba(54, 162, 235, 0.2)'
    }
  })

  const options = {
    ...ChartOptions,
    plugins: {
      ...ChartOptions?.plugins,
    },
    scales: {
      ...ChartOptions.scales,
      y: {
        ...ChartOptions.scales.y,
        title: {
          display: true,
          text: 'MINUTES',
          color: 'black',
        },
      },
      x: {
        ...ChartOptions.scales.x,
        title: {
          display: true,
          text: 'MONTHS',
          color: 'black',
        },
      },
    },
  }

  const data = {
    labels: label,
    datasets: [
      {
        backgroundColor: colors,
        borderRadius: '1rem',
        borderWidth: 1,

        label: 'HR (under)',

        data: value,
        maxBarThickness: 40,
      },
    ],
  }

  return (
    <div style={{ padding: '1rem', height: '100%' }}>
      <div className='dashboard-chart-title' style={{ cursor: editMode ? 'pointer' : '' }} onClick={() => editCMS(dashboard_cms_key)}>
        {cms(dashboard_cms_key)}
      </div>
      <BarChart options={options} data={data} />
    </div>
  )
}

export default DailyARTimeByMonth
