import { AvField } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import Select from 'react-select'

const OPTIONS = [
  {
    title: '5 min',
    value: 5,
  },
  {
    title: '10 min',
    value: 10,
  },
  {
    title: '15 min',
    value: 15,
  },
  {
    title: '20 min',
    value: 20,
  },
  {
    title: '30 min',
    value: 30,
  },
  {
    title: '1hr',
    value: 60,
  },
  {
    title: '1hr 25min',
    value: 85,
  },
  {
    title: '1hr 30min',
    value: 90,
  },
  {
    title: '1hr 45min',
    value: 105,
  },
  {
    title: '2hrs',
    value: 120,
  },
  {
    title: '3hrs',
    value: 180,
  },
  {
    title: '4hrs',
    value: 240,
  },
  {
    title: '5hrs',
    value: 300,
  },
  {
    title: '6hrs',
    value: 360,
  },
  {
    title: '12hrs',
    value: 720,
  },
  {
    title: '18hrs',
    value: 1080,
  },
  {
    title: '24hrs',
    value: 1440,
  },
]

const getSelectedPrePostTimeValue = (val) => {
  if (!val) return null
  const ans = OPTIONS?.find((item) => item?.value === parseInt(val))
  return {
    label: ans?.title,
    value: ans?.value,
  }
}

const MinuteGap = ({ defaultValue, value, formFieldValue, setFormFieldValue }) => {
  const [selectedValue, setSelectedValue] = useState()

  useEffect(() => {
    setSelectedValue(value)
  }, [value])


  const handleChange = (value) => {
    setFormFieldValue((data) => {
      let newData = {
        ...data,
      }
      newData['min_gap'] = value
      return newData
    })
    setSelectedValue(value)
  }

  const select_options = OPTIONS?.map((option) => {
    return {
      label: option?.title,
      value: option?.value,
    }
  })
  const selectedRepeateGap = getSelectedPrePostTimeValue(selectedValue)

  return (
    <div style={{ minHeight: '90px' }}>
      <label>Minute Gap</label>
      <Select
        options={select_options}
        // id={'min_gap'}
        onChange={(val) => handleChange(val?.value)}
        value={selectedRepeateGap}
        styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
        isClearable
        // isDisabled={!inlineEditActive}
        // placeholder=''
        // isMulti={data?.format && data?.format?.is_multi_select ? true : false}
        // isSearchable={ inlineEditActive }
        // menuIsOpen={inlineEditActive}
        // {...props}
      />
      <AvField type='hidden' name={'min_gap'} value={selectedValue}></AvField>
    </div>
  )
}

export default MinuteGap
