import { Col, Row } from 'reactstrap'

const QRPrint = ({ rows }) => {
  console.log('qr row:::::::', rows)
  return (
    <div className='qr-print-container'>
      {rows?.map((row, index) => {
        return (
          <>
            {' '}
            <Row className='border rounded mb-4' style={{ height: '50vh' }}>
              <Col md={4} className='p-4 d-flex flex-column justify-content-center'>
                <div className='font-weight-bold'>Location:</div>
                <div className='mb-2'>{row?.original?.location_id_label}</div>
                <div className='font-weight-bold'>Where:</div>
                <div className='mb-2'>{row?.original?.name}</div>
                <div className='font-weight-bold'>Location:</div>
                <div className='mb-2'>
                  {row?.original?.active ? (
                    <span className='badge badge-pill bg-success sidebar-text-light'>Yes</span>
                  ) : (
                    <span className='badge badge-pill bg-danger sidebar-text-light'>No</span>
                  )}
                </div>
              </Col>
              <Col md={8}>
                <div className='p-4'>
                  <img src={process.env.REACT_APP_STORAGE + row?.original?.qr} />
                </div>
              </Col>
            </Row>
            {index !== rows?.length - 1 && (
              <>
                <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
                <p style={{ pageBreakBefore: 'always' }}>&nbsp;</p>
              </>
            )}
          </>
        )
      })}
    </div>
  )
}

export default QRPrint
