import cx from 'classnames'

const SelectColor = ({ activeColor, setColor, isFont }) => {
  return (
    <div className='theme-settings-swatches'>
      <div
        className={cx('swatch-holder bg-primary', {
          active: activeColor === 'bg-primary sidebar-text-light' || activeColor === 'bg-primary-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-primary${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-secondary', {
          active: activeColor === 'bg-secondary sidebar-text-light' || activeColor === 'bg-secondary-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-secondary${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-success', {
          active: activeColor === 'bg-success sidebar-text-light' || activeColor === 'bg-success-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-success${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-info', {
          active: activeColor === 'bg-info sidebar-text-light' || activeColor === 'bg-info-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-info${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-warning', {
          active: activeColor === 'bg-warning sidebar-text-dark' || activeColor === 'bg-warning-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-warning${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-danger', {
          active: activeColor === 'bg-danger sidebar-text-light' || activeColor === 'bg-danger-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-danger${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-light', {
          active: activeColor === 'bg-light sidebar-text-dark' || activeColor === 'bg-light-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-light${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-white', {
          active: activeColor === 'bg-white sidebar-text-dark' || activeColor === 'bg-white-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-white${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-euro', {
          active: activeColor === 'bg-euro sidebar-text-light' || activeColor === 'bg-euro-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-euro${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-dark', {
          active: activeColor === 'bg-dark sidebar-text-light' || activeColor === 'bg-dark-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-dark${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-focus', {
          active: activeColor === 'bg-focus sidebar-text-light' || activeColor === 'bg-focus-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-focus${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-alternate', {
          active: activeColor === 'bg-alternate sidebar-text-light' || activeColor === 'bg-alternate-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-alternate${isFont ? '-text' : ''} sidebar-text-light`)}
      />

      <div className='divider' />

      <div
        className={cx('swatch-holder bg-vicious-stance', {
          active: activeColor === 'bg-vicious-stance sidebar-text-light',
        })}
        onClick={() => setColor(`bg-vicious-stance${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-midnight-bloom', {
          active: activeColor === 'bg-midnight-bloom sidebar-text-light' || activeColor === 'bg-midnight-bloom-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-midnight-bloom${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-night-sky', {
          active: activeColor === 'bg-night-sky sidebar-text-light' || activeColor === 'bg-night-sky-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-night-sky${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-slick-carbon', {
          active: activeColor === 'bg-slick-carbon sidebar-text-light' || activeColor === 'bg-slick-carbon-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-slick-carbon${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-asteroid', {
          active: activeColor === 'bg-asteroid sidebar-text-light' || activeColor === 'bg-asteroid-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-asteroid${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-royal', {
          active: activeColor === 'bg-royal sidebar-text-light' || activeColor === 'bg-royal-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-royal${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-warm-flame', {
          active: activeColor === 'bg-warm-flame sidebar-text-dark' || activeColor === 'bg-warm-flame-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-warm-flame${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-night-fade', {
          active: activeColor === 'bg-night-fade sidebar-text-dark' || activeColor === 'bg-night-fade-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-night-fade${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-sunny-morning', {
          active: activeColor === 'bg-sunny-morning sidebar-text-dark' || activeColor === 'bg-sunny-morning-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-sunny-morning${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-tempting-azure', {
          active: activeColor === 'bg-tempting-azure sidebar-text-dark' || activeColor === 'bg-tempting-azure-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-tempting-azure${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-amy-crisp', {
          active: activeColor === 'bg-amy-crisp sidebar-text-dark' || activeColor === 'bg-amy-crisp-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-amy-crisp${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-heavy-rain', {
          active: activeColor === 'bg-heavy-rain sidebar-text-dark' || activeColor === 'bg-heavy-rain-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-heavy-rain${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-mean-fruit', {
          active: activeColor === 'bg-mean-fruit sidebar-text-dark' || activeColor === 'bg-mean-fruit-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-mean-fruit${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-malibu-beach', {
          active: activeColor === 'bg-malibu-beach sidebar-text-dark' || activeColor === 'bg-malibu-beach-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-malibu-beach${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-deep-blue', {
          active: activeColor === 'bg-deep-blue sidebar-text-dark' || activeColor === 'bg-deep-blue-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-deep-blue${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-ripe-malin', {
          active: activeColor === 'bg-ripe-malin sidebar-text-light' || activeColor === 'bg-ripe-malin-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-ripe-malin${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-arielle-smile', {
          active: activeColor === 'bg-arielle-smile sidebar-text-light' || activeColor === 'bg-arielle-smile-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-arielle-smile${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-plum-plate', {
          active: activeColor === 'bg-plum-plate sidebar-text-light' || activeColor === 'bg-plum-plate-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-plum-plate${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-happy-fisher', {
          active: activeColor === 'bg-happy-fisher sidebar-text-dark' || activeColor === 'bg-happy-fisher-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-happy-fisher${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-happy-itmeo', {
          active: activeColor === 'bg-happy-itmeo sidebar-text-dark' || activeColor === 'bg-happy-itmeo-text sidebar-text-dark',
        })}
        onClick={() => setColor(`bg-happy-itmeo${isFont ? '-text' : ''} sidebar-text-dark`)}
      />
      <div
        className={cx('swatch-holder bg-mixed-hopes', {
          active: activeColor === 'bg-mixed-hopes sidebar-text-light' || activeColor === 'bg-mixed-hopes-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-mixed-hopes${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-strong-bliss', {
          active: activeColor === 'bg-strong-bliss sidebar-text-light' || activeColor === 'bg-strong-bliss-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-strong-bliss${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-grow-early', {
          active: activeColor === 'bg-grow-early sidebar-text-light' || activeColor === 'bg-grow-early-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-grow-early${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-love-kiss', {
          active: activeColor === 'bg-love-kiss sidebar-text-light' || activeColor === 'bg-love-kiss-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-love-kiss${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-premium-dark', {
          active: activeColor === 'bg-premium-dark sidebar-text-light' || activeColor === 'bg-premium-dark-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-premium-dark${isFont ? '-text' : ''} sidebar-text-light`)}
      />
      <div
        className={cx('swatch-holder bg-happy-green', {
          active: activeColor === 'bg-happy-green sidebar-text-light' || activeColor === 'bg-happy-green-text sidebar-text-light',
        })}
        onClick={() => setColor(`bg-happy-green${isFont ? '-text' : ''} sidebar-text-light`)}
      />
    </div>
  )
}

export default SelectColor
