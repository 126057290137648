import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Collapse, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { TagsInput } from 'react-tag-input-component'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncPaginate } from 'react-select-async-paginate'

import IconSet from '../../../../icon'
import { cms, generateArrayByNumber, tidyName } from '../../../../../helper'
import CMSRichText from '../../../../Forms/Fields/richText'
import { request } from '../../../../../Redux/Sagas/requests/api'
import { updatePageDataRedux } from '../../../../../Redux/Reducers/CMS'
import { setLoader } from '../../../../../Redux/Reducers/loader'
import Preview, { PreviewForExisitingTemplate } from './preview'
import BodyHeader from './bodyHeader'
import Body from './body'
import BodyColumn from './bodyColumn'
import BodyInfoBox from './bodyInfoBox'
import Footer from './footer'
import { setNotification } from '../../../../../Redux/Reducers/notificationHandling'
import ConfirmButton from '../../../../Common/confirmButton'
import { store } from '../../../../../Redux/Config/configureStore'
import { getDurationColumns } from '../../MainGrid/emailModal'

const getFrequencyTypeOption = (frequency) => {
  switch (frequency) {
    case 'monthly':
      return ['1st', '7th', '14th', '21st', '30th']
    case 'weekly':
      return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    default:
      return null
  }
}

export const ScheduleEmailReportsAccordian = ({
  content,
  parentPageName,
  isInstantEmail,
  onSubmit,
  selected_duration,
  set_selected_duration,
  startDate,
  endDate,
  email,
  setEmail,
  set_start_date,
  set_end_date,
}) => {
  const cards = [1, 2, 3, 4, 5]
  const [reports, setReports] = useState([])
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const dispatch = useDispatch()
  const [reportPreviewId, setReportPreviewId] = useState()

  useEffect(() => {
    if (content?.grid_email_report) {
      setReports(content?.grid_email_report)
    }
  }, [content])
  const [collapse, setCollapse] = useState()
  const toggle = (e) => {
    console.log('on toggle::::::::', toggle)
    let event = e.target.dataset.event

    if (Number(event) === collapse) {
      setCollapse()
    } else {
      setCollapse(Number(event))
    }
  }

  return (
    <div className='p-3'>
      <div className='mb-5'>
        <Button color={isAddModalOpen ? 'danger' : 'success'} onClick={() => setIsAddModalOpen(!isAddModalOpen)} className='btn m-1 float-right'>
          <IconSet icon={isAddModalOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
        </Button>
      </div>

      {reports.map((report, index) => {
        return (
          <div key={index}>
            <ListGroupItem onClick={toggle} data-event={index}>
              <span data-event={index}> {report?.title}</span>

              <span className='float-right'>
                <span
                  className='text-primary mr-2'
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setReportPreviewId(report?.id)
                  }}>
                  Preview
                </span>
                <ConfirmButton
                  style={{ padding: 0 }}
                  color='link'
                  id={'report' + report?.id}
                  clickEvent={() => {
                    request('delete-grid-email-report', 'POST', {
                      id: report?.id,
                      grid_id: content?.main_grid?.id,
                    }).then(() => {
                      request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
                        let pageData = {}
                        pageData[parentPageName] = data

                        dispatch(updatePageDataRedux(pageData))
                        dispatch(setLoader(false))
                      })
                    })
                  }}
                  modalTitle='Remove Email Reports'
                  modalBodyText={`Are you sure to delete report ${report?.title}`}>
                  <IconSet icon={'fa-solid fa-xmark'} color='danger' />
                </ConfirmButton>
                <Modal isOpen={report?.id === reportPreviewId} toggle={() => setReportPreviewId()}>
                  <ModalHeader toggle={() => setReportPreviewId()}>Preview</ModalHeader>
                  <ModalBody>
                    <PreviewForExisitingTemplate report={report} />
                  </ModalBody>
                </Modal>
              </span>
            </ListGroupItem>
            <Collapse isOpen={collapse === index}>
              <div className='mb-4'>
                <ScheduleEmailReports
                  content={content}
                  report={report}
                  parentPageName={parentPageName}
                  reportIndex={index}
                  grid_id={content?.main_grid?.id}
                />
              </div>
            </Collapse>
          </div>
        )
      })}
      <Modal isOpen={isAddModalOpen} toggle={() => setIsAddModalOpen()}>
        <ModalHeader toggle={() => setIsAddModalOpen()}>Add Reports Template</ModalHeader>
        <ModalBody>
          <ScheduleEmailReports
            parentPageName={parentPageName}
            isAddingNew={true}
            grid_id={content?.main_grid?.id}
            content={content}
            closeAddModal={() => setIsAddModalOpen(false)}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

const ScheduleEmailReports = ({
  content,
  parentPageName,
  isInstantEmail,
  onSubmit,
  selected_duration,
  set_selected_duration,
  startDate,
  endDate,
  email,
  setEmail,
  set_start_date,
  set_end_date,
  isAddingNew,
  reportIndex,
  grid_id,
  report,
  closeAddModal,
  user_type,
  showOnlyPreview,
}) => {
  const [grouping, set_grouping] = useState()
  const [add_body_field_open, set_add_body_field_open] = useState(false)
  const [date_field, set_date_field] = useState()
  const [time,setTime] = useState()

  const dispatch = useDispatch()

  //main details
  const [mainDetails, setMainDetails] = useState({ title: '', user_type: '', frequency: '', add_summary: false })

  //body data
  const [bodyRows, setBodyRows] = useState([])
  const [bodyForm, setBodyForm] = useState({
    field_id: '',
    title: '',
  })

  //body header
  const [bodyHeaderTitle, setBodyHeaderTitle] = useState('')
  const [bodyHeaderDescription, setBodyHeaderDescription] = useState('')

  //body
  const [bodyTitle, setBodyTitle] = useState('')
  const [bodyDescription, setBodyDescription] = useState()

  //body info
  const [selectedInfo, setSelectedInfo] = useState([])
  const [bodyInfo, setBodyInfo] = useState({ title: '', sub_title: '' })

  const [footer, setFooter] = useState('')

  const [paginationDataForSystemUser, setPaginationDataForSystemUser] = useState({
    pageNo: 1,
    hasMore: true,
  })

  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  useEffect(() => {
    const default_data = report

    console.log('report:::::::::',report)

    setMainDetails({
      title: default_data?.title,
      user_type: default_data?.user_type || user_type,
      frequency: default_data?.frequency,
      add_summary: default_data?.show_summary,
    })
    set_date_field(default_data?.duration_column)
    setTime(default_data?.time)
    setBodyHeaderTitle(default_data?.header_title || '')
    setBodyHeaderDescription(default_data?.header_description || '')
    setBodyTitle(default_data?.body_title || '')
    setBodyDescription(default_data?.body_description || '')
    setBodyInfo({
      title: default_data?.body_info_data?.title || '',
      sub_title: default_data?.body_info_data?.sub_title || '',
    })
    setFooter(default_data?.footer || '')

    const default_selected_info = []
    if (default_data?.body_info_data?.data) {
      Object.keys(default_data?.body_info_data?.data)?.map((key) => {
        if (default_data?.body_info_data?.data?.[key]) {
          const data = {
            title: default_data?.body_info_data?.data?.[key],
            header: key,
          }
          default_selected_info?.push(data)
        }
      })
    }
    setSelectedInfo(default_selected_info)

    const default_body_rows = []

    default_data?.grid_columns?.map((item) => {
      default_body_rows?.push({
        title: item?.title,
        field_id: item?.field_id,
      })
    })

    if (default_body_rows?.length === 0) {
      const grid_column = content?.main_grid?.component_call_cols
        ?.filter((item) => item?.component_name === 'text_data_col')
        ?.map((item) => item?.field_id)
      const default_data = grid_selector?.[content?.source_table]?.columns
        ?.filter((item) => {
          if (grid_column?.includes(item?.field_id) || grid_column?.includes(item?.name)) {
            return true
          }
        })
        ?.map((item) => {
          return {
            title: item?.title || tidyName(item?.name),
            field_id: item?.field_id || item?.name,
          }
        })
      setBodyRows(default_data)
    } else {
      setBodyRows(default_body_rows)
    }
  }, [report, user_type])

  async function loadOptionsForSystemUser(search, loadedOptions) {
    let payload = {
      source_table: content?.source_table,

      domain_id,
      page: paginationDataForSystemUser?.pageNo ? paginationDataForSystemUser?.pageNo : 1,
    }
    const response = await request('get-system-user', 'POST', { ...payload })
    setPaginationDataForSystemUser({
      pageNo: response?.data?.meta?.current_page + 1,
      hasMore: response?.data?.links?.next ? true : false,
    })
    const options = response?.data?.data?.map((item) => {
      return {
        value: item?.id,
        label: item?.name || item?.first_name || item?.full_name || item?.id,
      }
    })
    return {
      options: options,
      hasMore: response?.data?.links?.next ? true : false,
    }
  }

  const onSubmitBodyForm = () => {
    const data = [...bodyRows]
    data?.push(bodyForm)
    setBodyRows(data)
    setBodyForm({
      field_id: '',
      title: '',
    })
    set_add_body_field_open(false)
  }

  const onSaveSetting = () => {
    console.log('main details:::::::::',mainDetails)
    const data = {
      ...mainDetails,
      time,
      duration_column: date_field,
      id: report?.id,

      // body_header: {
      //   title: bodyHeaderTitle,
      //   description: bodyHeaderDescription,
      // },
      body_title: bodyTitle,
      body_description: bodyDescription,
      // body: {
      //   title: bodyTitle,
      //   description: bodyDescription,
      // },
      body_data: { columns: [...bodyRows] },
      body_info: {
        title: bodyInfo?.title,
        sub_title: bodyInfo?.sub_title,
        data: [...selectedInfo],
      },
      footer,
    }

    let payload = {
      data: data,
      grid_id: grid_id,
    }
    dispatch(setLoader(true))

    if (isInstantEmail) {
      dispatch(setLoader(false))
      onSubmit(payload)
    } else {
      if (checkValidationToAddTemplate(data)) {
        request('save-grid-email-report', 'POST', payload).then((res) => {
          if (res.status === 200 || res.status === 202) {
            request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
              let pageData = {}
              pageData[parentPageName] = data

              dispatch(updatePageDataRedux(pageData))
              dispatch(setLoader(false))
            })
            if (isAddingNew) {
              closeAddModal()
            }
          } else {
            dispatch(setLoader(false))
          }
        })
      } else {
        dispatch(setLoader(false))
      }
    }
  }

  const dynamic_column_table = mainDetails?.user_type === 'user' ? 'users' : mainDetails?.user_type === 'location' ? 'locations' : null

  let report_type = content?.grid_settings?.display_report_type

  return (
    <AvForm className='p-4'>
      {!showOnlyPreview && (
        <>
          <Row className='mb-1'>
            {!isInstantEmail && (
              <>
                <Col md={6}>
                  <AvField
                    name='title'
                    label={'Report Title'}
                    value={mainDetails?.title}
                    onChange={(e) => setMainDetails({ ...mainDetails, title: e.target.value })}></AvField>
                </Col>
                <Col md={6}>
                  <AvField
                    name='type'
                    label='Select Report Type'
                    type='select'
                    value={mainDetails?.user_type}
                    onChange={(e) => setMainDetails({ ...mainDetails, user_type: e.target.value })}>
                    <option value=''>Select Report Type</option>
                    {report_type?.map((report) => {
                      return (
                        <option value={report} key={report}>
                          {tidyName(report)}
                        </option>
                      )
                    })}
                  </AvField>
                </Col>
                {/* <Col md={6} className='mb-3'>
                  <label>Email to System User</label>
                  <AsyncPaginate
                    value={mainDetails?.system_user}
                    loadOptions={loadOptionsForSystemUser}
                    onChange={(val) => setMainDetails({ ...mainDetails, system_user: val })}
                    cacheUniqs={[domain_id]}
                    styles={{
                      menu: (base) => ({ ...base, zIndex: 15 }),
                    }}
                    isMulti={true}
                  />
                </Col> */}
              </>
            )}

            {!isInstantEmail && (
              <>
                <Col md={6}>
                  <AvField
                    name='type'
                    label='Select frequency'
                    type='select'
                    value={mainDetails?.frequency}
                    onChange={(e) => setMainDetails({ ...mainDetails, frequency: e.target.value })}>
                    <option>Select Frequency</option>
                    <option value='monthly'>Monthly</option>
                    <option value='weekly'>Weekly</option>
                    <option value='daily'>Daily</option>
                  </AvField>
                </Col>
                {mainDetails?.frequency && mainDetails?.frequency !== 'daily' && (
                  <Col md={6}>
                    <AvField
                      name='type'
                      label='Select frequency day'
                      type='select'
                      value={mainDetails?.frequency_type}
                      onChange={(e) => setMainDetails({ ...mainDetails, frequency_type: e.target.value })}>
                      <option>Select Frequency Day</option>
                      {getFrequencyTypeOption(mainDetails?.frequency)?.map((item, i) => (
                        <option key={i} value={item}>
                          {tidyName(item)}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                )}
                <Col md={6}>
                  <AvField
                    type='select'
                    name='duration_column'
                    label='Group by column'
                    value={date_field}
                    onChange={(e) => set_date_field(e.target.value)}>
                    <option>Select Field</option>
                    {getDurationColumns(grid_selector?.[content?.source_table]?.columns)?.map((item, index) => {
                      return (
                        <option key={index} value={item?.field_id || item?.name}>
                          {tidyName(item.title) || tidyName(item.name)}
                        </option>
                      )
                    })}
                  </AvField>
                </Col>
                <Col md={6}>
                  <AvField type='select' name='time' label='Time' value={time?.toString()} onChange={(e) => setTime(parseInt(e.target.value))}>
                    <option>Select Field</option>
                    {generateArrayByNumber(24)?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    })}
                  </AvField>
                </Col>
                <Col md={12}>
                  <hr></hr>
                </Col>
                {/* <Col md={6}>
              <AvField
                type='select'
                name='duration_column'
                label='Duration Column'
                value={date_field}
                onChange={(e) => set_date_field(e.target.value)}>
                <option>Select Field</option>
                {getDurationColumns(grid_selector?.[content?.source_table]?.columns)?.map((item, index) => {
                  return (
                    <option key={index} value={item?.field_id || item?.name}>
                      {tidyName(item.title) || tidyName(item.name)}
                    </option>
                  )
                })}
              </AvField>
            </Col> */}
              </>
            )}
          </Row>

          <Row>
            {isInstantEmail && (
              <>
                <Col md={12}>
                  <Card className='p-2 mb-3' body inverse color={'info'} style={{ padding: '0.5rem' }}>
                    Customize Report
                  </Card>
                </Col>
                <Col md={12}>
                  <hr></hr>
                </Col>
              </>
            )}
            {/* <Col md={12}>
              <CardTitle>Body Header</CardTitle>
              <BodyHeader
                bodyHeaderTitle={bodyHeaderTitle}
                bodyHeaderDescription={bodyHeaderDescription}
                setBodyHeaderTitle={setBodyHeaderTitle}
                setBodyHeaderDescription={setBodyHeaderDescription}
                columns={grid_selector?.[dynamic_column_table]?.columns}
                isAddingNew={isAddingNew}
                reportIndex={reportIndex}></BodyHeader>
            </Col> */}

            {content?.grid_settings?.display_add_summary_button && (
              <>
                {' '}
                <Col md={12}>
                  <div style={{ marginBottom: '0.5rem' }}>Add Summary?</div>
                  <div
                    className='switch has-switch mb-2 mr-2'
                    data-on-label='ON'
                    data-off-label='OFF'
                    onClick={() => setMainDetails({ ...mainDetails, add_summary: !mainDetails?.add_summary })}>
                    <div
                      className={cx('switch-animate', {
                        'switch-on': mainDetails?.add_summary,
                        'switch-off': !mainDetails?.add_summary,
                      })}>
                      <input type='checkbox' />
                      <span className='switch-left bg-success'>ON</span>
                      <label>&nbsp;</label>
                      <span className='switch-right bg-success'>OFF</span>
                    </div>
                  </div>
                </Col>{' '}
                <Col md={12}>
                  <hr></hr>
                </Col>
              </>
            )}
            <Col md={12}>
              <CardTitle>Body</CardTitle>
              <Body
                bodyTitle={bodyTitle}
                bodyDescription={bodyDescription}
                setBodyTitle={setBodyTitle}
                setBodyDescription={setBodyDescription}
                columns={grid_selector?.[dynamic_column_table]?.columns}
                isAddingNew={isAddingNew}
                reportIndex={reportIndex}></Body>
            </Col>

            <Col md={12}>
              <hr></hr>
            </Col>

            {!!mainDetails?.add_summary && (
              <>
                <Col md={12}>
                  <CardTitle>Body info box</CardTitle>
                  <BodyInfoBox
                    bodyInfo={bodyInfo}
                    setBodyInfo={setBodyInfo}
                    selectedInfo={selectedInfo}
                    setSelectedInfo={setSelectedInfo}
                    columns={grid_selector?.[dynamic_column_table]?.columns}
                    content={content}
                  />
                </Col>
                <Col md={12}>
                  <hr></hr>
                </Col>
              </>
            )}

            <Col md={12}>
              <CardTitle>Body data</CardTitle>
              <Col md={12} className='float-right'>
                <Button color='success' onClick={() => set_add_body_field_open(!add_body_field_open)} className='btn m-1 float-right'>
                  <IconSet icon={add_body_field_open ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
                </Button>
              </Col>
              {add_body_field_open && (
                <Row style={{ marginBottom: '1rem' }}>
                  <Col md={6}>
                    <AvField
                      type='select'
                      name='field_id'
                      label='Select Field'
                      value={bodyForm?.field_id}
                      onChange={(e) =>
                        setBodyForm({
                          ...bodyForm,
                          field_id: e.target.value,
                          title: tidyName(e.target.value),
                        })
                      }>
                      <option>Select Field</option>
                      {grid_selector?.[content?.source_table]?.columns?.map((item, index) => {
                        return (
                          <option key={index} value={item?.field_id || item?.name}>
                            {tidyName(item.title) || tidyName(item.name)}
                          </option>
                        )
                      })}
                    </AvField>
                  </Col>
                  <Col md={6}>
                    <AvField
                      name='field_title'
                      label={'Title'}
                      value={bodyForm?.title}
                      onChange={(e) =>
                        setBodyForm({
                          ...bodyForm,
                          title: e.target.value,
                        })
                      }></AvField>
                  </Col>

                  <Col md={12}>
                    <Button color='primary' className='float-right' onClick={() => onSubmitBodyForm()}>
                      Save
                    </Button>
                  </Col>
                </Row>
              )}

              <BodyColumn bodyRows={bodyRows} setBodyRows={setBodyRows} columns={grid_selector?.[content?.source_table]?.columns} />
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md={12}>
              <CardTitle>Footer</CardTitle>
              <Footer footer={footer} setFooter={setFooter} isAddingNew={isAddingNew} reportIndex={reportIndex} />
            </Col>
          </Row>
          {(isAddingNew || isInstantEmail) && (
            <>
              {' '}
              <CardTitle>Preview</CardTitle>
              <Preview
                mainDetails={mainDetails}
                bodyRows={bodyRows}
                bodyHeaderDescription={bodyHeaderDescription}
                bodyHeaderTitle={bodyHeaderTitle}
                selectedInfo={selectedInfo}
                bodyTitle={bodyTitle}
                bodyDescription={bodyDescription}
                footer={footer}
                bodyInfo={bodyInfo}
              />
            </>
          )}
        </>
      )}

      <Button color='primary' className='float-right' onClick={() => onSaveSetting()}>
        {isInstantEmail ? 'Send' : 'Save'}
      </Button>
    </AvForm>
  )
}

const checkValidationToAddTemplate = (data) => {
  if (!data?.title) {
    store.dispatch(setNotification({ type: 'error', message: 'Title is required' }))
    return false
  } else if (!data?.user_type) {
    store.dispatch(setNotification({ type: 'error', message: 'Report Type is required' }))
    return false
  } else if (!data?.frequency) {
    store.dispatch(setNotification({ type: 'error', message: 'Frequency is required' }))
    return false
  } else {
    return true
  }
}

export default ScheduleEmailReports
