import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import Loader from 'react-loaders'

import bg4 from '../../../assets/utils/images/dropdown-header/abstract4.jpg'
import { cms } from '../../../helper'
import { submitRequest } from '../../../Redux/Reducers/CMS'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
})

const AddNavGroup = ({ editPageMode, section, newNav = false, type = 'group' }) => {
  const dispatch = useDispatch()
  const [load, updateLoad] = useState(false)
  const [dropdownOpen, updateDropdownOpen] = useState(false)

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    dispatch(submitRequest({ url: 'create-sidebar-nav', data: { ...values, parent: section, parent_type: 'section', type } }))
    updateDropdownOpen(false)
  }

  if (!editPageMode && !newNav) return null

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => {
        if (dropdownOpen) {
          setTimeout(() => {
            updateDropdownOpen(!dropdownOpen)
          }, 1000)
        } else {
          updateDropdownOpen(!dropdownOpen)
        }
      }}
      className='float-right'>
      <DropdownToggle className='mr-2' color='info'>
        Add Nav Group
      </DropdownToggle>
      <DropdownMenu right className={'dropdown-menu-xl rm-pointers dropdown-menu-form'} style={{ padding: '0 0 0.65rem' }}>
        <div className='dropdown-menu-header'>
          <div className='dropdown-menu-header-inner bg-plum-plate'>
            <div
              className='menu-header-image'
              style={{
                backgroundImage: 'url(' + bg4 + ')',
              }}
            />
            <div className='menu-header-content text-white'>
              <h5 className='menu-header-title'>Add Nav Group in {cms(section)}</h5>
            </div>
          </div>
        </div>
        <div className='dropdown-form'>
          <Container>
            <AvForm onValidSubmit={handleValidSubmit}>
              <Row>
                <Col md={4}>
                  <AvField
                    name='name'
                    label='Name'
                    type='text'
                    required
                    validate={{ pattern: { value: '^[A-Za-z0-9_]+$', errorMessage: 'Your name must be composed only with letter and numbers' } }}
                  />
                </Col>
                <Col md={4}>
                  <AvField name='default_name' label='Default Name' type='text' />
                </Col>
                <Col md={4}>
                  <AvField name='icon' label='Icon' type='text' />
                </Col>
                <Col md={12}>
                  <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                    {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Submit'}
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default connect(select)(AddNavGroup)
