import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import Select from 'react-select'

import { cms, editCMS, tidyName } from '../../../helper'
import { checkSingleCondition } from './toggle'

const getNubmberForOption = (dependent_value) => {
  switch (dependent_value) {
    case '15':
      return 96
    case '30':
      return 48
    case '45':
      return 32
    case '60':
      return 24
    case '90':
      return 16
    case '120':
      return 12
    case '180':
      return 8
    case '240':
      return 4
    case '300':
      return 4
    case '360':
      return 3
    case '420':
      return 3
    case '480':
      return 2
    case '540':
      return 2
    case '600':
      return 2
    case '660':
      return 2
    case '720':
      return 2
    default:
      return null
  }
}

const RepeateGap = ({ data, formFieldValue, setFormFieldValue, value }) => {
  const cmsEditMode = useSelector((state) => state.CMS.editMode)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const currentFormData = useSelector((state) => state.CMS.coreData.currentFormData)

  const [selectedValue, setSelectedValue] = useState()

  useEffect(() => {
    setSelectedValue({ value, label: value })
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = value

      return updatedValue
    })
  }, [value])

  let isFieldVisible

  if (!formFieldValue?.repeats_mins) return
  if (
    data?.field_setting?.depend_fields &&
    data?.field_setting?.depend_fields?.length > 0 &&
    ((currentRow && currentRow[currentRow?.length - 1]) || currentFormData || formFieldValue)
  ) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      if (formFieldValue && Object.keys(formFieldValue)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, formFieldValue?.[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else if (currentFormData && Object.keys(currentFormData)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, currentFormData?.[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else {
        if (currentRow && currentRow[currentRow?.length - 1]) {
          return checkSingleCondition(
            dependForm?.condition,
            currentRow[currentRow?.length - 1][dependForm?.dependent_field],
            parseInt(dependForm?.value)
          )
        }
      }
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  const onChangeValue = (value) => {
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = value?.value

      return updatedValue
    })
    setSelectedValue(value)
  }

  if (!isFieldVisible) return

  const options = getNubmberForOption(formFieldValue?.repeats_mins)
    ? Array(getNubmberForOption(formFieldValue?.repeats_mins))
        .fill(0)
        ?.map((item, i) => {
          return {
            value: i + 1,
            label: i + 1,
          }
        })
    : []
  return (
    <>
      <div className='mb-3'>
        {' '}
        <label>Repeat Gap</label>
        <Select
          options={options}
          value={selectedValue}
          onChange={(v) => onChangeValue(v)}
          classNamePrefix='select'
          styles={{
            menu: (base) => ({ ...base, zIndex: 15000 }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              // borderColor: isFieldInSync ? '#30B1ff' : '#ced4da',
            }),
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color: 'hsl(0, 0%, 70%)',
              }
            },
          }}
          isClearable
        />
        <AvField type='hidden' name={data?.field_id || data?.name} value={selectedValue?.value}></AvField>
      </div>
    </>
  )
}

export default RepeateGap
