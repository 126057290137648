import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'
import { Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row, UncontrolledTooltip } from 'reactstrap'
import Loader from 'react-loaders'

import Icon from '../../icon'
import { getColor } from '../../../helper'
import { submitTopNavButton } from '../../../Redux/Reducers/CMS'

import bg4 from '../../../assets/utils/images/dropdown-header/abstract4.jpg'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
})

const EditTopNavButton = ({ editPageMode, nav }) => {
  const dispatch = useDispatch()
  const [load, updateLoad] = useState(false)
  const [liveColor, updateLiveColor] = useState('primary')
  const [breaks, updateBreaks] = useState([])
  const [dropdownOpen, updateDropdownOpen] = useState(false)
  const [form, updateForm] = useState({
    page_name: '',
    break_left: false,
    icon: '',
    icon_color: 'primary',
    text_right_header: '',
    text_right_sub_header: '',
  })

  const onChange = (e) => {
    if (e.target.type === 'checkbox') {
      updateForm({ ...form, [e.target.name]: !form[e.target.name] })
    } else {
      updateForm({ ...form, [e.target.name]: e.target.value })
    }
  }

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    dispatch(submitTopNavButton(values))
  }

  useEffect(() => {
    if (nav.break_left) updateBreaks(['break_left'])
    if (nav.color) updateLiveColor(nav.color)
  }, [])

  if (!editPageMode) return null

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => updateDropdownOpen(!dropdownOpen)} className='edit-top-nav-button'>
      <DropdownToggle className='p-0 mr-2' color='link' id={'editButton' + nav.name}>
        <Icon bgcolor='white' color='white' icon='fas fa-pen' navheadericon='true' />
        <UncontrolledTooltip placement='right' target={'editButton' + nav.name}>
          Edit Button
        </UncontrolledTooltip>
      </DropdownToggle>
      <DropdownMenu right className={'dropdown-menu-xl rm-pointers dropdown-menu-form'} style={{ padding: '0 0 0.65rem' }}>
        <div className='dropdown-menu-header'>
          <div className='dropdown-menu-header-inner bg-plum-plate'>
            <div
              className='menu-header-image'
              style={{
                backgroundImage: 'url(' + bg4 + ')',
              }}
            />
            <div className='menu-header-content text-white'>
              <h5 className='menu-header-title'>Edit {nav.name} button</h5>
              <h6 className='menu-header-subtitle'>Please enter some detais about the button.</h6>
            </div>
          </div>
        </div>
        <div className='dropdown-form'>
          <Container>
            <AvForm onValidSubmit={handleValidSubmit} model={{ break: breaks }}>
              <Row>
                <Col md={4}>
                  <AvField
                    name='page_name'
                    label='Button Name'
                    type='text'
                    required
                    disabled
                    value={nav.name}
                    validate={{ pattern: { value: '^[A-Za-z0-9_]+$', errorMessage: 'Your name must be composed only with letter and numbers' } }}
                  />
                </Col>
                <Col md={4}>
                  <AvField name='icon' label='Icon' type='text' value={nav.icon} />
                </Col>
                <Col md={4}>
                  <AvField name='text_right_header' label='Text Right Header' type='text' value={nav.text_right_header} />
                </Col>
                <Col md={4}>
                  <AvField name='text_right_sub_header' label='Text Right Sub Header' type='text' value={nav.text_right_sub_header} />
                </Col>
                <Col md={4}>
                  <label>
                    Icon Color{' '}
                    <div
                      title='#f44336'
                      style={{
                        background: 'transparent',
                        height: '20px',
                        width: '20px',
                        float: 'right',
                        marginLeft: '11px',
                        cursor: 'pointer',
                        position: 'relative',
                        outline: 'none',
                        borderRadius: '50%',
                        boxShadow: getColor(liveColor) + ' 0px 0px 0px 14px inset',
                        transition: 'box-shadow 100ms ease 0s',
                      }}></div>
                  </label>
                  <AvField
                    type='select'
                    name='icon_color'
                    onChange={(e) => {
                      onChange(e)
                      updateLiveColor(e.target.value)
                    }}
                    value={nav.color}>
                    <option value={'primary'}>primary</option>
                    <option value={'secondary'}>secondary</option>
                    <option value={'accent'}>accent</option>
                    <option value={'dark'}>dark</option>
                    <option value={'danger'}>danger</option>
                    <option value={'info'}>info</option>
                    <option value={'success'}>success</option>
                    <option value={'warning'}>warning</option>
                    <option value={'light'}>light</option>
                  </AvField>
                </Col>
                <Col md={4}>
                  <AvCheckboxGroup name='break' label='Break'>
                    <AvCheckbox value='break_left' />
                  </AvCheckboxGroup>
                </Col>
                <Col md={12}>
                  <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                    {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Edit'}
                  </Button>
                  {/* <RemoveTopNavButton nav={nav} basicButton={true} closeModal={() => updateDropdownOpen(false)} /> */}
                </Col>
              </Row>
            </AvForm>
          </Container>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default connect(select)(EditTopNavButton)
