import { call, put } from 'redux-saga/effects'
import { setLoader } from '../../Reducers/loader'
import { setNotification } from '../../Reducers/notificationHandling'
import { setSlides, setLogo } from '../../Reducers/slides'
import { requestSlides, requestWelcomImages } from '../requests/slides'

export function* fetchSlides() {
  try {
    yield put(setLoader(true))
    const response = yield call(requestSlides)
    if (response.status === 200) {
      yield put(setSlides(response.data))
    } else {
      yield put(setNotification({ type: 'error', message: response.message, status: response.status }))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* fetchWelcomImages() {
  try {
    yield put(setLoader(true))
    const response = yield call(requestWelcomImages)
    if (response.status === 200) {
      yield put(setSlides(response.data.slides))
      yield put(setLogo(response.data.logo))
    } else {
      yield put(setNotification({ type: 'error', message: response.message, status: response.status }))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}
