import { useSelector } from 'react-redux'
import { Row, Col, CardHeader, Card, ListGroupItem, Button } from 'reactstrap'
import CountUp from 'react-countup'
import { Progress } from 'react-sweet-progress'

import { cms, editCMS } from '../../../../../../helper'
import { useEffect, useState } from 'react'
import JobsPercentageChart from './jobsPercentageChart'
import JobsNumberChart from './jobsNumberChart'

const JobsNumberNpercent = ({ dashboardData }) => {
  const [questions, setQuestions] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const dashboard_cms_key = useSelector((state) => state.CMS?.dashboardCMSKey?.job_number_and_percent?.cms_key_heading)
  const editMode = useSelector((state) => state.CMS?.editMode)

  useEffect(() => {
    if (dashboardData && dashboardData?.job_number_and_percent && Object.keys(dashboardData?.job_number_and_percent)?.length > 0) {
      let q = []
      Object.keys(dashboardData?.job_number_and_percent)?.map((key) => {
        q.push(dashboardData?.job_number_and_percent[key])
      })
      setQuestions(q)
    }
  }, [])
  if (questions?.length === 0) return
  return (
    <div className='mb-5' style={{ padding: '1rem', height: '100%' }}>
      <div className='dashboard-chart-title' style={{ cursor: editMode ? 'pointer' : '' }} onClick={() => editCMS(dashboard_cms_key)}>
        {`Results (${questions?.length})`}
      </div>
      <Row>
        {questions?.slice(currentPage * 2, currentPage * 2 + 2)?.map((item, index) => {
          return (
            <Col md={6} className='mb-4'>
              <ListGroupItem>
                <div>{item?.question}</div>
                <div className='pt-4' style={{ height: '20rem' }}>
                  {item?.score_grade === 'percentage' && <JobsPercentageChart question={item}></JobsPercentageChart>}
                  {item?.score_grade === 'numeric' && <JobsNumberChart question={item}></JobsNumberChart>}
                </div>
              </ListGroupItem>
            </Col>
          )
        })}
      </Row>
      {questions?.length > 2 && (
        <div className='d-flex justify-content-between'>
          <div>
            {currentPage !== 0 && (
              <Button
                color='primary'
                disabled={currentPage === 0}
                onClick={() => {
                  setCurrentPage(currentPage - 1)
                }}>
                Previous
              </Button>
            )}
          </div>
          <div>
            {currentPage + 1 < questions?.length / 2 && (
              <Button
                color='primary'
                disabled={currentPage === questions?.length - 1}
                onClick={() => {
                  setCurrentPage(currentPage + 1)
                }}>
                Next
              </Button>
            )}
          </div>
        </div>
      )}

      {/* {questions?.map((item, index) => {
        return (
          <Card className='mb-3' key={index}>
            <CardHeader className='card-header-tab z-index-6'>
              <div className='card-header-title font-size-lg text-capitalize fw-normal'>
                <i className='header-icon lnr-charts icon-gradient bg-happy-green'> </i>
                {item?.question}
              </div>
            </CardHeader>
            <Row className='g-0'>
              {item?.answers?.map((answer, index) => {
                return (
                  <Col sm='6' md='3' xl='3' key={index}>
                    <div className='card no-shadow rm-border bg-transparent widget-chart text-start'>
                      <div className='widget-chart-content'>
                        <div className='widget-subheading'>{answer?.answer}</div>
                        <div className='widget-numbers text-success'>
                          {item?.score_grade === 'percentage' && (
                            <div className='' style={{ margin: 'auto', width: '4rem' }}>
                              <div className='progress-circle-wrapper'>
                                <Progress
                                  type='circle'
                                  percent={answer?.response ? answer?.response : 0}
                                  width='100%'
                                  theme={{
                                    active: {
                                      trailColor: '#e1e1e1',
                                      color: '#3ac47d',
                                    },
                                    success: {
                                      symbol: answer?.response + '%',
                                      trailColor: '#e1e1e1',
                                      color: '#3ac47d',
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {item?.score_grade === 'numeric' && (
                            <CountUp
                              start={0}
                              end={answer?.response}
                              separator=''
                              decimals={0}
                              decimal='.'
                              useEasing={false}
                              suffix=''
                              duration='7'
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Card>
        )
      })} */}
    </div>
  )
}

export default JobsNumberNpercent
