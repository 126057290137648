const MinPercent = ({ isMore = false, row, type }) => {
  let item_0_20 = 0,
    item_21_40 = 0,
    item_41_60 = 0,
    item_60_more = 0
 let items_count = row?.row?.original?.items_count
  let start_date, end_date
  if (row?.row?.original) {
    start_date = row?.row?.original?.raised_start_date_time
    // end_date = row?.row?.original?.completed_end_date_time
  }
      let items_complete_time = []
      const min_string = row?.row?.original?.completed_time_concat
      if (min_string?.length > 0) {
        const min_array = min_string?.split(',')
        const unique_min_array = new Set(min_array)

        unique_min_array?.forEach((ele) => {
          let value = ele.split(' :: ')?.[1]
          items_complete_time?.push(value)
        })
      }

     if (items_count === 0) return
  if (!isNaN(Date.parse(start_date))) {
    items_complete_time?.map((item) => {
      if (!isNaN(Date.parse(item))) {
        if (new Date(item) - new Date(start_date) <= 1200000) {
          item_0_20 += 1
        } else if (
          new Date(item) - new Date(start_date) > 1200000 &&
          new Date(item) - new Date(start_date) <= 2400000
        ) {
          item_21_40 += 1
        } else if (
          new Date(item) - new Date(start_date) > 2400000 &&
          new Date(item) - new Date(start_date) <= 3600000
        ) {
          item_41_60 += 1
        } else if (new Date(item) - new Date(start_date) > 3600000) {
          item_60_more += 1
        }
      }
    })
  }

  if(!start_date) return





switch (type) {
  //'0_20_min','21_40_min','41_60_min','60_+_min'
  case '0_20_min':
    return `${Math.ceil((item_0_20 / items_count) * 100)}%`
  case '21_40_min':
    return `${Math.ceil((item_21_40 / items_count) * 100)}%`
  case '41_60_min':
    return `${Math.ceil((item_41_60 / items_count) * 100)}%`
  case '60_+_min':
    return `${Math.ceil((item_60_more / items_count) * 100)}%`
}
}

export default MinPercent
