import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { closeOpenModal } from '../../../Redux/Reducers/CMS'
import ActionCall from '../../actionCall'

const select = (state) => ({
  openModal: state.CMS.openModal,
  CMS: state.CMS,
  page: state.CMS.coreData.page,
})

const OpenModal = ({ openModal, CMS }) => {
  const dispatch = useDispatch()
  const [modal, updateModal] = useState(false)
  const [actions, updateAction] = useState([])

  const toggle = () => {
    dispatch(closeOpenModal())
  }
  useEffect(() => {
    updateModal(openModal.active)
    if (openModal.action !== null) {
      updateAction([openModal.action])
    }
  }, [openModal])
  return (
    <Modal isOpen={modal} toggle={() => toggle()} scrollable={true}>
      <ModalHeader toggle={() => toggle()}></ModalHeader>
      <ModalBody>
        <ActionCall dropdownData={false} CMSData={CMS} actions={actions} isOpen={true} isModalAction={true} />
      </ModalBody>
    </Modal>
  )
}

export default connect(select)(OpenModal)
