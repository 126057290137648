import { Button } from 'reactstrap'
import IconSet from '../../../icon'
import CompletedMin from './completedMin'
import HrBool from './hrBool'
import HRPercent from './hrPercent'
import MinPercent from './minPercent'

const AggregateTableCell = ({ row }) => {
  switch (row?.column?.field_id) {
    case 'hr_under_bool':
      return <HrBool row={row} />
    case 'hr_more_bool':
      return <HrBool isMore={true} row={row} />
    case 'completed_min':
      return (
        <span style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <CompletedMin row={row} />
        </span>
      )
    // return <span>{row?.row?.original?.completed_min}</span>
    case 'text_time':
      const text_time = row?.row?.original?.text_time
      let output = ''
      if (text_time?.['hours']) {
        output = output + `${text_time['hours']} Hours, `
      }
      if (text_time?.['minutes']) {
        output += `${text_time['minutes']} mins`
      }
      return text_time
    case 'count':
      return row?.row?.original?.items_count
    case 'hr_under_percent':
      return <HRPercent row={row} />
    case 'hr_more_percent':
      return <HRPercent row={row} isMore={true} />
    case 'edit':
      return (
        <Button color='primary' size='sm'>
          <IconSet icon={'far fa-edit'} type={'font_awesome'} fontWeight={400} />
        </Button>
      )
    case '0_20_min':
      return <MinPercent type='0_20_min' row={row} />
    case '21_40_min':
      return <MinPercent type='21_40_min' row={row} />
    case '41_60_min':
      return <MinPercent type='41_60_min' row={row} />
    case '60_+_min':
      return <MinPercent type='60_+_min' row={row} />
    case 'month':
      return row?.row?.original?.month

    default:
      return <span></span>
  }
}

export default AggregateTableCell
