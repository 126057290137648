import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Col, Row, Button, FormGroup } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'

import SliderFormWrapper from '../../Components/Form/sliderFormWrapper'

import { postResetPassword, setResetPassword } from '../../Redux/Reducers/user'
import { fetchThemeOptions } from '../../Redux/Reducers/themeOptions'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const themeOptions = useSelector((state) => state.ThemeOptions)

  useEffect(() => {
    if (themeOptions.updatedAt === null) {
      dispatch(fetchThemeOptions())
    }
  }, [])

  const { token } = useParams()

  const handleSubmit = (e, values) => {
    values.token = token
    dispatch(setResetPassword({ ...values, history }))
    dispatch(postResetPassword())
  }

  return (
    <SliderFormWrapper
      message={
        <span>
          {/* Forgot your Password? <span className='text-success'>Reset your password</span>{' '}
          <span>by completing the below form. You will be emailed to your registered email address a link to reset your password.</span> */}
          {themeOptions?.forgotSubTitle}
        </span>
      }>
      <AvForm onValidSubmit={(e, values) => handleSubmit(e, values)}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <AvField
                type='password'
                name='password'
                label='password'
                validate={{
                  minLength: { value: 5, errorMessage: 'Minimum password length is 5.' },
                  required: { value: true, errorMessage: 'This field is required.' },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <AvField
                type='password'
                name='password_confirmation'
                label='Confirmation Password'
                validate={{
                  match: { value: 'password', errorMessage: "Password doesn't match." },
                  required: { value: true, errorMessage: 'This field is required.' },
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className='divider' />

        <Row>
          <Col md={12}>
            <div className='mt-4 d-flex align-items-center'>
              <div className='ml-auto'>
                <div className=''>
                  <Button color='primary' size='lg'>
                    Reset Password
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </AvForm>
    </SliderFormWrapper>
  )
}
export default ForgotPassword
