import AvForm from 'availity-reactstrap-validation/lib/AvForm'
import AvField from 'availity-reactstrap-validation/lib/AvField'
import AvInput from 'availity-reactstrap-validation/lib/AvInput'
import { cms, tidyName } from '../../../../helper'
import { Col, Label, Row } from 'reactstrap'
import { useState } from 'react'

const RowGroupSetting = ({ mainGrid, onChange = () => {}, groupBy }) => {
  const columns = mainGrid?.component_call_cols?.filter((item) => item.component_name === 'text_data_col')

  const [seletcedField, updateSelectedField] = useState()
  const updateDynamicValue = () => {
    const curPos = document.getElementById('group_by_text').selectionStart
   
    const old_string = groupBy?.text
    var txt2 = old_string.slice(0, curPos) +'{'+ seletcedField + '}'+old_string.slice(curPos)


     onChange(txt2, 'text')
    // if(check?.length===0){
    //   onChange(txt2, 'text')
    // }
    
  }




  return (
    <AvForm>
      <Row>
        <Col md={3} style={{ paddingLeft: '0px !important' }}>
          <AvField type='select' name='row_group_field' label='Group By' value={groupBy?.Column} onChange={(e) => onChange(e.target.value, 'Column')}>
            <option>Group By Column</option>
            {columns?.map((col,i) => {
              return <option key={i} value={col?.field_id}>{tidyName(cms(col?.cms_key))}</option>
            })}
          </AvField>
        </Col>
        <Col md={4} style={{ marginBottom: '1rem' }}>
          <Label>Group By Text</Label>
          {/* <textarea placeholder='Use {} to add dynamic value' value={groupBy?.text} onChange={(e) => onChange(e.target.value, 'text')}>{groupBy?.text}</textarea> */}
          <AvInput
            type='textarea'
            name='group_by_text'
            id='group_by_text'
            placeholder='Use {} to add dynamic value'
            value={groupBy?.text}
            onChange={(e) => onChange(e.target.value, 'text')}
          />
        </Col>
        <Col
          md={0}
          style={{ flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
          onClick={() => updateDynamicValue()}>
          <i className='fa-solid fa-circle-arrow-left fa-2x'></i>
        </Col>
        <Col md={4}>
          <AvField type='select' name='row_group_field' label='select field to group by text' onChange={(e) => updateSelectedField(e.target.value)}>
            <option value=''>Select column</option>
            {columns?.map((col,i) => {
              return <option key={i} value={col?.field_id}>{tidyName(cms(col?.cms_key))}</option>
            })}
          </AvField>
        </Col>
      </Row>
    </AvForm>
  )
}

export default RowGroupSetting
