import { Col } from 'reactstrap'
import { templateType } from '../../../../../helper'

const TemplateGrid = ({ data }) => {
  return (
    <>
      {data?.map((item, key) => {
        return (
          <Col md={6} key={key}>
            <div>
              <div style={{ fontSize: '1.1rem' }}>{item.title ? `${item.title}` : `${item.name}`}</div>
              <div style={{ opacity: '0.8', fontStyle: 'italic' }}>{templateType(item.type)}</div>
            </div>
            <hr></hr>
          </Col>
        )
      })}
    </>
  )
}

export default TemplateGrid
