import React from 'react'

import cx from 'classnames'
const SelectCustomColor = ({ activeColor, setColor, customColors }) => {
  return (
    <div className='theme-settings-swatches'>
      {customColors.map((data, index) => (
        <div
          key={index}
          // className={cx('swatch-holder bg-primary', {
          //   active: activeColor === 'bg-primary sidebar-text-light' || activeColor === 'bg-primary-text sidebar-text-light',
          // })}
          // onClick={() => setColor(`bg-primary${isFont ? '-text' : ''} sidebar-text-light`)}
          className={cx('swatch-holder', {
            active: activeColor === `uuid-${data.uuid}`,
          })}
          onClick={() => setColor(`uuid-${data.uuid}`)}
          style={{ backgroundColor: data.color }}
        />
      ))}
    </div>
  )
}

export default SelectCustomColor
