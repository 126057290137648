import React, { Fragment, useEffect, useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { Button, Col, Label, Row } from 'reactstrap'
import { connect, useSelector } from 'react-redux'
import { tidyName } from '../../../helper'
import { Combobox, Multiselect } from 'react-widgets'
import CreateField from '../ContentForms/createField'
import AggregateCheckBox from './aggregateCheckBox'

const select = (state) => ({
  grid_selector: state.CMS.coreData.grid_selector,
})

const reduxDataValidate = (value, ctx) => {
  if (ctx.redux_data_name) {
    if (!ctx.redux_data_value || !ctx.redux_data_id) {
      return 'You need to select "Redux Data ID" and "Redux Data Value"'
    }
  }
  return true
}
const manageDatabaseValidate = (value, ctx) => {
  // if (form.manage_data_database !== 'none' && form.manage_data_database) {
  //   if (!ctx.manage_data_field) {
  //     return 'This field is required.'
  //   }
  // }
  return true
}

const initialFields = {
  type: 'colDataAdvancedWithSearch',
  sorting: '',
  is_additional: false,
  column: '',
  inline_edit: 0,
  width: '10',
  title: '',
  values: [],
  validations: [],
  field_type: '',
}

const EditColumn = ({
  form,
  grid_selector,
  updateColumn,
  onSaveColumn = () => {},
  isGridSetting = false,
  isInlineEditDisabled = false,
  isRepeatingForm,
  mainGrid,
}) => {
  const [fieldCount, updateFieldCount] = useState(1)
  const [fieldForm, updateFieldForm] = useState([initialFields])
  const [tags, setTags] = useState([])

  return (
    <Fragment>
      <Col md={12}>
        <hr />
        Add/Edit column for {tidyName(form.table) || 'table'}.
        <hr />
      </Col>
      <Col md={12}>
        {fieldForm.map((item, i) => {
          return (
            <Fragment key={i}>
              <p className='text-left'>
                {i || 1 + '.'}{' '}
                {(i || 1) !== 1 && (
                  <span
                    className='text-danger cursor-pointer'
                    onClick={() => {
                      const updatedForm = fieldForm
                      delete updatedForm[i]
                      updateFieldForm(updatedForm)
                      updateFieldCount(fieldCount - 1)
                    }}>
                    Remove
                  </span>
                )}
              </p>
              <ColumnList
                fieldForm={fieldForm}
                form={form}
                index={i}
                grid_selector={grid_selector}
                updateColumn={(data, index) => {
                  const updatedFieldForm = fieldForm
                  updatedFieldForm[index] = data
                  updateColumn(updatedFieldForm)
                  updateFieldForm(updatedFieldForm)
                }}
                isInlineEditDisabled={isInlineEditDisabled}
                isRepeatingForm={isRepeatingForm}
                mainGrid={mainGrid}
              />
            </Fragment>
          )
        })}
      </Col>
      <Col md={12}>
        {isGridSetting && (
          <Button
            onClick={() => {
              onSaveColumn()
            }}
            color='primary'>
            Save
          </Button>
        )}

        <div className='float-right'>
          <Button
            color='success'
            onClick={() => {
              const updatedFieldForm = fieldForm
              updatedFieldForm[fieldCount + 1] = initialFields
              updateFieldForm(updatedFieldForm)
              updateFieldCount(fieldCount + 1)
            }}>
            + Add More Field or Column
          </Button>
        </div>
      </Col>
    </Fragment>
  )
}

const ColumnList = ({ fieldForm, grid_selector, form, updateColumn, index, isInlineEditDisabled, isRepeatingForm, mainGrid }) => {
  const [fields, updateFields] = useState(initialFields)
  const [canAddAdditionalField, setCanAddAdditionalField] = useState(false)
  const [addAdditionalField, setAddAdditionalField] = useState(false)
  const [render, rerender] = useState(false)
  const [aggregate_column, set_aggregate_column] = useState([])

  const onChangeColumn = (value, name) => {
    updateColumn({ ...fields, [name]: value }, index)
    updateFields({ ...fields, [name]: value })
  }

  useEffect(() => {
    if (fields?.type === 'aggregate_column') {
      updateColumn(
        {
          ...fields,
          column: 'aggregate_column',
          options: {
            columns: aggregate_column,
          },
        },
        index
      )
      updateFields({
        ...fields,
        column: 'aggregate_column',
        options: {
          columns: aggregate_column,
        },
      })
    }
  }, [aggregate_column])

  const onTagChange = (name) => {
    const tag = fields.values
    tag.push(name)
    updateFields({ ...fields, values: tag })
    rerender(!render)
  }

  const checkColumnIsField = () => {
    const check = grid_selector[form.table]?.columns.filter((item, i) => {
      if (item.type === 'function' && fields.column === item.name) {
        return item
      }
    })
    return check?.length === 0
  }

  const rendorCustomColumns = (fields) => {
    if (addAdditionalField) {
      return (
        <>
          <Label>
            Column Field OR{' '}
            <Button style={{ display: 'contents' }} color='link' onClick={() => setAddAdditionalField(false)}>
              Select Column
            </Button>
          </Label>
          <AvField
            name='column'
            value={fields.column}
            onChange={(e) => onChangeColumn(e.target.value, 'column')}
            required
            validate={{
              pattern: {
                value: '^[a-z_]+$',
                errorMessage: 'Column must be composed by small letter or underscore.',
              },
            }}
          />
        </>
      )
    }

    return (
      <>
        <Label>
          Select OR{' '}
          <Button
            style={{ display: 'contents' }}
            color='link'
            onClick={() => {
              setAddAdditionalField(true)
              onChangeColumn('', 'column')
            }}>
            + Add Custom Field
          </Button>
        </Label>
        <AvField
          type='select'
          name={'column'}
          value={fields.column}
          onChange={(e) => {
            onChangeColumn(e.target.value, 'column')
          }}
          required>
          <option value=''>Select field or column</option>
          {form.table &&
            grid_selector[form.table] &&
            grid_selector[form.table].columns.map((item, i) => {
              return (
                <option value={item.name} key={i}>
                  {tidyName(item.title) || tidyName(item.name)}
                </option>
              )
            })}
        </AvField>
      </>
    )
  }
  const validations = ['required', 'string', 'max_50', 'max_100', 'email', 'min_5', 'confirm_password']

  const isValueFieldShow = ['multi_radio', 'multi_checkbox', 'dropdown', 'multi_select'].includes(fields?.field_type) ? true : false

  useEffect(() => {
    if (grid_selector[form.table]?.cms_field_group) {
      setCanAddAdditionalField(true)
    }
  }, [fieldForm, form])

  const hasReduxData = useSelector((state) => state.CMS.coreData.has_redux_data)
  const coreData = useSelector((state) => state.CMS.coreData)

  let selected_field_type
  if (fields?.column && isRepeatingForm && !addAdditionalField) {
    const selectedField = grid_selector[form.table]?.columns?.filter((field) => field?.name === fields?.column)
    selected_field_type = selectedField && selectedField[0]?.field
  } else if (addAdditionalField) {
    selected_field_type = fields?.field_type
  }

  return (
    <div className='add-form'>
      <Row>
        {!isRepeatingForm && (
          <Col md={6}>
            <AvField
              type='select'
              label={'Type'}
              name={'type'}
              value={fields.type}
              onChange={(e) => {
                onChangeColumn(e.target.value, 'type')
              }}
              required>
              <option value={'colDataAdvancedWithSearch'}>Advanced column with search</option>
              <option value={'expandDataColumn'}>Expand Data Column</option>

              {(form.table === 'items' || form.table === 'item_groups') && <option value={'questionnaire'}>Questionnaire Button</option>}
              {(form.table === 'items' || form.table === 'item_groups') && (
                <option value={'expandQuestionnaire'}>Expand Questions And Answers</option>
              )}
              <option value={'replicate_button'}>Replicate Column</option>
              {form?.table === 'item_groups' && <option value={'aggregate_column'}>Aggregate Column</option>}
            </AvField>
          </Col>
        )}

        {fields.type !== 'questionnaire' && fields.type !== 'expandQuestionnaire' && fields?.type !== 'aggregate_column' && (
          <>
            <Col md={6}>
              {canAddAdditionalField || fields?.type === 'replicate_button' ? (
                rendorCustomColumns(fields)
              ) : (
                <AvField
                  type='select'
                  label='Select Column'
                  name={'column'}
                  value={fields.column}
                  onChange={(e) => {
                    onChangeColumn(e.target.value, 'column')
                  }}
                  required>
                  <option value=''>Select field or column</option>
                  {form.table &&
                    grid_selector[form.table] &&
                    grid_selector[form.table].columns.map((item, i) => {
                      return (
                        <option value={item.name} key={i}>
                          {tidyName(item.title) || tidyName(item.name)}
                        </option>
                      )
                    })}
                </AvField>
              )}
            </Col>
            {!addAdditionalField && (
              <Col md={6}>
                <label>Validations</label>
                <Multiselect
                  data={validations}
                  value={fields.validations}
                  onChange={(value) => onChangeColumn(value, 'validations')}
                  textField='validations'
                />
              </Col>
            )}

            {addAdditionalField && (
              <>
                <Col md={isValueFieldShow ? 4 : 6}>
                  <AvField type='select' label='Field Type' name={'field_type'} onChange={(e) => onChangeColumn(e.target.value, 'field_type')}>
                    <option>Select field type</option>
                    <option value={'dropdown'}>Dropdown</option>
                    <option value={'input'}>Input</option>
                    <option value={'date'}>Date</option>
                    <option value={'time'}>Time</option>
                    <option value={'static_text'}>Static Text</option>
                    <option value={'rich_text'}>Rich Text</option>
                    <option value={'submit_button'}>Submit Button</option>
                    <option value={'chart_pill'}>Chart Pill</option>
                    <option value={'toggle_switch'}>Toggle Switch</option>
                    <option value={'multi_select'}>Multi Select</option>
                    <option value={'multi_checkbox'}>Multi Check Box</option>
                    <option value={'multi_radio'}>Multi Radio Box</option>
                    <option value={'color_picker'}>Color picker</option>
                    <option value={'date_time_picker'}>Date time picker</option>
                    <option value={'date_time_range_picker'}>Date time Range picker</option>
                    <option value={'file_upload'}>File upload</option>
                  </AvField>
                </Col>
                <Col md={isValueFieldShow ? 4 : 6}>
                  <label>Validations</label>
                  <Multiselect
                    data={validations}
                    value={fields.validations}
                    onChange={(value) => onChangeColumn(value, 'validations')}
                    textField='validations'
                  />
                </Col>
                {isValueFieldShow && (
                  <Col md={4}>
                    <label>Values</label>
                    <Multiselect
                      // allowCreate={true}
                      // open={false}
                      onChange={(e) => updateFields({ ...fields, values: e })}
                      allowCreate={true}
                      onCreate={(name) => onTagChange(name)}
                      data={[]}
                      value={[...fields?.values]}
                      // onChange={(value) => updateForm({ ...form, validations: value })}
                      textField='validations'
                    />
                  </Col>
                )}
              </>
            )}
          </>
        )}
        {fields.type !== 'expandDataColumn' && fields?.type !== 'aggregate_column' && (
          <>
            {!isRepeatingForm && (
              <>
                {fields.type !== 'questionnaire' && fields.type !== 'expandQuestionnaire' && (
                  <Col md={6}>
                    <AvField
                      type='select'
                      label='Sorting'
                      name={'sorting'}
                      value={fields.sorting}
                      onChange={(e) => onChangeColumn(e.target.value, 'sorting')}>
                      <option value={''}>Select sorting</option>
                      <option value={'ASC'}>ASC</option>
                      <option value={'DESC'}>DESC</option>
                    </AvField>
                  </Col>
                )}
                <Col md={6}>
                  <AvField
                    type='number'
                    label='Width'
                    name={'width'}
                    max='100'
                    min='10'
                    value={fields.width}
                    onChange={(e) => onChangeColumn(e.target.value, 'width')}
                  />
                </Col>
              </>
            )}
            <Col md={isRepeatingForm ? 6 : 6}>
              <AvField type='title' label='Title' name='Title' value={fields.title} onChange={(e) => onChangeColumn(e.target.value, 'title')} />
            </Col>
            {checkColumnIsField() && fields.type !== 'questionnaire' && fields.type !== 'expandQuestionnaire' && (
              <Col md={6}>
                <AvField
                  type='select'
                  label='Inline Edit'
                  name={'inline_edit'}
                  value={fields.inline_edit}
                  onChange={(e) => {
                    onChangeColumn(e.target.value, 'inline_edit')
                  }}
                  disabled={isInlineEditDisabled}>
                  {/* <option value="">Select Inline Type</option> */}
                  <option value={0}>Read Only</option>
                  <option value={1}>Edit</option>
                </AvField>
              </Col>
            )}
          </>
        )}

        {fields?.type === 'aggregate_column' && <AggregateCheckBox aggregate_column={aggregate_column} set_aggregate_column={set_aggregate_column} />}

        {isRepeatingForm && (
          <CreateField
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            isCreatingFieldForRepeatingForm={true}
            selectedFieldType={selected_field_type}
            // grid_id={grid_id}
          />
        )}
      </Row>
      <hr />
    </div>
  )
}

export default connect(select)(EditColumn)
