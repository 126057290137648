import { AvField } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardTitle, CustomInput, FormGroup } from 'reactstrap'
import { capitalize, tidyName } from '../../../helper'
import { setSelectedDropdownData } from '../../../Redux/Reducers/CMS'

const SendReportRadio = ({ data, formFieldValue, setFormFieldValue }) => {
  const [radioOptions, setRadioOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState()
  const selectedDropdownData = useSelector((state) => state.CMS.selectedDropdownData)

  const dispatch = useDispatch()

  useEffect(() => {
    const data_connection = data?.component_call_data_connection
    const options = data?.component_call_data_connection?.default_options?.map((item) => {
      return {
        value: item,
        label: tidyName(capitalize(item)),
      }
    })
    setRadioOptions(options)
    if (data_connection?.default_options) {
      let default_options = []

      if (Array.isArray(data_connection?.default_options)) {
        default_options = data_connection?.default_options?.map((item, i) => {
          if (i === 0) {
            setSelectedValue(item)
            setFormFieldValue((formValue) => {
              let updatedValue = {
                ...formValue,
              }
              updatedValue[data?.field_id] = item

              return updatedValue
            })
          }
          return {
            value: item,
            label: tidyName(capitalize(item)),
          }
        })
      } else {
        Object.keys(data_connection?.default_options)?.map((item) => {
          default_options?.push({
            value: item,
            label: tidyName(capitalize(data_connection?.default_options[item])),
          })
        })
      }
    }
  }, [data])

  const onChangeRadio = (e) => {
    if (e.target.checked) {
      setSelectedValue(e.target.value)
      setFormFieldValue((formValue) => {
        let updatedValue = {
          ...formValue,
        }
        updatedValue[data?.field_id] = e.target.value

        return updatedValue
      })
    }
    const data_connection = data?.component_call_data_connection
    if (data_connection.component_name === 'manual') {
      let newSelectedDropdownData = {
        ...selectedDropdownData,
      }

      newSelectedDropdownData[data?.field_id] = e.target.value
      dispatch(setSelectedDropdownData(newSelectedDropdownData))
    }
  }
  return (
    <div>
      <label>Select Type</label>
      <FormGroup>
        <div>
          {radioOptions?.map((option, index) => {
            return (
              <CustomInput
                type='radio'
                key={option?.value}
                id={`radio${index}`}
                name={`radio${index}`}
                value={option?.value}
                label={option?.label}
                onChange={(e) => onChangeRadio(e)}
                checked={option?.value === selectedValue ? true : false}
              />
            )
          })}
        </div>
        <AvField type='hidden' value={selectedValue} name={data?.field_id}></AvField>
      </FormGroup>
    </div>
  )
}

export default SendReportRadio
