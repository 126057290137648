import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import 'rc-tabs/assets/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { cms, tidyName } from '../../helper'
import { useSelector } from 'react-redux'


const getCurrentRowName = (item) =>{
  if(!item) return
  return item['name'] || item['domain_name'] || item['full_name'] || item['group_name']
}
// const CustomPrevIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop:'19px' }}>⬅️</div>
const CustomPrevIcon = () => <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '24px', marginTop: '19px' }} />

// Custom right icon component
// const CustomNextIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop: '15px' }}>➡️</div>
const CustomNextIcon = () => <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '24px', marginTop: '15px' }} />

const RCTabs = ({ items, selectedTabKey, onChange: onChangeTab, activeTab, pageData }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const name = getCurrentRowName(currentRow?.[0])

  return (
    <Tabs
      defaultActiveKey='0'
      activeKey={activeTab?.toString() || '0'}
      onChange={(e) => onChangeTab(e)}
      renderTabBar={(props) => (
        <ScrollableInkTabBar
          inkBarStyle={{ backgroundColor: 'blue', height: '2px' }}
          {...props}
          prevIcon={<CustomPrevIcon />}
          nextIcon={<CustomNextIcon />}
        />
      )}
      renderTabContent={() => <TabContent />}>
      {items?.map((tab, index) => {
        return (
          <TabPane tab={tab?.title} key={index}>
            {activeTab === index && (
              <div>
                <div className='font-weight-bold mb-2 ml-3' style={{ fontSize: '1rem' }}>
                  {tidyName(pageData?.name)}&nbsp; &gt;&nbsp;
                  {cms(tab?.cmsTabKey)}&nbsp;&gt;&nbsp;{name}&nbsp;
                </div>
                <div key={index}>{tab?.getContent()}</div>
              </div>
            )}
          </TabPane>
        )
      })}
    </Tabs>
  )
}

export default RCTabs
