import React, { useEffect, useState, Fragment } from 'react'

import {
  addChangedFieldIdInReduxByFormName,
  checkFieldExistInSyncToUpdate,
  cms,
  editCMS,
  removeChangedFieldIdInReduxByFormName,
} from '../../../helper'
import { connect, useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import { CLOSE_OPEN_MODAL, setCurrentRow } from '../../../Redux/Reducers/CMS'

export const checkUniqueFieldVisible = (formFieldValue) => {
  const quetionType = ['select', 'radio', 'signiture_select']
  if (formFieldValue?.type && quetionType?.includes(formFieldValue?.type)) {
    return true
  } else {
    return false
  }
}

export const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
  currentFormData: state.CMS.coreData.currentFormData,
})

const CMSSwitch = ({
  data,
  cmsEditMode,
  id,
  label = true,
  currentRow,
  value,
  onChangeAction = () => {},
  currentFormData,
  formName,
  formFieldValue,
  setFormFieldValue,
  onSetFormFieldValue,
  submittedFormData = {},
  form,
  onChangeValue = () => {},
}) => {
  const [toggle, setTogle] = useState(false)
  const [isFieldDisabled, setIsFieldDisabled] = useState(false)
  useEffect(() => {}, [data])

  useEffect(() => {
    let valueToUpdate = value

    if (
      data?.field_id === 'active' &&
      form?.name === 'templates' &&
      valueToUpdate !== false &&
      valueToUpdate !== 0 &&
      !Object.keys(formFieldValue)?.includes('active')
    ) {
      valueToUpdate = true
    }
    setTogle(valueToUpdate)

    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = valueToUpdate || 0
      return updatedValue
    })
    onChangeAction('', data.component_call_actions, data.field_id, valueToUpdate)
  }, [value])

  useEffect(() => {
    const disabled_if = data?.field_setting?.disable_if

    if (!Array.isArray(disabled_if) && disabled_if) {
      let isFieldDisabled = false
      Object.keys(disabled_if).map((key) => {
        let disabled_field_value
        if (currentRow?.length > 0) {
          disabled_field_value = currentRow?.[currentRow?.length - 1]?.[key]
        } else {
          disabled_field_value = currentFormData?.[key]
        }

        if (disabled_field_value == disabled_if[key]) {
          isFieldDisabled = true
        }
      })

      setIsFieldDisabled(isFieldDisabled)
    }

    if (toggle != formFieldValue[data?.field_id]) {
      setTogle(formFieldValue[data?.field_id])
    }
  }, [formFieldValue])

  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)

  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
  useEffect(() => {
    if (currentRow && currentRow?.length > 0 && currentRow[currentRow?.length - 1] && changedFieldRendered) {
      if (toggle == value) {
        removeChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      } else {
        if (toggle || toggle === false) {
          addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
        }
      }
    }
    if (!changedFieldRendered) {
      setChangedFieldRendered(true)
    }
  }, [toggle])

  const dispatch = useDispatch()

  const onChangeToggle = () => {
    if (isFieldDisabled) return
    setTogle(!toggle)
    onChangeAction('', data.component_call_actions, data.field_id, !toggle)
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      if (data?.field_id === 'auto_approve') {
        if (Object.keys(updatedValue).includes('approval') && updatedValue['approval'] && !toggle) {
          updatedValue['approval'] = false
        }
      }
      updatedValue[data?.field_id] = !toggle

      if (data?.field_id === 'view_repeat') {
        updatedValue['view_limit'] = false
      } else if (data?.field_id === 'view_limit') {
        updatedValue['view_repeat'] = false
      }

      return updatedValue
    })

    //call only when user update the data
    onChangeValue(data.field_id, !toggle)
  }

  let isFieldVisible = true

  if (
    data?.field_setting?.depend_fields &&
    data?.field_setting?.depend_fields?.length > 0 &&
    ((currentRow && currentRow[currentRow?.length - 1]) || currentFormData || formFieldValue)
  ) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      if (data?.field_id === 'view_on') {
        if (!submittedFormData?.view_on) {
          return true
        }
      } else if (dependForm?.dependent_field === 'view_on' && Object.keys(formFieldValue)?.includes('item_type_id') && submittedFormData['view_on']) {
        return false
      } else if (
        formFieldValue &&
        Object.keys(formFieldValue)?.includes(dependForm?.dependent_field)
        // !submittedFormData?.[dependForm?.dependent_field]
      ) {
        return checkSingleCondition(dependForm?.condition, formFieldValue[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else if (submittedFormData && Object.keys(submittedFormData)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, submittedFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else if (currentFormData && Object.keys(currentFormData)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, currentFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else {
        if (currentRow && currentRow[currentRow?.length - 1]) {
          return checkSingleCondition(
            dependForm?.condition,
            currentRow[currentRow?.length - 1][dependForm?.dependent_field],
            parseInt(dependForm?.value)
          )
        }
      }
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (data?.field_id === 'unique_answer' || data?.name === 'unique_answer') {
    isFieldVisible = checkUniqueFieldVisible(formFieldValue)
  }

  if (!isFieldVisible) return

  let avFieldValue = toggle
  if (toggle === '') {
    avFieldValue = false
  }

  if (isFieldDisabled) {
    if (toggle) {
      return (
        <div>
          <div>{label && <label className='font-weight-bold'>{cms(data?.cms_key)} :</label>}</div>

          <div className='badge badge-pill bg-success sidebar-text-light'>Yes</div>
          <AvField type='hidden' value={avFieldValue} name={data?.field_id} id={data?.field_id} />
        </div>
      )
    } else {
      return (
        <div>
          <div>{label && <label className='font-weight-bold'>{cms(data?.cms_key)} :</label>}</div>
          <div className='badge badge-pill bg-danger sidebar-text-light'>No</div>
          <AvField type='hidden' value={avFieldValue} name={data?.field_id} id={data?.field_id} />
        </div>
      )
    }
  }

  return (
    <Fragment>
      <div className={isFieldInSync ? 'blue-border p-2' : ''}>
        {label && <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>{cms(data?.cms_key)}</label>}
        <div>
          <div
            className='switch has-switch '
            style={{ marginBottom: '-5px' }}
            data-on-label='ON'
            data-off-label='OFF'
            onClick={(e) => onChangeToggle()}>
            <div
              className={cx('switch-animate', {
                'switch-on': toggle,
                'switch-off': !toggle,
              })}>
              <input type='checkbox' />
              <span className='switch-left bg-info'>ON</span>
              <label>&nbsp;</label>
              <span className='switch-right bg-info'>OFF</span>
            </div>
          </div>
        </div>
        <AvField type='hidden' value={avFieldValue} name={data?.field_id} id={data?.field_id} />
      </div>
    </Fragment>
  )
}

export default connect(select)(CMSSwitch)
