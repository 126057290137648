import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Card, CardBody, CardTitle, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Loader from 'react-loaders'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import Icon from '../../icon'
import { submitRequest } from '../../../Redux/Reducers/CMS'
import { Multiselect } from 'react-widgets'
import EditColumn from '../Grid/editColumn'
import EditTab from '../Grid/editTab'
import { capitalize, cms } from '../../../helper'
import CreateAdvanceGrid from '../Grid/createAdvanceGrid'
import { TabContent, TabPane } from 'rc-tabs'
import Tabs from 'rc-tabs/lib/Tabs'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import CreateTemplateForm from './Form/template-form'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  coreData: state.CMS.coreData,
  grid_selector: state.CMS.coreData.grid_selector,
  nav: state.CMS.coreData.nav,
})

const CreatePageGrid = ({ editPageMode, pageName, coreData, grid_selector = {}, edit = false }) => {
  const dispatch = useDispatch()

  const defaultForm = {
    action_types: [],
    table: '',
  }

  const [load, updateLoad] = useState(false)
  const [modalValue, setModal] = useState(false)
  const [columnForm, updateColumn] = useState([])
  const [tabForm, updateTab] = useState([])
  const [form, updateForm] = useState(defaultForm)
  const [tab, setTab] = useState('grid')

  const onTabChange = (key) => {
    setTab(key)
  }

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    dispatch(
      submitRequest({
        url: 'create-page-grid',
        load: true,
        data: {
          ...values,
          page_name: pageName,
          columnForm,
          tabForm,
          source_table: form.table,
          action_types: form.action_types.map((word) => (word === 'EXPORT' ? 'X' : word[0])).join(''),
        },
      })
    )
    setModal(false)
  }

  useEffect(() => {}, [])

  const actionTypes = ['ADD', 'EDIT', 'DELETE', 'RESTORE', 'FILTER', 'SEARCH', 'EXPORT', 'Preferences', 'Mail']
  if (edit && editPageMode) {
    return (
      <>
        <Button onClick={() => setModal(true)} color='' className='btn-wide mb-2 mr-2 btn-pill btn-gradient-info' size='lg'>
          <Icon icon={'fas fa-pen'} color='light' /> Edit Grid
        </Button>
        <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: '100%' }}>
          <ModalHeader toggle={() => setModal(!modalValue)}>Edit {pageName} page</ModalHeader>
          <ModalBody>
            <Container>
              <AvForm onValidSubmit={handleValidSubmit}>
                <Row>
                  <CreateAdvanceGrid
                    updateColumn={updateColumn}
                    updateTab={updateTab}
                    grid_selector={grid_selector}
                    form={form}
                    updateForm={updateForm}
                    actionTypes={actionTypes}
                  />
                  {/* <Col md={6}>
                    <AvField type='select' label='Table' name={'table'} onChange={(e) => updateForm({ ...form, table: e.target.value })}>
                      <option value=''>Select Table</option>
                      {Object.keys(grid_selector).map(function (key, index) {
                        return (
                          <option value={key} key={key}>
                            {capitalize(key)}
                          </option>
                        )
                      })}
                    </AvField>
                  </Col>
                  <Col md={6}>
                    <label>Action Types</label>
                    <Multiselect
                      data={actionTypes}
                      // value={form.action_types}
                      onChange={(value) => updateForm({ ...form, action_types: value })}
                      textField='action_types'
                      className='mb-4'
                    />
                    <AvField name='action_types_copy' value={form.action_types} type='hidden' required />
                  </Col>
                  <EditColumn form={form} updateColumn={updateColumn} />
                  <EditTab form={form} updateTab={updateTab} /> */}
                  <Col md={12}>
                    <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                      {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </Container>
          </ModalBody>
        </Modal>
      </>
    )
  }
  return (
    <Fragment>
      <CSSTransitionGroup
        component='div'
        transitionName='TabsAnimation'
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md='12'>
            <Card className='main-card mb-3'>
              <CardBody>
                <CardTitle>{cms(pageName)}</CardTitle>
                <div className='text-center'>
                  <div className='font-10rem'>
                    <div className='mx-auto mb-0 font-size-lg text-capitalize font-weight-normal card-title'>No grid found!</div>
                    <i className='fa-solid fa-face-frown-open icon-gradient bg-mean-fruit' />
                  </div>
                  {editPageMode && (
                    <>
                      <Button onClick={() => setModal(true)} color='' className='btn-wide mb-2 mr-2 btn-pill btn-gradient-info' size='lg'>
                        <Icon icon={'fas fa-pen'} color='light' /> Create Grid
                      </Button>
                      <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: '100%' }}>
                        <ModalHeader toggle={() => setModal(!modalValue)}>Create {pageName} page</ModalHeader>
                        <ModalBody>
                          <Container>
                            <Tabs
                              defaultActiveKey={1}
                              renderTabBar={() => <ScrollableInkTabBar onTabClick={onTabChange} />}
                              renderTabContent={() => <TabContent />}>
                              <TabPane tab='Create Advance Grid From Scretch' key='grid' id='test1'>
                                <AvForm onValidSubmit={handleValidSubmit}>
                                  <Row>
                                    <CreateAdvanceGrid
                                      updateColumn={updateColumn}
                                      updateTab={updateTab}
                                      grid_selector={grid_selector}
                                      form={form}
                                      updateForm={updateForm}
                                      actionTypes={actionTypes}
                                    />
                                    <Col md={12}>
                                      <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                                        {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Submit'}
                                      </Button>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </TabPane>
                              <TabPane tab='Import Template' key='template' id='test2'>
                                {load ? (
                                  <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} />
                                ) : (
                                  <CreateTemplateForm
                                    handleValidSubmit={handleValidSubmit}
                                    updateColumn={updateColumn}
                                    updateTab={updateTab}
                                    updateForm={updateForm}
                                    form={form}
                                    load={load}
                                    onlyGrid={true}
                                  />
                                )}
                              </TabPane>
                            </Tabs>
                          </Container>
                        </ModalBody>
                      </Modal>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default connect(select)(CreatePageGrid)
