import { Fragment, useEffect, useState } from 'react'
import { FormGroup, Label } from 'reactstrap'
import CommonFields from './commonFields'
import Select from 'react-select'

function SelectQuestion({ item, answers, setAnswers, requiredError }) {
  const [value, setValue] = useState(null)
  const [selectOptions, setSelectOptions] = useState()

  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  useEffect(() => {
    if (item.item_answer_responses?.length > 0) {
      setValue({ value: item.item_answer_responses[0].item_answer_id, label: item.item_answer_responses[0].response_text })
    }
    const options = []
    {
      item.item_answers
        ?.filter((que) => !que?.unique_answered)
        ?.map((item, key) => {
          options.push({ value: item.id, label: item.answer })
        })
    }
    setSelectOptions(options)
  }, [])

  useEffect(() => {
    if (value?.value) {
      const old_answers = [...answers]
      const selectedOptionData = item.item_answers?.find((ans) => ans.id === value?.value)
      const updated_answers = old_answers?.map((question) => {
        if (question?.item_question_id === item?.id) {
          return {
            ...question,
            answer_id: value?.value,
            response_text: value?.label,
            add_comment: selectedOptionData?.add_comment,
          }
        } else {
          return question
        }
      })

      setAnswers(updated_answers)
    }
  }, [value])

  useEffect(() => {
    setValue({ value: current_answer_object?.answer_id, label: current_answer_object?.response_text })
  }, [JSON.stringify(current_answer_object)])

  return (
    <Fragment>
      <div style={{ marginLeft: '1rem', height: '100%' }}>
        <FormGroup>
          <Label>{item.question}?</Label>
          <Select
            options={selectOptions}
            onChange={(e) => setValue(e)}
            defaultValue={value}
            value={value}
            styles={{
              container: (base, state) => ({
                ...base,
                // zIndex: '999',
              }),
            }}
            // menuIsOpen={true}
          />
        </FormGroup>
        <CommonFields
          item={item}
          selectedAnswer={current_answer_object?.answer_id}
          answers={answers}
          setAnswers={setAnswers}
          current_answer_object={current_answer_object}
          requiredError={requiredError}
        />

        {item?.validations?.includes('required') && requiredError && (!current_answer_object?.response_text || !current_answer_object?.answer_id) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
      </div>
    </Fragment>
  )
}

export default SelectQuestion
