import { useEffect, useState } from 'react'
import IconSet from '../../CMSComponent/icon'
import { cms } from '../../helper'
import './permission.scss'

const Theme = ({
  data,
  isMainPage,
  expandedMainPageId,
  setExpandedMainPageId,
  onChangeMainPagePermission,
  onChangeChildPagePermission,
  mainPageId,
}) => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    setTableData(data)
  }, [data])

  let isChildAvailable = false
  data?.map((item) => {
    if (item?.child && item?.child?.length > 0) {
      isChildAvailable = true
    }
  })

  const onSearchByName = (value) => {
    if (value && value?.length > 0) {
      const newData = tableData?.filter((data) => {
        if (cms(data?.key)?.toLowerCase().includes(value)) {
          return true
        }
      })
      setTableData(newData)
    } else {
      setTableData(data)
    }
  }

  return (
    <table className={`table ${!isMainPage && 'mt-0'}`}>
      {isMainPage && (
        <thead className='thead-light'>
          <tr>
            {isChildAvailable && <th></th>}

            <th>Name</th>

            <th>Access</th>
          </tr>
          <tr>
            {isChildAvailable && <th></th>}

            <th>
              <input type='text' style={{ width: '100%' }} onChange={(e) => onSearchByName(e.target.value)} placeholder='search...'></input>
            </th>

            <th></th>
          </tr>
        </thead>
      )}

      <tbody className='thead-light'>
        {tableData?.map((item, index) => {
          return (
            <>
              <RenderRow
                item={item}
                isChildAvailable={isChildAvailable}
                key={index}
                isMainPageRow={isMainPage}
                expandedMainPageId={expandedMainPageId}
                setExpandedMainPageId={setExpandedMainPageId}
                onChangeMainPagePermission={onChangeMainPagePermission}
                onChangeChildPagePermission={onChangeChildPagePermission}
                mainPageId={mainPageId}
              />
            </>
          )
        })}
      </tbody>
    </table>
  )
}

const RenderRow = ({
  item,
  isChildAvailable,
  isMainPageRow,
  expandedMainPageId,
  setExpandedMainPageId,
  onChangeMainPagePermission,
  onChangeChildPagePermission,
  mainPageId,
}) => {
  const child_name = item?.child?.map((item) => cms(item?.key))

  const isExpanded = expandedMainPageId === item?.key

  return (
    <>
      <tr>
        {item?.child && item?.child?.length > 0 ? (
          <td className='checkBox'>
            {' '}
            <IconSet
              icon={isExpanded ? 'far fa-arrow-down' : 'far fa-arrow-right'}
              color='primary'
              style={{ cursor: 'pointer', marginRight: '0.5rem' }}
              onClick={() => {
                if (isExpanded) {
                  setExpandedMainPageId(null)
                } else {
                  setExpandedMainPageId(item?.key)
                }
              }}
            />
          </td>
        ) : (
          <td className='checkBox'> </td>
        )}

        <td>{cms(item?.cms_key)}</td>

        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.access}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.key, 'access', e.target.checked ? 1 : 0, true)
              } else {
                onChangeChildPagePermission(item?.key, mainPageId, 'access', e.target.checked ? 1 : 0, true)
              }
            }}></input>
        </td>
      </tr>
      {isExpanded && isChildAvailable && (
        <tr>
          <td colspan={9} className='p-0'>
            <Theme data={item?.child} onChangeChildPagePermission={onChangeChildPagePermission} mainPageId={item?.key} />
          </td>
        </tr>
      )}
    </>
  )
}
export default Theme
