import React from 'react'
import Templateform from './form'
import TemplateGrid from './grid'
import { Col } from 'reactstrap'

const renderType = (data) => {
  switch (data.type) {
    case 'FORM':
      return <Templateform data={data?.forms} />
    case 'REPEATING_FORM':
      let a = [data?.form]
      return <Templateform data={a} />
    case 'GRID':
      return <TemplateGrid data={data?.grid_columns} />
    case 'INLINE_GRID':
      return <TemplateGrid data={data?.grid_columns} />
    default:
      return
  }
}

const Templatetabs = ({ data }) => {
  const data_key = Object.keys(data)
  return (
    <>
      {Object.values(data).map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Col md={12} style={{ border: '2px 0px solid black' }}>
              <div style={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: '1.5rem', margin: '1rem 0' }}>
                {item.title ? item.title : data_key[index]} || ( {item.type})
              </div>
            </Col>

            {renderType(item)}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Templatetabs
