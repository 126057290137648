import { useEffect, useState } from 'react'
import IconSet from '../../CMSComponent/icon'
import { cms } from '../../helper'
import './permission.scss'

const Pages = ({
  data,
  isMainPage,
  expandedMainPageId,
  setExpandedMainPageId,
  onChangeMainPagePermission,
  onChangeChildPagePermission,
  mainPageId,
}) => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    setTableData(data)
  }, [data])

  let isChildAvailable = false
  data?.map((item) => {
    if (item?.child && item?.child?.length > 0) {
      isChildAvailable = true
    }
  })

  const onSearchByName = (value) => {
    if (value && value?.length > 0) {
      const newData = tableData?.filter((data) => {
        if (cms(data?.cms_key)?.toLowerCase().includes(value?.toLowerCase())) {
          return true
        }
      })
      setTableData(newData)
    } else {
      setTableData(data)
    }
  }

  return (
    <table className={`table ${!isMainPage && 'mt-0'}`}>
      {isMainPage && (
        <thead className='thead-light'>
          <tr>
            <th></th>

            <th>Name</th>

            <th className='w-5'>View</th>
            <th className='w-5'>Add</th>
            <th className='w-5'>Edit</th>
            <th className='w-5'> Delete</th>
            <th className='w-5'>Trash</th>
            <th className='w-5'>Restore</th>
            <th className='w-5'>Export</th>
            <th className='w-5'>Print</th>
            <th className='w-5'>Preferance</th>
          </tr>
          <tr>
            {isChildAvailable && <th></th>}

            <th>
              <input type='text' style={{ width: '100%' }} onChange={(e) => onSearchByName(e.target.value)} placeholder='search...'></input>
            </th>

            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
              <th key={key}></th>
            ))}
          </tr>
        </thead>
      )}

      <tbody className='thead-light'>
        {tableData?.map((item, index) => {
          return (
            <>
              <RenderRow
                item={item}
                isChildAvailable={isChildAvailable}
                key={index}
                isMainPageRow={isMainPage}
                expandedMainPageId={expandedMainPageId}
                setExpandedMainPageId={setExpandedMainPageId}
                onChangeMainPagePermission={onChangeMainPagePermission}
                onChangeChildPagePermission={onChangeChildPagePermission}
                mainPageId={mainPageId}
              />
            </>
          )
        })}
      </tbody>
    </table>
  )
}

const RenderRow = ({
  item,
  isChildAvailable,
  isMainPageRow,
  expandedMainPageId,
  setExpandedMainPageId,
  onChangeMainPagePermission,
  onChangeChildPagePermission,
  mainPageId,
}) => {
  const child_name = item?.child?.map((item) => cms(item?.cms_key))

  const isExpanded = expandedMainPageId === item?.id

  return (
    <>
      <tr>
        <td className='expand'>
          {' '}
          <IconSet
            icon={isExpanded ? 'far fa-arrow-down' : 'far fa-arrow-right'}
            color='primary'
            style={{ cursor: 'pointer', marginRight: '0.5rem', visibility: (!item?.child || item?.child?.length === 0) && 'hidden' }}
            onClick={() => {
              if (isExpanded) {
                setExpandedMainPageId(null)
              } else {
                setExpandedMainPageId(item?.id)
              }
            }}
          />
        </td>

        <td className={`${!isMainPageRow && 'pl-4'}`}>{cms(item?.cms_key)}</td>

        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.view}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'view', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'view', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.add}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'add', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'add', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.edit}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'edit', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'edit', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.delete}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'delete', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'delete', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.trash}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'trash', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'trash', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.restore}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'restore', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'restore', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.export}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'export', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'export', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className='checkBox'>
          <input
            type='checkbox'
            checked={item?.print}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'print', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'print', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
        <td className={`checkBox ${!isMainPageRow && 'w-6'}`}>
          <input
            type='checkbox'
            checked={item?.preference}
            onChange={(e) => {
              if (isMainPageRow) {
                onChangeMainPagePermission(item?.id, 'preference', e.target.checked ? 1 : 0)
              } else {
                onChangeChildPagePermission(item?.child_id, mainPageId, 'preference', e.target.checked ? 1 : 0)
              }
            }}></input>
        </td>
      </tr>
      {isExpanded && isChildAvailable && (
        <tr>
          <td colspan={11} className='p-0'>
            <Pages data={item?.child} onChangeChildPagePermission={onChangeChildPagePermission} mainPageId={item?.id} />
          </td>
        </tr>
      )}
    </>
  )
}
export default Pages
