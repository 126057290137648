import React, { Component } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import Select from 'react-select'

import { Button, ListGroup, ListGroupItem, ButtonGroup, UncontrolledTooltip } from 'reactstrap'

import {
  setBackgroundColor,
  setColorScheme,
  setBackgroundImage,
  setBackgroundImageOpacity,
  setEnableBackgroundImage,
  setEnableFixedHeader,
  setEnableHeaderShadow,
  setEnableSidebarShadow,
  setEnableFixedSidebar,
  setEnableFixedFooter,
  setHeaderBackgroundColor,
  setEnablePageTitleSubheading,
  setEnablePageTabsAlt,
  setEnablePageTitleIcon,
  setBodyColor,
  setTitleAreaColor,
  setFontFamily,
  setHeaderFontFamily,
  setSubHeaderFontFamily,
  setLogInTitle,
  setSliderTitle,
  setSliderSubTitle,
  setLogInSubTitle,
  setSignUpTitle,
  setSignUpSubTitle,
  setForgotSubTitle,
  setForgotTitle,
  setLogoTextColor,
  setAuthSubTitleColor,
  setAuthTitleColor,
  setPrimaryColor,
  toggleSignUpEnableDisable,
} from '../../Redux/Reducers/themeOptions'
import SelectColor from './selectColor'
import { request } from '../../Redux/Sagas/requests/api'
import { submitRequest } from '../../Redux/Reducers/CMS'
import { fetchUser } from '../../Redux/Reducers/user'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheck, faThLarge, faCog } from '@fortawesome/free-solid-svg-icons'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { checkAllThemePermission, checkThemePermission } from '../../helper'
import CustomColor from '../../CMSComponent/ThemeOptions/CustomColors'
import SelectCustomColor from '../../CMSComponent/ThemeOptions/CustomColors/selectCustomColor'
import SidebarMain from '../../CMSComponent/ThemeOptions/Sidebar/sidebarMain'
import SlideMain from '../../CMSComponent/ThemeOptions/Slider/slideMain'
import { FileUploader } from 'react-drag-drop-files'
import IconSet from '../../CMSComponent/icon'

const fontOptions = [
  {
    label: 'Sans sarif',
    value: 'sans_sarif',
  },
  {
    label: 'Times New Roman',
    value: 'times_new_roman',
  },
  {
    label: 'Mono space',
    value: 'mono_space',
  },
  {
    label: 'Poppin',
    value: 'poppin',
  },
]

const fileTypes = ['JPG', 'JPEG', 'TIFF', 'PNG']
class ThemeOptions extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
      logo: '',
      auth_text_data: {
        logIn: '',
        forgot: '',
        sliderTitle: '',
        sliderSubTitle: '',
        signUp: '',
      },
    }
  }

  componentDidMount() {
    const { domain, logInTitle, logInSubTitle, signUpTitle, signUpSubTitle, forgotTitle, forgotSubTitle, sliderTitle, sliderSubTitle } = this.props
    this.setState({ logo: domain?.logo })

    this.setState({
      auth_text_data: {
        loginTitle: logInTitle,
        loginSubTitle: logInSubTitle,
        signUpTitle: signUpTitle,
        signUpSubTitle: signUpSubTitle,
        forgotTitle: forgotTitle,
        forgotSubTitle: forgotSubTitle,
        sliderTitle,
        sliderSubTitle,
      },
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  state = {
    showing: false,
  }

  toggleEnableBackgroundImage = () => {
    let { enableBackgroundImage, setEnableBackgroundImage } = this.props
    setEnableBackgroundImage(!enableBackgroundImage)
  }

  toggleEnableFixedHeader = () => {
    let { enableFixedHeader, setEnableFixedHeader } = this.props
    setEnableFixedHeader(!enableFixedHeader)
  }

  toggleEnableHeaderShadow = () => {
    let { enableHeaderShadow, setEnableHeaderShadow } = this.props
    setEnableHeaderShadow(!enableHeaderShadow)
  }

  toggleEnableSidebarShadow = () => {
    let { enableSidebarShadow, setEnableSidebarShadow } = this.props
    setEnableSidebarShadow(!enableSidebarShadow)
  }

  toggleEnableFixedSidebar = () => {
    let { enableFixedSidebar, setEnableFixedSidebar } = this.props
    setEnableFixedSidebar(!enableFixedSidebar)
  }

  toggleEnablePageTitleIcon = () => {
    let { enablePageTitleIcon, setEnablePageTitleIcon } = this.props
    setEnablePageTitleIcon(!enablePageTitleIcon)
  }

  toggleEnablePageTitleSubheading = () => {
    let { enablePageTitleSubheading, setEnablePageTitleSubheading } = this.props
    setEnablePageTitleSubheading(!enablePageTitleSubheading)
  }

  toggleEnablePageTabsAlt = () => {
    let { enablePageTabsAlt, setEnablePageTabsAlt } = this.props
    setEnablePageTabsAlt(!enablePageTabsAlt)
  }

  toggleEnableFixedFooter = () => {
    let { enableFixedFooter, setEnableFixedFooter } = this.props
    setEnableFixedFooter(!enableFixedFooter)
  }

  onChangeFontFamily = (option) => {
    // document.body.className = option.value
    this.props.setFontFamily(option.value)
  }
  onChangeHeaderFontFamily = (option) => {
    // document.body.className = option.value
    this.props.setHeaderFontFamily(option.value)
  }
  onChangeSubHeaderFontFamily = (option) => {
    // document.body.className = option.value
    this.props.setSubHeaderFontFamily(option.value)
  }

  restoreFont = () => {
    this.props.setFontFamily('sans_sarif')
    this.props.setHeaderFontFamily('sans_sarif')
    this.props.setSubHeaderFontFamily('sans_sarif')
  }

  onFileChange = (e, id) => {
    console.log('file:::::::', e, id)
    // const data = form?.filter((item) => item.id === id)[0]
    const formData = new FormData()
    // formData.append("domain_name",data.domain_name)
    formData.append('id', id)
    // formData.append('name', '')
    // formData.append("logo_default",data.logo_default)
    formData.append('logo', e)
    request('set-domain-logo', 'POST', formData).then((res) => {
      // data.logo = res.data.path
      this.setState({ logo: res.data.path })
      this.props.fetchUser()
    })
  }

  onLogoRemove = (id) => {
    this.setState({ logo: '' })
    const formData = new FormData()
    formData.append('id', id)
    formData.append('name', '')
    // formData.append("logo_default",data.logo_default)
    formData.append('logo', null)
    request('set-domain-logo', 'POST', formData).then((res) => {
      // data.logo = res.data.path
      this.setState({ logo: res.data.path })
      this.props.fetchUser()
    })
  }

  render() {
    let {
      backgroundColor,
      setBackgroundColor,

      titleAreaColor,
      setTitleAreaColor,
      bodyColor,
      setBodyColor,

      authSubTitleColor,
      authTitleColor,

      headerBackgroundColor,
      setHeaderBackgroundColor,

      colorScheme,
      setColorScheme,

      backgroundImageOpacity,
      setBackgroundImageOpacity,

      enableFixedHeader,
      enableHeaderShadow,
      enableSidebarShadow,
      enableFixedSidebar,
      enableFixedFooter,

      enablePageTitleIcon,
      enablePageTitleSubheading,
      enablePageTabsAlt,

      enableBackgroundImage,
      backgroundImage,
      setBackgroundImage,

      domain,
      enableRegisterButton,

      Slides,
      themeColors,
      editPageMode,
    } = this.props
    let slides_option = []
    Slides?.map((item) => {
      if (!slides_option.includes(item?.image)) {
        slides_option.push(item?.image)
      }
    })

    // const current_domain = domains?.filter(item=>item.logo_default===1)[0];

    const { showing } = this.state

    const selectedFont = fontOptions.filter((item) => item.value === this.props.fontFamily)
    const selectedHeaderFont = fontOptions.filter((item) => item.value === this.props.headerFontFamily)
    const selectedSubHeaderFont = fontOptions.filter((item) => item.value === this.props.subHeaderFontFamily)

    return (
      <div className={'ui-theme-settings ' + (showing ? 'settings-open' : '')}>
        {checkThemePermission('themeSettings') && (
          <>
            <Button className='btn-open-options no-print' id='TooltipDemo' color='light' onClick={() => this.setState({ showing: !showing })}>
              <FontAwesomeIcon icon={faCog} spin fixedWidth={false} size='2x' />
            </Button>

            <UncontrolledTooltip placement='left' target={'TooltipDemo'}>
              Open Layout Configurator
            </UncontrolledTooltip>
          </>
        )}

        <div className='theme-settings__inner'>
          <PerfectScrollbar>
            <div className='theme-settings__options-wrapper'>
              <h3 className='themeoptions-heading'> Layout Options</h3>
              <div className='p-3'>
                <ListGroup>
                  {checkThemePermission('enableFixedHeader') && (
                    <ListGroupItem>
                      <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                          <div className='widget-content-left mr-3'>
                            <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnableFixedHeader}>
                              <div
                                className={cx('switch-animate', {
                                  'switch-on': enableFixedHeader,
                                  'switch-off': !enableFixedHeader,
                                })}>
                                <input type='checkbox' />
                                <span className='switch-left bg-success'>ON</span>
                                <label>&nbsp;</label>
                                <span className='switch-right bg-success'>OFF</span>
                              </div>
                            </div>
                          </div>
                          <div className='widget-content-left'>
                            <div className='widget-heading'>Fixed Header</div>
                            <div className='widget-subheading'>Makes the header top fixed, always visible!</div>
                          </div>
                          <div
                            className={cx('widget-content-right text-success opacity-6', {
                              'd-block': enableFixedHeader,
                              'd-none': !enableFixedHeader,
                            })}>
                            <FontAwesomeIcon size='2x' icon={faCheck} />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  )}

                  {checkThemePermission('enableFixedSidebar') && (
                    <ListGroupItem>
                      <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                          <div className='widget-content-left mr-3'>
                            <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnableFixedSidebar}>
                              <div
                                className={cx('switch-animate', {
                                  'switch-on': enableFixedSidebar,
                                  'switch-off': !enableFixedSidebar,
                                })}>
                                <input type='checkbox' />
                                <span className='switch-left bg-success'>ON</span>
                                <label>&nbsp;</label>
                                <span className='switch-right bg-success'>OFF</span>
                              </div>
                            </div>
                          </div>
                          <div className='widget-content-left'>
                            <div className='widget-heading'>Fixed Sidebar</div>
                            <div className='widget-subheading'>Makes the sidebar left fixed, always visible!</div>
                          </div>
                          <div
                            className={cx('widget-content-right text-success opacity-6', {
                              'd-block': enableFixedSidebar,
                              'd-none': !enableFixedSidebar,
                            })}>
                            <FontAwesomeIcon size='2x' icon={faCheck} />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  )}

                  {checkThemePermission('enableFixedFooter') && (
                    <ListGroupItem>
                      <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                          <div className='widget-content-left mr-3'>
                            <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnableFixedFooter}>
                              <div
                                className={cx('switch-animate', {
                                  'switch-on': enableFixedFooter,
                                  'switch-off': !enableFixedFooter,
                                })}>
                                <input type='checkbox' />
                                <span className='switch-left bg-success'>ON</span>
                                <label>&nbsp;</label>
                                <span className='switch-right bg-success'>OFF</span>
                              </div>
                            </div>
                          </div>
                          <div className='widget-content-left'>
                            <div className='widget-heading'>Fixed Footer</div>
                            <div className='widget-subheading'>Makes the app footer bottom fixed, always visible!</div>
                          </div>
                          <div
                            className={cx('widget-content-right text-success opacity-6', {
                              'd-block': enableFixedFooter,
                              'd-none': !enableFixedFooter,
                            })}>
                            <FontAwesomeIcon size='2x' icon={faCheck} />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
              {(checkThemePermission('enableHeaderShadow') || checkThemePermission('headerBackgroundColor')) && (
                <>
                  {' '}
                  <h3 className='themeoptions-heading'>
                    <div>Header Options</div>
                    <Button
                      size='sm'
                      color='focus'
                      className={cx('btn-pill btn-shadow btn-wide ml-auto', {
                        active: headerBackgroundColor === '',
                      })}
                      onClick={() => setHeaderBackgroundColor('')}>
                      Restore Default
                    </Button>
                  </h3>
                  <div className='p-3'>
                    <ListGroup>
                      {checkThemePermission('enableHeaderShadow') && (
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnableHeaderShadow}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': enableHeaderShadow,
                                      'switch-off': !enableHeaderShadow,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-success'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-success'>OFF</span>
                                  </div>
                                </div>
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Header Shadow</div>
                                <div className='widget-subheading'>Add a shadow for the header!</div>
                              </div>
                              <div
                                className={cx('widget-content-right text-success opacity-6', {
                                  'd-block': enableHeaderShadow,
                                  'd-none': !enableHeaderShadow,
                                })}>
                                <FontAwesomeIcon size='2x' icon={faCheck} />
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('headerBackgroundColor') && (
                        <ListGroupItem>
                          <h5 className='pb-2'>Choose Color Scheme</h5>
                          <SelectColor activeColor={headerBackgroundColor} setColor={setHeaderBackgroundColor} />
                          <div className='divider' />
                          {editPageMode && checkThemePermission('editCustomColor') && <CustomColor />}
                          <h5 className='pb-2'>Choose Header Background Color From Custom Color Scheme</h5>
                          <SelectCustomColor activeColor={headerBackgroundColor} setColor={setHeaderBackgroundColor} customColors={themeColors} />
                        </ListGroupItem>
                      )}
                    </ListGroup>

                    {/* <div className='theme-settings-swatches'>
                      {tempColorData.map((data) => (
                        <div
                          className='swatch-holder'
                          // className={cx('swatch-holder bg-primary', {
                          //   active: activeColor === 'bg-primary sidebar-text-light' || activeColor === 'bg-primary-text sidebar-text-light',
                          // })}
                          // onClick={() => setColor(`bg-primary${isFont ? '-text' : ''} sidebar-text-light`)}
                          style={{ backgroundColor: data.bgColor }}
                        />
                      ))}
                    </div> */}
                  </div>
                </>
              )}

              {checkThemePermission('uploadLogo') && (
                <>
                  <h3 className='themeoptions-heading'>
                    <div>Logo</div>
                    {/* <Button
                  size="sm"
                  color="focus"
                  className={cx("btn-pill btn-shadow btn-wide ml-auto", {
                    active: headerBackgroundColor === "",
                  })}
                  onClick={() => setHeaderBackgroundColor("")}
                >
                  Restore Default
                </Button> */}
                  </h3>
                  <ListGroup>
                    <ListGroupItem>
                      <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                          <div className='widget-content-left mr-3'></div>
                          <div className='widget-content-left'>
                            <div className='widget-heading'>Upload logo for {domain?.name}</div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <FileUploader
                        handleChange={(e) => this.onFileChange(e, domain.id)}
                        name='file'
                        types={fileTypes}
                        multiple={false}
                        // fileOrFiles={file}
                        classes={'upload-file-form'}>
                        <div className='upload_container'>
                          <span style={{ marginRight: '1rem', fontSize: '2rem' }}>
                            <IconSet icon='fa-solid fa-folder-arrow-up' color='info' />
                          </span>
                          Upload logo here!
                        </div>
                      </FileUploader>
                      {/* <AvForm>
                        <AvField
                          name='file_upload'
                          label='Upload logo'
                          type='file'
                          onChange={(e) => this.onFileChange(e, domain.id)}
                          multiple={false}
                        />
                      </AvForm> */}
                      {this.state.logo && (
                        <div>
                          <img src={`${process.env.REACT_APP_STORAGE}${this.state.logo}`} width='100px' style={{ objectFit: 'cover' }} />
                        </div>
                      )}
                      {this.state.logo && (
                        <Button onClick={() => this.onLogoRemove(domain.id)} style={{ marginTop: '0.7rem' }}>
                          Remove Logo
                        </Button>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </>
              )}

              {(checkThemePermission('fontFamily') || checkThemePermission('headerFontFamily') || checkThemePermission('subheaderFontFamily')) && (
                <>
                  <h3 className='themeoptions-heading'>
                    <div>Font Options</div>
                    <Button
                      size='sm'
                      color='focus'
                      className={cx('btn-pill btn-shadow btn-wide ml-auto', {
                        active: headerBackgroundColor === '',
                      })}
                      onClick={() => this.restoreFont('')}>
                      Restore Default
                    </Button>
                  </h3>
                  <ListGroup>
                    <ListGroupItem>
                      <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                          <div className='widget-content-left mr-3'></div>
                          <div className='widget-content-left'>
                            <div className='widget-heading'>Select Font family</div>
                            <div className='widget-subheading'>Add a Font family!</div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    {checkThemePermission('fontFamily') && (
                      <>
                        {' '}
                        <ListGroupItem>
                          <h5 className='pb-2'>Choose Body Font Family</h5>
                          {/* <AvField type='hidden' id="fontFamily" name='fontFamily' value="sans_sarif"/> */}
                          <Select
                            options={fontOptions}
                            value={selectedFont[0]}
                            onChange={this.onChangeFontFamily}
                            // id={id}
                            // onChange={(e) =>
                            //   changeOption(e.value, data.component_call_actions, data.field_id)
                            // }
                            // value={selectedOption}
                            styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
                          />
                        </ListGroupItem>
                      </>
                    )}
                    {checkThemePermission('headerFontFamily') && (
                      <>
                        {' '}
                        <ListGroupItem>
                          <h5 className='pb-2'>Choose Header Font Family</h5>
                          {/* <AvField type='hidden' id="fontFamily" name='fontFamily' value="sans_sarif"/> */}
                          <Select
                            options={fontOptions}
                            value={selectedHeaderFont[0]}
                            onChange={this.onChangeHeaderFontFamily}
                            // id={id}
                            // onChange={(e) =>
                            //   changeOption(e.value, data.component_call_actions, data.field_id)
                            // }
                            // value={selectedOption}
                            styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
                          />
                        </ListGroupItem>
                      </>
                    )}
                    {checkThemePermission('subHeaderFontFamily') && (
                      <ListGroupItem>
                        <h5 className='pb-2'>Choose Sub header Font Family</h5>
                        {/* <AvField type='hidden' id="fontFamily" name='fontFamily' value="sans_sarif"/> */}
                        <Select
                          options={fontOptions}
                          value={selectedSubHeaderFont[0]}
                          onChange={this.onChangeSubHeaderFontFamily}
                          // id={id}
                          // onChange={(e) =>
                          //   changeOption(e.value, data.component_call_actions, data.field_id)
                          // }
                          // value={selectedOption}
                          styles={{ menu: (base) => ({ ...base, zIndex: 15 }) }}
                        />
                      </ListGroupItem>
                    )}
                  </ListGroup>
                </>
              )}

              {(checkThemePermission('bodyColor') || checkThemePermission('titleAreaColor')) && (
                <>
                  {' '}
                  <h3 className='themeoptions-heading'>
                    <div>Background Options</div>
                    <Button
                      size='sm'
                      color='focus'
                      className={cx('btn-pill btn-shadow btn-wide ml-auto', {
                        active: headerBackgroundColor === '',
                      })}
                      onClick={() => setHeaderBackgroundColor('')}>
                      Restore Default
                    </Button>
                  </h3>
                  <div className='p-3'>
                    {checkThemePermission('titleAreaColor') && (
                      <ListGroup>
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'></div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Title area color</div>
                                <div className='widget-subheading'>Add a title area color!</div>
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          <h5 className='pb-2'>Choose Color Scheme</h5>
                          <SelectColor activeColor={titleAreaColor} setColor={setTitleAreaColor} />
                          <div className='divider' />
                          {editPageMode && checkThemePermission('editCustomColor') && <CustomColor />}
                          <h5 className='pb-2'>Choose Title Area Color From Custom Color Scheme</h5>
                          <SelectCustomColor activeColor={titleAreaColor} setColor={setTitleAreaColor} customColors={themeColors} />
                        </ListGroupItem>
                      </ListGroup>
                    )}

                    {checkThemePermission('bodyColor') && (
                      <ListGroup>
                        {' '}
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'></div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Body color</div>
                                <div className='widget-subheading'>Add a body color!</div>
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>{' '}
                        <ListGroupItem>
                          <h5 className='pb-2'>Choose Color Scheme</h5>
                          <SelectColor activeColor={bodyColor} setColor={setBodyColor} />
                          <div className='divider' />
                          {editPageMode && checkThemePermission('editCustomColor') && <CustomColor />}
                          <h5 className='pb-2'>Choose Body Color From Custom Color Scheme</h5>
                          <SelectCustomColor activeColor={bodyColor} setColor={setBodyColor} customColors={themeColors} />
                        </ListGroupItem>
                      </ListGroup>
                    )}
                  </div>
                </>
              )}

              {(checkThemePermission('enableBackgroundImage') ||
                checkThemePermission('enableSidebarShadow') ||
                checkThemePermission('backgroundColor') ||
                checkThemePermission('backgroundImageOpacity') ||
                checkThemePermission('backgroundImage')) && (
                <>
                  <h3 className='themeoptions-heading'>
                    <div>Sidebar Options</div>
                    <Button
                      size='sm'
                      color='focus'
                      className={cx('btn-pill btn-shadow btn-wide ml-auto', {
                        active: backgroundColor === '',
                      })}
                      onClick={() => setBackgroundColor('')}>
                      Restore Default
                    </Button>
                  </h3>
                  <div className='p-3'>
                    <ListGroup>
                      {checkThemePermission('enableBackgroundImage') && (
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnableBackgroundImage}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': enableBackgroundImage,
                                      'switch-off': !enableBackgroundImage,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-success'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-success'>OFF</span>
                                  </div>
                                </div>
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Sidebar Background Image</div>
                                <div className='widget-subheading'>Enable background images for sidebar!</div>
                              </div>
                              <div
                                className={cx('widget-content-right text-success opacity-6', {
                                  'd-block': enableBackgroundImage,
                                  'd-none': !enableBackgroundImage,
                                })}>
                                <FontAwesomeIcon size='2x' icon={faCheck} />
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('enableSidebarShadow') && (
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnableSidebarShadow}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': enableSidebarShadow,
                                      'switch-off': !enableSidebarShadow,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-success'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-success'>OFF</span>
                                  </div>
                                </div>
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Sidebar Shadow</div>
                                <div className='widget-subheading'>Add a shadow for the sidebar!</div>
                              </div>
                              <div
                                className={cx('widget-content-right text-success opacity-6', {
                                  'd-block': enableSidebarShadow,
                                  'd-none': !enableSidebarShadow,
                                })}>
                                <FontAwesomeIcon size='2x' icon={faCheck} />
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}

                      {checkThemePermission('backgroundColor') && (
                        <ListGroupItem>
                          <h5 className='pb-2'>Sidebar Background Color</h5>
                          <SelectColor activeColor={backgroundColor} setColor={setBackgroundColor} isFont={false} />
                          <div className='divider' />
                          {editPageMode && checkThemePermission('editCustomColor') && <CustomColor />}
                          <h5 className='pb-2'>Choose Sidebar Background Color From Custom Color Scheme</h5>
                          <SelectCustomColor activeColor={backgroundColor} setColor={setBackgroundColor} customColors={themeColors} />
                        </ListGroupItem>
                      )}

                      {checkThemePermission('backgroundImageOpacity') && (
                        <ListGroupItem
                          className={cx('theme-settings-swatches', {
                            'd-block': enableBackgroundImage,
                            'd-none': !enableBackgroundImage,
                          })}>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Opacity</div>
                              </div>
                              <div className='widget-content-right'>
                                <ButtonGroup size='sm'>
                                  <Button
                                    className={cx('btn-shadow opacity-3', {
                                      active: backgroundImageOpacity === 'opacity-04',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-04')}>
                                    4%
                                  </Button>
                                  <Button
                                    className={cx('btn-shadow opacity-4', {
                                      active: backgroundImageOpacity === 'opacity-06',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-06')}>
                                    6%
                                  </Button>
                                  <Button
                                    className={cx('btn-shadow opacity-5', {
                                      active: backgroundImageOpacity === 'opacity-08',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-08')}>
                                    8%
                                  </Button>
                                  <Button
                                    className={cx('btn-shadow opacity-6', {
                                      active: backgroundImageOpacity === 'opacity-1',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-1')}>
                                    10%
                                  </Button>
                                  <Button
                                    className={cx('btn-shadow opacity-7', {
                                      active: backgroundImageOpacity === 'opacity-15',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-15')}>
                                    15%
                                  </Button>
                                  <Button
                                    className={cx('btn-shadow opacity-8', {
                                      active: backgroundImageOpacity === 'opacity-2',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-2')}>
                                    20%
                                  </Button>
                                  <Button
                                    className={cx('btn-shadow opacity-8', {
                                      active: backgroundImageOpacity === 'opacity-5',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-5')}>
                                    50%
                                  </Button>
                                  <Button
                                    className={cx('btn-shadow opacity-8', {
                                      active: backgroundImageOpacity === 'opacity-7',
                                    })}
                                    color='primary'
                                    onClick={() => setBackgroundImageOpacity('opacity-7')}>
                                    70%
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('backgroundImage') && (
                        <>
                          <SidebarMain backgroundImage={backgroundImage} setBackgroundImage={setBackgroundImage} slides_option={slides_option} />
                        </>
                      )}
                    </ListGroup>
                  </div>
                </>
              )}

              {(checkThemePermission('sliderTitle') ||
                checkThemePermission('sliderSubTitle') ||
                checkThemePermission('logInTitle') ||
                checkThemePermission('logInSubTitle') ||
                checkThemePermission('signUpTitle') ||
                checkThemePermission('signUpSubTitle') ||
                checkThemePermission('forgotTitle') ||
                checkThemePermission('forgotSubTitle') ||
                checkThemePermission('authTitleColor') ||
                checkThemePermission('authSubTitleColor') ||
                checkThemePermission('enableRegisterButton')) && (
                <>
                  <h3 className='themeoptions-heading'>
                    <div>Welcome page</div>
                  </h3>
                  <div className='p-3'>
                    <ListGroup>
                      {checkThemePermission('sliderTitle') && (
                        <>
                          <ListGroupItem>
                            <div className='widget-content p-0'>
                              <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'></div>
                                <div className='widget-content-left'>
                                  <div className='widget-heading'>Slide</div>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                          <SlideMain />
                        </>
                      )}

                      {checkThemePermission('logInTitle') && (
                        <ListGroupItem>
                          <AvForm>
                            <div style={{ display: 'flex' }}>
                              <AvField
                                name='login_title'
                                id='login_title'
                                label='Login Page Title'
                                value={this.state.auth_text_data.loginTitle}
                                onChange={(e) => {
                                  this.setState({
                                    auth_text_data: {
                                      ...this.state.auth_text_data,
                                      loginTitle: e.target.value,
                                    },
                                  })
                                }}></AvField>
                              <Button onClick={() => this.props.setLogInTitle(this.state.auth_text_data.loginTitle)} className='theme_auth_button'>
                                Save
                              </Button>
                            </div>
                          </AvForm>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('logInSubTitle') && (
                        <ListGroupItem>
                          <AvForm>
                            <div style={{ display: 'flex' }}>
                              <AvField
                                name='login_sub_title'
                                id='login_sub_title'
                                label='Login Page Subtitle'
                                value={this.state.auth_text_data.loginSubTitle}
                                onChange={(e) => {
                                  this.setState({
                                    auth_text_data: {
                                      ...this.state.auth_text_data,
                                      loginSubTitle: e.target.value,
                                    },
                                  })
                                }}
                              />
                              <Button
                                onClick={() => this.props.setLogInSubTitle(this.state.auth_text_data.loginSubTitle)}
                                className='theme_auth_button'>
                                Save
                              </Button>
                            </div>
                          </AvForm>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('signUpTitle') && (
                        <ListGroupItem>
                          <AvForm>
                            <div style={{ display: 'flex' }}>
                              <AvField
                                name='signup_title'
                                id='signup_title'
                                label='SignUp Page Title'
                                value={this.state.auth_text_data.signUpTitle}
                                onChange={(e) => {
                                  this.setState({
                                    auth_text_data: {
                                      ...this.state.auth_text_data,
                                      signUpTitle: e.target.value,
                                    },
                                  })
                                }}
                              />
                              <Button onClick={() => this.props.setSignUpTitle(this.state.auth_text_data.signUpTitle)} className='theme_auth_button'>
                                Save
                              </Button>
                            </div>
                          </AvForm>
                        </ListGroupItem>
                      )}

                      {checkThemePermission('signUpSubTitle') && (
                        <ListGroupItem>
                          <AvForm>
                            <div style={{ display: 'flex' }}>
                              <AvField
                                name='signup_sub_title'
                                id='signup_sub_title'
                                label='Signup Page Sub Title'
                                value={this.state.auth_text_data.signUpSubTitle}
                                onChange={(e) => {
                                  this.setState({
                                    auth_text_data: {
                                      ...this.state.auth_text_data,
                                      signUpSubTitle: e.target.value,
                                    },
                                  })
                                }}
                              />
                              <Button
                                onClick={() => this.props.setSignUpSubTitle(this.state.auth_text_data.signUpSubTitle)}
                                className='theme_auth_button'>
                                Save
                              </Button>
                            </div>
                          </AvForm>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('forgotTitle') && (
                        <ListGroupItem>
                          <AvForm>
                            <div style={{ display: 'flex' }}>
                              <AvField
                                name='forgot_page_title'
                                id='forgot_page_title'
                                label='Forgot Page Title'
                                value={this.state.auth_text_data.forgotTitle}
                                onChange={(e) => {
                                  this.setState({
                                    auth_text_data: {
                                      ...this.state.auth_text_data,
                                      forgotTitle: e.target.value,
                                    },
                                  })
                                }}
                              />
                              <Button onClick={() => this.props.setForgotTitle(this.state.auth_text_data.forgotTitle)} className='theme_auth_button'>
                                Save
                              </Button>
                            </div>
                          </AvForm>
                        </ListGroupItem>
                      )}

                      {checkThemePermission('forgotSubTitle') && (
                        <ListGroupItem>
                          <AvForm>
                            <div style={{ display: 'flex' }}>
                              <AvField
                                name='forgot_page_sub_title'
                                id='forgot_page_sub_title'
                                label='Forgot Page Subtitle'
                                value={this.state.auth_text_data.forgotSubTitle}
                                onChange={(e) => {
                                  this.setState({
                                    auth_text_data: {
                                      ...this.state.auth_text_data,
                                      forgotSubTitle: e.target.value,
                                    },
                                  })
                                }}
                              />
                              <Button
                                onClick={() => this.props.setForgotSubTitle(this.state.auth_text_data.forgotSubTitle)}
                                className='theme_auth_button'>
                                Save
                              </Button>
                            </div>
                          </AvForm>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('enableRegisterButton') && (
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <div
                                  className='switch has-switch'
                                  data-on-label='ON'
                                  data-off-label='OFF'
                                  onClick={() => this.props.toggleSignUpEnableDisable(!enableRegisterButton)}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': enableRegisterButton,
                                      'switch-off': !enableRegisterButton,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-success'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-success'>OFF</span>
                                  </div>
                                </div>
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Sign up</div>
                                <div className='widget-subheading'>Enable the register page!</div>
                              </div>
                              <div
                                className={cx('widget-content-right text-success opacity-6', {
                                  'd-block': enablePageTitleIcon,
                                  'd-none': !enablePageTitleIcon,
                                })}>
                                <FontAwesomeIcon size='2x' icon={faCheck} />
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}

                      {checkThemePermission('authTitleColor') && (
                        <ListGroupItem>
                          <h5 className='pb-2'>Choose Title Color</h5>
                          <SelectColor activeColor={authTitleColor} setColor={this.props.setAuthTitleColor} isFont={true} />
                          {/* <FormColorPicker defaultValue={authTitleColor} onChange={(id,color)=>{this.props.setAuthTitleColor(color)}}/> */}
                          <div className='divider' />
                          {editPageMode && checkThemePermission('editCustomColor') && <CustomColor />}
                          <h5 className='pb-2'>Choose Title Color From Custom Color Scheme</h5>
                          <SelectCustomColor activeColor={authTitleColor} setColor={this.props.setAuthTitleColor} customColors={themeColors} />
                        </ListGroupItem>
                      )}
                      {checkThemePermission('authSubTitleColor') && (
                        <ListGroupItem>
                          <h5 className='pb-2'>Choose Sub Title Color</h5>
                          <SelectColor activeColor={authSubTitleColor} setColor={this.props.setAuthSubTitleColor} isFont={true} />
                          <div className='divider' />
                          {editPageMode && checkThemePermission('editCustomColor') && <CustomColor />}
                          <h5 className='pb-2'>Choose Sub Title Color From Custom Color Scheme</h5>
                          <SelectCustomColor activeColor={authSubTitleColor} setColor={this.props.setAuthSubTitleColor} customColors={themeColors} />
                          {/* <FormColorPicker defaultValue={authSubTitleColor} onChange={(id,color)=>{this.props.setAuthSubTitleColor(color)}}/> */}
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </div>
                </>
              )}

              {checkThemePermission('primaryColor') && (
                <>
                  <h3 className='themeoptions-heading'>Primary Color</h3>
                  <ListGroup>
                    <ListGroupItem>
                      <h5 className='pb-2'>Choose Primary Color</h5>
                      <SelectColor
                        activeColor={this.props.primaryColor}
                        setColor={this.props.setPrimaryColor}
                        // isFont={true}
                      />
                      <div className='divider' />
                      {editPageMode && checkThemePermission('editCustomColor') && <CustomColor />}
                      <h5 className='pb-2'>Choose Primary Color From Custom Color Scheme</h5>
                      <SelectCustomColor activeColor={this.props.primaryColor} setColor={this.props.setPrimaryColor} customColors={themeColors} />
                    </ListGroupItem>
                  </ListGroup>
                </>
              )}

              {(checkThemePermission('enablePageTitleIcon') ||
                checkThemePermission('enablePageTitleSubheading') ||
                checkThemePermission('enablePageTabsAlt') ||
                checkThemePermission('colorScheme')) && (
                <>
                  {' '}
                  <h3 className='themeoptions-heading'>
                    <div>Main Content Options</div>
                    <Button
                      size='sm'
                      color='focus'
                      className={cx('btn-pill btn-shadow btn-wide ml-auto', {
                        active: colorScheme === 'white',
                      })}
                      onClick={() => setColorScheme('white')}>
                      Restore Default
                    </Button>
                  </h3>
                  <div className='p-3'>
                    <ListGroup>
                      {checkThemePermission('enablePageTitleIcon') && (
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnablePageTitleIcon}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': enablePageTitleIcon,
                                      'switch-off': !enablePageTitleIcon,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-success'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-success'>OFF</span>
                                  </div>
                                </div>
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Page Title Icon</div>
                                <div className='widget-subheading'>Enable the icon box for page titles!</div>
                              </div>
                              <div
                                className={cx('widget-content-right text-success opacity-6', {
                                  'd-block': enablePageTitleIcon,
                                  'd-none': !enablePageTitleIcon,
                                })}>
                                <FontAwesomeIcon size='2x' icon={faCheck} />
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('enablePageTitleSubheading') && (
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <div
                                  className='switch has-switch'
                                  data-on-label='ON'
                                  data-off-label='OFF'
                                  onClick={this.toggleEnablePageTitleSubheading}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': enablePageTitleSubheading,
                                      'switch-off': !enablePageTitleSubheading,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-success'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-success'>OFF</span>
                                  </div>
                                </div>
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Page Title Description</div>
                                <div className='widget-subheading'>Enable the description below page title!</div>
                              </div>
                              <div
                                className={cx('widget-content-right text-success opacity-6', {
                                  'd-block': enablePageTitleSubheading,
                                  'd-none': !enablePageTitleSubheading,
                                })}>
                                <FontAwesomeIcon size='2x' icon={faCheck} />
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('enablePageTabsAlt') && (
                        <ListGroupItem>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <div className='switch has-switch' data-on-label='ON' data-off-label='OFF' onClick={this.toggleEnablePageTabsAlt}>
                                  <div
                                    className={cx('switch-animate', {
                                      'switch-on': enablePageTabsAlt,
                                      'switch-off': !enablePageTabsAlt,
                                    })}>
                                    <input type='checkbox' />
                                    <span className='switch-left bg-success'>ON</span>
                                    <label>&nbsp;</label>
                                    <span className='switch-right bg-success'>OFF</span>
                                  </div>
                                </div>
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>Page Section Shadow Tabs</div>
                                <div className='widget-subheading'>Enable an alternate style for the page sections tabs!</div>
                              </div>
                              <div
                                className={cx('widget-content-right text-success opacity-6', {
                                  'd-block': enablePageTabsAlt,
                                  'd-none': !enablePageTabsAlt,
                                })}>
                                <FontAwesomeIcon size='2x' icon={faCheck} />
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                      {checkThemePermission('colorScheme') && (
                        <ListGroupItem>
                          <h5 className='pb-2'>Light Color Schemes</h5>
                          <div className='theme-settings-swatches'>
                            <ButtonGroup className='mt-2'>
                              <Button
                                className={cx('btn-wide btn-shadow btn-primary', {
                                  active: colorScheme === 'white',
                                })}
                                onClick={() => setColorScheme('white')}>
                                White Theme
                              </Button>
                              <Button
                                className={cx('btn-wide btn-shadow btn-primary', {
                                  active: colorScheme === 'gray',
                                })}
                                onClick={() => setColorScheme('gray')}>
                                Gray Theme
                              </Button>
                            </ButtonGroup>
                          </div>
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </div>
                </>
              )}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  backgroundColor: state.ThemeOptions.backgroundColor,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,

  colorScheme: state.ThemeOptions.colorScheme,

  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,

  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  backgroundImage: state.ThemeOptions.backgroundImage,

  bodyColor: state.ThemeOptions.bodyColor,
  titleAreaColor: state.ThemeOptions.titleAreaColor,

  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,

  fontFamily: state.ThemeOptions.fontFamily,
  headerFontFamily: state.ThemeOptions.headerFontFamily,
  subHeaderFontFamily: state.ThemeOptions.subHeaderFontFamily,
  logInTitle: state.ThemeOptions.logInTitle,
  logInSubTitle: state.ThemeOptions.logInSubTitle,
  sliderTitle: state.ThemeOptions.sliderTitle,
  sliderSubTitle: state.ThemeOptions.sliderSubTitle,
  signUpTitle: state.ThemeOptions.signUpTitle,
  signUpSubTitle: state.ThemeOptions.signUpSubTitle,
  forgotTitle: state.ThemeOptions.forgotTitle,
  forgotSubTitle: state.ThemeOptions.forgotSubTitle,
  authTitleColor: state.ThemeOptions.authTitleColor,
  authSubTitleColor: state.ThemeOptions.authSubTitleColor,
  primaryColor: state.ThemeOptions.primaryColor,

  Slides: state.Slides.slides,

  domain: state?.User?.user?.domain,
  enableRegisterButton: state.ThemeOptions.enableRegisterButton,
  themeColors: state.CMS.themeColors,
  editPageMode: state.CMS.editPageMode,
})

const mapDispatchToProps = (dispatch) => ({
  setEnableBackgroundImage: (enable) => dispatch(setEnableBackgroundImage(enable)),

  setEnableFixedHeader: (enable) => dispatch(setEnableFixedHeader(enable)),
  setEnableHeaderShadow: (enable) => dispatch(setEnableHeaderShadow(enable)),
  setEnableSidebarShadow: (enable) => dispatch(setEnableSidebarShadow(enable)),
  setEnableFixedFooter: (enable) => dispatch(setEnableFixedFooter(enable)),
  setEnableFixedSidebar: (enable) => dispatch(setEnableFixedSidebar(enable)),

  setEnablePageTitleIcon: (enable) => dispatch(setEnablePageTitleIcon(enable)),
  setEnablePageTitleSubheading: (enable) => dispatch(setEnablePageTitleSubheading(enable)),
  setEnablePageTabsAlt: (enable) => dispatch(setEnablePageTabsAlt(enable)),

  setBackgroundImage: (image) => dispatch(setBackgroundImage(image)),

  setColorScheme: (color) => dispatch(setColorScheme(color)),

  setBackgroundColor: (color) => dispatch(setBackgroundColor(color)),

  setBodyColor: (color) => dispatch(setBodyColor(color)),

  setTitleAreaColor: (color) => dispatch(setTitleAreaColor(color)),

  setHeaderBackgroundColor: (color) => dispatch(setHeaderBackgroundColor(color)),

  setBackgroundImageOpacity: (color) => dispatch(setBackgroundImageOpacity(color)),

  setFontFamily: (font) => dispatch(setFontFamily(font)),
  setHeaderFontFamily: (font) => dispatch(setHeaderFontFamily(font)),
  setSubHeaderFontFamily: (font) => dispatch(setSubHeaderFontFamily(font)),
  setLogInTitle: (title) => dispatch(setLogInTitle(title)),
  setSliderTitle: (title) => dispatch(setSliderTitle(title)),
  setSliderSubTitle: (title) => dispatch(setSliderSubTitle(title)),
  setLogInSubTitle: (title) => dispatch(setLogInSubTitle(title)),
  setSignUpTitle: (title) => dispatch(setSignUpTitle(title)),
  setSignUpSubTitle: (title) => dispatch(setSignUpSubTitle(title)),
  setForgotTitle: (title) => dispatch(setForgotTitle(title)),
  setForgotSubTitle: (title) => dispatch(setForgotSubTitle(title)),
  setLogoTextColor: (color) => dispatch(setLogoTextColor(color)),
  setAuthTitleColor: (color) => dispatch(setAuthTitleColor(color)),
  setAuthSubTitleColor: (color) => dispatch(setAuthSubTitleColor(color)),
  setPrimaryColor: (color) => dispatch(setPrimaryColor(color)),
  onLogoSubmit: (payload) =>
    dispatch(
      submitRequest({
        url: 'set-domains',
        data: { domains: payload },
      })
    ),
  fetchUser: () => dispatch(fetchUser()),
  toggleSignUpEnableDisable: (enable) => dispatch(toggleSignUpEnableDisable(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThemeOptions)
