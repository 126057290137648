import React from 'react'
import { connect } from 'react-redux'

import NoData from '../../PageVariation/noData'
import ButtonMenu from '../ContentForms/buttonMenu'
import PageWithTabs from '../ContentForms/PageWithTabs'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
})

const EditTopNavPage = ({ mainPage, editPageMode }) => {
  if (!editPageMode && mainPage === null) return null
  switch (mainPage.component_name) {
    case 'page_with_tabs':
      return <PageWithTabs mainPage={mainPage} />
    case 'button_menu':
      return <ButtonMenu mainPage={mainPage} />
    case 'horizontal_menu':
      return (
        <NoData message="Swith Database data will come from database-list API and submited on database-switch (Please create those API if you didn't created it yet.)" />
      )
    default:
      return <NoData />
  }
}

export default connect(select)(EditTopNavPage)
