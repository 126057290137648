import { useHistory } from "react-router-dom"
import { Button } from "reactstrap"


const AccessDenied = () =>{
  const history = useHistory()
  return (
    <div style={{marginTop: '10rem',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <div style={{ textAlign: 'center', fontSize: '1.3rem',marginBottom:'2rem' }}>
        You don't have permission to access. Please contact your admin!
      </div>
      <Button color='danger' onClick={()=>history.push('/logout')}> Log out</Button>
    </div>
  ) 
}

export default AccessDenied