import { Card, Nav, NavItem, DropdownItem, InputGroup, InputGroupAddon, Input, Modal, ModalHeader, ModalBody, Dropdown, Button } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfiniteScroll from 'react-infinite-scroll-component'

import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { clearConnectTemplateReduxData, getPayloadToLoadGridData } from '../../../CMSComponent/Grid/Component/MainGrid/gridHelper'
import { getConnectTemplateReduxDataAfterDelete } from '../../../helper'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setConnectTemplateDataRedux } from '../../../Redux/Reducers/CMS'
import IconSet from '../../../CMSComponent/icon'
import { tidyName } from '../../../helper'

import AddUserInMessage from '../../../CMSComponent/Grid/Component/AddGridData/Component/PageSpecificForm/addUserInChat'
import { setQueue } from '../../../Redux/Reducers/queue'

const ChatSideBar = ({ pageData, setSelectedUserId, selectedUserId, showLoader = () => {} }) => {
  const [sidebarData, setSidebarData] = useState()

  const [searchData, setSearchData] = useState()
  const chatPageData = pageData?.component_call_buttons?.find((tab) => tab?.component_name === 'hidden_tab')
  const main_grid = chatPageData?.component_call_form?.[0]?.main_grid
  const [currentPageData, setCurrentPageData] = useState()
  const [addDataDetail, setAddGridDataDetail] = useState()

  const tableGrid = chatPageData?.component_call_form?.[0]
  const selectedGrid = 'main_grid'
  const page_data = pageData
  const currentTabData = chatPageData
  const parentPageName = pageData?.name
  const pageName = pageData?.name
  const content = chatPageData?.component_call_form?.[0]

  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const updateApiData = useSelector((state) => state.CMS?.updateApiData?.updated_data)
  const dispatch = useDispatch()
  const [gridUpdated, updateGridUpdated] = useState()
  const [gridNotSyncWithDB, setGridNotSyncWithDB] = useState(false)
  // const [hoveredUserId,setHoveredUserId] = useState()

  const primaryColor = useSelector((theme) => theme.ThemeOptions.primaryColor)
  const linkClassName = primaryColor?.split(' ')[0] + '-text'

  const gridRelatedData = {
    tableGrid,
    selectedGrid,
    page_data,
    currentTabData,
    parentPageName,
    pageName,
    content,
  }

  const updateDataInRedux = (res) => {
    let connectTemplateDataReduxToUpdate = {
      ...connectTemplateDataRedux,
    }
    connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

    let newData = {}
    if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
      newData = {
        ...connectTemplateDataRedux[parentPageName]?.[pageName],
      }
    }

    newData[res?.data?.meta?.current_page] = {
      ...res,
      reduxUpdatedTime: new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' }),
    }
    updateGridUpdated(new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' }))

    connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData

    dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxToUpdate))
  }
  useEffect(() => {
    if (updateApiData[content?.source_table] && updateApiData[content?.source_table]?.length > 0 && gridUpdated) {
      const gridUpdatedDate = gridUpdated && new Date(gridUpdated)
      var offset = gridUpdatedDate.getTimezoneOffset()
      const utcGridUpdated = new Date(gridUpdatedDate.getTime() + offset * 60000)
      const lastSyncDate = gridUpdated && new Date(utcGridUpdated)
      const lastUpdatedDateInDB = new Date(updateApiData[content?.source_table])
      if (lastSyncDate - lastUpdatedDateInDB < 0) {
        setGridNotSyncWithDB(true)
        clearConnectTemplateReduxData({ pageName, parentPageName })
        // clearDeletedIdsRedux(parentPageName, pageName, content?.source_table, currentRow && currentRow?.length > 0 && !isModel)
      } else {
        setGridNotSyncWithDB(false)
      }
    }
  }, [updateApiData, gridUpdated])

  const fetchData = () => {
    let payload = getPayloadToLoadGridData(gridRelatedData)
    if (searchData) {
      payload = {
        ...payload,
        '-filter-search': searchData,
      }
    }
    request('/connect-template', 'POST', payload).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data
        const { note, ...rest } = data
        const side_bar_data = []
        Object.keys(rest)?.map((key) => {
          side_bar_data?.push(rest[key])
        })
        if (!searchData) {
          updateDataInRedux(res)
        }
        setSidebarData(side_bar_data)
        if (selectedUserId?.id) {
          const selectedUser = side_bar_data?.find((user) => user?.id === selectedUserId?.id)
          setSelectedUserId(selectedUser)
        } else {
          setSelectedUserId(side_bar_data[0])
        }

        const currentPage = {
          current_page: res?.data?.meta?.current_page,
          hasMore: res?.data?.meta?.current_page !== res?.data?.meta?.last_page,
        }

        setCurrentPageData(currentPage)
      }
    })
  }

  useEffect(() => {
    if (main_grid) {
      if (connectTemplateDataRedux && connectTemplateDataRedux[parentPageName]?.[pageName]?.[1] && !searchData) {
        const res = connectTemplateDataRedux && connectTemplateDataRedux[parentPageName]?.[pageName]?.[1]
        const data = res?.data?.data
        const { note, ...rest } = data
        const side_bar_data = []
        Object.keys(rest)?.map((key) => {
          side_bar_data?.push(rest[key])
        })
        setSidebarData(side_bar_data)
        updateGridUpdated(res?.reduxUpdatedTime)
        if (selectedUserId?.id) {
          const selectedUser = side_bar_data?.find((user) => user?.id === selectedUserId?.id)
          setSelectedUserId(selectedUser)
        } else {
          setSelectedUserId(side_bar_data[0])
        }
        // setSelectedUserId(side_bar_data?.[0])
        const currentPage = {
          current_page: res?.data?.meta?.current_page,
          hasMore: res?.data?.meta?.current_page !== res?.data?.meta?.last_page,
        }

        setCurrentPageData(currentPage)
      } else {
        fetchData()
      }
    }
  }, [main_grid, connectTemplateDataRedux, gridNotSyncWithDB])

  const fetchMoreData = () => {
    let payload = getPayloadToLoadGridData(gridRelatedData)
    if (searchData) {
      payload = {
        ...payload,
        '-filter-name': searchData,
      }
    }
    if (connectTemplateDataRedux && connectTemplateDataRedux[parentPageName]?.[pageName]?.[currentPageData?.current_page] && !searchData) {
      const res = connectTemplateDataRedux[parentPageName]?.[pageName]?.[currentPageData?.current_page]
      const data = res?.data?.data
      const { note, ...rest } = data
      const side_bar_data = [...sidebarData]
      Object.keys(rest)?.map((key) => {
        side_bar_data?.push(rest[key])
      })
      setSidebarData(side_bar_data)
      const currentPage = {
        current_page: res?.data?.meta?.current_page,
        hasMore: res?.data?.meta?.current_page !== res?.data?.meta?.last_page,
      }
      setCurrentPageData(currentPage)
    } else {
      request(`/connect-template?page=${currentPageData?.current_page + 1}`, 'POST', payload).then((res) => {
        if (res?.status === 200) {
          const data = res?.data?.data
          const { note, ...rest } = data
          const side_bar_data = [...sidebarData]
          if (!searchData) {
            updateDataInRedux(res)
          }

          Object.keys(rest)?.map((key) => {
            side_bar_data?.push(rest[key])
          })
          setSidebarData(side_bar_data)
          const currentPage = {
            current_page: res?.data?.meta?.current_page,
            hasMore: res?.data?.meta?.current_page !== res?.data?.meta?.last_page,
          }
          setCurrentPageData(currentPage)
        }
      })
    }
  }

  const onDeleteUserFromChat = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    const dataToUpdate = getConnectTemplateReduxDataAfterDelete(
      connectTemplateDataRedux,
      parentPageName,
      pageName,
      content?.source_table,
      [],
      false,
      [id]
    )
    dispatch(setConnectTemplateDataRedux(dataToUpdate))

    const payload = {
      ids: [id],
      source_table: content?.source_table,
      domain_id: domain_id,
    }
    dispatch(setQueue({ url: 'connect-template-delete', payload: { data: { ...payload } } }))
  }

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      fetchData()
    }
  }

  const sideBarDataWithoutKey = sidebarData?.filter((item) => !item?.id) || []
  const sideBarDataWithKey = sidebarData?.filter((item) => item?.id) || []
  const sideBarUserData = [...sideBarDataWithoutKey, ...sideBarDataWithKey]

  return (
    <Card className='app-inner-layout__sidebar' style={{ position: 'relative' }}>
      <div className='app-inner-layout__sidebar-header'>
        <Nav vertical>
          <NavItem className='pt-4 pl-3 pr-3 pb-3'>
            <InputGroup>
              <InputGroupAddon addonType='prepend'>
                <div className='input-group-text'>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              </InputGroupAddon>
              <Input placeholder='Search...' onChange={(e) => setSearchData(e.target.value)} onKeyPress={handleKeyPress} />
              <InputGroupAddon addonType='prepend'>
                <div className='input-group-text' onClick={() => fetchData()}>
                  <IconSet icon='fa-solid fa-rotate' color='dark' style={{ cursor: 'pointer' }} />
                </div>
              </InputGroupAddon>
            </InputGroup>
          </NavItem>
          {/* <NavItem className='nav-item-header'>Friends Online</NavItem> */}
        </Nav>
      </div>
      {sideBarUserData?.length > 0 && (
        <Nav vertical style={{ maxHeight: 'calc(100vh - 16rem)', overflow: 'auto', flexWrap: 'nowrap' }} id='scrollableDiv'>
          <InfiniteScroll
            dataLength={sideBarUserData?.length}
            next={fetchMoreData}
            style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
            // inverse={true} //
            hasMore={currentPageData?.hasMore}
            loader={<h4>Loading...</h4>}
            className='chat_infinite_scroll'
            scrollableTarget='scrollableDiv'>
            {sideBarUserData?.map((user, index) => {
              return (
                <Dropdown toggle={() => {}} isOpen={true}>
                  <NavItem key={user?.id + index.toString()} style={{ maxWidth: '20rem' }}>
                    <DropdownItem active={user?.id === selectedUserId?.id ? true : false} onClick={() => setSelectedUserId(user)}>
                      <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                          <div className='widget-content-left mr-3'>
                            <div className='avatar-icon-wrapper'>
                              {user?.profile ? (
                                <div className='avatar-icon' style={{ border: user?.id === selectedUserId?.id ? '2px solid white' : 'none' }}>
                                  <img src={user?.profile} alt='' />
                                </div>
                              ) : (
                                <IconSet
                                  icon={'fa-regular fa-circle-user'}
                                  style={{ fontSize: '3rem', color: 'black', fontWeight: '300' }}
                                  color={user?.id === selectedUserId?.id ? 'light' : 'info'}
                                  // color={color}
                                  // navheadericon='info'
                                />
                              )}
                              {/* <div className='badge badge-bottom badge-success badge-dot badge-dot-lg' /> */}
                            </div>
                          </div>
                          <div className='widget-content-left flex-grow-1'>
                            <div className='widget-heading'>{tidyName(user?.user_name)}</div>
                            <div className='widget-subheading'>{user?.last_message || user?.messages[user?.messages?.length - 1]?.body}</div>
                          </div>
                          {user?.id && (
                            <FontAwesomeIcon
                              icon={faTrash}
                              className='mr-1'
                              style={{ color: '#ed5a74', marginLeft: '1rem', cursor: 'pointer' }}
                              onClick={(e) => onDeleteUserFromChat(e, user?.id)}
                            />
                          )}
                        </div>
                      </div>
                    </DropdownItem>
                  </NavItem>
                </Dropdown>
              )
            })}
          </InfiniteScroll>
        </Nav>
      )}
      <div
        style={{ position: 'absolute', bottom: 5, right: 5, cursor: 'pointer', zIndex: 10 }}
        onClick={() => {
          const addDataDetail = {
            open: true,
            mainGrid: tableGrid?.main_grid,
            tableGrid: tableGrid,
            pageName: parentPageName,
            parentPageName: pageName,
            isTab: false,
            isAddNew: true,
          }
          setAddGridDataDetail(addDataDetail)
        }}>
        <NavItem className='pt-4 pl-3 pr-3 pb-3'>
          <Button
            block
            color='info'
            className={`btn-pill btn-shadow`}
            onClick={() => {
              const addDataDetail = {
                open: true,
                mainGrid: tableGrid?.main_grid,
                tableGrid: tableGrid,
                pageName: parentPageName,
                parentPageName: pageName,
                isTab: false,
                isAddNew: true,
              }
              setAddGridDataDetail(addDataDetail)
            }}>
            Add User
          </Button>
        </NavItem>
      </div>
      <Modal isOpen={addDataDetail?.open} toggle={() => setAddGridDataDetail()} style={{ width: 600 }}>
        <ModalHeader toggle={() => setAddGridDataDetail()}>Send Message</ModalHeader>
        <ModalBody>
          <AddUserInMessage pageData={pageData} addDataDetail={addDataDetail} parentPageName={parentPageName} toggle={() => setAddGridDataDetail()} />
        </ModalBody>
      </Modal>
    </Card>
  )
}

export default ChatSideBar
