import React, { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { cms } from '../../../../../helper'
import { reduxLoad } from '../../../../../Redux/Reducers'
import { request } from '../../../../../Redux/Sagas/requests/api'
import IconSet from '../../../../icon'
import './copyTab.scss'

const CopyTab = ({ source_table, content, pageName, parentPageName }) => {
  const nav = useSelector((state) => state.CMS.coreData.nav)
  const [expandedIndex, setExpandedIndex] = useState()
  const [loading, setLoading] = useState(false)
  const [copyData, setCopyData] = useState()

  const [selectedData, setSelectedData] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    let names = []
    nav?.map((item) => {
      if (item?.items) {
        item?.items?.map((page) => {
          if (page) {
            names?.push(page?.page)
          }
        })
      }
    })
    setLoading(true)
    request('get-page-names', 'POST', { by_source_table: source_table, names }).then((res) => {
      if (res?.data) {
        setCopyData(res?.data?.data)
      }
      setLoading(false)
    })
  }, [])

  const onChangeData = (value, checked, key) => {
    const allData = selectedData
    let newData = selectedData[key] || []
    if (checked) {
      newData?.push(value)
    } else {
      const index = newData?.indexOf(value)
      newData?.splice(index, 1)
    }
    allData[key] = newData
    setSelectedData(allData)
  }

  const onSubmit = () => {
    let payload = {
      tabs: selectedData,
      page_name: parentPageName,
    }
    request('copy-tab-data', 'POST', payload).then(() => {
      dispatch(reduxLoad())
    })
  }

  if (loading)
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader color='#3f6ad8' type='ball-pulse' />
      </div>
    )
  if (!copyData) return
  return (
    <div className='copy-tab-grid'>
      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {copyData?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <IconSet
                      icon={expandedIndex === index ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                      color='primary'
                      style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                      onClick={() => {
                        if (expandedIndex === index) {
                          setExpandedIndex()
                        } else {
                          setExpandedIndex(index)
                        }
                      }}
                    />
                  </td>
                  <td>{cms(item?.cms_header_name)}</td>
                </tr>
                {expandedIndex === index && (
                  <tr>
                    <td colSpan={2} className='item-container'>
                      {item?.page_buttons?.map((tab, key) => {
                        return (
                          <div className='item' key={key}>
                            <div>{cms(tab?.cms_tab_key)}</div>
                            <input
                              type='checkbox'
                              checked={selectedData[item.name]?.includes(tab?.name)}
                              onChange={(e) => onChangeData(tab?.name, e.target.checked, item.name)}
                            />
                          </div>
                        )
                      })}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      <Button color='primary' onClick={() => onSubmit()}>
        Save
      </Button>
    </div>
  )
}

export default CopyTab
