import { store } from '../../Redux/Config/configureStore'
import { setPermissionByUserId } from '../../Redux/Reducers/CMS'

export const getUserPermissionByIdFromRedux = (id) => {
  const permissionByUserId = store.getState().CMS.permissionByUserId
  return permissionByUserId[id]
}

export const setUserPermissionInReduxById = (id, data) => {
  const permissionByUserId = store.getState().CMS.permissionByUserId

  let newPermissionByUserId = { ...permissionByUserId }
  newPermissionByUserId[id] = data
  store.dispatch(setPermissionByUserId(newPermissionByUserId))
}

export const addUserPermissionInReduxById = (id, data) => {
  const permissionByUserId = store.getState().CMS.permissionByUserId

  let newPermissionByUserId = { ...permissionByUserId }
  newPermissionByUserId[id] = [...newPermissionByUserId[id], data]
  store.dispatch(setPermissionByUserId(newPermissionByUserId))
}
export const deleteUserPermissionInReduxById = (id, data) => {
  const permissionByUserId = store.getState().CMS.permissionByUserId

  let newPermissionByUserId = { ...permissionByUserId }
  let oldData = [...newPermissionByUserId[id]]
  oldData.splice(oldData?.indexOf(data), 1)
  newPermissionByUserId[id] = oldData
  store.dispatch(setPermissionByUserId(newPermissionByUserId))
}

export const getQueueAfterUpdatingPermission = (queues, queue) => {
  let isAppendInExistingQueue, indexToUpdateQueue, samePermissionChanging, samePermissionIndex

  queues?.map((item, index) => {
    if (item?.url === queue?.url && queue?.payload?.data?.parent_row_id === item?.payload?.data?.parent_row_id) {
      isAppendInExistingQueue = true
      indexToUpdateQueue = index
    }
  })

  if (isAppendInExistingQueue) {
    let newQueues = [...queues]
    let newData = [...newQueues[indexToUpdateQueue]['payload']['data']['data'], ...queue?.payload?.data?.data]
    newQueues[indexToUpdateQueue] = {
      ...newQueues[indexToUpdateQueue],
      payload: {
        ...newQueues[indexToUpdateQueue]['payload'],
        data: {
          ...newQueues[indexToUpdateQueue]['payload']['data'],
          data: newData,
        },
      },
    }

    return newQueues
  } else {
    return [...queues, queue]
  }
}
