import moment from 'moment'

import { Bar, Pie, Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { ChartDatasetOptions, ChartOptions } from '../../../../../CMSComponent/Common/Chart/chartHelper'
import { cms, editCMS } from '../../../../../helper'
import { DASHBOARD_DUMMY_DATA } from '../../dummy-data'

const emptyplugin = {
  id: 'emptyDoughnut',
  afterDraw(chart, args, options) {
    const { datasets } = chart.data
    const { color, width, radiusDecrease } = options
    let hasData = false

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i]
      hasData |= dataset.data.length > 0
    }

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart
      const centerX = (left + right) / 2
      const centerY = (top + bottom) / 2
      const r = Math.min(right - left, bottom - top) / 2

      ctx.beginPath()
      ctx.lineWidth = width || 2
      ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)'
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI)
      ctx.stroke()
    }
  },
}

const TagQuestionJobs = ({ dashboardData }) => {
  const tagQuestionJobsData = dashboardData?.tags_and_questions_job
  const tagJob = tagQuestionJobsData
    ?.filter((item) => item?.tag_item)
    ?.reduce((total, item) => {
      return total + item?.items_count
    }, 0)
  const question_job = tagQuestionJobsData
    ?.filter((item) => !item?.tag_item)
    ?.reduce((total, item) => {
      return total + item?.items_count
    }, 0)

  const dashboard_cms_key = useSelector((state) => state.CMS?.dashboardCMSKey?.tags_and_questions_job?.cms_key_heading)
  const editMode = useSelector((state) => state.CMS?.editMode)

  const donutLabelLine = {
    id: 'doughnutLabel',
    afterDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
      } = chart
      chart?.data?.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition()

          const text = ctx.measureText(dataset.data[index])
          if (dataset.data[index] > 0) {
            const halfWidth = width / 2
            const halfHeight = (height + 200) / 2

            const xLine = x >= halfWidth ? x + 30 : x - 30

            const yLine = y >= halfHeight ? y + 20 : y - 20

            const extraLine = x >= halfWidth ? 20 : -20
            //line
            // ctx.beginPath()
            // ctx.moveTo(x, y)
            // ctx.lineTo(xLine, yLine)
            // ctx.lineTo(xLine + extraLine, yLine)
            // ctx.strokeStyle = dataset.backgroundColor[index]
            // ctx.stroke()
            //box

            let RectWidth = 60
            let RectHeight = 20

            const rectPosition = x >= halfWidth ? 0 : -30

            ctx.beginPath()
            ctx.roundRect(x - 30, y - 10, RectWidth, RectHeight, [4])
            ctx.fillStyle = dataset.backgroundColor[index]
            ctx.fill()
            // ctx.lineWidth = 1
            // ctx.strokeStyle = 'black'
            // ctx.stroke()

            // ctx.roundRect(xLine + extraLine + rectPosition, yLine - RectHeight / 2, RectWidth, RectHeight, [4])
            // ctx.fillStyle = dataset.backgroundColor[index]
            // ctx.fill()
            // ctx.stroke()

            //text

            ctx.font = '10px Poppins'

            const textWidth = text.width

            ///control the postion

            // const textXPosition = x >= halfWidth ? 'left' : 'right';
            const plusFivePx = x >= halfWidth ? RectWidth / 2 : -RectWidth / 2
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#000'

            const percentage = Math.round((dataset.data[index] / (tagJob + question_job)) * 100)
            const dataToShow = dataset.data[index] + ' (' + percentage + '%)'
            // ctx.fillText(dataset.data[index], xLine + extraLine + plusFivePx, yLine)
            ctx.fillText(dataToShow, x, y)
          }
        })
      })
    },
  }

  const options = {
    // ...ChartOptions,

    plugins: {
      tooltip: {
        enabled: false, // <-- this option disables tooltips
      },
      emptyDoughnut: {
        color: 'rgba(255, 128, 0, 0.5)',
        width: 2,
        radiusDecrease: 20,
      },
      datalabels: {
        display: false,
        align: 'bottom',
        backgroundColor: '#ccc',
        borderRadius: 3,
        font: {
          size: 18,
        },
      },
    },
  }

  const data = {
    labels: ['Tag jobs (completed)', 'Question jobs (completed)'],
    datasets: [
      {
        label: 'Jobs',
        data: tagJob || question_job ? [tagJob, question_job] : [],
        backgroundColor: ['rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
        hoverOffset: 4,
      },
    ],
  }

  return (
    <div style={{ padding: '1rem', height: '100%' }}>
      <div className='dashboard-chart-title' style={{ cursor: editMode ? 'pointer' : '' }} onClick={() => editCMS(dashboard_cms_key)}>
        {cms(dashboard_cms_key)}
      </div>
      <Doughnut options={options} data={data} plugins={[donutLabelLine, emptyplugin]} />
    </div>
  )
}

export default TagQuestionJobs
