import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { editCMS, getNavFilterList, getFullNavListCms, cms } from '../../helper'
import { clearCurrentRowAllData, clearParentTableAllData, clearParentTableData, setCoreData, setCurrentUrl } from '../../Redux/Reducers/CMS'
import { clearPageTab } from '../../Redux/Reducers/page'
import { clearCurrentRowData } from '../../Redux/Reducers/CMS'
import cx from 'classnames'

const select = (state) => ({
  editMode: state.CMS.editMode,
  nav: state.CMS.coreData.nav,
  coreData: state.CMS.coreData,
})

const CustomLink = (props) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const [render, reRender] = useState(false)
  useEffect(() => {
    reRender(!render)
  }, [props.children])

  const onClick = (e) => {
    if (props.hasSubMenu) {
      if (props.editMode) {
        const navList = getFullNavListCms(props.nav)
        navList.map((item) => {
          if (cms(item.cms) === props.label) {
            editCMS(item.cms)
          }
        })
      }
      props.toggleSubMenu(e)
    } else {
      if (props.editMode) {
        const navList = getNavFilterList(props.nav)
        navList.map((item) => {
          if (item.path === props.to.replace('#/', '')) {
            editCMS(item.cms)
          }
        })
      } else {
        // console.log("vertical nav:::",props.to,props.to.replace('#/', ''))
        dispatch(clearPageTab())
        // dispatch(clearCurrentRowData())
        // dispatch(clearParentTableData())
        dispatch(clearParentTableAllData())
        dispatch(clearCurrentRowAllData())

        const updatedCoreData = {
          ...props.coreData,
          currentFormData: {},
          currentFormFullData: {},
        }
        dispatch(setCoreData(updatedCoreData))

        dispatch(setCurrentUrl(props.to.replace('#/', '')))
        history.push(props.to.replace('#/', ''))
      }
    }
  }

  return (
    <a
      className={cx({ 'metismenu-link': true, [props.classNameActive]: props.active, [props.classNameHasActiveChild]: props.hasActiveChild })}
      onClick={(e) => onClick(e)}
      href={props.to}>
      {props.children}
    </a>
  )
}
export default connect(select)(CustomLink)
