import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Row, UncontrolledTooltip } from 'reactstrap'
import Loader from 'react-loaders'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'

import Icon from '../../icon'
import { submitPage } from '../../../Redux/Reducers/CMS'
import bg4 from '../../../assets/utils/images/dropdown-header/abstract4.jpg'
import { cms, getCmsData } from '../../../helper'
import EditTopNavPageContent from './editTopNavPageContent'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  page: state.CMS.coreData.page,
})

const EditTopNavPage = ({ editPageMode, nav, actions, page }) => {
  const dispatch = useDispatch()
  const [load, updateLoad] = useState(false)
  const [mainPage, updateMainPage] = useState(null)
  const [autofill, updateAutofill] = useState({
    cms_header: [],
    cms_sub_header: [],
  })
  const [dropdownOpen, updateDropdownOpen] = useState(false)
  const [form, updateForm] = useState({
    page_name: '',
    source: '',
    source_type: '',
    cms_header: '',
    cms_sub_header: '',
    icon: '',
    page_type: '',
    component_name: 'page_with_tabs',
    not_allowed_message: '',
    style: 'model_form',
  })

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    dispatch(submitPage({ ...values, source: nav.name, source_type: 'nav_top_buttons' }))
  }

  const onChangeAutoFill = (e) => {
    updateAutofill({ ...autofill, [e.target.name]: getCmsData(e.target.value) })
    updateForm({ ...form, [e.target.name]: e.target.value })
  }

  const onClickAutofill = (item, field) => {
    updateForm({ ...form, [field]: item })
    updateAutofill({
      cms_header: [],
      cms_sub_header: [],
    })
  }

  const onChange = (e) => {
    updateForm({ ...form, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (actions !== null && page?.[actions?.[0]?.page_name]) {
      updateForm({
        source: nav.name,
        source_type: 'nav_top_buttons',
        page_name: actions[0].page_name,
        cms_header: cms(page[actions[0].page_name].cms_header_name),
        cms_sub_header: cms(page[actions[0].page_name].cms_sub_header_name),
        not_allowed_message: page[actions[0].page_name].not_allowed_message,
        style: page[actions[0].page_name].style === '' || page[actions[0].page_name].style === null ? form.style : page[actions[0].page_name].style,
        icon: page[actions[0].page_name].icon,
        component_name: page[actions[0].page_name].component_name,
        page_type: page[actions[0].page_name].component_name,
      })
      updateMainPage(page[actions[0].page_name])
    }
  }, [page])

  if (!editPageMode) return null
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => {
        if (dropdownOpen) {
          setTimeout(() => {
            updateDropdownOpen(!dropdownOpen)
          }, 1000)
        } else {
          updateDropdownOpen(!dropdownOpen)
        }
      }}
      className='edit-top-nav-page'>
      <DropdownToggle className='p-0 mr-2' color='link' id={'editPage' + nav.name}>
        <Icon bgcolor='white' color='white' icon='fa-light fa-memo-circle-info' navheadericon='true' />
        <UncontrolledTooltip placement='right' target={'editPage' + nav.name}>
          Edit Content
        </UncontrolledTooltip>
      </DropdownToggle>
      <DropdownMenu right className={'dropdown-menu-xl rm-pointers dropdown-menu-form'} style={{ padding: '0 0 0.65rem' }}>
        <div className='dropdown-menu-header'>
          <div className='dropdown-menu-header-inner bg-plum-plate'>
            <div
              className='menu-header-image'
              style={{
                backgroundImage: 'url(' + bg4 + ')',
              }}
            />
            <div className='menu-header-content text-white'>
              <h5 className='menu-header-title'>Edit {nav.name}</h5>
              <h6 className='menu-header-subtitle'>Manage content of the this page.</h6>
            </div>
          </div>
        </div>
        <div className='dropdown-form'>
          <Container>
            <Tabs defaultActiveKey='1' renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
              <TabPane tab='Page Setting' key='1' style={{ padding: '1.5rem 15px' }}>
                <AvForm onValidSubmit={handleValidSubmit} model={form}>
                  <Row>
                    <Col md={4}>
                      <AvField
                        name='page_name'
                        label='Page Name'
                        type='text'
                        required
                        value={form.page_name || ''}
                        validate={{ pattern: { value: '^[A-Za-z0-9_]+$', errorMessage: 'Your name must be composed only with letter and numbers' } }}
                      />
                    </Col>
                    <Col md={4}>
                      <AvField name='icon' label='Icon' type='text' value={form.icon || ''} />
                    </Col>
                    <Col md={4}>
                      <AvField type='select' label='Page Type' name='page_type' value={form.component_name || ''} onChange={(e) => onChange(e)}>
                        <option value={'page_with_tabs'}>Page With Tabs</option>
                        <option value={'horizontal_menu'}>Switch Database</option>
                        <option value={'button_menu'}>Button Menu</option>
                        {/* <option value={'page_without_tabs'}>Page Without Tabs</option> */}
                        {/* <option value={'list_menu'}>List Menu</option> */}
                      </AvField>
                    </Col>
                    <Col md={6}>
                      <AvField
                        name='cms_header'
                        label='CMS Header'
                        value={form.cms_header || ''}
                        type='text'
                        onChange={onChangeAutoFill}
                        onBlur={() =>
                          setTimeout(() => {
                            updateAutofill({
                              cms_header: [],
                              cms_sub_header: [],
                            })
                          }, 500)
                        }
                      />
                      <ListGroup className='auto-complete'>
                        {autofill['cms_header'].map((item, key) => (
                          <ListGroupItem key={key} onClick={() => onClickAutofill(item, 'cms_header')}>
                            {item}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Col>
                    <Col md={6}>
                      <AvField
                        name='cms_sub_header'
                        label='CMS Sub Header'
                        value={form.cms_sub_header || ''}
                        type='text'
                        onChange={onChangeAutoFill}
                        onBlur={() =>
                          setTimeout(() => {
                            updateAutofill({
                              cms_header: [],
                              cms_sub_header: [],
                            })
                          }, 500)
                        }
                      />
                      <ListGroup className='auto-complete'>
                        {autofill['cms_sub_header'].map((item, key) => (
                          <ListGroupItem key={key} onClick={() => onClickAutofill(item, 'cms_sub_header')}>
                            {item}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Col>
                    <Col md={6}>
                      <AvField type='select' label='Style' name='style' value={form.style || ''}>
                        <option value={'full_page'}>Full Page</option>
                        <option value={'model_form'}>Model Form</option>
                        <option value={'model_form_sm'}>Model Form Small</option>
                        <option value={'model_form_lg'}>Model Form Large</option>
                      </AvField>
                    </Col>
                    <Col md={6}>
                      <AvField name='not_allowed_message' label='Not Allowed Message' type='text' value={form.not_allowed_message || ''} />
                    </Col>
                    <Col md={12}>
                      <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
                        {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : actions !== null ? 'Save' : 'Submit'}
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </TabPane>
              <TabPane
                tab={form.page_type !== form.component_name ? 'Content (save page setting)' : 'Content'}
                key='2'
                style={{ padding: '1.5rem 15px' }}
                disabled={mainPage === null || form.page_type !== form.component_name}>
                <EditTopNavPageContent mainPage={mainPage} />
              </TabPane>
            </Tabs>
          </Container>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default connect(select)(EditTopNavPage)
