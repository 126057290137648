import React, { Fragment } from "react";

import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { cms, editCMS, validateForAv } from '../../../helper'
import { connect } from "react-redux";

// import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
// import moment from 'moment';

class FormDateRangePicker extends React.Component {
  state = {
    date: [new Date(), new Date()],
  };

  onChange = (date) => {
    this.props.onChange(this.props.data.field_id,date)
    this.setState({ date })};

  render() {
    const data = this.props.data;


       let isFieldVisible = true

       if (data?.field_setting?.depend_fields && this.props.currentRow[this.props.currentRow?.length - 1]) {
         const dependsData = data?.field_setting?.depend_fields
         const condition_array = dependsData?.map((dependForm) => {
           return this.checkSingleCondition(
             dependForm?.condition,
             this.props.currentRow[this.props.currentRow?.length - 1][dependForm?.dependent_field],
             parseInt(dependForm?.value)
           )
         })
         const final_condition_array = condition_array?.filter((item) => item)
         if (final_condition_array?.length > 0) {
           isFieldVisible = true
         } else {
           isFieldVisible = false
         }
       }

       if (!isFieldVisible) return
    return (
      <Fragment>
          <label>{cms(data.cms_key)}</label>
          <div style={{marginBottom:"1rem"}}>
          <DateTimeRangePicker onChange={this.onChange} value={this.state.date}/>
          </div>
          
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentRow: state.CMS.currentRow,
  }
}



export default (connect(mapStateToProps)(FormDateRangePicker));
