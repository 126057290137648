import {
  Col,
  Button,
  FormGroup,
  Card,
  Nav,
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import { Elastic } from 'react-burgers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCog, faCalendarAlt, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import IconSet from '../../../CMSComponent/icon'
import moment from 'moment'

import { setConnectTemplateDataRedux } from '../../../Redux/Reducers/CMS'
import { setQueue } from '../../../Redux/Reducers/queue'
import { callInsertRepeatingGridData, tidyName } from '../../../helper'

const avatar2 = 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg'

export const getConnectTemplateReduxDataAfterSendingMessage = (
  connectTemplateDataRedux,
  parentPageName,
  pageName,

  data
) => {
  let connectTemplateDataReduxToUpdate = {
    ...connectTemplateDataRedux,
  }
  connectTemplateDataReduxToUpdate[parentPageName] = { ...connectTemplateDataReduxToUpdate[parentPageName] }

  let newData = {}
  if (connectTemplateDataRedux[parentPageName]?.[pageName]) {
    newData = {
      ...connectTemplateDataRedux[parentPageName]?.[pageName],
    }
  }

  const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[pageName]

  let pageIndex, rowIndex
  Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
    Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
      if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === data?.id) {
        pageIndex = key
        rowIndex = rowKey
      }
    })
  })
  newData[pageIndex]['data']['data'] = {
    ...newData[pageIndex]['data']['data'],
  }
  newData[pageIndex]['data']['data'][rowIndex] = data

  connectTemplateDataReduxToUpdate[parentPageName][pageName] = newData
  return connectTemplateDataReduxToUpdate
}

const ChatContent = ({ pageData, selectedUserId }) => {
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)

  const [chatData, setChatData] = useState([])
  const [enteredText, setEnteredText] = useState('')
  const currentUserData = useSelector((state) => state.User.user)
  const editPageMode = useSelector((state) => state.CMS.editPageMode)
  const chatPageData = pageData?.component_call_buttons?.find((tab) => tab?.component_name === 'show_tabs_on_load')

  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  const tableGrid = chatPageData?.component_call_form?.[0]
  const selectedGrid = 'main_grid'
  const page_data = pageData
  const currentTabData = chatPageData
  const parentPageName = pageData?.name
  const pageName = pageData?.name
  const content = chatPageData?.component_call_form?.[0]

  const APP_URL = process.env.REACT_APP_URL

  const dispatch = useDispatch()

  useEffect(() => {
    const messages = selectedUserId?.messages

    messages?.sort(function (a, b) {
      return new Date(a.created_at) - new Date(b.created_at)
    })
    setChatData(messages)
  }, [selectedUserId?.id, selectedUserId?.messages])

  const onDeleteMessage = (id) => {
    let payload = {
      ids: [id],
      source_table: 'messages',
      parent_table: 'conversations',
      parent_table_row_id: selectedUserId?.id,
      domain_id: domain_id,
    }
    const newMessages = selectedUserId?.messages?.filter((item) => item?.id !== id)
    const dataToUpdateInRedux = {
      ...selectedUserId,
      messages: newMessages,
    }
    const connectTemplateDataReduxToUpdate = getConnectTemplateReduxDataAfterSendingMessage(
      connectTemplateDataRedux,
      parentPageName,
      pageName,
      dataToUpdateInRedux
    )
    dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxToUpdate))
    dispatch(setQueue({ url: 'connect-template-delete', payload: { data: { ...payload } } }))
  }

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      const payload = {
        data: {
          messages: {
            body: enteredText,
            user_id: currentUserData?.id,
          },
        },
        is_nested: true,
        parent_table: 'conversations',
        parent_table_row_id: selectedUserId?.id,
        domain_id: domain_id,
      }
      const dataToUpdateInRedux = {
        ...selectedUserId,
        messages: [
          ...selectedUserId?.messages,
          {
            body: enteredText,
            user_id: currentUserData?.id,
          },
        ],
      }
      const connectTemplateDataReduxToUpdate = getConnectTemplateReduxDataAfterSendingMessage(
        connectTemplateDataRedux,
        parentPageName,
        pageName,
        dataToUpdateInRedux
      )
      dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxToUpdate))
      callInsertRepeatingGridData(
        {
          messages: {
            body: enteredText,
            user_id: currentUserData?.id,
          },
        },
        'messages',
        {},
        false,
        domain_id,
        'conversations',
        selectedUserId?.id
      )
      // dispatch(
      //   setQueue({
      //     url: 'insert-repeating-grid-data',
      //     payload: {
      //       data: {
      //         ...payload,
      //       },
      //     },
      //   })
      // )
      setEnteredText('')

      // request('insert-repeating-grid-data', 'POST', payload).then((res) => {
      //   setChatData([
      //     ...chatData,
      //     {
      //       user_id: currentUserData?.id,
      //       body: enteredText,

      //       created_at: new Date(),
      //     },
      //   ])
      //   setEnteredText('')
      // })
    }
  }

  return (
    <Card className='app-inner-layout__content'>
      <div className='table-responsive' style={{ flex: 1 }}>
        {!selectedUserId && (
          <div
            className=' app-inner-layout__top-pane bg-gray '
            style={{ background: '#e9ecef', textAlign: 'center', fontWeight: '600', fontSize: '1.3rem' }}>
            Select user to start chat
          </div>
        )}
        {selectedUserId && (
          <div className='app-inner-layout__top-pane bg-gray' style={{ background: '#e9ecef' }}>
            <div className='pane-left'>
              <div className='mobile-app-menu-btn'>
                <Elastic width={26} lineHeight={2} lineSpacing={5} color='#6c757d' />
              </div>
              {selectedUserId?.profile ? (
                <div className='avatar-icon-wrapper mr-2'>
                  <div className='badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg' />
                  <div className='avatar-icon avatar-icon-xl rounded'>
                    <img width={82} src={avatar2} alt='' />
                  </div>
                </div>
              ) : (
                <div className='avatar-icon-wrapper mr-2'>
                  <IconSet icon={'fa-regular fa-circle-user'} style={{ fontSize: '3rem', fontWeight: '300' }} color='info' />
                </div>
              )}

              <h4 className='mb-0 text-nowrap'>
                {tidyName(selectedUserId?.user_name)}
                {selectedUserId?.last_update && (
                  <div className='opacity-7'>
                    Last Seen Online: <span className='opacity-8'>{selectedUserId?.last_update}</span>
                  </div>
                )}
              </h4>
            </div>
          </div>
        )}

        <div className='chat-wrapper' style={{ maxHeight: 'calc(100vh - 20rem)', overflow: 'auto', height: '70%' }}>
          {chatData?.map((item, index) => {
            let currentUserMessage
            if (item?.user_id === currentUserData?.id) {
              currentUserMessage = true
            }
            if (!currentUserMessage) {
              return (
                <div className='chat-box-wrapper'>
                  <div>
                    {selectedUserId?.profile ? (
                      <div className='avatar-icon-wrapper mr1'>
                        <div className='avatar-icon avatar-icon-xl rounded'>
                          <img width={82} src={selectedUserId?.profile} alt='' />
                        </div>
                      </div>
                    ) : (
                      <div className='avatar-icon-wrapper mr-2'>
                        <IconSet icon={'fa-regular fa-circle-user'} style={{ fontSize: '2rem', color: 'info', fontWeight: '300' }} color='info' />
                      </div>
                    )}
                  </div>
                  <div>
                    <div className='chat-box'>{item?.body}</div>
                    {item?.created_at && (
                      <small className='opacity-6' style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faCalendarAlt} className='mr-1' />
                        {moment(item?.created_at).format('HH:MM Do MMM, YYYY')}
                      </small>
                    )}
                  </div>
                </div>
              )
            } else {
              return (
                <div className='chat-box-wrapper' style={{ flexDirection: 'row-reverse' }}>
                  <div className='chat-box-wrapper chat-box-wrapper-right'>
                    <div>
                      <div className='chat-box'>{item?.body}</div>
                      {item?.created_at && (
                        <small className='opacity-6' style={{ display: 'flex', alignItems: 'center' }}>
                          <FontAwesomeIcon icon={faCalendarAlt} className='mr-1' />
                          {moment(item?.created_at).format('HH:MM Do MMM, YYYY')}

                          <FontAwesomeIcon
                            icon={faTrash}
                            className='mr-1'
                            style={{ color: 'red', marginLeft: '1rem', cursor: 'pointer' }}
                            onClick={() => onDeleteMessage(item?.id)}
                          />
                        </small>
                      )}
                    </div>
                    <div>
                      <div className='avatar-icon-wrapper ml-1'>
                        {currentUserData?.photo ? (
                          <>
                            <div className='badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg' />
                            <div className='avatar-icon avatar-icon-lg rounded'>
                              <img src={APP_URL + currentUserData?.photo} alt='' />
                            </div>
                          </>
                        ) : (
                          <IconSet icon={'fa-sharp fa-solid fa-circle-user'} style={{ fontSize: '3rem' }} color={'dark'} navheadericon='true' />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
        <div className='app-inner-layout__bottom-pane d-block text-center'>
          <FormGroup className='mb-0' row>
            <Col sm={12}>
              <div style={{ display: 'flex' }}>
                <Input
                  // bsSize='lg'
                  type='text'
                  placeholder='Write here to send...'
                  onChange={(e) => setEnteredText(e.target.value)}
                  onKeyPress={handleKeyPress}
                  value={enteredText}
                  disabled={!selectedUserId?.id}
                />
                {/* <IconSet icon={'fa-solid fa-paper-plane'} style={{ fontSize: '2rem' }} bgColor='light' color={'dark'} navheadericon='true' /> */}
              </div>
            </Col>
          </FormGroup>
        </div>
      </div>
    </Card>
  )
}

export default ChatContent
