import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import BreadCrumbs from './breadcrumbs'
import { getCustomThemeColor, renderBlockColorClass, renderBlockStyle } from '../../helper'

class PageTitle extends Component {
  randomize(myArray) {
    return myArray[Math.floor(Math.random() * myArray.length)]
  }

  render() {
    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,
      heading,
      headingButton = null,
      footerButton = null,
      icon,
      subheading,
      onTitleClick,
      onSubTitleClick,
      hideBreadCrumbs,
      component_name,
      actions,
      fullPage = false,
      titleAreaColor,
      headerFont,
      subHeaderFont,
    } = this.props
    // const titleStyle = !fullPage ? { marginBottom: 0,  } : {}

    const titleStyle = !fullPage
      ? {
          marginBottom: '0px',
          ...renderBlockStyle(titleAreaColor),
        }
      : { marginBottom: '1px', ...renderBlockStyle(titleAreaColor) }

    return (
      <div
        className={component_name === 'basic_grid' ? 'app-page-title mb-0' : cx('app-page-title', renderBlockColorClass(titleAreaColor))}
        style={titleStyle}>
        <div className='page-title-wrapper'>
          <div className='page-title-heading'>
            {icon && (
              <div
                className={cx('page-title-icon', {
                  'd-none': !enablePageTitleIcon,
                })}>
                <i className={icon} />
              </div>
            )}

            <div className={headerFont}>
              <span onClick={onTitleClick}>
                {heading} {headingButton}
              </span>

              <div
                className={cx('page-title-subheading', subHeaderFont, {
                  'd-none': !enablePageTitleSubheading,
                })}
                onClick={onSubTitleClick}>
                {subheading}
              </div>

              {!hideBreadCrumbs && (
                <div className='page-title-subheading page-title-actions'>
                  <BreadCrumbs />
                </div>
              )}
            </div>
          </div>
          {footerButton}
          {actions && <div className='page-title-actions'> {actions}</div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
  titleAreaColor: state.ThemeOptions.titleAreaColor,
  subHeaderFont: state.ThemeOptions.subHeaderFontFamily,
  headerFont: state.ThemeOptions.headerFontFamily,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle)
