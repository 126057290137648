import React from 'react'

import PageWithTabs from './pageWithTabs'
import { connect } from 'react-redux'

const select = (state) => ({
  cmsData: state.CMS.cmsData,
})

const DrawerPage = ({ data, cmsData }) => {
  if (data && data.component_name === 'page_with_tabs') {
    return <PageWithTabs page={data} CMSData={cmsData} disableBG={true} />
  }
  return null
}

export default connect(select)(DrawerPage)
