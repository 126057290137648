import React from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Row, Col, Card, CardBody, CardTitle, Container, Button } from 'reactstrap'
// import { useDispatch } from 'react-redux'
import { connect, useDispatch } from 'react-redux'
import { authLogout } from '../../Redux/Reducers/user'

// import { authLogout } from '../../redux/reducers/User'

const select = (state) => ({
  isAuth: state.User.isAuth,
  defaultUrl: state.User.defaultUrl,
  loader: state.Loader.loader,
})

const PageCrash = ({ message, isAuth, defaultUrl, loader, hideButton = false, error, info }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const title = () => {
    switch (location.pathname) {
      case '/response/error':
        return 'We are sorry we seem to have had a problem or you no internet connection. Please try again.'
      default:
        return message ? message : '404 - Page Not Found!'
    }
  }
  if (!isAuth && location.pathname !== '/response/error') {
    return <Redirect to='/welcome/login' />
  }
  const onLogout = () => {
    if (isAuth) {
      dispatch(authLogout())
    }
  }

  return (
    <CSSTransitionGroup
      component='div'
      transitionName='TabsAnimation'
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}>
      <Container fluid>
        <Row>
          <Col md='12'>
            <Card className='main-card mb-3 mt-4'>
              <CardBody className='text-center'>
                {loader ? (
                  <CardTitle style={{ fontSize: '34px' }}>Loading...</CardTitle>
                ) : (
                  <>
                    <CardTitle style={{ fontSize: '20px' }}>
                      {/* {title()} */}
                      {/* {error.toString()}  */}
                      Error!
                    </CardTitle>
                    <CardBody>
                      <div>Oops! Something went wrong.</div>
                      <div>Please refresh the page to continue.</div>
                    </CardBody>

                    <div className='font-icon-wrapper '>
                      <i className='pe-7s-attention icon-gradient bg-ripe-malin' style={{ fontSize: '128px' }}></i>
                    </div>

                    <br />
                    <br />
                    {isAuth && (
                      <Button color='danger' size='lg' onClick={() => onLogout()}>
                        Log out
                      </Button>
                    )}
                    <br></br>
                    <br></br>
                    {/* <div>{info?.componentStack}</div> */}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </CSSTransitionGroup>
  )
}

export default connect(select)(PageCrash)
