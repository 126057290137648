import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import Loader from 'react-loaders'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import ResizeDetector from 'react-resize-detector'

import 'loaders.css/loaders.css'

import Routes from './Routes/routes'
import ErrorAlert from '../CMSComponent/errorAlert'

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closedSmallerSidebar: false,
    }
  }
  componentDidMount() {
    document.body.className = this.props.fontFamily
  }
  componentDidUpdate() {
    document.body.className = this.props.fontFamily
  }

  render() {
    let {
      loader,
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <LoadingOverlay
              tag='div'
              active={loader}
              styles={{
                overlay: (base) => ({
                  ...base,
                  zIndex: 99999999,
                  background: '#fff',
                  opacity: 0.5,
                }),
              }}
              spinner={<Loader active color='#000' type='ball-pulse' />}>
              <div
                className={cx(
                  'app-container app-theme-' + colorScheme,
                  { 'fixed-header': enableFixedHeader },
                  { 'fixed-sidebar': enableFixedSidebar || width < 960 },
                  { 'fixed-footer': enableFixedFooter },
                  { 'closed-sidebar': enableClosedSidebar || width < 960 },
                  {
                    'closed-sidebar-mobile': closedSmallerSidebar || width < 960,
                  },
                  { 'sidebar-mobile-open': enableMobileMenu },
                  { 'body-tabs-shadow-btn': enablePageTabsAlt }
                )}>
                <Routes />
                <ErrorAlert />
              </div>
            </LoadingOverlay>
          </Fragment>
        )}
      />
    )
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  loader: state.Loader.loader,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.StaticThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  fontFamily: state.ThemeOptions.fontFamily,
})

export default withRouter(connect(mapStateToProp)(Main))
