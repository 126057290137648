import { Button, Label } from 'reactstrap'
import AvField from 'availity-reactstrap-validation/lib/AvField'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { tidyName } from '../../../../helper'


const getFilteredColumn = (selectedField,columns)=>{
  let list = []
  let otherList = []

  switch(selectedField){
    case 'dropdown':
    case 'select':
      list = columns?.filter((column)=>column?.field === 'select' || column?.field === 'dropdown')
      otherList = columns?.filter((column) => column?.field !== 'select' && column?.field !== 'dropdown')
      break;

    case 'date_time_picker':
    case 'datetime':
      list = columns?.filter((column) => column?.field === 'datetime')
      otherList = columns?.filter((column) => column?.field !== 'datetime')
      break;
    case 'switch':
    case 'toggle_switch':
      list = columns?.filter((column) => column?.field === 'switch' || column?.field === 'toggle_switch')
      otherList = columns?.filter((column) => column?.field !== 'switch' && column?.field !== 'toggle_switch')
      break;
    case 'multi_radio':
      list = columns?.filter((column) => column?.field === 'multi_radio' || column?.field === 'radio')
      otherList = columns?.filter((column) => column?.field !== 'multi_radio' && column?.field !== 'radio')
      break;
    default:
      list = columns?.filter((column) => column?.field === selectedField)
      otherList = columns?.filter((column) => column?.field !== selectedField)
  }

  return {
    list,
    otherList
  }
}

const AddCustomColumn = ({ source_table, value, onChange, selectedField }) => {
  const [addAdditionalField, setAddAdditionalField] = useState(false)
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)

  if (addAdditionalField) {
    return (
      <>
        <Label>
          Column Field OR{' '}
          <Button style={{ display: 'contents' }} color='link' onClick={() => setAddAdditionalField(false)}>
            Select Column
          </Button>
        </Label>
        <AvField
          name='field_id'
          value={value}
          onChange={(e) => onChange(e)}
          // value={fields.column}
          // onChange={(e) => onChangeColumn(e.target.value, 'column')}
          required
          validate={{ pattern: { value: '^[a-z_]+$', errorMessage: 'Column must be composed by small letter or underscore.' } }}
        />
      </>
    )
  }

  let columns = getFilteredColumn(selectedField, grid_selector?.[source_table]?.columns)



  return (
    <>
      <Label>
        Select OR{' '}
        <Button
          style={{ display: 'contents' }}
          color='link'
          onClick={() => {
            setAddAdditionalField(true)
            onChange({
              target: {
                value: '',
              },
            })
          }}>
          + Add Custom Field
        </Button>
      </Label>
      <AvField
        type='select'
        name={'field_id'}
        value={value}
        onChange={(e) => {
          onChange(e)
        }}
        required>
        <option value=''>Select field or column</option>
        {columns?.list?.length > 0 && (
          <optgroup label={`${tidyName(selectedField)} Fields`}>
            {columns?.list?.map((item, i) => (
              <option value={item.name} key={i}>
                {tidyName(item.title) || tidyName(item.name)}
              </option>
            ))}
          </optgroup>
        )}
        {columns?.otherList?.length > 0 && (
          <optgroup label={`Other Fields`}>
            {columns?.otherList?.map((item, i) => (
              <option value={item.name} key={i}>
                {tidyName(item.title) || tidyName(item.name)}
              </option>
            ))}
          </optgroup>
        )}
        {/* {source_table &&
        
          columns.map((item, i) => {
            // grid_selector[source_table].columns?.filter(column=>column.type!=='function').map((item, i) => {
            return (
              <option value={item.name} key={i}>
                {tidyName(item.title) || tidyName(item.name)}
              </option>
            )
          })} */}
      </AvField>
    </>
  )
}

export default AddCustomColumn
