import axios from 'axios'
import { getCookie } from '../../../Helper/cookieHelper'
import { getUrlDomainConfigNameByUrl } from '../../../Helper/urlHelper'
// axios.defaults.withCredentials = true
// axios.defaults.headers.common['Authorization'] = 'Bearer token'
// axios.defaults.headers.common['authorization'] = 'Bearer token'

export function request(url, method, data = {}, headers = {}, params = {}, auth = {}, rest) {
  if (localStorage.getItem('IS_INTERNET_ACTIVE')) return
  // const base = getUrlDomainConfigNameByUrl(url)
  const base = null
  //base null

  const token = getCookie('AUTH_TOKEN')

  return axios
    .request({
      baseURL: base || process.env.REACT_APP_API_URL,
      // withCredentials: true,
      url,
      data,
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        authorization: token ? `Bearer ${token}` : 'Bearer token',
        // ...headers,
      },
      params,
      // auth,
      ...rest,
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log('error::::::::::::', url, error.response)
      if (error.response?.status === 401 && !url?.includes('logout') && !url?.includes('login')) {
        window.location.href = '/logout'
        return error.response
      } else {
        return error.response
      }
      // if (!error.response || error.response.status === 500) {
      //   window.location.href = '/#/response/error'
      // } else if (error.response.status === 401) {
      //   window.location.href = '/#/logout'
      //   return error.response
      // } else {
      //   return error.response
      // }
    })
}
