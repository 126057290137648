import React, { Fragment } from 'react'

import { InputGroup, InputGroupAddon } from 'reactstrap'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker from 'react-datepicker'
import { addChangedFieldIdInReduxByFormName, checkFieldExistInSyncToUpdate, cms, editCMS, tidyName, validateForAv } from '../../../helper'
import { connect } from 'react-redux'
import { AvField } from 'availity-reactstrap-validation'
import { checkIsDate } from '../../../helper'
import { checkNeedToUpdateCurrentDate } from './date'
import { checkSingleCondition } from './toggle'
import moment from 'moment'

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: '',
    }
    this.handleChange = this.handleChange.bind(this)
  }
  componentDidMount() {
    let value = ''

    if (this.props.defaultValue && checkIsDate(this.props.defaultValue)) {
      value = new Date(this.props.defaultValue)
    } else if (checkNeedToUpdateCurrentDate(this.props.data?.field_id)) {
      value = new Date()
    }
    this.setState({ startDate: value })

    this.props.setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[this.props.data?.field_id] = moment(value).format('YYYY-MM-DD HH:mm:ss')

      return updatedValue
    })

    // this.props?.onChange(this.props.data.field_id,new Date(this.props.data.cms_value))
  }
  componentDidUpdate(prevProps) {
    if (this.props.data?.field_id === 'pre_time' || this.props.data?.field_id === 'post_time') {
      if (prevProps?.formFieldValue?.date_time !== this.props.formFieldValue?.date_time) {
        let momentDate = moment(this.props.formFieldValue?.date_time, 'YYYY-MM-DD HH:mm:ss')

        // Add 120 minutes to the date
        if (this.props.data?.field_id === 'post_time') {
          momentDate.add(this.props.formFieldValue?.post_time_mins, 'minutes')
        } else {
          momentDate.subtract(this.props.formFieldValue?.pre_time_mins, 'minutes')
        }

        // Format the new date to the desired format
        let newDate = new Date(momentDate)
        this.handleChange(newDate)
      }
    }
  }
  handleChange(date) {
    this.setState({
      startDate: new Date(moment(date).format('YYYY-MM-DD HH:mm:ss')),
    })
    this.props?.onChange(this.props.data.field_id, moment(date).format('YYYY-MM-DD HH:mm:ss'))
    this.props.setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[this.props.data?.field_id] = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return updatedValue
    })
    addChangedFieldIdInReduxByFormName(this.props.formName, this.props.currentRow[this.props.currentRow?.length - 1]?.id, this.props.data?.field_id)
  }

  checkSingleCondition = (condition, formValue, compareValue) => {
    switch (condition) {
      case 'equals':
        if (formValue == compareValue) {
          return true
        } else {
          return false
        }
      case 'less_than':
        if (formValue < compareValue) {
          return true
        } else {
          return false
        }
      case 'greater_than_and_equal':
        if (formValue >= compareValue) {
          return true
        } else {
          return false
        }
      case 'greater_than':
        if (formValue >= compareValue) {
          return true
        } else {
          return false
        }
      case 'less_than_and_equal':
        if (formValue <= compareValue) {
          return true
        } else {
          return false
        }
    }
  }

  render() {
    const data = this.props.data

    const { currentRow, currentFormData, formFieldValue } = this.props

    let isFieldVisible = true

    // applicable to upload table
    if ((data?.field_id === 'valid_too' || data?.field_id === 'valid_from') && this.props.parentPageName === 'contractors') {
      if (this.props.selectedDropdownData?.upload_document_id?.has_expiry === 0 || !this.props.selectedDropdownData) {
        isFieldVisible = false
      }
      if (!this.props.selectedDropdownData?.upload_document_id?.value) {
        isFieldVisible = false
      }
    }

    if (
      data?.field_setting?.depend_fields &&
      data?.field_setting?.depend_fields?.length > 0 &&
      ((currentRow && currentRow[currentRow?.length - 1]) || currentFormData || formFieldValue)
    ) {
      const dependsData = data?.field_setting?.depend_fields
      const condition_array = dependsData?.map((dependForm) => {
        if (formFieldValue && Object.keys(formFieldValue)?.includes(dependForm?.dependent_field)) {
          return checkSingleCondition(dependForm?.condition, formFieldValue[dependForm?.dependent_field], parseInt(dependForm?.value))
        } else if (currentFormData && Object.keys(currentFormData)?.includes(dependForm?.dependent_field)) {
          return checkSingleCondition(dependForm?.condition, currentFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
        } else {
          if (currentRow && currentRow[currentRow?.length - 1]) {
            return checkSingleCondition(
              dependForm?.condition,
              currentRow[currentRow?.length - 1][dependForm?.dependent_field],
              parseInt(dependForm?.value)
            )
          }
        }
      })
      const final_condition_array = condition_array?.filter((item) => item)
      if (final_condition_array?.length > 0) {
        isFieldVisible = true
      } else {
        isFieldVisible = false
      }
    }

    if (!isFieldVisible) return

    const isFieldInSync = checkFieldExistInSyncToUpdate(
      this.props.formName,
      this.props.currentRow[this.props.currentRow?.length - 1]?.id,
      data?.field_id
    )

    let isRequiredField

    if (
      (this.props.selectedDropdownData?.upload_document_id?.label?.toLowerCase()?.includes('cv') &&
        this.props.selectedDropdownData?.upload_document_id?.label?.toLowerCase()?.includes('required')) ||
      (this.props.selectedDropdownData?.upload_document_id?.name?.toLowerCase()?.includes('cv') &&
        this.props.selectedDropdownData?.upload_document_id?.name?.toLowerCase()?.includes('required'))
    ) {
      isRequiredField = true
    }
    let isFieldDisabled
    const disabled_if = this.props.data?.field_setting?.disable_if

    if (!Array.isArray(disabled_if) && disabled_if) {
      Object.keys(disabled_if).map((key) => {
        let disabled_field_value
        if (this.props.currentRow?.length > 0) {
          disabled_field_value = this.props.currentRow?.[this.props.currentRow?.length - 1]?.[key]
        } else {
          disabled_field_value = this.props.currentRow?.[this.props.currentRow?.length - 1]?.[key]
          // disabled_field_value = currentFormData?.[key]
        }

        if (disabled_field_value == disabled_if[key]) {
          isFieldDisabled = true
        }
      })
    }

    console.log('date field:::::::', this.state.startDate)

    return (
      <div style={{ marginBottom: '1rem' }}>
        <label className={isFieldDisabled && 'font-weight-bold'}>{cms(data.cms_key) || tidyName(data?.name)}</label>
        {isFieldDisabled ? (
          <div>{moment(this.state.startDate).format('Do MMM YYYY, h:mm a')}</div>
        ) : (
          <InputGroup className={isFieldInSync ? 'blue-border' : ''}>
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleChange}
              showTimeSelect
              className='form-control'
              timeFormat='HH:mm'
              timeIntervals={30}
              dateFormat='d MMMM , yyyy HH:mm'
              timeCaption='Time'
              disabled={data?.field_id === 'created_at'}
            />
            <InputGroupAddon addonType='append'>
              <div className='input-group-text'>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </InputGroupAddon>
          </InputGroup>
        )}

        <AvField
          type='hidden'
          value={this.state.startDate && moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss')}
          name={data?.field_id}
          required={isRequiredField}
          validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentRow: state.CMS.currentRow,
    selectedDropdownData: state.CMS.selectedDropdownData,
    currentFormData: state.CMS.coreData.currentFormData,
  }
}

export default connect(mapStateToProps)(DateTimePicker)
