import { useEffect, useState } from 'react'
import { Col, ListGroupItem, Row, UncontrolledCollapse } from 'reactstrap'
import { triggerBase64Download } from 'common-base64-downloader-react'
import moment from 'moment'
import { compareDate } from '../../../../helper'
import QR from '../../../Table/Component/QR/QR'

const fileTypes = ['PDF', 'EPS', 'JPG', 'JPEG', 'TIFF', 'GIF', 'PNG', 'ZIP']

const PreviewFile = ({ file, onDownLoadFile }) => {
  const fileType = file.slice(file?.indexOf('/') + 1, file?.indexOf(';'))

  if (fileType === 'pdf') {
    return <div>Pdf</div>
  } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
    return (
      <>
        <div>
          <img src={file} height={50} width={120} style={{ maxWidth: '100%', overflow: 'hidden' }}></img>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            onClick={() => {
              onDownLoadFile()
            }}
            style={{ cursor: 'pointer', color: 'blue' }}>
            Click here to download File
          </span>
        </div>
      </>
    )
  }
}

const FileUpload = ({ uploadedFile }) => {
  const [file, setFile] = useState(null)
  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  useEffect(() => {
    setFile(uploadedFile)
  }, [])

  const fileType = file?.slice(file?.indexOf('/') + 1, file?.indexOf(';'))
  let fileIsImage
  if (file && fileType && (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png')) {
    fileIsImage = true
  } else {
    fileIsImage = false
  }

  const onDownLoadFile = () => {
    triggerBase64Download(file, (Math.random() + 1).toString(36).substring(7))
  }

  return (
    <>
      {file && !fileIsImage && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            onClick={() => {
              if (fileIsImage) {
                setPreviewModalOpen(!previewModalOpen)
              } else {
                onDownLoadFile()
              }
            }}
            style={{ cursor: 'pointer' }}>
            {fileIsImage ? 'Preview' : 'Click here to download'}
          </span>
        </div>
      )}
      {file && fileIsImage && <PreviewFile file={file} onDownLoadFile={onDownLoadFile} />}
    </>
  )
}

export const getExpandableAnswer = (question) => {
  switch (question.type) {
    case 'radio':
    case 'select':
    case 'text':
    case 'time':
      return (
        <>
          <div>{question?.response[0]?.response_text}</div>
          {question?.response[0]?.comment && (
            <div className='mt-2 mb-2 font-italic' dangerouslySetInnerHTML={{ __html: question?.response[0]?.comment }}></div>
          )}
          <QR data={question?.response[0]?.upload_base64} height={200} className='my-3' />
          {/* {question?.response[0]?.upload_base64 && (
            <img
              src={question?.response[0]?.upload_base64}
              alt='signature'
              style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
          )} */}
        </>
      )
      return question?.response[0]?.response_text
    case 'date':
      return (
        <>
          <div>{question?.response[0]?.response_text && moment(question?.response[0]?.response_text).format('MMMM Do YYYY')}</div>
          {question?.response[0]?.comment && <div className='mt-2 mb-2' dangerouslySetInnerHTML={{ __html: question?.response[0]?.comment }}></div>}
          <QR data={question?.response[0]?.upload_base64} height={200} className='my-3' />
          {/* {question?.response[0]?.upload_base64 && (
            <img
              src={question?.response[0]?.upload_base64}
              alt='signature'
              style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
          )} */}
        </>
      )
      return question?.response[0]?.response_text && moment(question?.response[0]?.response_text).format('MMMM Do YYYY')

    case 'date-time':
      return (
        <>
          <div>{question?.response[0]?.response_text && moment(question?.response[0]?.response_text).format('MMMM Do YYYY, h:mm ')}</div>
          {question?.response[0]?.comment && <div className='mt-2 mb-2' dangerouslySetInnerHTML={{ __html: question?.response[0]?.comment }}></div>}
          <QR data={question?.response[0]?.upload_base64} height={200} className='my-3' />
          {/* {question?.response[0]?.upload_base64 && (
            <img
              src={question?.response[0]?.upload_base64}
              alt='signature'
              style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
          )} */}
        </>
      )
      return question?.response[0]?.response_text && moment(question?.response[0]?.response_text).format('MMMM Do YYYY, h:mm ')
    case 'rich-text':
      if (question?.response[0]?.response_text)
        return <div dangerouslySetInnerHTML={{ __html: question?.response[0]?.response_text }} style={{ flex: 1 }}></div>
    case 'check':
    case 'multy-select':
      const answer_array = question?.response?.map((answer) => {
        return answer?.response_text
      })
      return answer_array?.join(', ')
    case 'signiture_select':
      const url3 = question?.response[0]?.image_base64
      return (
        <>
          <img src={url3} alt='signature' style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
          <div>{question?.response[0]?.response_text}</div>
        </>
      )
    case 'signiture_name':
      const url2 = question?.response[0]?.image_base64
      return (
        <>
          <img src={url2} alt='signature' style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
          <div>{question?.response[0]?.response_text}</div>
        </>
      )
    case 'signiture_basic':
      const url1 = question?.response[0]?.image_base64
      return <img src={url1} alt='signature' style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '50px', width: '120px' }}></img>
    case 'upload':
      const url_upload = question?.response[0]?.upload_base64
      if (!url_upload) return <div>File not uploaded yet!</div>
      return <FileUpload isReadOnly uploadedFile={url_upload} />
    case 'divider':
      return <hr className='my-5'></hr>
    case 'heading':
      return (
        <div className='mb-3 card text-white card-body bg-info d-flex justify-content-between flex-row p-2 pl-3  align-items-center'>
          {question?.question}
        </div>
      )
  }

  //question?.type === 'time' ||
  // question?.type === 'date-time' ||
  // question?.type === 'rich-text')
}

const ExpandableQuestion = ({ item }) => {
  return (
    <div className='mb-1'>
      <div
        style={{
          padding: '0.5rem 2rem 0.5rem 0.5rem',
          background: '#00681621',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{}}>
          {/* <strong>Title:</strong> */}
          {item?.item_name}
        </div>
        {/* <div style={{}}>
          <strong>Type:</strong> {item?.item_type_id_label}
        </div> */}
        {/* <div style={{}}>
          <strong>Employee name:</strong> {item?.user_id_label}
        </div> */}
      </div>
      {/* <div className='mb-1 d-flex justify-content-between p-2'>
        
        <div style={{ fontSize: '0.8rem' }}>
          <strong>Start (expected):</strong> {moment(item?.created_at).format('D MMM YYYY h:mm a')}
        </div>
        <div style={{ fontSize: '0.8rem' }}>
          <strong>Start (actual):</strong> {moment(item?.updated_at).format('D MMM YYYY h:mm a')}
        </div>
        <div>
          <strong>Status: &nbsp;</strong>{' '}
          {!compareDate(item?.updated_at, item?.created_at) ? (
            <span className='badge badge-pill badge-danger'>Late</span>
          ) : (
            <span className='badge badge-pill badge-success'>On Time</span>
          )}
        </div>
      </div> */}
      <table className='table'>
        <thead>
          <th></th>
          <th></th>
        </thead>
        <tbody>
          <tr>
            <td>Type</td>
            <td>{item?.item_type_id_label}</td>
          </tr>
          <tr>
            <td>Start (expected):</td>
            <td>{moment(item?.created_at).format('D MMM YYYY h:mm a')}</td>
          </tr>
          <tr>
            <td>Start (actual):</td>
            <td> {moment(item?.updated_at).format('D MMM YYYY h:mm a')}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              {!compareDate(item?.updated_at, item?.created_at) ? (
                <span className='badge badge-pill badge-danger'>Late</span>
              ) : (
                <span className='badge badge-pill badge-success'>On Time</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {item?.item_questions?.length > 0 && (
        <div style={{ margin: '0.3rem 0rem 1rem 0rem', padding: '1rem 0rem', borderRadius: '0.5rem' }}>
          <ListGroupItem id={'question_title'}>
            <Row>
              <Col md={6} style={{ fontWeight: '900' }}>
                Question
              </Col>
              <Col md={6} style={{ paddingLeft: '1rem', fontWeight: '900' }}>
                Answer
              </Col>
            </Row>
          </ListGroupItem>
          {item?.item_questions?.map((question, index) => {
       
            if (question?.type === 'heading') {
              return (
                <div className='my-3 card text-white card-body bg-info d-flex justify-content-between flex-row p-2 pl-3  align-items-center'>
                  {question?.question}
                </div>
              )
            }
            if (question?.type === 'divider') {
              return <hr></hr>
            }
            return (
              <div key={index}>
                <ListGroupItem id={'question' + question?.id}>
                  <Row>
                    <Col md={6}>
                      {' '}
                      {index + 1}. {question?.question}?
                    </Col>
                    <Col md={6} style={{ paddingLeft: '1rem' }}>
                      {getExpandableAnswer(question)}
                    </Col>
                  </Row>
                </ListGroupItem>
                {/* <UncontrolledCollapse toggler={'#' + 'question' + question?.id}>
                <div style={{ padding: '1rem', border: '1px solid rgba(0, 0, 0, 0.125)' }}>{getExpandableAnswer(question)}</div>
              </UncontrolledCollapse> */}
              </div>
            )
          })}
          {item?.additional_information && (
            <>
              <ListGroupItem id={'additional_info' + item?.id}>
                <Row>
                  {' '}
                  <Col md={6}>Additional Information</Col>
                  <Col md={6}>
                    <div
                      style={{ padding: '1rem', border: '1px solid rgba(0, 0, 0, 0.125)' }}
                      dangerouslySetInnerHTML={{ __html: item?.additional_information }}></div>
                  </Col>
                </Row>
              </ListGroupItem>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ExpandableQuestion
