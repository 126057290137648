import AddGridData from '../../../../CMSComponent/Grid/Component/AddGridData/addGridData'

const AddMail = ({ addDataDetail, setAddDataDetail, onGoBack }) => {
  return (
    <div>
      <AddGridData
        addDataDetail={addDataDetail}
        setAddGridDataDetail={setAddDataDetail}
        hideTitle={true}
        clearCurrentRowOnBack={false}
        onGoBack={onGoBack}
      />
    </div>
  )
}

export default AddMail
