import React from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Row, Col, Card, CardBody, CardTitle, Container, Button } from 'reactstrap'
// import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'

// import { authLogout } from '../../redux/reducers/User'

const select = (state) => ({
  isAuth: state.User.isAuth,
  defaultUrl: state.User.defaultUrl,
  loader: state.Loader.loader,
})

const ErrorPage = ({ message, isAuth, defaultUrl, loader, hideButton = false }) => {
  const location = useLocation()
  const title = () => {
    switch (location.pathname) {
      case '/response/error':
        return 'We are sorry we seem to have had a problem or you no internet connection. Please try again.'
      default:
        return message ? message : '404 - Page Not Found!'
    }
  }
  if (!isAuth && location.pathname !== '/response/error') {
    return <Redirect to='/welcome/login' />
  }
  return (
    <CSSTransitionGroup
      component='div'
      transitionName='TabsAnimation'
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}>
      <Container fluid>
        <Row>
          <Col md='12'>
            <Card className='main-card mb-3 mt-4'>
              <CardBody className='text-center'>
                {loader ? (
                  <CardTitle style={{ fontSize: '34px' }}>Loading...</CardTitle>
                ) : (
                  <>
                    <CardTitle style={{ fontSize: '34px' }}>{title()}</CardTitle>
                    <div className='font-icon-wrapper '>
                      <i className='pe-7s-attention icon-gradient bg-ripe-malin' style={{ fontSize: '128px' }}></i>
                    </div>
          
                    {!hideButton && (
                      <Link to={isAuth ? defaultUrl || '/' : '/welcome/login'}>
                        <Button color='primary' size='lg'>
                          {isAuth ? 'Go To Default Page' : 'Go To Login'}
                        </Button>
                      </Link>
                    )}
                    <br />
                    <br />
                    {isAuth && (
                      <Link to={'/logout'}>
                        <Button color='danger' size='lg'>
                          Log out
                        </Button>
                      </Link>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </CSSTransitionGroup>
  )
}

export default connect(select)(ErrorPage)
