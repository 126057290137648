import Cookies from 'universal-cookie'

const cookies = new Cookies({ domain: '' })

const NEXT_YEAR = new Date().setFullYear(new Date().getFullYear() + 1)

const configOption = {
  path: '/',
  // domain: IS_DEV ? 'localhost' : `.${extractHostname(window.location.href)}`,
  domain: '',
  expires: new Date(NEXT_YEAR),
}

export const getCookie = (key) => {
  return cookies.get(key, configOption)
}


export const setCookie = (key, value) => {
  cookies.set(key, value, configOption)
}