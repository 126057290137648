import { useEffect, useState } from 'react'

import CommonFields from './commonFields'

const Time = ({ type, item, answers, setAnswers, requiredError }) => {
  const [selectedValue, setSelectedValue] = useState()
  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  const onHandleChange = (v) => {
    setSelectedValue(v)

    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          question_type: 'time',
          response_text: v,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    setSelectedValue(current_answer_object?.response_text)
  }, [JSON.stringify(current_answer_object), item?.id])

  return (
    <div>
      <div>{item?.question}</div>
      <div style={{ marginTop: '1rem' }}>
        <input type='time' value={selectedValue} onChange={(e) => onHandleChange(e.target.value)} style={{ width: '30%' }}></input>
      </div>
      <CommonFields
        item={item}
        selectedAnswer={selectedValue}
        answers={answers}
        setAnswers={setAnswers}
        current_answer_object={current_answer_object}
      />
      {item?.validations?.includes('required') &&
        requiredError &&
        (!current_answer_object?.response_text || current_answer_object?.response_text?.length === 0) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
    </div>
  )
}

export default Time
