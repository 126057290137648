import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import { Combobox, Multiselect } from 'react-widgets'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { submitRequest } from '../../../../Redux/Reducers/CMS'
import AddCustomColumn from './addCustomColumn'

const defaultForm = {
  name: '',
  type: 'input',
  field_id: '',
  api_submit: '',
  validations: [],
  col: '1',
  manage_data_database: '',
}
const validations = ['required', 'string', 'max_50', 'max_100', 'email', 'min_5', 'confirm_password']

const SubmitButton = ({ formName, pageName, setModal, type, field = null, setAddForm, tab, section, source_table, is_create, order }) => {
  const [form, updateForm] = useState(defaultForm)
  const [load, updateLoad] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    updateForm({ ...form, type: type, col: field?.col })
  }, [])

  useEffect(() => {
    if (field) {
      const validationValueFromDb = field.component_call_validation?.map((item) => item?.validation_type)

      updateForm({
        ...form,
        field_id: field.name,
        validations: validationValueFromDb,
      })
    }
  }, [])

  const handleValidSubmit = (e, values) => {
    updateLoad(true)
    setTimeout(() => {
      updateLoad(false)
    }, 2000)
    const data = {
      ...values,
      form_name: formName,
      page_name: pageName,
      validations: form.validations,
      manage_data_database: form.manage_data_database,
      type: form.type,
      order,
    }
    if (tab) {
      data = {
        ...data,
        tab: true,
        section: section,
      }
    }
    // dispatch(submitRequest({ url: "submit-form-field", data }));
    setModal(false)
    setAddForm(false)
    updateForm(defaultForm)
  }
  return (
    <>
      <AvForm onValidSubmit={handleValidSubmit} model={form}>
        <Row>
          {tab && is_create ? (
            <Col md={6}>
              <AddCustomColumn
                source_table={source_table}
                value={form.field_id}
                onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
              />
            </Col>
          ) : (
            <Col md={6}>
              <AvField
                name='field_id'
                label='Field ID'
                type='text'
                required
                value={form.api_submit}
                validate={{
                  pattern: {
                    value: '^[A-Za-z0-9_]+$',
                    errorMessage: 'Your name must be composed only with letter and numbers',
                  },
                }}
                onChange={(e) => updateForm({ ...form, api_submit: e.target.value })}
              />
            </Col>
          )}

          <Col md={6}>
            <label>Validations</label>
            <Multiselect
              data={validations}
              value={form.validations}
              onChange={(value) => updateForm({ ...form, validations: value })}
              textField='validations'
            />
          </Col>
          <Col md={6}>
            <AvField
              name='api_submit'
              label='api'
              type='text'
              required
              value={form.field_id}
              //   validate={{
              //     pattern: {
              //       value: "^[A-Za-z0-9_]+$",
              //       errorMessage:
              //         "Your name must be composed only with letter and numbers",
              //     },
              //   }}
              onChange={(e) => updateForm({ ...form, field_id: e.target.value })}
            />
          </Col>
          <Col md={12}>
            <Button color='primary' style={{ width: 90, height: 38, fontSize: '15px' }}>
              {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default SubmitButton
