import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { clearPageTab } from '../../../Redux/Reducers/page'
import QuestionnaireSummary from './questionnaireSummary'

function CompleteQuestions({ onFormSubmit, isGroupedQuestionnaire, completed_all, isAnswerUpdated, answers }) {
  const dispatch = useDispatch()
  return (
    <Fragment>
      <div className='form-wizard-content'>
        {!isAnswerUpdated && (
          <>
            {!!completed_all && (
              <div className='no-results'>
                <div className='sa-icon sa-success animate'>
                  <span className='sa-line sa-tip animateSuccessTip' />
                  <span className='sa-line sa-long animateSuccessLong' />
                  <div className='sa-placeholder' />
                  <div className='sa-fix' />
                </div>
                <div className='results-subtitle mt-4'>Finished!</div>
                <div className='results-title'>All Done!</div>

                <div className='mt-3 mb-2' />
              </div>
            )}
          </>
        )}

        {!isGroupedQuestionnaire && (
          <div>
            <QuestionnaireSummary answers={answers} />
            <Button color='primary' size='lg' className='btn-shadow mt-2' onClick={() => onFormSubmit()}>
              Save
            </Button>
          </div>
        )}

        {isGroupedQuestionnaire && (!completed_all || (isAnswerUpdated && completed_all)) && (
          <div>
            <QuestionnaireSummary answers={answers} />

            <Button color='primary' size='lg' className='btn-shadow mt-2' onClick={() => onFormSubmit()}>
              <span>Save</span>
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default CompleteQuestions
