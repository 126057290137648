import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap'
import Dropdown from './Fields/dropdown'
import CommonField from './Fields/commonField'
import SubmitButton from './Fields/submitButton'
import FileUpload from './Fields/fileUpload'
import HeaderSection from './Fields/headerSection'
import Spaccer from './Fields/spaccer'
import { useState } from 'react'
import PrePostTime from './Fields/prepostTime'
import AdvancedMultiSelect from './Fields/advancedMultiSelect'
import MultipleInput from './Fields/multipleInput'
const EditField = ({
  item,
  coreData,
  hasReduxData,
  reduxDataValidate,
  manageDatabaseValidate,
  formName,
  pageName,
  setModal,
  tab,
  section,
  isLayout,
  allFields,
  grid_id,
  isCreatingFieldForRepeatingForm,
  source_table,
  parentPageName,
  isAddDataForm,
  order
}) => {

  const renderEditForm = (field, fieldType) => {


    switch (fieldType) {
      case 'dropdown':
      case 'select':
        return (
          <Dropdown
            field={field}
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            tab={tab}
            section={section}
            type='dropdown'
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'multi_checkbox':
        return (
          <Dropdown
            field={field}
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            tab={tab}
            section={section}
            type='multi_checkbox'
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'multi_radio':
        return (
          <Dropdown
            field={field}
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            tab={tab}
            section={section}
            type='multi_radio'
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )

      case 'submit_button':
        return (
          <SubmitButton
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'file_upload':
        return (
          <FileUpload
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'section_heading':
        return (
          <HeaderSection
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isEdit={true}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'spacer':
        return (
          <Spaccer
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'pre_time':
        return (
          <PrePostTime
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'post_time':
        return (
          <PrePostTime
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'advanced_multi_select':
        return (
          <AdvancedMultiSelect
            field={field}
            coreData={coreData}
            hasReduxData={hasReduxData}
            reduxDataValidate={reduxDataValidate}
            manageDatabaseValidate={manageDatabaseValidate}
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            tab={tab}
            section={section}
            type='advanced_multi_select'
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
      case 'multiple_input':
        return (
          <MultipleInput
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )

      default:
        return (
          <CommonField
            formName={formName}
            pageName={pageName}
            parentPageName={parentPageName}
            setModal={setModal}
            type={field.component_name}
            field={field}
            tab={tab}
            section={section}
            isLayout={isLayout}
            rowIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.row_index : field?.row_index}
            blockIndex={isCreatingFieldForRepeatingForm ? field?.field_setting?.block_index : field?.block_index}
            allFields={allFields}
            grid_id={grid_id}
            isCreatingFieldForRepeatingForm={isCreatingFieldForRepeatingForm}
            source_table={source_table}
            isAddDataForm={isAddDataForm}
            order={order}
          />
        )
    }
  }

  const [selectedField, setSelectedField] = useState(item?.field_setting?.field)

  return (
    <>
      {/* <Row>
        <AvForm>
          <AvField type='select' label='Field Type' value={selectedField} name={'type'} onChange={(e) => setSelectedField(e.target.value)}>
            <option value={'dropdown'}>Dropdown</option>
            <option value={'input'}>Input</option>
            <option value={'date'}>Date</option>
            <option value={'time'}>Time</option>
            <option value={'static_text'}>Static Text</option>
            <option value={'rich_text'}>Rich Text</option>
            <option value={'submit_button'}>Submit Button</option>
            <option value={'chart_pill'}>Chart Pill</option>
            <option value={'toggle_switch'}>Toggle Switch</option>
            <option value={'multi_checkbox'}>Checkbox</option>
            <option value={'multi_radio'}>Radio</option>
            <option value={'color_picker'}>Color picker</option>
            <option value={'date_time_picker'}>Date time picker</option>
            <option value={'date_time_range_picker'}>Date time Range picker</option>
            <option value={'file_upload'}>File upload</option>
            <option value={'section_heading'}>Header Section</option>
            <option value={'spacer'}>Spacer</option>
          </AvField>
        </AvForm>
      </Row> */}
 
      {renderEditForm(item, item?.field_setting?.field || item?.component_name)}
    </>
  )
}

export default EditField
