export const SET_PAGE = 'SET_PAGE'
export const SET_TAB = 'SET_TAB'
export const SET_FULL_PAGE = 'SET_FULL_PAGE'
export const ENABLE_SEARCH = 'ENABLE_SEARCH'
export const SET_GLOBAL_SEARCH_VALUE = 'SET_GLOBAL_SEARCH_VALUE'
export const TRIGGER_GLOBAL_SEARCH = 'TRIGGER_GLOBAL_SEARCH'

export const CLEAR_PAGE = 'CLEAR_PAGE'
export const SET_CURRENT_TAB_DATA = 'SET_CURRENT_TAB_DATA'
export const SET_TAB_FORM_DATA = 'SET_TAB_FORM_DATA'
export const CLEAR_TAB_PAGE = 'CLEAR_TAB_PAGE'
export const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE'


const initialSate = {
  currentPage: '',
  currentTab: '',
  currentTabData: '',
  tabFormData: {},
  questionnaire: null,
  isPageFull: true,
  enableSearch: false,
  triggerGlobalSearch: false,
  globalSearchValue: '',
  savedFilters: {},
}

export const SetPage = (currentPage) => ({
  type: SET_PAGE,
  currentPage,
})

export const setQuestionnaire = (questionnaire) => ({
  type: SET_QUESTIONNAIRE,
  questionnaire,
})

export const clearPageTab = () => ({
  type: CLEAR_TAB_PAGE,
})

export const setCurrentTabData = (currentTabData) => ({
  type: SET_CURRENT_TAB_DATA,
  currentTabData,
})



export const triggerGlobalSearch = (triggerGlobalSearch) => ({
  type: TRIGGER_GLOBAL_SEARCH,
  triggerGlobalSearch,
})

export const setGlobalSearchValue = (globalSearchValue) => ({
  type: SET_GLOBAL_SEARCH_VALUE,
  globalSearchValue,
})

export const setSearch = (enableSearch) => ({
  type: ENABLE_SEARCH,
  enableSearch,
})

export const SetFullPage = (isPageFull) => ({
  type: SET_FULL_PAGE,
  isPageFull,
})

export const SetTab = (currentTab) => {
  return {
    type: SET_TAB,
    currentTab,
  }
}

export const setTabFormData = (tabFormData) => {
  return {
    type: SET_TAB_FORM_DATA,
    tabFormData,
  }
}

export default (state = initialSate, action) => {
  switch (action.type) {
    case SET_PAGE:
      const { currentPage } = action
      return { ...state, currentPage }
    case SET_CURRENT_TAB_DATA:
      const { currentTabData } = action
      return { ...state, currentTabData }
    case SET_TAB_FORM_DATA:
      const { tabFormData } = action
      return { ...state, tabFormData }

    case SET_GLOBAL_SEARCH_VALUE:
      const { globalSearchValue } = action
      return { ...state, globalSearchValue }
    case TRIGGER_GLOBAL_SEARCH:
      const { triggerGlobalSearch } = action
      return { ...state, triggerGlobalSearch }
    case SET_TAB:
      const { currentTab } = action
      return { ...state, currentTab }
    case ENABLE_SEARCH:
      const { enableSearch } = action
      return { ...state, enableSearch }
    case SET_FULL_PAGE:
      const { isPageFull } = action
      return { ...state, isPageFull }
    case SET_QUESTIONNAIRE:
      const { questionnaire } = action
      return { ...state, questionnaire }
    case CLEAR_PAGE:
      return initialSate
    case CLEAR_TAB_PAGE:
      return initialSate
    default:
  }
  return state
}
