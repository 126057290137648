import { useEffect, useState, useRef } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { connect, useSelector } from 'react-redux'

import RedactorX from '../../../Vendor/redactorx/redactorx.js'
import '../../../Vendor/redactorx/redactorx.css'
import {
  addChangedFieldIdInReduxByFormName,
  checkFieldExistInSyncToUpdate,
  cms,
  editCMS,
  removeChangedFieldIdInReduxByFormName,
  validateForAv,
} from '../../../helper'

const checkIsFieldVisible = (formFieldValue, field_id) => {
  if (field_id === 'termination_reason' && !formFieldValue?.termination_date) {
    return false
  }
  return true
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
})

const CMSRichText = ({ data, cmsEditMode, id, value, formName, currentRow, onChange = () => {}, formFieldValue }) => {
  const [enteredValue, setEnteredValue] = useState(value)
  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)
  const editorRef = useRef(null)

   const onChangeRef = useRef(onChange) // Store onChange in a ref

   useEffect(() => {
     onChangeRef.current = onChange // Update ref with latest onChange callback
   }, [onChange])

  useEffect(() => {
    const initEditor = () => {
      try {
        const app = RedactorX(`#${id}`, {
          editor: { minHeight: data?.section_rowspan?.rowspan === 3 ? '237px' : '150px' },
          subscribe: {
            'editor.change': (event) => {
              setEnteredValue(event.params.html)
              onChangeRef.current(event.params.html)
            },
            'editor.before.load': function (event) {
              event.set('html', value ? value : '')
            },
          },
        })

        editorRef.current = app
      } catch (error) {
        console.error('RedactorX initialization error:', error)
      }
    }

    const textareaElement = document.getElementById(id)
    if (textareaElement) {
      initEditor()
    } else {
      console.error(`Element with id ${id} not found.`)
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (value !== enteredValue) {
      setEnteredValue(value)
      if(value){
        editorRef.current.app.editor.setContent({ html: value })
      }
      
    }
  }, [value])

  useEffect(() => {
    const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
    if (currentRow && currentRow?.length > 0 && changedFieldRendered) {
      if (enteredValue === value && enteredValue) {
        removeChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      } else if (enteredValue) {
        addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      }
    }
    if (!changedFieldRendered) {
      setChangedFieldRendered(true)
    }
  }, [enteredValue, value, formName, currentRow, changedFieldRendered, data?.field_id])

  if (!checkIsFieldVisible(formFieldValue, data?.field_id)) return null

  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)

  return (
    <div className={`${data?.background_color || ''} ${isFieldInSync ? 'blue-border p-2' : ''}`}>
      <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>{cms(data?.cms_key)}</label>
      <textarea id={id} style={{ width: '100%' }} className='blue-border' />
      <AvField
        type='hidden'
        name={data.field_id || 'rich-text'}
        value={enteredValue}
        validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
      />
    </div>
  )
}

export default connect(select)(CMSRichText)
