import moment from 'moment'
import { useSelector } from 'react-redux'
import ExpandableQuestion from '../../../CMSComponent/Grid/Component/ExpandableRow/expandableQuestion'
import QuestionnaireMain from '../../../CMSComponent/Questionnaire/questionnaireMain'



const GeneralInfo = ({row})=>{
  console.log('general info:::::::',row)
  return (
    <table className='table'>
      <thead>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        <tr>
          <td>Customer Name</td>
          <td>{row?.location_id_label}</td>
        </tr>
        <tr>
          <td>Contractor:</td>
          <td>{row?.contractor_id_label}</td>
        </tr>
        <tr>
          <td>Date:</td>
          <td> {moment(row?.completed_end_date_time).format('D MMM YYYY h:mm a')}</td>
        </tr>
        <tr>
          <td>Time Call Received:</td>
          <td>{moment(row?.raised_start_date_time).format('D MMM YYYY h:mm a')}</td>
        </tr>
        <tr>
          <td>Arrival:</td>
          <td>{moment(row?.completed_start_date_time).format('D MMM YYYY h:mm a')}</td>
        </tr>
        <tr>
          <td>Departure:</td>
          <td>{moment(row?.completed_end_date_time).format('D MMM YYYY h:mm a')}</td>
        </tr>
        <tr>
          <td>Time On Site:</td>
          <td>{moment(row?.completed_minute_duration).format('D MMM YYYY h:mm a')}</td>
        </tr>
      </tbody>
    </table>
  )
}

const ApproveJobsQuestionnaire = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const parentTableReduxData = useSelector((state) => state.CMS.parentTableData)
  const details = {
    source_table: 'item_groups',
    activeRowData: currentRow?.[0],
    grid_id: parentTableReduxData?.[0]?.grid_id,
  }

  if (currentRow?.[0]?.['all_questions_answers']?.length === 0 && currentRow?.[0]?.['items']?.length === 0)
    return <div style={{ marginTop: '2rem', textAlign: 'center', fontSize: '2rem' }}>No Items available!</div>
  if (currentRow[currentRow?.length - 1]?.approved || currentRow[currentRow?.length - 1]?.auto_approve) {
    return (
      <div>
        <div
          className='mb-2'
          style={{
            padding: '0.5rem 2rem 0.5rem 0.5rem',
            background: '#00681621',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          General Info
        </div>
        <GeneralInfo row={currentRow?.[0]} />
        {currentRow?.[0]?.['all_questions_answers']?.map((item, key) => {
          return <ExpandableQuestion item={item} key={key} />
        })}
      </div>
    )
  }

  return (
    <div style={{ marginTop: '3rem' }}>
      <QuestionnaireMain questionnaireDetail={details} isInsideTab={true} />
    </div>
  )
}

export default ApproveJobsQuestionnaire
