import { useEffect, useState } from 'react'
import { Button, Col, Fade, Label, Row } from 'reactstrap'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'
import DatePicker from 'react-datepicker'

import IconSet from '../../../icon'
import { cms, findColumns, getPageNameForCoreDataFromUrl, tidyName } from '../../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { submitRequest, updatePageDataRedux } from '../../../../Redux/Reducers/CMS'
import moment from 'moment'
import WhereClause from '../../../EditCMS/Grid/whereClause'
import ConditionCommonField from '../../../EditCMS/Grid/conditionCommonField'
import { setLoader } from '../../../../Redux/Reducers/loader'
import { request } from '../../../../Redux/Sagas/requests/api'

const getColumns = (pages, pageName) => {
  const page_data = pages[pageName]
  let columns
  const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
  const page_main_table = source_table && source_table?.length > 0 && source_table[0]?.component_call_form[0]?.main_grid
  if (page_main_table?.component_call_cols && page_main_table?.component_call_cols?.length > 0) {
    columns = page_main_table?.component_call_cols
      ?.filter((item) => item.field_id)
      .map((item) => {
        return {
          cms_key: item?.cms_key,
          field_id: item?.field_id,
        }
      })
  }

  if (page_main_table?.component_call_expand_fields && page_main_table?.component_call_expand_fields?.length > 0) {
    columns = [
      ...columns,
      ...page_main_table?.component_call_expand_fields
        ?.filter((item) => item.field_id)
        .map((item) => {
          return {
            cms_key: item?.cms_key,
            field_id: item?.field_id,
          }
        }),
    ]
  }

  const method_with_main_source_table = page_data?.component_call_buttons?.filter(
    (item) => item.component_name !== 'hidden_tab' && item.source_table === source_table[0]?.source_table
  )
  const fieldData = method_with_main_source_table?.map((item) => {
    item?.component_call_form?.map((form) => {
      form.component_call_fields?.map((field) => {
        if (!columns.includes(field.field_id)) {
          columns.push({
            cms_key: field?.cms_key,
            field_id: field?.field_id,
          })
        }
      })
    })
  })
  return columns
}

export const AddConditionForm = ({
  toggle,
  handleSubmit,
  onChange = () => {},
  form,
  dbfields,
  source_table,
  content,
  pageName,
  updateWhereClauseForm,
}) => {
  const pages = useSelector((state) => state.CMS.coreData.page)
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)

  const select_column_option = grid_selector[source_table]?.columns

  const getColumnWidthForNotes = (type) => {
    const a = ['todays_date', 'db']
    if (a.includes(type)) {
      return 6
    } else {
      return 8
    }
  }
  const db_option = select_column_option?.map((option) => {
    return {
      name: option?.cms_key || option?.name,
      value: source_table + '.' + (option?.field_id || option?.name),
    }
  })

  return (
    <Fade in={toggle} tag='h5' className='mt-3 text-dark'>
      <AvForm
        style={{ textAlign: 'left', fontSize: 13 }}
        // onSubmit={handleSubmit}
      >
        <Row>
          <Col md={6}>
            <AvField type='number' name='order' label='Order' required value={form?.order} onChange={(e) => onChange(e.target.value, 'order')} />
          </Col>
          <ConditionCommonField
            source_table={source_table}
            columnValue={db_option?.filter((column) => column?.value === source_table + '.' + form?.db_field_name)[0]?.value?.split('.')[1]}
            onColumnChange={(value) => onChange(value, 'db_field_name')}
            type={form?.type}
            onTypeChange={(value) => onChange(value, 'type')}
            condition={form?.condition}
            onConditionChange={(value) => onChange(value, 'condition')}
            onValueChange={(value) => {
              if (form.type === 'dynamic') {
                onChange(value, 'dynamic_value')
              } else if (form.type === 'fixed_date_value') {
                onChange(value, 'date_value')
              } else if (form.type === 'fixed_numeric_value' || form.type === 'between_from_current_date_time') {
                onChange(value, 'numeric_value')
              } else if (form.type === 'select_multiple_values') {
                onChange(value, 'multiple_values')
              } else {
                onChange(value, 'dynamic_value')
              }
            }}
            onChangeBetweenType={(value) => onChange(value, 'between_type')}
            between_type={form?.between_type}
            onChangeBetweenPeriod={(value) => onChange(value, 'between_period')}
            between_period={form?.between_period}
            between_value={form?.between_value}
            onChangeBetweenValue={(value) => onChange(value, 'between_value')}
            value={form?.value || form?.dynamic_value || form?.date_value || form?.numeric_value || form?.multiple_values}
          />
          {/* <Col md={4}>
            <AvField
              type='select'
              name='type'
              label='Type'
              required
              onChange={(e) => {
                onChange(e.target.value, 'type')
              }}
              value={form.type}>
              <option value='todays_date'>Todays Date</option>
              <option value='fixed_numeric_value'>Numeric Value</option>
              <option value='fixed_date_value'>Date</option>
              <option value='dynamic'>Dynamic</option>
    
            </AvField>
          </Col>
          <Col md={4}>
            <AvField
              type='select'
              name='condition'
              label='Condition'
              value={form.condition}
              required
              onChange={(e) => onChange(e.target.value, 'condition')}>
              <option value='equals'>Equals</option>
              {form.type !== 'db' && form.type !== 'dynamic' && (
                <>
                  <option value='greater_than'>Greater Than</option>
                  <option value='less_than'>Less Than</option>
                </>
              )}
            </AvField>
          </Col>
          <Col md={getColumnWidthForNotes(form.type) === 6 ? 6 : 4}>
            <AvField
              type='select'
              name='db_field_name'
              label='DB Field'
              required
              onChange={(e) => onChange(e.target.value, 'db_field_name')}
              value={form?.db_field_name}>
              <option>Select field</option>
              {db_option.map((item, key) => (
                <option value={source_table + '.' + item?.field_id} key={key}>
                  {cms(item?.cms_key)}
                </option>
              ))}
            </AvField>
          </Col> */}
          <Col md={6}>
            <AvField type='text' name='note' label='Note' onChange={(e) => onChange(e.target.value, 'note')} value={form?.note} />
          </Col>
          {/* {form.type === 'dynamic' && (
            <Col md={4}>
              <AvField
                type='text'
                name='dynamic_value'
                label='Value'
                onChange={(e) => onChange(e.target.value, 'dynamic_value')}
                value={form?.dynamic_value}
              />
            </Col>
          )}
          {form.type === 'fixed_date_value' && (
            <Col md={4}>
              <Label>Date</Label>
              <DatePicker
                className='form-control'
                dateFormat='d MMMM , yyyy'
                onChange={(e) => onChange(e, 'date_value')}
                selected={new Date(form.date_value)}
              />
            </Col>
          )}
          {form.type === 'fixed_numeric_value' && (
            <Col md={4}>
              <AvField
                type='number'
                name='numeric_value'
                label='Value'
                onChange={(e) => onChange(e.target.value, 'numeric_value')}
                value={form?.numeric_value}
              />
            </Col>
          )} */}

          <Col md={12}>
            <Button onClick={() => handleSubmit()}>Save</Button>
          </Col>
        </Row>
      </AvForm>
    </Fade>
  )
}

const ConditionRow = ({ item, key, dbfields, source_table, content, pageName, grid_id, group_id, parentPageName }) => {
  const dispatch = useDispatch()
  const [editConditionOpen, setEditConditionOpen] = useState(false)
  const [conditionForm, setConditionForm] = useState({
    number: 0,
    type: item?.type,
    condition: item?.condition,
    note: item?.note,
    date_value: item?.date_value,
    dynamic_value: item?.dynamic_value,
    fixed_value: item?.dynamic_value,
    numeric_value: item?.numeric_value,
    db_field_name: item?.db_field_name,
    multiple_values: item?.multiple_values,
    order: item?.order,
  })
  const [whereClauseForm, updateWhereClauseForm] = useState([])

  const onChange = (value, name) => {
    if (name === 'type') {
      setConditionForm({
        ...conditionForm,
        [name]: value,
        fixed_value: '',
        order: 1,
        fixed_date_value: '',
        numeric_value: '',
      })
    } else {
      setConditionForm({ ...conditionForm, [name]: value })
    }
  }

  const handleSubmit = () => {
    dispatch(setLoader(true))
    request('save-grid-color-condition', 'POST', { ...conditionForm, grid_id, group_id, id: item?.id }).then((res) => {
      request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
        let pageData = {}
        pageData[parentPageName] = data

        dispatch(updatePageDataRedux(pageData))
        dispatch(setLoader(false))
      })
    })
    // dispatch(
    //   submitRequest({
    //     url: 'save-grid-color-condition',
    //     data: { ...conditionForm, grid_id, group_id, id: item?.id },
    //   })
    // )
    setEditConditionOpen(false)
  }

  return (
    <>
      <tr key={key}>
        <td onClick={() => setEditConditionOpen(!editConditionOpen)} className='text-align-center'>
          {editConditionOpen ? <IconSet icon='far fa-arrow-down' color='primary' /> : <IconSet icon='far fa-arrow-right' color='primary' />}
        </td>
        <td style={{ width: '3rem' }}>
          <Button
            className='btn-danger'
            onClick={() => {
              dispatch(setLoader(true))
              request('remove-grid-color/condition', 'POST', { id: item.id }).then((res) => {
                request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
                  let pageData = {}
                  pageData[parentPageName] = data

                  dispatch(updatePageDataRedux(pageData))
                  dispatch(setLoader(false))
                })
              })
              // dispatch(
              //   submitRequest({
              //     url: 'remove-grid-color/condition',
              //     data: { id: item.id },
              //   })
              // )
            }}>
            <IconSet icon='pe-7s-trash text-white' />
          </Button>
        </td>
        <td>{tidyName(item.condition)}</td>
        <td>{tidyName(item.type)}</td>
        <td>{tidyName(item.db_field_name)}</td>
        <td>
          {item.type === 'todays_date' || item.type === 'fixed_date_value'
            ? item.date_value?.length > 0
              ? moment(item.date_value).format('D MMM YYYY h:mm a')
              : ''
            : item?.type === 'select_multiple_values'
            ? item?.multiple_values?.join(', ')
            : item.numeric_value || item.dynamic_value}
        </td>
      </tr>
      {editConditionOpen && (
        <tr style={{ background: 'none' }}>
          <td colSpan={6}>
            <AddConditionForm
              toggle={editConditionOpen}
              form={conditionForm}
              onChange={onChange}
              handleSubmit={handleSubmit}
              dbfields={dbfields}
              source_table={source_table}
              content={content}
              pageName={pageName}
              updateWhereClauseForm={updateWhereClauseForm}
            />
          </td>
        </tr>
      )}
    </>
  )
}

const ConditionTable = ({ conditions, source_table, grid_id, group_id, content, pageName, isFilter, parentPageName }) => {
  const [toggle, setToggle] = useState(false)
  const dispatch = useDispatch()
  const initialFormData = {
    number: 0,
    type: 'todays_date',
    condition: 'equals',
    note: '',
    date_value: '',
    fixed_value: '',
    numeric_value: '',
    order: '1',
  }
  const [form, setForm] = useState(initialFormData)

  const dbfields = findColumns(source_table)

  useEffect(() => {
    setForm({ ...form, db_field_name: `${source_table}.${dbfields[0]}` })
  }, [])

  const onChange = (value, name) => {
    if (name === 'type') {
      setForm({
        ...form,
        [name]: value,
        fixed_value: '',
        order: 1,
        fixed_date_value: '',
        numeric_value: '',
      })
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  const handleSubmit = () => {
    dispatch(setLoader(true))
    request('save-grid-color-condition', 'POST', { ...form, grid_id, group_id }).then((res) => {
      request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
        let pageData = {}
        pageData[parentPageName] = data

        dispatch(updatePageDataRedux(pageData))
        dispatch(setLoader(false))
      })
    })
    // dispatch(
    //   submitRequest({
    //     url: 'save-grid-color-condition',
    //     data: { ...form, grid_id, group_id },
    //   })
    // )
    setForm(initialFormData)
    setToggle(false)
  }

  return (
    <tr style={{ background: 'transparent' }}>
      <td colSpan={isFilter ? 6 : 5}>
        <Button color={toggle ? 'danger' : 'success'} onClick={() => setToggle(!toggle)} className='btn-info m-1 float-right'>
          <IconSet icon={toggle ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
        </Button>
        {toggle && (
          <AddConditionForm
            toggle={toggle}
            form={form}
            onChange={onChange}
            handleSubmit={handleSubmit}
            dbfields={dbfields}
            source_table={source_table}
            content={content}
            pageName={pageName}
          />
        )}
        <table style={{ color: '#000' }}>
          <thead>
            <tr>
              <th style={{ width: '3rem' }}></th>
              <th></th>
              <th>Condition</th>
              <th>Type</th>
              <th>DB field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {conditions?.map((item, key) => {
              return (
                <ConditionRow
                  item={item}
                  key={key}
                  dbfields={dbfields}
                  source_table={source_table}
                  content={content}
                  pageName={pageName}
                  grid_id={grid_id}
                  group_id={group_id}
                  parentPageName={parentPageName}
                />
              )
            })}
          </tbody>
        </table>
      </td>
    </tr>
  )
}

const RenderRow = ({ row, source_table, grid_id, content, pageName, isFilter, parentPageName }) => {
  const gridColors = useSelector((state) => state.CMS.coreData.grid_colors)
  const dispatch = useDispatch()
  const [isExpand, setIsExpand] = useState(false)

  return (
    <>
      <tr className={`bg-${row?.color}`}>
        <td onClick={() => setIsExpand(!isExpand)} className='text-align-center'>
          {isExpand ? <IconSet icon='far fa-arrow-down' color='light' /> : <IconSet icon='far fa-arrow-right' color='light' />}
        </td>
        <td>
          <Button
            className='btn-danger'
            onClick={() => {
              dispatch(setLoader(true))
              const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
              request('remove-grid-color/group', 'POST', { id: row.id }).then((res) => {
                request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
                  let pageData = {}
                  pageData[parentPageName] = data

                  dispatch(updatePageDataRedux(pageData))
                  dispatch(setLoader(false))
                })
              })
              // dispatch(
              //   submitRequest({
              //     url: 'remove-grid-color/group',
              //     data: { id: row.id },
              //   })
              // )
            }}>
            <IconSet icon='pe-7s-trash' color='light' />
          </Button>
          {/* <AvForm style={{ textAlign: 'left', fontSize: 13 }}>
            <Col col='6'>
              <AvField type='select' name='select-multiple' label='Color'>
                {gridColors?.map((item,key) => (
                  <option value={item} key={key}>{item}</option>
                ))}
              </AvField>
            </Col>
          </AvForm> */}
        </td>
        <td>{row?.name}</td>
        <td>{row?.color}</td>
        {isFilter && <td>INCLUDE</td>}
        <td>{row?.note}</td>
      </tr>
      {isExpand && (
        <ConditionTable
          content={content}
          group_id={row.id}
          grid_id={grid_id}
          conditions={row?.conditions}
          source_table={source_table}
          pageName={pageName}
          isFilter={isFilter}
          parentPageName={parentPageName}
        />
      )}
    </>
  )
}
const GridSettingTable = ({ content, source_table, pageName, isFilter = false, parentPageName }) => {
  const table_data = content?.main_grid?.grid_colors

  return (
    <div className='gridSettigTable'>
      <table>
        <thead>
          <tr>
            <th style={{ width: '3rem' }}></th>
            <th style={{ width: '3rem' }}></th>
            <th>Name</th>
            <th>Color</th>
            {isFilter && <th>Type</th>}
            <th>Note</th>
          </tr>
        </thead>
        <tbody style={{ color: '#fff' }}>
          {table_data &&
            table_data?.map((row, key) => {
              return (
                <RenderRow
                  key={key}
                  content={content}
                  row={row}
                  source_table={source_table}
                  grid_id={content?.main_grid?.id}
                  pageName={pageName}
                  isFilter={isFilter}
                  parentPageName={parentPageName}
                />
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default GridSettingTable
