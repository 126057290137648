import React, { Fragment, useState } from 'react'
import cx from 'classnames'
import { connect, useDispatch } from 'react-redux'
import { setGlobalSearchValue, triggerGlobalSearch } from '../../../Redux/Reducers/page'

const select = (state) => ({
  enableSearch: state.Page.enableSearch,
  globalSearchValue: state.Page.globalSearchValue,
})

const SearchBox = ({ enableSearch }) => {
  const dispatch = useDispatch()
  const [activeSearch, updateActiveSearch] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(triggerGlobalSearch(true))
  }

  const handleOnChange = (e) => {
    dispatch(setGlobalSearchValue(e.target.value))
  }

  if (!enableSearch) return null
  return (
    <Fragment>
      <div
        className={cx('search-wrapper', {
          active: activeSearch,
        })}>
        <div className='input-holder'>
          <form onSubmit={handleSubmit}>
            <input type='text' name='search' className='search-input' placeholder='Type to search' onChange={(e) => handleOnChange(e)} />
          </form>
          <button onClick={() => updateActiveSearch(!activeSearch)} className='search-icon'>
            <span />
          </button>
        </div>
        <button onClick={() => updateActiveSearch(!activeSearch)} className='close' />
      </div>
    </Fragment>
  )
}

export default connect(select)(SearchBox)
