import React, { useState } from 'react'
import { Button, Card, CardBody, Table } from 'reactstrap'
import AddCustomColor from './add'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteCustomColor,
  setAuthSubTitleColor,
  setAuthTitleColor,
  setBodyColor,
  setHeaderBackgroundColor,
  setPrimaryColor,
  setTitleAreaColor,
} from '../../../Redux/Reducers/themeOptions'
import ConfirmButton from '../../Common/confirmButton'
import IconSet from '../../icon'

const List = ({ customColors }) => {
  const dispatch = useDispatch()
  const themeOptions = useSelector((state) => state.ThemeOptions)
  const { headerBackgroundColor } = useSelector((state) => state.ThemeOptions)
  const [showEditCard, setShowEditCard] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [selData, setSelData] = useState({})
  const onClickDelete = (uuid) => {
    if (themeOptions.headerBackgroundColor == `uuid-${uuid}`) {
      dispatch(setHeaderBackgroundColor(''))
    }
    if (themeOptions.titleAreaColor == `uuid-${uuid}`) {
      dispatch(setTitleAreaColor(''))
    }
    if (themeOptions.bodyColor == `uuid-${uuid}`) {
      dispatch(setBodyColor(''))
    }
    if (themeOptions.authTitleColor == `uuid-${uuid}`) {
      dispatch(setAuthTitleColor(''))
    }
    if (themeOptions.authSubTitleColor == `uuid-${uuid}`) {
      dispatch(setAuthSubTitleColor(''))
    }
    if (themeOptions.primaryColor == `uuid-${uuid}`) {
      dispatch(setPrimaryColor(''))
    }

    dispatch(deleteCustomColor({ uuid }))
  }
  const toggleShowEditCard = () => {
    setSelectedId('')
    setSelData({})
    setShowEditCard(!showEditCard)
  }
  const onClickEdit = (item) => {
    setSelectedId(item.uuid)
    setSelData(item)
    setShowEditCard(true)
  }
  return (
    <>
      {showEditCard ? (
        <AddCustomColor toggleShowCard={toggleShowEditCard} id={selectedId} data={selData} />
      ) : (
        <Card>
          <CardBody>
            <Table bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Color</th>
                  <th>Text Color</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {customColors.map((item) => (
                  <tr key={item.key}>
                    <td>{item.name}</td>
                    <td>{item.color}</td>
                    <td>{item.is_dark == true ? 'Dark' : 'Light'}</td>
                    <td>{item.status == '1' ? 'Active' : 'Inactive'}</td>
                    <td>
                      <Button className='p-0 mr-1 btn-icon btn-icon-only btn-pill' color='link' onClick={() => onClickEdit(item)}>
                        <i className='far fa-edit btn-icon-wrapper' style={{ fontSize: '14px' }} />
                      </Button>
                      {/* {headerBackgroundColor != `uuid-${item.uuid}` && ( */}
                      {/* <Button className='p-0 btn-icon btn-icon-only btn-pill' color='link' onClick={() => onClickDelete(item.uuid)}>
                        <i className='pe-7s-trash btn-icon-wrapper text-danger' style={{ fontSize: '14px' }} />
                      </Button> */}
                      <ConfirmButton color='link' id={'custom_color'} clickEvent={() => onClickDelete(item.uuid)} style={{ padding: 0 }}>
                        <i className='pe-7s-trash btn-icon-wrapper text-danger' style={{ fontSize: '14px' }} />
                      </ConfirmButton>
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default List
