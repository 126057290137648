import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'

const ListItem = ({ item }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState()

  const onApprove = (item) => {
    setIsLoading(true)
    request(`approve-unlock-item`, 'POST', {
      id: item?.id,
      approve: true,
    }).then((res) => {
      setIsLoading(false)
      if (res.status === 200 || res?.status === 202) {
        dispatch(fetchUpdateApiData())
        dispatch(
          setNotification({
            type: 'success',
            message: 'Item approved successfully',
          })
        )
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: 'Something went wrong!',
          })
        )
      }
    })
    // Handle approve action
    console.log(`Approved: ${item}`)
  }

  const onCancel = (item) => {
    // Handle cancel action
     setIsLoading(true)
     request(`approve-unlock-item`, 'POST', {
       id: item?.id,
       approve: false,
     }).then((res) => {
       setIsLoading(false)
       if (res.status === 200 || res?.status === 202) {
         dispatch(fetchUpdateApiData())
         dispatch(
           setNotification({
             type: 'success',
             message: 'Item approved successfully',
           })
         )
       } else {
         dispatch(
           setNotification({
             type: 'error',
             message: 'Something went wrong!',
           })
         )
       }
     })
  }

  return (
    <li className='list-group-item d-flex justify-content-between align-items-center'>
      <p className='m-0'>
        {item?.user_id_label} has requested to unlock <span className='font-weight-bold'>{item?.item_id_label || item?.item_id}</span>
      </p>

      <div>
        <button className='btn btn-info mr-2' onClick={() => onApprove(item)} disabled={isLoading}>
          Approve
        </button>
        <button className='btn btn-danger' onClick={() => onCancel(item)} disabled={isLoading}>
          Cancel
        </button>
      </div>
    </li>
  )
}

const ListComponent = ({ items }) => {
  return (
    <ul className='list-group'>
      {items?.map((item, index) => (
        <ListItem key={index} item={item} />
      ))}
    </ul>
  )
}

const UnlockItem = () => {
  const items = useSelector((state) => state.CMS.updateApiData?.unlock_items)
  const dispatch = useDispatch()

  return (
    <div className='container p-3'>
      <h5 className='mb-3 font-weight-bold'>Unlock Jobs</h5>

      {items?.length === 0 && <div className='text-center'>No request to unlock jobs.</div>}
      <ListComponent items={items} />
    </div>
  )
}

export default UnlockItem
