import { takeLatest, all, takeLeading } from 'redux-saga/effects'

// handlers
import { handleLogin, handleRegister, handleForgotPassword, handleResetPassword, profile, userTimeout, handleLogout } from './handlers/user'
import { fetchSlides, fetchWelcomImages } from './handlers/slides'
import { showNotification } from './handlers/notification'
import {
  getThemeOptions,
  handleDeleteCustomColor,
  handleSaveCustomColor,
  handleThemeOption,
  shootReduxLoad,
  shootReduxUnload,
} from './handlers/themeOptions'
import {
  fetchCMS,
  handleCMSEditSubmit,
  fetchCoreAPI,
  handleSubmitApi,
  handleSubmitCommonApi,
  handleSubmitApiString,
  callUpdateApi,
  callScheduleApi,
  handleCoreApiUpdate,
  handleConnectApi,
  handleConnectTemplateApi,
  handleFreeApi,
  handleSubmitRequestApi,
  handleTopNavButton,
  handleRemoveTopNavButton,
  handleSubmitPage,
  handleSubmitPageContent,
  handleRemoveItemByName,
  handleRemoveTabByName,
  handleConnectTemplateRestoreApi,
} from './handlers/CMS'

// reducers
import { GET_SLIDES, GET_WELCOME_IMAGES } from '../Reducers/slides'
import {
  FETCH_THEME_OPTIONS,
  SET_ENABLE_BACKGROUND_IMAGE,
  SET_ENABLE_MOBILE_MENU,
  SET_ENABLE_MOBILE_MENU_SMALL,
  SET_ENABLE_FIXED_HEADER,
  SET_ENABLE_HEADER_SHADOW,
  SET_ENABLE_SIDEBAR_SHADOW,
  SET_ENABLE_FIXED_SIDEBAR,
  SET_ENABLE_FIXED_FOOTER,
  SET_ENABLE_PAGETITLE_ICON,
  SET_ENABLE_PAGETITLE_SUBHEADING,
  SET_ENABLE_PAGE_TABS_ALT,
  SET_BACKGROUND_IMAGE,
  SET_BACKGROUND_COLOR,
  SET_COLOR_SCHEME,
  SET_BACKGROUND_IMAGE_OPACITY,
  SET_HEADER_BACKGROUND_COLOR,
  SET_BODY_COLOR,
  SET_TITLE_AREA_COLOR,
  SET_FONT_FAMILY,
  SET_HEADER_FONT_FAMILY,
  SET_SUB_HEADER_FONT_FAMILY,
  SET_LOGO_TEXT_COLOR,
  SET_FORGOT_SUB_TITLE,
  SET_LOGIN_SUB_TITLE,
  SET_FORGOT_TITLE,
  SET_LOGIN_TITLE,
  SET_SLIDER_TITLE,
  SET_SLIDER_SUB_TITLE,
  SET_SIGNUP_SUB_TITLE,
  SET_SIGNUP_TITLE,
  SET_AUTH_SUB_TITLE_COLOR,
  SET_AUTH_TITLE_COLOR,
  SET_PRIMARY_COLOR,
  TOGGLE_SIGNUP_ENALBLE_DISABLE,
  SAVE_CUSTOM_COLOR,
  DELETE_CUSTOM_COLOR,
} from '../Reducers/themeOptions'
import { SET_NOTIFICATION } from '../Reducers/notificationHandling'
import { AUTH_LOGIN, USER_REGISTER, AUTH_LOGOUT, USER_TIMEOUT, POST_FORGOT_PASSWORD, POST_RESET_PASSWORD, FETCH_USER } from '../Reducers/user'
import { REDUX_LOAD, REDUX_UNLOAD } from '../Reducers'
import {
  CALL_CONNECT_API,
  CALL_CONNECT_TEMPLATE_API,
  FETCH_CORE_DATA,
  FETCH_SCHEDULE_API_DATA,
  FETCH_UPDATE_API_DATA,
  GET_CMS,
  SUBMIT_API,
  SUBMIT_API_STRING,
  SUBMIT_CMS_FORM,
  SUBMIT_COMMON_API,
  UPDATE_CORE_DATA,
  CALL_FREE_API,
  SUBMIT_TOP_NAV_BUTTON,
  SUBMIT_PAGE,
  SUBMIT_PAGE_CONTENT,
  CALL_SUBMIT_REQUEST_API,
  REMOVE_ITEM_BY_NAME,
  REMOVE_TAB_BY_NAME,
  CALL_CONNECT_TEMPLATE_RESTORE_API,
} from '../Reducers/CMS'

export default function* rootSaga() {
  yield all([
    getSlides(),
    getWelcomImages(),
    SubmitApiString(),
    fetchCMSData(),
    fetchCoreApi(),
    authLogin(),
    userRegister(),
    postForgotPassword(),
    postResetPassword(),
    handleNotification(),
    userProfile(),
    fetchThemeOptions(),
    updateThemeOption(),
    reduxLoad(),
    reduxUnload(),
    checkUserTimeout(),
    authLogout(),
    editCMSValue(),
    submitCMSApi(),
    submitCommonApi(),
    fetchUpdateApi(),
    fetchScheduleApi(),
    updateCoreApiData(),
    connectApi(),
    connectTemplateApi(),
    connectTemplateRestoreApi(),
    freeApi(),
    submitTopNavButton(),
    submitPage(),
    submitPageContent(),
    removeItemByName(),
    removeTabByName(),
    submitRequestApi(),
    saveCustomColor(),
    deleteCustomColor(),
  ])
}

export function* getSlides() {
  yield takeLatest(GET_SLIDES, fetchSlides)
}

export function* getWelcomImages() {
  yield takeLatest(GET_WELCOME_IMAGES, fetchWelcomImages)
}

export function* fetchCMSData() {
  yield takeLatest(GET_CMS, fetchCMS)
}

export function* authLogin() {
  yield takeLatest(AUTH_LOGIN, handleLogin)
}

export function* userRegister() {
  yield takeLatest(USER_REGISTER, handleRegister)
}

export function* postForgotPassword() {
  yield takeLatest(POST_FORGOT_PASSWORD, handleForgotPassword)
}

export function* postResetPassword() {
  yield takeLatest(POST_RESET_PASSWORD, handleResetPassword)
}

export function* authLogout() {
  yield takeLatest(AUTH_LOGOUT, handleLogout)
}

export function* handleNotification() {
  yield takeLatest(SET_NOTIFICATION, showNotification)
}

export function* userProfile() {
  yield takeLeading(FETCH_USER, profile)
}

export function* fetchThemeOptions() {
  yield takeLeading(FETCH_THEME_OPTIONS, getThemeOptions)
}

export function* fetchCoreApi() {
  yield takeLeading(FETCH_CORE_DATA, fetchCoreAPI)
}

export function* fetchUpdateApi() {
  yield takeLeading(FETCH_UPDATE_API_DATA, callUpdateApi)
}

export function* fetchScheduleApi() {
  yield takeLeading(FETCH_SCHEDULE_API_DATA, callScheduleApi)
}

export function* reduxLoad() {
  yield takeLatest(REDUX_LOAD, shootReduxLoad)
}

export function* reduxUnload() {
  yield takeLeading(REDUX_UNLOAD, shootReduxUnload)
}

export function* checkUserTimeout() {
  yield takeLeading(USER_TIMEOUT, userTimeout)
}

export function* updateThemeOption() {
  yield takeLatest(
    [
      SET_ENABLE_BACKGROUND_IMAGE,
      SET_ENABLE_MOBILE_MENU,
      SET_ENABLE_MOBILE_MENU_SMALL,
      SET_ENABLE_FIXED_HEADER,
      SET_ENABLE_HEADER_SHADOW,
      SET_ENABLE_SIDEBAR_SHADOW,
      SET_ENABLE_FIXED_SIDEBAR,
      SET_ENABLE_FIXED_FOOTER,
      SET_ENABLE_PAGETITLE_ICON,
      SET_ENABLE_PAGE_TABS_ALT,
      SET_ENABLE_PAGETITLE_SUBHEADING,
      SET_BACKGROUND_IMAGE,
      SET_BACKGROUND_COLOR,
      SET_COLOR_SCHEME,
      SET_BACKGROUND_IMAGE_OPACITY,
      SET_HEADER_BACKGROUND_COLOR,
      SET_BODY_COLOR,
      SET_TITLE_AREA_COLOR,
      SET_FONT_FAMILY,
      SET_HEADER_FONT_FAMILY,
      SET_SUB_HEADER_FONT_FAMILY,
      SET_FORGOT_SUB_TITLE,
      SET_LOGIN_SUB_TITLE,
      SET_FORGOT_TITLE,
      SET_LOGIN_TITLE,
      SET_SLIDER_TITLE,
      SET_SLIDER_SUB_TITLE,
      SET_SIGNUP_SUB_TITLE,
      SET_SIGNUP_TITLE,
      SET_LOGO_TEXT_COLOR,
      SET_AUTH_SUB_TITLE_COLOR,
      SET_AUTH_TITLE_COLOR,
      SET_PRIMARY_COLOR,
      TOGGLE_SIGNUP_ENALBLE_DISABLE,
    ],
    handleThemeOption
  )
}

export function* editCMSValue() {
  yield takeLatest(SUBMIT_CMS_FORM, handleCMSEditSubmit)
}

export function* submitCMSApi(actions) {
  yield takeLatest(SUBMIT_API, handleSubmitApi)
}

export function* updateCoreApiData() {
  yield takeLatest(UPDATE_CORE_DATA, handleCoreApiUpdate)
}

export function* submitCommonApi() {
  yield takeLatest(SUBMIT_COMMON_API, handleSubmitCommonApi)
}

export function* SubmitApiString() {
  yield takeLatest(SUBMIT_API_STRING, handleSubmitApiString)
}

export function* connectApi() {
  yield takeLeading(CALL_CONNECT_API, handleConnectApi)
}
export function* connectTemplateApi() {
  yield takeLeading(CALL_CONNECT_TEMPLATE_API, handleConnectTemplateApi)
}

export function* connectTemplateRestoreApi() {
  yield takeLeading(CALL_CONNECT_TEMPLATE_RESTORE_API, handleConnectTemplateRestoreApi)
}

export function* freeApi() {
  yield takeLatest(CALL_FREE_API, handleFreeApi)
}

export function* submitRequestApi() {
  yield takeLatest(CALL_SUBMIT_REQUEST_API, handleSubmitRequestApi)
}

export function* submitTopNavButton() {
  yield takeLatest(SUBMIT_TOP_NAV_BUTTON, handleTopNavButton)
}

export function* submitPage() {
  yield takeLatest(SUBMIT_PAGE, handleSubmitPage)
}

export function* submitPageContent() {
  yield takeLatest(SUBMIT_PAGE_CONTENT, handleSubmitPageContent)
}

export function* removeItemByName() {
  yield takeLatest(REMOVE_ITEM_BY_NAME, handleRemoveItemByName)
}

export function* removeTabByName() {
  yield takeLatest(REMOVE_TAB_BY_NAME, handleRemoveTabByName)
}

export function* saveCustomColor() {
  yield takeLatest(SAVE_CUSTOM_COLOR, handleSaveCustomColor)
}

export function* deleteCustomColor() {
  yield takeLatest(DELETE_CUSTOM_COLOR, handleDeleteCustomColor)
}
