import React from 'react'
import { connect } from 'react-redux'

import HeaderNavButton from './navHeaderButton'
import { getFromRedux } from '../../helper'
import RemoveTopNavButton from './removeTopNavButton'
import { PROFILE_MODAL_DEFAULT_DATA } from './profileModalData'

const select = (state) => ({
  CMS: state.CMS,
  coreData: state.CMS.coreData,
  editPageMode: state.CMS.editPageMode,
  page: state.CMS.coreData.page,
})

const NavHeaderBtn = (props) => {
  const { navHeader, CMS, editPageMode, coreData } = props

  let isProfileNavAvailable = false
  navHeader?.forEach((nav) => {
    if (nav?.name === 'profile_form') {
      isProfileNavAvailable = true
    }
  })


  return (
    <>
      {navHeader.map((nav, key) => {
        // if (!nav.allowed) return null
        return (
          <div key={key} className={nav.break_left ? 'header-btn-lg pr-0' : ''}>
            <div className='widget-content p-0'>
              <div className='widget-content-wrapper'>
                <div className='widget-content-left'>
                  <HeaderNavButton nav={nav} coreData={coreData} />
                  {editPageMode && <RemoveTopNavButton nav={nav} />}
                </div>
                {nav.component_call_data_connection && nav.text_right_header ? (
                  <div className='widget-content-left  ml-3 header-user-info'>
                    <div className='widget-heading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_header)}</div>
                    <div className='widget-subheading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_sub_header)}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
      })}
      {!isProfileNavAvailable && (
        <div key={'profile'} className={PROFILE_MODAL_DEFAULT_DATA.break_left ? 'header-btn-lg pr-0' : ''}>
          <div className='widget-content p-0'>
            <div className='widget-content-wrapper'>
              <div className='widget-content-left'>
                <HeaderNavButton nav={PROFILE_MODAL_DEFAULT_DATA} coreData={coreData} isProfileDefaultModal/>
              
              </div>
              {/* {nav.component_call_data_connection && nav.text_right_header ? (
                <div className='widget-content-left  ml-3 header-user-info'>
                  <div className='widget-heading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_header)}</div>
                  <div className='widget-subheading'>{getFromRedux(nav.component_call_data_connection.name + '.' + nav.text_right_sub_header)}</div>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default connect(select)(NavHeaderBtn)
