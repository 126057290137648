import { connect } from 'react-redux'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, Row } from 'reactstrap'
import { useState } from 'react'
import Loader from 'react-loaders'

import CreateAdvanceGrid from '../../Grid/createAdvanceGrid'
import { tidyName } from '../../../../helper'

const select = (state) => ({
  grid_selector: state.CMS.coreData.grid_selector,
})

const actionTypes = ['ADD', 'EDIT', 'DELETE', 'RESTORE', 'FILTER', 'SEARCH', 'EXPORT', 'Preferences', 'Mail']

const PageWithGridForm = ({ handleValidSubmit, updateColumn, updateTab, updateForm, form, load, grid_selector, pageName, updateWhereClauseForm }) => {
  const [defaultHeader, setDefaultHeader] = useState(tidyName(pageName))

  return (
    <AvForm onValidSubmit={handleValidSubmit}>
      <Row>
        <Col md={12}>
          <hr />
          Add details for "Page Route" by filling the below fields.
          <hr />
        </Col>
        <Col md={4}>
          <AvField name='icon' label='Icon' type='text' />
        </Col>
        <Col md={4}>
          <AvField
            name='default_header_name'
            label='Default Header Name'
            type='text'
            value={defaultHeader}
            onChange={(e) => setDefaultHeader(e.target.value)}
          />
        </Col>
        <Col md={4}>
          <AvField name='default_sub_header_name' label='Default Sub Header Name' type='text' />
        </Col>
        <Col md={12}>
          <hr></hr>
        </Col>
        <CreateAdvanceGrid
          updateColumn={updateColumn}
          updateTab={updateTab}
          grid_selector={grid_selector}
          form={form}
          updateForm={updateForm}
          actionTypes={actionTypes}
          updateWhereClauseForm={updateWhereClauseForm}
        />
        <Col md={12}>
          <Button
            color='primary'
            style={{
              width: 90,
              height: 38,
              fontSize: '15px',
            }}>
            {load ? <Loader type='ball-beat' style={{ transform: 'scale(0.5)' }} /> : 'Submit'}
          </Button>
        </Col>
      </Row>
    </AvForm>
  )
}

export default connect(select)(PageWithGridForm)
