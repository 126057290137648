import React from 'react'
import { Col } from 'reactstrap'
import { templateType } from '../../../../../helper'

const renderField = (data, key) => {
  switch (data.field) {
    default:
      return (
        <Col md={6} key={key}>
          <div>
            <div style={{ fontSize: '1.1rem' }}>{data.title}</div>
            <div style={{ opacity: '0.8', fontStyle: 'italic' }}>{templateType(data.type)}</div>
          </div>
          <hr></hr>
        </Col>
      )
  }
}

const Templateform = ({ data }) => {
  return (
    <>
      {data?.map((item, key) => {
        return (
          <React.Fragment key={key}>
            <Col md={12}>
              <div
                style={{
                  fontSize: '1.2rem',
                  margin: '0.5rem 0',
                  fontWeight: 'bold',
                }}>
                {item?.header ? item?.header : data.title}
              </div>
            </Col>

            {item?.fields?.map((data, key) => {
              return renderField(data, key)
            })}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Templateform
