import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, ListGroupItem, Row } from 'reactstrap'
import IconSet from '../../../../../CMSComponent/icon'
import { cms, tidyName } from '../../../../../helper'
import { updatePageDataRedux } from '../../../../../Redux/Reducers/CMS'
import { setNotification } from '../../../../../Redux/Reducers/notificationHandling'
import { request } from '../../../../../Redux/Sagas/requests/api'

const dashboardItems = [
  {
    title: 'Completed By Task Type',
    value: 'completed_by_task_type',
  },
  {
    title: 'Average Response Time By Week',
    value: 'ar_time_by_week',
  },
  {
    title: 'Tag/Questions Jobs',
    value: 'tag_question_jobs',
  },
  {
    title: 'Average Response Time By Months',
    value: 'ar_time_by_months',
  },
  {
    title: 'Average Response Time By Daily',
    value: 'ar_time_by_daily',
  },
]
const DashboardLayout = ({ isCreatingMainPage, onCloseModal = () => {}, currentNavData, initialRows, isUpdatingLayout, page }) => {
  const dashboardContent = useSelector((state) => state.CMS.dashboardCMSKey)
  const [rows, setRow] = useState([])
  const [defaultHeader, setDefaultHeader] = useState()
  let pageName = currentNavData?.page || page?.name

  const dispatch = useDispatch()

  useEffect(() => {
    if (isUpdatingLayout) {
      setRow(initialRows)
    }
  }, [])

  const onSaveLayout = (layout) => {
    if (isCreatingMainPage && (!defaultHeader || defaultHeader === '')) {
      dispatch(setNotification({ type: 'error', message: 'Please add default header' }))
      return
    }
    let payload = {}

    if (isCreatingMainPage) {
      payload['default_header_name'] = defaultHeader
    }
    if (isUpdatingLayout) {
      payload['page_name'] = page?.name
    }
    payload['common_setting'] = layout || rows
    request('create-dashboard-page', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        if (!layout) {
          onCloseModal()
        }

        request(`get-page/${pageName}`).then((data) => {
          let pageData = {}
          if (data.status === 200 || data?.status === 202) {
            pageData[pageName] = { ...data?.data, isDashboardPage: true }
            dispatch(updatePageDataRedux(pageData))
          }
        })
        // request(`get-page/${item?.page}`, 'GET').then()
      } else {
        dispatch(setNotification({ type: 'error', message: res.message }))
      }
    })
  }

  //refs for ordering field and layout
  const dragBlockItem = useRef()
  const dragOverBlockItem = useRef()
  const [dragOverBlock, setDragOverBlock] = useState()

  const onBlockDragStart = (e) => {
    const type = e.target.getAttribute('data-drag-type')

    dragBlockItem.current = e.target
  }

  const onDragOverBlockItem = (e) => {
    e.preventDefault()
    const type = dragBlockItem.current.getAttribute('data-drag-type')

    //destination
    const row_index = e.target.getAttribute('data-row-index')
    const column_index = e.target.getAttribute('data-column-index')

    if (!column_index) return

    // source
    const dragged_block_row_index = dragBlockItem.current.getAttribute('data-row-index')
    const dragged_block_column_index = dragBlockItem.current.getAttribute('data-column-index')

    //check if field order in same block
    if (parseInt(row_index) === parseInt(dragged_block_row_index) && parseInt(column_index) === parseInt(dragged_block_column_index)) {
      dragOverBlockItem.current = null
      setDragOverBlock()
      return
    }
    dragOverBlockItem.current = e.target
    setDragOverBlock({
      row_index: parseInt(row_index),
      block_index: parseInt(column_index),
    })
  }

  const onBlockDragComplete = (rowIndex, blockIndex) => {
    const type = dragBlockItem.current?.getAttribute('data-drag-type')

    const dragged_block_field_index = dragFieldItem?.current?.getAttribute('data-column-index')

    setDragOverBlock()
    if (!dragOverBlockItem.current) return
    const row_index = dragOverBlockItem.current.getAttribute('data-row-index')
    const column_index = dragOverBlockItem.current.getAttribute('data-column-index')

    if (type === 'field') {
      const field_id = dragBlockItem.current.getAttribute('field_id')
      const new_rows = rows?.map((row, index) => {
        if (index === parseInt(row_index)) {
          row['column'][parseInt(column_index)]['items'] = [...row['column'][parseInt(column_index)]['items'], field_id]
          const new_column = []
          row['column'][parseInt(dragged_block_field_index)]['items']?.forEach((item) => {
            if (item !== field_id) {
              new_column?.push(item)
            }
          })
          row['column'][parseInt(dragged_block_field_index)]['items'] = new_column
          return row
        } else {
          return row
        }
      })
      setRow(new_rows)
      onSaveLayout(new_rows)
    } else if (type === 'block') {
      {
        const new_r = []
        rows?.map((r, i) => {
          if (i !== parseInt(row_index)) {
            return new_r?.push(r)
          } else {
            let new_row = {
              column: [],
            }

            //logic to change block index
            const new_column = r.column && [...r.column]
            const source = parseInt(dragBlockItem.current.getAttribute('data-column-index'))
            const dest = parseInt(column_index)
            const element1 = new_column[source]
            const element2 = new_column[dest]
            new_column.splice(source, 1, element2)
            new_column.splice(dest, 1, element1)

            new_row['column'] = new_column
            new_r?.push(new_row)
          }
        })

        setRow(new_r)
        onSaveLayout(new_r)
      }
    }
    // onSaveLayout(true, type, updated_fields_payload)
  }

  const dragFieldItem = useRef()
  const dragOverFieldItem = useRef()
  const [dragOverField, setDragOverField] = useState()

  const onFieldDragStart = (e) => {
    dragFieldItem.current = e.target

    // e.preventDefault()
    // e.stopPropagation()
  }

  const onFieldDragOver = (e) => {
    if (dragFieldItem.current?.getAttribute('field_id') === e.target.getAttribute('field_id') && dragFieldItem.current.getAttribute('field_id'))
      return
    dragOverFieldItem.current = e.target

    const draggedFieldRowIndex = parseInt(dragFieldItem.current.getAttribute('data-row-index'))
    const draggedFieldColumnIndex = parseInt(dragFieldItem.current.getAttribute('data-column-index'))

    const dragOverFieldRowIndex = parseInt(e.target.getAttribute('data-row-index'))
    const dragOverFieldColumnIndex = parseInt(e.target.getAttribute('data-column-index'))
    const dragOverFieldId = e.target.getAttribute('field_id')

    if (dragOverFieldColumnIndex === draggedFieldColumnIndex && dragOverFieldRowIndex === draggedFieldRowIndex) {
      setDragOverField({
        row_index: dragOverFieldRowIndex,
        column_index: dragOverFieldColumnIndex,
        field_id: dragOverFieldId,
      })
    }
  }

  const onFieldDragEnd = (e) => {
    if (!dragFieldItem.current || !dragOverFieldItem.current) return

    const draggedFieldRowIndex = parseInt(dragFieldItem.current.getAttribute('data-row-index'))
    const draggedFieldColumnIndex = parseInt(dragFieldItem.current.getAttribute('data-column-index'))
    const field_id = dragFieldItem.current.getAttribute('field_id')

    const dragOverFieldRowIndex = parseInt(dragOverFieldItem.current.getAttribute('data-row-index'))
    const dragOverFieldColumnIndex = parseInt(dragOverFieldItem.current.getAttribute('data-column-index'))
    const dragOverFieldId = dragOverFieldItem.current.getAttribute('field_id')

    if (dragOverFieldColumnIndex === draggedFieldColumnIndex && dragOverFieldRowIndex === draggedFieldRowIndex) {
      const new_rows = rows?.map((row, index) => {
        if (index === draggedFieldRowIndex) {
          let new_row = {
            ...row,
          }
          let new_columns = []
          row['column'][parseInt(dragOverFieldColumnIndex)]['items']?.map((item) => {
            if (item !== dragOverFieldId && item !== field_id) {
              new_columns?.push(item)
            } else if (item === dragOverFieldId) {
              new_columns?.push(field_id)
              new_columns?.push(item)
            }
          })

          row['column'][parseInt(dragOverFieldColumnIndex)]['items'] = new_columns
          return row
          // row['column'][parseInt(column_index)]['items']
        } else {
          return row
        }
      })
      setRow(new_rows)
      onSaveLayout(new_rows)
    }
    setDragOverField()
  }
  return (
    <div style={{ margin: '1rem' }}>
      {isCreatingMainPage && (
        <Row>
          <Col md={6}>
            <AvForm>
              <AvField
                type='text'
                name='default_header'
                label='Default Header'
                value={defaultHeader}
                onChange={(e) => setDefaultHeader(e.target.value)}></AvField>
            </AvForm>
          </Col>
        </Row>
      )}

      {rows?.map((row, index) => (
        <RenderRow
          key={index}
          row={row}
          rowIndex={index}
          rows={rows}
          setRows={setRow}
          dashboardContent={dashboardContent}
          onBlockDragStart={onBlockDragStart}
          onDragOverBlockItem={onDragOverBlockItem}
          onBlockDragComplete={onBlockDragComplete}
          dragOverBlock={dragOverBlock}
          onFieldDragStart={onFieldDragStart}
          onFieldDragOver={onFieldDragOver}
          onFieldDragEnd={onFieldDragEnd}
          dragOverFieldItem={dragOverField}
        />
      ))}
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <span
          style={{ border: '1px dashed #788f7f', color: '#788f7f', padding: '0.3rem 0.8rem', borderRadius: '5px', cursor: 'pointer' }}
          onClick={() => setRow([...rows, { column: [] }])}>
          Add Row
        </span>
      </div>
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <Button color='primary' className='float-right' onClick={() => onSaveLayout()}>
          Save Layout
        </Button>
      </div>
    </div>
  )
}

const RenderRow = ({
  row,
  rowIndex,
  rows,
  setRows,
  dashboardContent,
  onBlockDragStart,
  onDragOverBlockItem,
  onBlockDragComplete,
  dragOverBlock,
  onFieldDragStart,
  onFieldDragOver,
  onFieldDragEnd,
  dragOverFieldItem,
}) => {
  const [isAddBlockFormOpen, setIsAddBlockFormOpen] = useState(false)
  const [colWidth, setColWidth] = useState(6)

  const onAddBlock = () => {
    let newRows = [...rows]

    newRows[rowIndex] = { column: [...newRows[rowIndex]['column'], { width: colWidth, items: [] }] }
    setRows(newRows)
    setColWidth(6)
    setIsAddBlockFormOpen(false)
  }

  return (
    <Row className='layout-setting-row'>
      {row?.column?.map((item, index) => {
        return (
          <RenderBlock
            key={rowIndex + index}
            column={item}
            rowIndex={rowIndex}
            blockIndex={index}
            rows={rows}
            setRows={setRows}
            dashboardContent={dashboardContent}
            onBlockDragStart={onBlockDragStart}
            onDragOverBlockItem={onDragOverBlockItem}
            onBlockDragComplete={onBlockDragComplete}
            dragOverBlock={dragOverBlock}
            onFieldDragStart={onFieldDragStart}
            onFieldDragOver={onFieldDragOver}
            onFieldDragEnd={onFieldDragEnd}
            dragOverFieldItem={dragOverFieldItem}
          />
        )
      })}
      {isAddBlockFormOpen && (
        <Col md={12}>
          <AvForm>
            <Row style={{ marginTop: '1rem', border: '1px dashed', borderRadius: '5px', paddingTop: '1rem' }}>
              <Col md={6}>
                <AvField type='select' name='width' value={colWidth} onChange={(e) => setColWidth(parseInt(e.target.value))}>
                  <option value=''>Select width</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]?.map((item, i) => {
                    return (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </AvField>
              </Col>
              <Col md={6}>
                <Button onClick={() => onAddBlock()}>Add</Button>
              </Col>
            </Row>
          </AvForm>
        </Col>
      )}
      <Col md={12} style={{ margin: '1rem' }}>
        <div style={{ textAlign: 'center' }}>
          <span
            style={{
              border: '1px dashed #788f7f',
              color: '#788f7f',
              padding: '0.3rem 0.8rem',
              borderRadius: '5px',
              cursor: 'pointer',
              marginLeft: '-2rem',
            }}
            onClick={() => setIsAddBlockFormOpen(true)}>
            Add Block
          </span>
        </div>
      </Col>
    </Row>
  )
}

const RenderBlock = ({
  column,
  rowIndex,
  blockIndex,
  setRows,
  rows,
  dashboardContent,
  onBlockDragStart,
  onDragOverBlockItem,
  onBlockDragComplete,
  dragOverBlock,
  onFieldDragStart,
  onFieldDragOver,
  onFieldDragEnd,
  dragOverFieldItem,
}) => {
  const [selectedItem, setSelectedItem] = useState()
  const [addItemOpen, setAddItemOpen] = useState(false)
  const onAddItem = () => {
    const newRows = [...rows]
    let updatedColumn = newRows[rowIndex]?.['column']?.map((col, index) => {
      if (index === blockIndex) {
        return {
          ...col,
          items: [...col?.items, selectedItem],
        }
      } else {
        return {
          ...col,
        }
      }
    })
    newRows[rowIndex]['column'] = updatedColumn

    setRows(newRows)
    setAddItemOpen(false)
    setSelectedItem()
  }

  const onRemoveItem = (itemIndex) => {
    const newRows = [...rows]
    let items = newRows[rowIndex]?.['column']?.[blockIndex]?.['items']
    items?.splice(itemIndex, 1)
    let updatedColumn = newRows[rowIndex]?.['column']?.map((col, index) => {
      if (index === blockIndex) {
        return {
          ...col,
          items: items,
        }
      } else {
        return {
          ...col,
        }
      }
    })
    newRows[rowIndex]['column'] = updatedColumn

    setRows(newRows)
    setAddItemOpen(false)
    setSelectedItem()
  }

  let isDraggingOnBlock = false

  if (dragOverBlock?.row_index === rowIndex && dragOverBlock?.block_index === blockIndex) {
    isDraggingOnBlock = true
  }
  return (
    <Col
      md={column?.width}
      className='layout-setting-block m-0'
      data-drag-type='block'
      data-row-index={rowIndex}
      data-column-index={blockIndex}
      draggable
      onDragStart={(e) => onBlockDragStart(e)}
      onDragEnd={() => onBlockDragComplete(rowIndex, blockIndex)}
      onDragEnter={(e) => onDragOverBlockItem(e)}
      style={{ border: isDraggingOnBlock && '1px solid red' }}>
      {addItemOpen && (
        <AvForm style={{ display: 'flex', alignItems: 'center' }}>
          <AvField type='select' name='width' onChange={(e) => setSelectedItem(e.target.value)} value={selectedItem}>
            <option value=''>Select Field</option>
            {dashboardContent &&
              Object.keys(dashboardContent || {})?.map((key) => {
                return (
                  <option value={key} key={key}>
                    {cms(dashboardContent[key]?.cms_key_heading)}
                  </option>
                )
              })}
            {/* {dashboardItems?.map((item) => {
              return <option value={item?.value}>{item?.title}</option>
            })} */}
          </AvField>
          <Button color='primary' style={{ marginBottom: '1rem', marginLeft: '1rem' }} onClick={() => onAddItem()}>
            Add
          </Button>
        </AvForm>
      )}
      {column?.items?.map((item, index) => {
        let isDraggingOnField = false
        if (dragOverFieldItem && dragOverFieldItem?.field_id === item) {
          isDraggingOnField = true
        }

        return (
          <ListGroupItem
            key={index}
            style={{
              marginBottom: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: isDraggingOnField && '1px solid red',
            }}
            draggable
            data-drag-type='field'
            data-row-index={rowIndex}
            data-column-index={blockIndex}
            field_id={item}
            onDragStart={(e) => onFieldDragStart(e)}
            onDragOver={(e) => onFieldDragOver(e)}
            onDragEnd={() => onFieldDragEnd()}>
            {tidyName(item)}{' '}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => onRemoveItem(index)}
              data-drag-type='field'
              data-row-index={rowIndex}
              data-column-index={blockIndex}
              field_id={item}>
              <IconSet icon={'fa-solid fa-xmark'} color='danger' />
            </span>
          </ListGroupItem>
        )
      })}

      <div style={{ textAlign: 'center' }}>
        <span
          style={{ color: '#788f7f', padding: '0.3rem 0.8rem', borderRadius: '5px', cursor: 'pointer' }}
          onClick={() => {
            setAddItemOpen(true)
          }}>
          {'Add field'}
          {/* in Block {index + 1} */}
        </span>
      </div>
    </Col>
  )
}

export default DashboardLayout
