import { useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, CardTitle, CardHeader, CardFooter, Container } from 'reactstrap'
import { cms, editCMS, tidyName } from '../../../helper'
import { checkSingleCondition } from './toggle'

const SectionHeading = ({ data, formFieldValue, submittedFormData }) => {
  const editMode = useSelector((state) => state.CMS.editMode)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const currentFormData = useSelector((state) => state.CMS.coreData.currentFormData)

  const editCMSKey = (key) => {
    if (editMode) {
      editCMS(key)
    }
  }

  let isFieldVisible = true
  if (
    data?.field_setting?.depend_fields &&
    data?.field_setting?.depend_fields?.length > 0 &&
    ((currentRow && currentRow[currentRow?.length - 1]) || currentFormData)
  ) {
    const dependsData = data?.field_setting?.depend_fields

    const condition_array = dependsData?.map((dependForm) => {
      if (
        dependForm?.dependent_field === 'view_on' &&
        Object.keys(formFieldValue || {})?.includes('item_type_id') &&
        submittedFormData?.['view_on']
      ) {
        return false
      } else if (formFieldValue && Object.keys(formFieldValue)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, formFieldValue[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else if (submittedFormData && Object.keys(submittedFormData)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, submittedFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else if (currentFormData && Object.keys(currentFormData)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, currentFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else {
        if (currentRow && currentRow[currentRow?.length - 1]) {
          return checkSingleCondition(
            dependForm?.condition,
            currentRow[currentRow?.length - 1][dependForm?.dependent_field],
            parseInt(dependForm?.value)
          )
        }
      }
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (!isFieldVisible) return

  return (
    <Card className='mb-3' body inverse color={data?.background_color} style={{ padding: '0.5rem' }}>
      <div
        className='text-white'
        style={{ cursor: editMode ? 'pointer' : '' }}
        onClick={() => {
          editCMSKey(data?.cms_key_heading)
        }}>
        {cms(data?.cms_key_heading)}
      </div>
      <span
        style={{ cursor: editMode ? 'pointer' : '' }}
        onClick={() => {
          editCMSKey(data?.cms_key_sub_heading)
        }}>
        {cms(data?.cms_key_sub_heading)}
      </span>
    </Card>
  )
}

export default SectionHeading
