import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import { Button, Col } from 'reactstrap'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { request } from '../../../../Redux/Sagas/requests/api'

const ImportTemplate = ({ importedTemplates, setImportedTemplates, setImportTemplateModalOpen }) => {
  const [locations, setLocations] = useState()
  const [locationsData, setLocationsData] = useState()
  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedContractor, setSelectedContractor] = useState()

  const [templateGroups, setTemplateGroups] = useState()
  const [selectedTemplateGroup, setSelectedTemplateGroup] = useState(null)
  const [templatesData, setTemplatesData] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    request('get-locations', 'GET').then((data) => {
      if (data?.data?.data) {
        setLocationsData(data?.data?.data)
        let options = data?.data?.data?.map((item) => {
          if (!item?.default_template_group_id) return null
          return {
            label: item?.name,
            value: item?.id,
          }
        })
        let a = options?.filter((item) => item)
        setLocations(a)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedLocation?.value) {
      const location = locationsData?.find((location) => location?.id === parseInt(selectedLocation?.value))
      if (location?.contractors?.length > 0) {
        setSelectedContractor(location?.contractors?.[0])
      }
      request(`get-template-groups?location_id=${selectedLocation?.value}`, 'GET').then((data) => {
        if (data?.data?.data) {
          setTemplateGroups(data?.data?.data)
        }
      })
    }
  }, [selectedLocation?.value])

  const templateGroupsOptions = templateGroups?.map((group) => {
    return {
      value: group?.id,
      label: group?.name,
    }
  })

  const onSelectTemplateGroup = (e) => {
    setTemplatesData(null)
    setSelectedTemplateGroup(e)
    const payload = {
      template_group_id: e?.value,
    }
    if (e.value) {
      request('get-templates', 'POST', payload).then((data) => {
        if (data?.status === 200 || data?.status === 202) {
          setTemplatesData(data?.data)
        } else {
          dispatch(setNotification({ type: 'error', message: 'Error in getting templates' }))
        }
      })
    }
  }

  const onImportData = () => {
    if (templatesData) {
      const { success, ...rest } = templatesData
      let dataToUpdate = rest
      let times = 0
      let templates = rest?.item_groups?.items?.map((item) => {
        if (item?.min_gap) {
          times += item?.min_gap
        }
        const pre_time_to_add = item?.pre_time_mins || 120
        const post_time_to_add = item?.post_time_mins || 120
        const datetime = new Date(new Date().getTime() + parseInt(times) * 60000)

        const pre_time = new Date(new Date(datetime).getTime() - parseInt(pre_time_to_add) * 60000)
        const post_time = new Date(new Date(datetime).getTime() + parseInt(post_time_to_add) * 60000)
        // const pre_time = new Date(new Date().getTime() + times - parseInt(pre_time_to_add) * 60000)
        // const post_time = new Date(new Date().getTime() + times + parseInt(post_time_to_add) * 60000)
        return {
          ...item,
          pre_time: moment(pre_time).format('YYYY-MM-DD HH:mm:ss'),
          post_time: moment(post_time).format('YYYY-MM-DD HH:mm:ss'),
          date_time: moment(datetime).format('YYYY-MM-DD HH:mm:ss'),
          datetime: moment(datetime).format('YYYY-MM-DD HH:mm:ss'),
          // datetime: new Date(),
        }
      })
      dataToUpdate['item_groups'] = {
        ...dataToUpdate['item_groups'],
        location_id: selectedLocation?.value,
        location_id_label: selectedLocation?.label,
      }

      if (selectedContractor) {
        dataToUpdate['item_groups'] = {
          ...dataToUpdate['item_groups'],
          contractor_id: selectedContractor?.id,
          contractor_id_label: selectedContractor?.name,
        }
      }

      dataToUpdate['item_groups']['items'] = templates
      setImportedTemplates(dataToUpdate)
      setImportTemplateModalOpen(false)
    }
  }

  return (
    <div className='mb-4'>
      <div className='mb-2'>
        <lable style={{ marginBottom: '1rem' }}>Select Location</lable>
        <ReactSelect
          options={locations}
          // id={id}
          onChange={(e) => {
            setSelectedLocation(e)
            setSelectedTemplateGroup(null)
          }}
          value={selectedLocation ? selectedLocation : null}
          styles={{
            menu: (base) => ({ ...base, zIndex: 15000 }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: '#ced4da',
            }),
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color: 'hsl(0, 0%, 70%)',
              }
            },
          }}
          placeholder='Select Location'
          className='mt-2'
        />
      </div>
      {selectedLocation?.value && (
        <div>
          <lable style={{ marginBottom: '1rem' }}>Select Template group</lable>
          <ReactSelect
            options={templateGroupsOptions}
            // id={id}
            onChange={(e) => {
              onSelectTemplateGroup(e)
            }}
            value={selectedTemplateGroup ? selectedTemplateGroup : null}
            styles={{
              menu: (base) => ({ ...base, zIndex: 15000 }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: '#ced4da',
              }),
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: 'hsl(0, 0%, 70%)',
                }
              },
            }}
            placeholder='Select template group'
            className='mt-2'
          />
        </div>
      )}

      {templatesData && (
        <Button color='primary' style={{ marginTop: '1rem' }} onClick={() => onImportData()}>
          Import
        </Button>
      )}
    </div>
  )
}

export default ImportTemplate
